@media screen and (min-width: 1400px) {

    #homePageSliderBar {
        position: fixed;
        top: 150px;
        right: 10px;
        width: 52px;
        height: 245px;
        z-index: 101;
        font-family: "Frutiger 57", sans-serif;
    }

    #homePageSliderBar a {
        display: block;
        position: absolute;
        right: 0;
        background-color: #fff;
        border-radius: 35px;
        width: 50px;
        height: 50px;
        border: 1px solid #79a342;
        transition: 0.2s;
        text-decoration: none;
        box-sizing: border-box;
    }

    #homePageSliderBar #chat {
        display:none;
    }

    #homePageSliderBar a:nth-of-type(1) {
        top: 0;
    }

    #homePageSliderBar a:nth-of-type(2) {
        top: 64px;
    }

    /*#homePageSliderBar a:nth-of-type(3) {*/
    /*    top: 128px;*/
    /*}*/

    #homePageSliderBar a:last-of-type {
        top: 128px;
    }

    #homePageSliderBar p {
        top: 14px;
        left: 18px;
        color: #79a342;
        font-size: 20px;
        opacity: 0;
        position: absolute;
    }

    #homePageSliderBar i {
        position: absolute;
        width:25px;
        height:25px;
    }

    #homePageSliderBar a:nth-of-type(1) i {
        right: 9px;
        top:12px;
    }

    #homePageSliderBar a:nth-of-type(2) i {
        top: 12px;
        right: 11px;
    }

    #homePageSliderBar a:nth-of-type(3) i {
        right: 11px;
        top:11px;
    }

    #homePageSliderBar a:nth-of-type(4) i {
        top: 11px;
        right: 11px;
    }

    #homePageSliderBar i svg {
        height: 25px;
        width:25px;
        fill: #79a342
    }

    /* hover */
    #homePageSliderBar a:hover:nth-of-type(1) {
        width: 122px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(2) {
        width: 166px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(3) {
        width: 115px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(4) {
        width: 150px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover p {
        opacity: 1;
        transition: 0.7s;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

    #homePageSliderBar {
        position: fixed;
        top: 150px;
        right: 10px;
        width: 52px;
        height: 245px;
        z-index: 101;
        font-family: "Frutiger 57", sans-serif;
    }

    #homePageSliderBar a {
        display: block;
        position: absolute;
        right: 0;
        background-color: #fff;
        border-radius: 35px;
        width: 50px;
        height: 50px;
        border: 1px solid #79a342;
        transition: 0.2s;
        text-decoration: none;
        box-sizing: border-box;
    }

    #homePageSliderBar #chat {
        display:none;
    }

    #homePageSliderBar a:nth-of-type(1) {
        top: 0;
    }

    #homePageSliderBar a:nth-of-type(2) {
        top: 64px;
    }

    /*#homePageSliderBar a:nth-of-type(3) {*/
    /*    top: 128px;*/
    /*}*/

    #homePageSliderBar a:last-of-type {
        top: 128px;
    }

    #homePageSliderBar p {
        top: 14px;
        left: 18px;
        color: #79a342;
        font-size: 20px;
        opacity: 0;
        position: absolute;
    }

    #homePageSliderBar i {
        color: #79a342;
        position: absolute;
        width:25px;
        height:25px;
    }

    #homePageSliderBar i svg {
        height: 25px;
        width:25px;
        fill: #79a342
    }

    #homePageSliderBar a:nth-of-type(1) i {
        right: 9px;
        top:12px;
    }

    #homePageSliderBar a:nth-of-type(2) i {
        top: 12px;
        right: 11px;
    }

    #homePageSliderBar a:nth-of-type(3) i {
        right: 11px;
        top:11px;
    }

    #homePageSliderBar a:nth-of-type(4) i {
        top: 11px;
        right: 11px;
    }

    /* hover */
    #homePageSliderBar a:hover:nth-of-type(1) {
        width: 122px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(2) {
        width: 166px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(3) {
        width: 115px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(4) {
        width: 150px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover p {
        opacity: 1;
        transition: 0.7s;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {

    #homePageSliderBar {
        position: fixed;
        top: 150px;
        right: 10px;
        width: 52px;
        height: 245px;
        z-index: 101;
        font-family: "Frutiger 57", sans-serif;
    }

    #homePageSliderBar a {
        display: block;
        position: absolute;
        right: 0;
        background-color: #fff;
        border-radius: 35px;
        width: 50px;
        height: 50px;
        border: 1px solid #79a342;
        transition: 0.2s;
        text-decoration: none;
        box-sizing: border-box;
    }

    #homePageSliderBar #chat {
        display:none;
    }

    #homePageSliderBar a:nth-of-type(1) {
        top: 0;
    }

    #homePageSliderBar a:nth-of-type(2) {
        top: 64px;
    }

    /*#homePageSliderBar a:nth-of-type(3) {*/
    /*    top: 128px;*/
    /*}*/

    #homePageSliderBar a:last-of-type {
        top: 128px;
    }

    #homePageSliderBar p {
        top: 14px;
        left: 18px;
        color: #79a342;
        font-size: 20px;
        opacity: 0;
        position: absolute;
    }

    #homePageSliderBar i {
        position: absolute;
        width:25px;
        height:25px;
    }

    #homePageSliderBar i svg {
        width:25px;
        height: 25px;
        fill: #79a342
    }

    #homePageSliderBar a:nth-of-type(1) i {
        right: 9px;
        top:12px;
    }

    #homePageSliderBar a:nth-of-type(2) i {
        top: 12px;
        right: 11px;
    }

    #homePageSliderBar a:nth-of-type(3) i {
        right: 11px;
        top:11px;
    }

    #homePageSliderBar a:nth-of-type(4) i {
        top: 11px;
        right: 11px;
    }
    /* hover */
    #homePageSliderBar a:hover:nth-of-type(1) {
        width: 122px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(2) {
        width: 166px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(3) {
        width: 115px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(4) {
        width: 150px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover p {
        opacity: 1;
        transition: 0.7s;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    #homePageSliderBar {
        display: none;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

    #homePageSliderBar {
        display: none;
    }
}

@media screen and (max-width: 478px) {

    #homePageSliderBar {
        display: none;
    }
}

@media print {

    #homePageSliderBar {
        position: fixed;
        top: 150px;
        right: 10px;
        width: 52px;
        height: 245px;
        z-index: 101;
        font-family: "Frutiger 57", sans-serif;
    }

    #homePageSliderBar a {
        display: block;
        position: absolute;
        right: 0;
        background-color: #fff;
        border-radius: 35px;
        width: 50px;
        height: 50px;
        border: 1px solid #79a342;
        transition: 0.2s;
        text-decoration: none;
        box-sizing: border-box;
    }

    #homePageSliderBar #chat {
        display:none;
    }

    #homePageSliderBar a:nth-of-type(1) {
        top: 0;
    }

    #homePageSliderBar a:nth-of-type(2) {
        top: 64px;
    }

    #homePageSliderBar a:nth-of-type(3) {
        top: 128px;
    }

    #homePageSliderBar a:last-of-type {
        top: 192px;
    }

    #homePageSliderBar p {
        top: 16px;
        left: 18px;
        color: #79a342;
        font-size: 20px;
        opacity: 0;
        position: absolute;
    }

    #homePageSliderBar i {
        width:25px;
        height:25px;
        position: absolute;
    }

    #homePageSliderBar i svg {
        height: 25px;
        width:25px;
        fill: #79a342
    }

    #homePageSliderBar a:nth-of-type(4) i {
        top: 12px;
        right: 12px;
    }
    /* hover */
    #homePageSliderBar a:hover:nth-of-type(1) {
        width: 122px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(2) {
        width: 166px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(3) {
        width: 115px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover:nth-of-type(4) {
        width: 150px;
        transition: 0.3s;
    }

    #homePageSliderBar a:hover p {
        opacity: 1;
        transition: 0.7s;
    }
}
