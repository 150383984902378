.prevBtn img{
    transform: rotate(180deg);
}
.prevBtn img{
   width: 10px;
}
.nextBtn img{
   width: 10px;
}
.testimonialText{
    max-width: 810px;
}
.visible{
    animation: .9s fadein;
    -webkit-animation:.9s fadein; /* Safari & Chrome */
    -moz-animation:.9s fadein; /* Firefox */
    -ms-animation:.9s fadein; /* Internet Explorer */
    -o-animation:.9s fadein; /* Opera */
}
@media screen and (min-width: 1400px) {
    #newTestimonials{
        width: 1300px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
        height: 320px;
    }
    .testimonialElement{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 50px;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 14px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        max-width: 200px;
    }
    .prevBtn{
        position: relative;
        left: 21%;
        top: 91px;
    }
    .nextBtn{
        position: relative;
        left: 89%;
        top: 91px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #newTestimonials{
        width: 1100px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
    }
    .testimonialElement{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 50px;
        max-width: 62%;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 14px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        max-width: 198px;
        margin-top: 12px;
    }
    .prevBtn{
        position: relative;
        left: 22%;
        top: 99px;
    }
    .nextBtn{
        position: relative;
        left: 90%;
        top: 99px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #newTestimonials{
        width: 900px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
    }
    .testimonialElement{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 50px;
        max-width: 57%;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 14px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        max-width: 198px;
        margin-top: 12px;
    }
    .prevBtn{
        position: relative;
        left: 25%;
        top: 99px;
    }
    .nextBtn{
        position: relative;
        left: 90%;
        top: 99px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #newTestimonials{
        width: 720px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
    }
    .testimonialElement{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 38px;
        max-width: 56%;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 14px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        max-width: 198px;
        margin-top: 12px;
    }
    .prevBtn{
        position: relative;
        left: 29%;
        top: 99px;
    }
    .nextBtn{
        position: relative;
        left: 90%;
        top: 99px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #newTestimonials{
        width: 470px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
    }
    .testimonialElement{
        display: block;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 33px;
        max-width: 44%;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 13px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        width: 175px;
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
        margin-top: 15%;
    }
    .prevBtn{
        position: relative;
        left: 38%;
        top: 107px;
    }
    .nextBtn{
        position: relative;
        left: 86%;
        top: 107px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #newTestimonials{
        width: 340px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
    }
    .testimonialElement{
        display: block;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 40px;
        max-width: 77%;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 13px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        height: 134px;
        display: block;
        margin: 0px auto;
        margin-bottom: 5px;
    }
    .prevBtn{
        position: relative;
        left: 0%;
        top: 241px;
    }
    .nextBtn{
        position: relative;
        left: 86%;
        top: 241px;
    }
}

@media screen and (max-width: 350px){
    #newTestimonials{
        width: 300px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle {
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .testimonialName{
        font-weight: bold;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .testimonialPosition{
        font-family: "Helvetica Regular",sans-serif;
        font-size: 13px;
    }
    .newTests{
        margin-top: 30px;
    }
    .testimonialElement{
        display: block;
        justify-content: center;
        margin-bottom: 30px;
    }
    .testimonialElementRight{
        padding: 40px;
        max-width: 77%;
        margin: 0 auto;
        -webkit-box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
        box-shadow: 6px 1px 10px -3px rgb(0 0 0 / 63%);
    }
    blockquote {
        padding: 0.5em 10px;
        quotes: "\201D""" !important;
        font-family: "Helvetica Regular",sans-serif;
        font-style: italic;
        font-size: 13px;
    }
    blockquote:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    blockquote p {
        display: inline;
    }
    .testimonialImage img {
        height: 140px;
        display: block;
        margin: 0px auto;
        margin-bottom: 5px;
    }
    .prevBtn{
        position: relative;
        left: 0%;
        top: 241px;
    }
    .nextBtn{
        position: relative;
        left: 86%;
        top: 241px;
    }
}

@media print {
    #newTestimonials{
        width: 1300px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    .newTestimonialsTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
}

.prevBtn,
.nextBtn {
    padding: 11px;
    background-color: #79a342;
    color: #fff;
    font-weight: bold;
    font-size: 23px;
    z-index: 1;
}
.prevBtn:hover,
.nextBtn:hover{
    cursor: pointer;
}
.prevBtn{
    padding-right: 6px;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

