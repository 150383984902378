@media screen and (min-width: 1400px){
   #courseFinderCourses {
       width: 1300px;
       margin: 0 auto;
       display: flex;
       flex-flow: row wrap;
       justify-content: left;
   }
    .singleCourse {
        width: 358px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
        margin-left: 35px;
        margin-right: 40px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column, .course-right-column {
        display: inline-block;
        width: 40%;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
    .course-education-type-label {
        word-break: break-all;
    }
    /*.favourites-count-2 {*/
    /*    justify-content: left !important;*/
    /*}*/
    /*.favourites-count-2 .singleCourse {*/
    /*    margin-right: 8.5% !important;*/
    /*}*/
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #courseFinderCourses {
        width: 1100px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }
    .singleCourse {
        width: 358px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column, .course-right-column {
        display: inline-block;
        width: 40%;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
    .course-education-type-label {
        word-break: break-all;
    }
    /*.favourites-count-2 {*/
    /*    justify-content: left !important;*/
    /*}*/
    /*.favourites-count-2 .singleCourse {*/
    /*    margin-right: 2% !important;*/
    /*}*/
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #courseFinderCourses {
        width: 922px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }
    .singleCourse {
        width: 290px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column {
        display: inline-block;
        width: 140px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }

    .course-right-column {
        display: inline-block;
        width: 120px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
  .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
    .favourites-count-2 {
        justify-content: left !important;
    }
    .favourites-count-2 .singleCourse {
        margin-right: 2.5% !important;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #courseFinderCourses {
        width: 666px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }
    .singleCourse {
        width: 326px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column {
        display: inline-block;
        width: 160px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-right-column {
        display: inline-block;
        width: 120px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #courseFinderCourses {
        width: 428px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .singleCourse {
        width: 428px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column, .course-right-column {
        display: inline-block;
        width: 40%;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
    .course-education-type-label {
        word-break: break-all;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #courseFinderCourses {
        width: 320px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .singleCourse {
        width: 320px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column {
        display: inline-block;
        width: 160px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-right-column {
        display: inline-block;
        width: 130px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }
    .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
}

@media screen and (max-width: 350px){
    #courseFinderCourses {
        width: 300px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .singleCourse {
        width: 300px;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }
    .singleCourse img {
        width: 100%;
        height: 162px;
    }
    .course-image-description {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 162px;
    }
    .course-image-inner-text {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        bottom: 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: white;
        background-color: #31417A;
        opacity: 0.85;
        padding: 8px 20px 8px;
    }
    .bottomCourseContainer {
        background-color: #F4F5F8;
        position: relative;
    }
    .course-left-column {
        display: inline-block;
        width: 144px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }

    .course-right-column {
        display: inline-block;
        width: 130px;
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        line-height: 20px;
    }

    .course-left-column div, .course-right-column div {
        padding-bottom: 5px;
    }
    .course-left-column {
        padding-left: 20px;
        padding-top: 16px;
    }
    .favouriteCourse {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .favouriteCourseButton {
        cursor: pointer;
    }
    .favouriteCourse .fullStar, .favouriteCourse .emptyStar {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .favouriteCourse .fullStar {
        color: #79A342;
        visibility: hidden;
    }
    .favouriteCourse .emptyStar {
        color: #31417A;
    }
}

/*course-education-type-label with word-break:all*/