/*!
    This CSS resource incorporates links to font software which is the valuable copyrighted
    property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
    redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
    Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/
@font-face {
    font-family: 'Frutiger 47';
    src: url('../fonts/d081ebc4-72ee-49ad-94a1-b3cd3e01e2a1.woff2') format('woff2'),
    url('../fonts/e4b55b70-bcab-4701-b1ce-3cd09b3c8077.woff') format('woff'),
    url('../fonts/5a1bab15-ef53-49fb-aaa8-16c62d878661.eot') format('eot'),
    url('../fonts/a8692796-3220-4d4b-a593-167aafa86ec9.svg#a8692796-3220-4d4b-a593-167aafa86ec9') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Frutiger 57';
    src: url('../fonts/5995e4b5-97ff-4823-bebb-dc150282a2cf.woff2') format('woff2'),
    url('../fonts/bd26bfea-b97f-4b32-b5f6-504be0b1938d.woff') format('woff'),
    url('../fonts/e6c921f4-c7f3-4375-a9e2-2a30e1af2014.eot?'),
    url('../fonts/247bce89-c2d1-44ba-a36c-c3c2eef2131c.svg#247bce89-c2d1-44ba-a36c-c3c2eef2131c') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Frutiger 67';
    src: url('../fonts/4c4ee705-e593-4456-988d-03a5a2594534.woff2') format('woff2'),
    url('../fonts/e728739a-5d86-4213-9f4f-73394eff3d19.woff') format('woff'),
    url('../fonts/d4125907-9465-43ee-b1eb-b9195f69cec7.eot?'),
    url('../fonts/d6cc9dd9-5ba8-4746-a2dd-ee86b58a9db4.svg#d6cc9dd9-5ba8-4746-a2dd-ee86b58a9db4') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Frutiger 47 Light Condensed';
    src: url('../fonts/Frutiger/Frutiger_47_Light_Condensed.woff2') format('woff2'),
    url('../fonts/Frutiger/Frutiger_47_Light_Condensed.woff') format('woff'),
    url('../fonts/Frutiger/Frutiger_47_Light_Condensed.eot?');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Frutiger 57 Condensed';
    src: url('../fonts/Frutiger/Frutiger_57_Condensed.woff2') format('woff2'),
    url('../fonts/Frutiger/Frutiger_57_Condensed.woff') format('woff'),
    url('../fonts/Frutiger/Frutiger_57_Condensed.svg') format('svg'),
    url('../fonts/Frutiger/Frutiger_57_Condensed.eot?');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Frutiger 67 Condensed';
    src: url('../fonts/Frutiger/Frutiger_67_Bold_Condensed.woff2') format('woff2'),
    url('../fonts/Frutiger/Frutiger_67_Bold_Condensed.woff') format('woff'),
    url('../fonts/Frutiger/Frutiger_67_Bold_Condensed.svg') format('svg'),
    url('../fonts/Frutiger/Frutiger_67_Bold_Condensed.eot?');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Regular';
    src: url('../fonts/helvetica-regular.eot?');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Bold';
    src: url('../fonts/helvetica-bold.eot?');
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}