@media screen and (min-width: 1400px){
    /* footer - address and logos */
    #footer{
        width: 100%;
        font-size: 14px;
        color: #31417A;
        background-color: #f4f5f8;
        padding: 55px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }
    #footerAddress {
        width: 1300px;
        height: 146px;
        margin: 0 auto;
        position: relative;
    }
    #footerAddress li{
        position: absolute;
        line-height: 1.3;
    }
    #footerAddress li img{
        height: 35px;
        margin-left: 55px;
    }
    #footerAddress li:first-of-type{
        left: 0;
    }
    #footerAddress li:last-of-type{
        right: 0;
    }
    #footerAddress a{
        font-size: 14px;
        color: #31417A;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }
    #footerAddress a:hover{
        color: #79a342;
    }
    #footerAddressLeft{

    }
    #footerAddressLeft a{
        display: block;
        margin: 0;
    }
    #footerAddressLeft a:first-of-type{
        margin-top: 20px;
    }
    #footerAddressLeft h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{

    }
    #footerMenu{
        margin: 28px 0;
        text-align: right;
    }
    #footerMenu a:last-of-type{
        margin-right: 0;
    }

    /* footer copyright */
    #footerCopyright{
        margin-top: 8px;
        text-align: right;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* footer - address and logos */
    #footer{
        width: 100%;
        font-size: 14px;
        color: #31417A;
        background-color: #f4f5f8;
        padding: 55px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }
    #footerAddress{
        width: 1100px;
        height: 146px;
        margin: 0 auto;
        position: relative;
    }
    #footerAddress li{
        position: absolute;
        line-height: 1.3;
    }
    #footerAddress li img{
        height: 35px;
        margin-left: 55px;
    }
    #footerAddress li:first-of-type{
        left: 0;
    }
    #footerAddress li:last-of-type{
        right: 0;
    }
    #footerAddress a{
        font-size: 14px;
        color: #31417A;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }
    #footerAddress a:hover{
        color: #79a342;
    }
    #footerAddressLeft{

    }
    #footerAddressLeft a{
        display: block;
        margin: 0;
    }
    #footerAddressLeft a:first-of-type{
        margin-top: 20px;
    }
    #footerAddressLeft h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{

    }
    #footerMenu{
        margin: 28px 0;
        text-align: right;
    }
    #footerMenu a:last-of-type{
        margin-right: 0;
    }

    /* footer copyright */
    #footerCopyright{
        margin-top: 8px;
        text-align: right;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* footer - address and logos */
    #footer{
        width: 100%;
        font-size: 14px;
        color: #31417A;
        background-color: #f4f5f8;
        padding: 55px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }
    #footerAddress{
        width: 900px;
        height: 146px;
        margin: 0 auto;
        position: relative;
    }
    #footerAddress li{
        position: absolute;
        line-height: 1.3;
    }
    #footerAddress li img{
        height: 35px;
        margin-left: 55px;
    }
    #footerAddress li:first-of-type{
        left: 0;
    }
    #footerAddress li:last-of-type{
        right: 0;
    }
    #footerAddress a{
        font-size: 14px;
        color: #31417A;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }
    #footerAddress a:hover{
        color: #79a342;
    }
    #footerAddressLeft{

    }
    #footerAddressLeft a{
        display: block;
        margin: 0;
    }
    #footerAddressLeft a:first-of-type{
        margin-top: 20px;
    }
    #footerAddressLeft h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{

    }
    #footerMenu{
        margin: 28px 0;
        text-align: right;
    }
    #footerMenu a:last-of-type{
        margin-right: 0;
    }

    /* footer copyright */
    #footerCopyright{
        margin-top: 8px;
        text-align: right;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /* footer - address and logos */
    #footer{
        width: 100%;
        font-size: 14px;
        color: #31417A;
        background-color: #f4f5f8;
        padding: 55px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }
    #footerAddress{
        width: 720px;
        height: 146px;
        margin: 0 auto;
        position: relative;
    }
    #footerAddress li{
        position: absolute;
        line-height: 1.3;
    }
    #footerAddress li img{
        height: 35px;
        margin-left: 55px;
    }
    #footerAddress li:first-of-type{
        left: 0;
    }
    #footerAddress li:last-of-type{
        right: 0;
    }
    #footerAddress a{
        font-size: 14px;
        color: #31417A;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }
    #footerAddress a:hover{
        color: #79a342;
    }
    #footerAddressLeft{

    }
    #footerAddressLeft a{
        display: block;
        margin: 0;
    }
    #footerAddressLeft a:first-of-type{
        margin-top: 20px;
    }
    #footerAddressLeft h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{

    }
    #footerMenu{
        margin: 28px 0;
        text-align: right;
    }
    #footerMenu a:last-of-type{
        margin-right: 0;
    }

    /* footer copyright */
    #footerCopyright{
        margin-top: 8px;
        text-align: right;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /* footer - address and logos */
    #footer{
        width: 100%;
        font-size: 14px;
        color: #f4f5f8;
        background-color: #31417A;
        padding: 20px 0 20px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }
    #footerAddress{
        width: 468px;
        /*height: 146px;*/
        position: relative;
        margin: 0 auto;
    }
    #footerAddress li{
        line-height: 1.3;
        display: block;
    }
    #footerAddress li img{
        height: 35px;
        margin-left: 55px;
    }
    #footerAddress a{
        font-size: 14px;
        color: #f4f5f8;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }
    #footerAddress a:hover{
        color: #79a342;
    }
    #footerAddressLeft{

    }

    .footer-address {
        display: inline-block;
    }

    .footer-contacts {
        display: inline-block;
        float: right;
    }

    .footer-contacts a{
        text-align: right;
    }

    #footerAddressLeft a{
        display: block;
        margin: 0;
    }
    #footerAddressLeft a:first-of-type{
        margin-top: 0;

    }
    #footerAddressLeft h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{
    }

    .accreditation-images {
        display: none;
    }

    #footerMenu {
        margin: 20px 0 20px;
        text-align: left;
        width:420px;
    }
    #footerMenu a {
        margin: 0;
        padding: 5px;
        display: inline-block;
        width: 106px;
    }
    #footerMenu a:nth-child(3n+1) {
        padding-left:0;
    }
    #footerMenu a:last-of-type{
        margin-right: 0;
    }

    #footerMenu a:first-of-type{
        margin-left: 0;
        padding-left:0;
    }

    /* footer copyright */
    #footerCopyright{
        margin-top: 8px;
        text-align: left;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /* footer - address and logos */
    #footer {
        width: 100%;
        font-size: 14px;
        color: #f4f5f8;
        background-color: #31417A;
        padding: 40px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }

    #footerAddress {
        width: 300px;
        /*height: 146px;*/
        position: relative;
        margin: 0 auto;
    }

    #footerAddress li {
        line-height: 1.3;
        display: block;
    }

    #footerAddress li img {
        height: 35px;
        margin-left: 55px;
    }

    #footerAddress a {
        font-size: 14px;
        color: #f4f5f8;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }

    #footerAddress a:hover {
        color: #79a342;
    }

    #footerAddressLeft {

    }

    .footer-address {
        display: inline-block;
    }

    .footer-contacts {
        display: inline-block;
        float: right;
    }

    .footer-contacts a {
        text-align: right;
    }

    #footerAddressLeft a {
        display: block;
        margin: 0;
    }

    #footerAddressLeft a:first-of-type {
        margin-top: 0;
    }

    #footerAddressLeft h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #footerAddressRight {
    }

    .accreditation-images {
        display: none;
    }

    #footerMenu {
        margin: 20px 0 20px;
        text-align: left;
        width:180px;
    }
    #footerMenu a {
        margin: 0;
        padding: 5px;
        display: inline-block;
        width: 80px;
    }
    #footerMenu a:nth-child(odd) {
        padding-left:0;
    }


    /*#footerMenu a:last-of-type {*/
    /*    margin-left:0;*/
    /*}*/

    #footerMenu a:first-of-type {
        margin-left: 0;
    }

    #footerMenu a:nth-child(odd) {
        margin-left:0;
    }

    /* footer copyright */
    #footerCopyright {
        text-align: left;
    }

}
@media screen and (max-width: 350px) {
    /* footer - address and logos */
    #footer {
        width: 100%;
        font-size: 14px;
        color: #f4f5f8;
        background-color: #31417A;
        padding: 40px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }

    #footerAddress {
        width: 300px;
        /*height: 146px;*/
        position: relative;
        margin: 0 auto;
    }

    #footerAddress li {
        line-height: 1.3;
        display: block;
    }

    #footerAddress li img {
        height: 35px;
        margin-left: 55px;
    }

    #footerAddress a {
        font-size: 14px;
        color: #f4f5f8;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }

    #footerAddress a:hover {
        color: #79a342;
    }

    #footerAddressLeft{

    }

    .footer-address {
        display: inline-block;
    }

    .footer-contacts {
        display: inline-block;
        float: right;
    }

    .footer-contacts a {
        text-align: right;
    }

    #footerAddressLeft a {
        display: block;
        margin: 0;
    }

    #footerAddressLeft a:first-of-type {
        margin-top: 0;
    }

    #footerAddressLeft h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{
    }

    .accreditation-images {
        display: none;
    }

    #footerMenu {
        margin: 20px 0 20px;
        text-align: left;
        width:180px;
    }
    #footerMenu a {
        margin: 0;
        padding: 5px;
        display: inline-block;
        width: 80px;
    }
    #footerMenu a:nth-child(odd) {
        padding-left:0;
    }

    /*#footerMenu a:last-of-type {*/
    /*    margin-left:0;*/
    /*}*/

    #footerMenu a:first-of-type {
        margin-left: 0;
    }

    #footerMenu a:nth-child(odd) {
        margin-left:0;
    }

    /* footer copyright */
    #footerCopyright {
        text-align: left;
    }

}

/* print */
@media print {
    /* footer - address and logos */
    #footer{
        width: 100%;
        font-size: 14px;
        color: #31417A;
        background-color: #f4f5f8;
        padding: 55px 0 40px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        position:relative;
        z-index: 2;
    }
    #footerAddress {
        width: 1300px;
        height: 146px;
        margin: 0 auto;
        position: relative;
    }
    #footerAddress li{
        position: absolute;
        line-height: 1.3;
    }
    #footerAddress li img{
        height: 35px;
        margin-left: 55px;
    }
    #footerAddress li:first-of-type{
        left: 0;
    }
    #footerAddress li:last-of-type{
        right: 0;
    }
    #footerAddress a{
        font-size: 14px;
        color: #31417A;
        text-decoration: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        margin: 10px 20px;
    }
    #footerAddress a:hover{
        color: #79a342;
    }
    #footerAddressLeft{

    }
    #footerAddressLeft a{
        display: block;
        margin: 0;
    }
    #footerAddressLeft a:first-of-type{
        margin-top: 20px;
    }
    #footerAddressLeft h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #footerAddressRight{

    }
    #footerMenu{
        margin: 28px 0;
        text-align: right;
    }
    #footerMenu a:last-of-type{
        margin-right: 0;
    }

    /* footer copyright */
    #footerCopyright{
        margin-top: 8px;
        text-align: right;
    }
}
