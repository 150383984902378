@media screen and (min-width: 1400px){
    .desktop-only {
        display: block;
    }

    #mobileShowDropdowns,
    .mobile-dropdown-arrow-down,
    .filter-dropdown-container,
    #mobileSearchCourseFinder,
    .mobile-only {
        display: none;
    }

    /* Dropdowns */
    #courseFinderDropdowns-CF {
        width: 1300px;
        margin: 20px auto;
        border-bottom: 2px solid #7a7a7a;
    }
    #courseFinderDropdowns-CF ul li {
        display: inline-block;
    }
    #courseFinderDropdowns-CF ul {
        padding-bottom: 30px;
    }
    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 205px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin-right: 10px;
    }
    .course-finder-dropdown-CF {
        cursor: pointer;
    }
    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 12px 10px 0;
    }
    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 10px;
    }
    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }
    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }
    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }
    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
    }
    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }
    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }
    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
        margin-right: 0;
        width:204px;
    }

    /* Filter in use */
    #filtersInUse-CF {
        width: 1300px;
        margin: 0 auto 26px;
        display: none;
    }

    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }

    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }

    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /* Added Filters */
    #addedFilters-CF {
        width: 1300px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }

    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }

    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }

    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }

    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }

    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }

    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }

    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }

    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }

    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }

    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }

    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }

    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }

    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }

    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }

    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }

    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }

    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /* Dropdowns */

    .dropdown-menu-CF {
        display: none;
        width: 205px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        line-height: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .dropdown-menu-CF li {
        padding: 12px 2px 12px 12px;
    }

    .dropdown-menu-CF li:hover {
        color: #79A342;
    }

    .dropdown-menu-CF li:first-child {
        padding-top: 18px;
    }

    #courseFinderDropdowns-CF .dropdown-title {
        display: inline-block;
        max-width: 90%;
        height: 24px;
    }

    .dropdown-topics-menu-CF {
        display: none;
        width: 1300px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
        line-height: 16px;
    }

    #courseFinderDropdowns-CF .dropdown-topics-menu-CF .dropdown-title {
        max-width: 100%;
    }

    .topics-dropdown-CF li {
        display: inline-block !important;
        width: 285px;
        padding: 16px 24px 16px 12px;
        vertical-align: top;
        list-style: none !important;
    }

    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline-block;
        visibility: hidden;
        color: #79A342;
    }

    .topics-dropdown-CF li:hover {
        color: #79A342;
    }

    .border-bottom-type-1 {
        border-bottom: 3px solid #B2B2B2;
    }

    .border-bottom-type-2 {
        border-bottom: 3px solid #79A342;
    }

    .border-bottom-type-3 {
        border-bottom: 3px solid #31417A;
    }

    .border-bottom-type-4 {
        border-bottom: 3px solid #666666;
    }

    .border-bottom-type-5 {
        border-bottom: 3px solid #007290;
    }

    .border-bottom-type-6 {
        border-bottom: 3px solid #D84826;
    }

    .arrow-dropdown-arrow-CF {
        transform: rotate(180deg);
        transition: 0.4s;
        padding-bottom: 12px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .desktop-only {
        display: block;
    }

    #mobileShowDropdowns,
    .mobile-dropdown-arrow-down,
    .filter-dropdown-container,
    #mobileSearchCourseFinder,
    .mobile-only {
        display: none;
    }

    /* Dropdowns */
    #courseFinderDropdowns-CF {
        width: 1100px;
        margin: 20px auto;
        border-bottom: 2px solid #7a7a7a;
    }
    #courseFinderDropdowns-CF ul li {
        display: inline-block;
    }
    #courseFinderDropdowns-CF ul {
        padding-bottom: 30px;
    }
    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 171px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin-right: 10px;
    }
    .course-finder-dropdown-CF {
        cursor: pointer;
    }
    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 13px 10px 10px;
    }
    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 10px;
    }
    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }
    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }
    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }
    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
    }
    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }
    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }
    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
        margin-right: 0;
    }

    /* Filter in use */
    #filtersInUse-CF {
        width: 1100px;
        margin: 0 auto 26px;
        display: none;
    }
    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }
    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }
    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /* Added Filters */
    #addedFilters-CF {
        width: 1100px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }
    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }
    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }
    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }
    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }
    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }
    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }
    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }
    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }
    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }
    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }
    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }
    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }
    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }
    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }
    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }
    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /* Dropdowns */

    .dropdown-menu-CF {
        display: none;
        width: 171px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        line-height: 14px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .dropdown-menu-CF li {
        padding: 12px 2px 12px 12px;
    }
    .dropdown-menu-CF li:first-child {
        padding-top: 18px;
    }
    .dropdown-menu-CF li:hover {
        color: #79A342;
    }
    #courseFinderDropdowns-CF .dropdown-title {
        display: inline-block;
        max-width: 85%;
        height: 24px;
    }
    #courseFinderDropdowns-CF .dropdown-topics-menu-CF .dropdown-title {
        max-width: 90%;
    }
    .dropdown-topics-menu-CF {
        display: none;
        width: 1100px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
        line-height: 14px;
    }

    .topics-dropdown-CF li {
        display: inline-block !important;
        width: 256px;
        padding: 12px 2px 16px 12px;
        vertical-align: top;
        list-style: none !important;
    }

    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline-block;
        visibility: hidden;
        color: #79A342;
    }
    .topics-dropdown-CF li:hover {
        color: #79A342;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .desktop-only {
        display: block;
    }

    #mobileShowDropdowns,
    .mobile-dropdown-arrow-down,
    .filter-dropdown-container,
    #mobileSearchCourseFinder,
    .mobile-only {
        display: none;
    }

    /* Dropdowns */
    #courseFinderDropdowns-CF {
        width: 922px;
        margin: 20px auto;
        border-bottom: 2px solid #7a7a7a;
    }

    #courseFinderDropdowns-CF ul li {
        display: inline-block;
    }

    #courseFinderDropdowns-CF ul {
        padding-bottom: 30px;
    }

    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 142px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin-right: 10px;
    }

    .course-finder-dropdown-CF {
        cursor: pointer;
    }

    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 12px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 13px 10px 10px;
    }

    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 8px 4px 4px;
    }

    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }

    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }

    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }

    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
    }

    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }

    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }

    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
        margin-right: 0;
        width:141px;
    }

    /* Filter in use */
    #filtersInUse-CF {
        width: 922px;
        margin: 0 auto 26px;
        display: none;
    }

    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }

    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }

    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /* Added Filters */
    #addedFilters-CF {
        width: 922px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }

    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }

    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }

    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }

    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }

    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }

    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }

    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }

    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }

    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }

    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }

    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }

    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }

    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }

    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }

    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }

    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }

    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /* Dropdowns */

    .dropdown-menu-CF {
        display: none;
        width: 142px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        line-height: 14px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .dropdown-menu-CF li {
        padding: 4px 24px 4px 12px;
    }

    .dropdown-menu-CF li:hover {
        color: #79A342;
    }

    .dropdown-menu-CF li:first-child {
        padding-top: 12px;
    }

    #courseFinderDropdowns-CF .dropdown-title {
        display: inline-block;
        max-width: 165px;
        height: 24px;
    }

    #courseFinderDropdowns-CF .dropdown-topics-menu-CF .dropdown-title {
        max-width: 90%;
    }

    .dropdown-topics-menu-CF {
        display: none;
        width: 922px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
        line-height: 14px;
    }
    .topics-dropdown-CF li {
        display: inline-block !important;
        width: 190px;
        padding: 8px 24px 8px 12px;
        vertical-align: top;
        list-style: none !important;
    }
    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline-block;
        visibility: hidden;
        color: #79A342;
    }
    .topics-dropdown-CF li:hover {
        color: #79A342;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .desktop-only {
        display: block;
    }

    #mobileShowDropdowns,
    .mobile-dropdown-arrow-down,
    .filter-dropdown-container,
    #mobileSearchCourseFinder,
    .mobile-only {
        display: none;
    }
    /* Dropdowns */
    #courseFinderDropdowns-CF {
        width: 666px;
        margin: 20px auto;
        border-bottom: 2px solid #7a7a7a;
    }

    #courseFinderDropdowns-CF ul li {
        display: inline-block;
    }

    #courseFinderDropdowns-CF ul {
        padding-bottom: 10px;
    }

    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 209px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin-right: 15px;
        margin-top: 10px;
    }

    .course-finder-dropdown-CF {
        cursor: pointer;
    }

    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 13px 10px 10px;
    }

    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 8px 4px 4px;
    }

    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }

    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }

    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }

    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
        margin-right: 0;
    }

    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }

    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }

    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
        margin-right: 0;
    }

    /* Filter in use */
    #filtersInUse-CF {
        width: 666px;
        margin: 0 auto 26px;
        display: none;
    }

    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }

    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }

    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /* Added Filters */
    #addedFilters-CF {
        width: 666px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }

    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }

    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }

    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }

    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }

    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }

    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }

    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }

    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }

    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }

    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }

    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }

    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }

    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }

    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }

    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }

    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }

    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /* Dropdowns */

    .dropdown-menu-CF {
        display: none;
        width: 209px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 12px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        line-height: 12px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .dropdown-menu-CF li {
        padding: 4px 24px 4px 12px;
    }

    .dropdown-menu-CF li:first-child {
        padding-top: 12px;
    }

    .dropdown-menu-CF li:hover {
        color: #79A342;
    }

    #courseFinderDropdowns-CF .dropdown-title {
        display: inline-block;
        max-width: 165px;
        height: 24px;
    }

    .dropdown-topics-menu-CF {
        display: none;
        width: 666px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 12px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
    }

    .topics-dropdown-CF li {
        display: inline-block !important;
        width: 180px;
        padding: 10px 24px 10px 12px;
        vertical-align: top;
        list-style: none !important;
        line-height: 12px;
    }

    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline;
        visibility: hidden;
        color: #79A342;
    }

    .topics-dropdown-CF li:hover {
        color: #79A342;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .filter-in-user-mobile-container {
        margin: 20px auto 0 auto;
        width:428px;
    }
    .filter-in-user-mobile-container #addedFilters-CF {
        margin-top:20px;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .filter-dropdown-container {
        display: block;
        position: relative;
    }
    #mobileShowDropdowns {
        display: block;
        background-color: #79A342;
        color: #fff;
        width: 428px;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin: 10px auto 0;
        font-family: "Frutiger 57 Light Condensed", sans-serif;
    }
    .mobile-dropdown-arrow-down {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #79A342;
        left: 50%;
        top: 34px;
        margin-left: -10px;
    }
    #mobileSearchCourseFinder {
        display: block;
        background-color: #79A342;
        color: #fff;
        width: 228px;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin: 20px auto;
        font-family: "Frutiger 57 Light Condensed", sans-serif;
    }

    /* Dropdowns */
    #courseFinderDropdowns-CF {
        display: none;
        width: 100%;
        margin: 0 auto;
        border-bottom: 2px solid #7a7a7a;
        background: #cacfde;
        padding-top: 10px;
    }
    #courseFinderDropdowns-CF ul li {
        display: block;
    }
    #courseFinderDropdowns-CF ul {
        padding-bottom: 10px;
    }
    .career-level-dropdown-CF,
    .topics-dropdown-CF,
    .format-dropdown-CF,
    .type-of-education-dropdown-CF,
    .language-dropdown-CF,
    .date-dropdown-CF {
        width: 428px;
        margin: 0 auto;
    }
    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 428px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin: 10px auto 0;
    }
    .course-finder-dropdown-CF {
        cursor: pointer;
    }
    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 13px 10px 10px;
    }
    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 8px 4px 4px;
    }
    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }
    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }
    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }
    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
    }
    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }
    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }
    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
    }

    /*
    | Filter in use
    */
    #filtersInUse-CF {
        width: 428px;
        margin: 0 auto 26px;
        display: none;
    }
    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }
    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }
    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /*
    | Added Filters
    */
    #addedFilters-CF {
        width: 428px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }
    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }
    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }
    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }
    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }
    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }
    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }
    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }
    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }
    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }
    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }
    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }
    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }
    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }
    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }
    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }
    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /*
    | Drop-downs
    */
    .dropdown-menu-CF {
        display: none;
        width: 428px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        margin: 0 auto;
        max-height: 260px;
        overflow-y: scroll;
        line-height: 1px !important;
        -webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
        box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
    }
    .dropdown-menu-CF li:first-child {
        padding-top: 12px;
    }
    .dropdown-menu-CF li {
        padding: 10px 24px 10px 12px;
    }
    .dropdown-menu-CF li:hover {
        color: #79A342;
    }
    #courseFinderDropdowns-CF .dropdown-title {
        display: inline;
    }
    .dropdown-topics-menu-CF {
        display: none;
        width: 428px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
        max-height: 260px;
        overflow-y: scroll;
    }
    .topics-dropdown-CF li:first-child {
        padding-top: 12px;
    }
    .topics-dropdown-CF li {
        display: block;
        padding: 10px 24px 10px 12px;
        vertical-align: top;
        list-style: none !important;
        line-height: 1px !important;
    }
    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline-block;
        visibility: hidden;
        color: #79A342;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .filter-in-user-mobile-container {
        margin: 20px auto 0 auto;
        width:320px;
    }
    .filter-in-user-mobile-container #addedFilters-CF {
        margin-top:20px;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .filter-dropdown-container {
        display: block;
        position: relative;
    }

    #mobileShowDropdowns {
        display: block;
        background-color: #79A342;
        color: #fff;
        width: 320px;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin: 10px auto 0;
        font-family: "Frutiger 57 Light Condensed", sans-serif;
    }
    .mobile-dropdown-arrow-down {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #79A342;
        left: 50%;
        top: 34px;
        margin-left: -10px;
    }
    #mobileSearchCourseFinder {
        display: block;
        background-color: #79A342;
        color: #fff;
        width: 228px;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin: 20px auto;
        font-family: "Frutiger 57 Light Condensed", sans-serif;
    }

    /*
    | Dropdowns
    */
    #courseFinderDropdowns-CF {
        display: none;
        width: 100%;
        margin: 0 auto;
        border-bottom: 2px solid #7a7a7a;
        background: #cacfde;
        padding-top: 10px;
    }
    #courseFinderDropdowns-CF ul li {
        display: block;
    }
    #courseFinderDropdowns-CF ul {
        padding-bottom: 10px;
    }
    .career-level-dropdown-CF,
    .topics-dropdown-CF,
    .format-dropdown-CF,
    .type-of-education-dropdown-CF,
    .language-dropdown-CF,
    .date-dropdown-CF {
        width: 320px;
        margin: 0 auto;
    }
    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 320px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin: 10px auto 0;
    }
    .course-finder-dropdown-CF {
        cursor: pointer;
    }
    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 13px 10px 10px;
    }
    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 8px 4px 4px;
    }
    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }
    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }
    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }
    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
    }
    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }
    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }
    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
    }

    /* Filter in use */
    #filtersInUse-CF {
        width: 320px;
        margin: 0 auto 26px;
        display: none;
    }
    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }
    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }
    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /* Added Filters */
    #addedFilters-CF {
        width: 320px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }
    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }
    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }
    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }
    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }
    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }
    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }
    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }
    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }
    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }
    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }
    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }
    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }
    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }
    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }
    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }
    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /* Dropdowns */

    .dropdown-menu-CF {
        display: none;
        width: 320px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        margin: 0 auto;
        max-height: 260px;
        overflow-y: scroll;
        line-height: 1px !important;
        -webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
        box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
    }
    .dropdown-menu-CF li:first-child {
        padding-top: 12px;
    }
    .dropdown-menu-CF li {
        padding: 10px 24px 10px 12px;
    }
    .dropdown-menu-CF li:hover {
        color: #79A342;
    }
    #courseFinderDropdowns-CF .dropdown-title {
        display: inline;
        color: #666666;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .dropdown-topics-menu-CF {
        display: none;
        width: 320px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
        max-height: 260px;
        overflow-y: scroll;
    }
    .topics-dropdown-CF li:first-child {
        padding-top: 12px;
    }
    .topics-dropdown-CF li {
        display: block;
        padding: 10px 24px 10px 12px;
        vertical-align: top;
        list-style: none !important;
        line-height: 1px !important;
    }
    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline-block;
        visibility: hidden;
        color: #79A342;
    }

}

@media screen and (max-width: 350px){
    .filter-in-user-mobile-container {
        margin: 20px auto 0 auto;
        width:320px;
    }
    .filter-in-user-mobile-container #addedFilters-CF {
        margin-top:20px;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .filter-dropdown-container {
        display: block;
        position: relative;
    }

    #mobileShowDropdowns {
        display: block;
        background-color: #79A342;
        color: #fff;
        width: 300px;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin: 10px auto 0;
        font-family: "Frutiger 57 Light Condensed", sans-serif;
    }
    .mobile-dropdown-arrow-down {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #79A342;
        left: 50%;
        top: 34px;
        margin-left: -10px;
    }
    #mobileSearchCourseFinder {
        display: block;
        background-color: #79A342;
        color: #fff;
        width: 228px;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin: 20px auto;
        font-family: "Frutiger 57 Light Condensed", sans-serif;
    }

    /* Dropdowns */
    #courseFinderDropdowns-CF {
        display: none;
        width: 100%;
        margin: 0 auto;
        border-bottom: 2px solid #7a7a7a;
        background: #cacfde;
        padding-top: 10px;
    }
    #courseFinderDropdowns-CF ul li {
        display: block;
    }
    #courseFinderDropdowns-CF ul {
        padding-bottom: 10px;
    }
    .career-level-dropdown-CF,
    .topics-dropdown-CF,
    .format-dropdown-CF,
    .type-of-education-dropdown-CF,
    .language-dropdown-CF,
    .date-dropdown-CF {
        width: 300px;
        margin: 0 auto;
    }
    #careerLevelDropdown-CF,
    #topicsDropdown-CF,
    #formatDropdown-CF,
    #typeOfEducationDropdown-CF,
    #languageDropdown-CF,
    #dateDropdown-CF {
        width: 300px;
        height: 36px    ;
        background-color: #F4F5F8;
        margin: 10px auto 0;
    }
    .course-finder-dropdown-CF {
        cursor: pointer;
    }
    .course-finder-dropdown-CF p {
        color: #666666;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        display: inline-block;
        padding: 13px 10px 10px;
    }
    .course-finder-dropdown-CF i {
        color: #B2B2B2;
        float: right;
        padding: 8px 4px 4px;
    }
    #courseFinderDropdowns-CF .dropdown-menu-CF li {
        display: block;
    }
    #careerLevelDropdown-CF {
        border-bottom: 3px solid #B2B2B2;
    }
    #topicsDropdown-CF {
        border-bottom: 3px solid #79A342;
    }
    #formatDropdown-CF {
        border-bottom: 3px solid #31417A;
    }
    #typeOfEducationDropdown-CF {
        border-bottom: 3px solid #666666;
    }
    #languageDropdown-CF {
        border-bottom: 3px solid #007290;
    }
    #dateDropdown-CF {
        border-bottom: 3px solid #D84826;
    }

    /* Filter in use */
    #filtersInUse-CF {
        width: 300px;
        margin: 0 auto 26px;
        display: none;
    }
    .filter-in-use-title-CF {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
        display: inline-block;
    }
    .filter-in-use-right-container-CF {
        display: inline-block;
        float: right;
        margin-right: 1px;
    }
    .filter-in-use-right-container-CF div {
        display: inline-block;
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        cursor: pointer;
        margin-left: 20px;
    }

    /* Added Filters */
    #addedFilters-CF {
        width: 300px;
        margin: 0 auto 26px;
        text-align: left;
        overflow: hidden;
        display: none;
    }
    .single-filter-item-CF {
        position: relative;
        display: inline-block;
        border: 2px solid #666666;
        padding: 8px 26px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        margin-bottom: 8px;
        margin-right: 10px;
    }
    .close-filter-button-CF, .single-filter-item-CF p {
        display: inline-block;
    }
    .close-filter-button-CF {
        position: absolute;
        cursor: pointer;
        right: 12px;
    }
    .single-filter-item-CF p {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    .single-filter-item-CF i {
        display: inline-block;
        font-size: 12px;
        color: #666666;
    }
    .single-filter-item-career-level-CF {
        border: 2px solid #B2B2B2;
    }
    .single-filter-item-career-level-CF i,
    .single-filter-item-career-level-CF p {
        color: #B2B2B2;
    }
    .single-filter-item-topic-CF {
        border: 2px solid #79A342;
    }
    .single-filter-item-topic-CF i,
    .single-filter-item-topic-CF p {
        color: #79A342;
    }
    .single-filter-item-format-CF {
        border: 2px solid #31417A;
    }
    .single-filter-item-format-CF i,
    .single-filter-item-format-CF p {
        color: #31417A;
    }
    .single-filter-item-type-of-education-CF {
        border: 2px solid #666666;
    }
    .single-filter-item-type-of-education-CF i,
    .single-filter-item-type-of-education-CF p {
        color: #666666;
    }
    .single-filter-item-language-CF {
        border: 2px solid #007290;
    }
    .single-filter-item-language-CF i,
    .single-filter-item-language-CF p {
        color: #007290;
    }
    .single-filter-item-date-CF {
        border: 2px solid #D84826;
    }
    .single-filter-item-date-CF i,
    .single-filter-item-date-CF p {
        color: #D84826;
    }

    /* Dropdowns */

    .dropdown-menu-CF {
        display: none;
        width: 300px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        margin: 0 auto;
        max-height: 260px;
        overflow-y: scroll;
        line-height: 1px !important;
        -webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
        box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.55);
    }
    .dropdown-menu-CF li:first-child {
        padding-top: 12px;
    }
    .dropdown-menu-CF li {
        padding: 10px 24px 10px 12px;
    }
    .dropdown-menu-CF li:hover {
        color: #79A342;
    }
    #courseFinderDropdowns-CF .dropdown-title {
        display: inline;
    }
    .dropdown-topics-menu-CF {
        display: none;
        width: 300px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 14px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
        list-style: none !important;
        vertical-align: top;
        max-height: 260px;
        overflow-y: scroll;
    }
    .topics-dropdown-CF li:first-child {
        padding-top: 12px;
    }
    .topics-dropdown-CF li {
        display: block;
        padding: 10px 24px 10px 12px;
        vertical-align: top;
        list-style: none !important;
        line-height: 1px !important;
    }
    .dropdown-menu-CF li i,
    .dropdown-topics-menu-CF li i {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: inline-block;
        visibility: hidden;
        color: #79A342;
    }

}