@media screen and (min-width: 1400px){ /*1300*/
    .exec-content-page #header{
        box-sizing: content-box;
    }
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #headerLogo svg{
        width:208px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/
    .exec-content-page #header{
        box-sizing: content-box;
    }
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #headerLogo svg{
        width:208px;
    }
    #mobileHeader_icons #mobileLang p {
        margin-top: 0px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/
    .exec-content-page #header{
        box-sizing: content-box;
    }

    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #headerLogo svg{
        width:208px;
    }

}
@media screen and (min-width: 766px) and (max-width: 1022px) { /*666*/
    /*.exec-content-page #mobileHeader{*/
    /*    box-sizing: content-box;*/
    /*}*/

    #mobileHeader_logo svg{
        height:52px;
        width:180px;
    }
    .exec-content-page #mobileHeader_icons #mobileLang p {
        margin-top: 0px;
    }
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #headerLogo svg{
        width:180px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/
    /*.exec-content-page #mobileHeader{*/
    /*    box-sizing: content-box;*/
    /*}*/
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #mobileHeader_logo svg{
        height:42px;
        width: 145px;
    }
    .exec-content-page #mobileHeader_icons #mobileLang p {
        margin-top: 0px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    .exec-content-page #mobileHeader{
        box-sizing: content-box;
    }
    .exec-content-page #mobileHeader_inner {
        width:340px;
    }
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #mobileHeader_logo svg{
        width:130px;
        height:37px;
    }
    .exec-content-page #mobileHeader_icons #mobileLang p {
        margin-top: 0px;
    }
}

@media screen and (max-width: 350px){/*300*/
    .exec-content-page #mobileHeader{
        box-sizing: content-box;
    }
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #mobileHeader_logo svg{
        width:130px;
        height:37px;
    }
    .exec-content-page #mobileHeader_inner {
        width:300px;
    }
    .exec-content-page #mobileHeader_icons #mobileLang p {
        margin-top: 0px;
    }
}
@media print {
    .exec-content-page #header{
        box-sizing: content-box;
    }
    /*Specifying Width to have the headerLogo and content aligned on IE11*/
    #headerLogo svg{
        width:208px;
    }
}
