@media screen and (min-width: 1400px) {
    #directoryPage{
        margin: 0 auto;
        width: 1300px;
        box-sizing: border-box;
        padding-bottom: 80px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #directoryPage{
        margin: 0 auto;
        width: 1100px;
        padding: 45px 0 85px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #directoryPage{
        margin: 0 auto;
        width: 900px;
        padding: 45px 0 85px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #directoryPage {
        margin: 0 auto;
        width: 720px;
        padding: 40px 0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #directoryPage {
        margin: 0 auto;
        width: 470px;
        padding: 40px 0;
    }
    #directoryPageOuter #herospaceTexts{
        top: 126px !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #directoryPage {
        margin: 0 auto;
        width: 340px;
        padding: 40px 0;
    }
    #directoryPageOuter #herospace{
        height: 235px !important;
    }
    #directoryPageOuter #herospace #herospaceContainer{
        height: 195px !important;
    }
    #directoryPageOuter #herospaceTexts{
        top: 84px !important;
    }
}

@media screen and (max-width: 350px){
    #directoryPage {
        margin: 0 auto;
        width: 300px;
        padding: 40px 0;
    }
    #directoryPageOuter #herospace{
        height: 235px !important;
    }
    #directoryPageOuter #herospace #herospaceContainer{
        height: 195px !important;
    }
    #directoryPageOuter #herospaceTexts{
        top: 84px !important;
    }
}