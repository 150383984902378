@media screen and (min-width: 1400px) {
    .personalContact{
        margin-top: 25px;
        border-bottom: 1px solid #ccc;
    }
    .personalContact .personalContactImage{
        width: 100%;
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .personalContact h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 16px 0 13px !important;
        line-height: 26px;
    }
    .personalContact h4{
        font-family: "Helvetica Regular", sans-serif !important;
        font-weight: bold;
        line-height: 20px;
        font-size: 16px !important;
        color: #666666 !important;
        margin: 0 0 4px !important;
    }
    .personalContact h5{
        font-family: "Helvetica Regular", sans-serif;
        font-weight: 100;
        line-height: 20px;
        font-size: 16px !important;
        color: #666666 !important;
        margin: 0 !important;
    }
    .personalContact a{
        font-family: "Helvetica Regular", sans-serif;
        font-weight: bold;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px !important;
        color: #79A342 !important;
        margin: 20px 0;
    }
    .personalContact a i{
        font-size: 14px;
        color: #808080;
    }
    .personalContact a:hover{
        color: #31417a !important;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .personalContact{
        margin-top: 25px;
        border-bottom: 1px solid #ccc;
    }
    .personalContact .personalContactImage{
        width: 100%;
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .personalContact h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 16px 0 13px !important;
        line-height: 26px;
    }
    .personalContact h4{
        font-family: "Helvetica Regular", sans-serif !important;
        font-weight: bold;
        line-height: 20px;
        font-size: 16px !important;
        color: #666666 !important;
        margin: 0 0 4px !important;
    }
    .personalContact h5{
        font-family: "Helvetica Regular", sans-serif;
        font-weight: 100;
        line-height: 20px;
        font-size: 16px !important;
        color: #666666 !important;
        margin: 0 !important;
    }
    .personalContact a{
        font-family: "Helvetica Regular", sans-serif;
        font-weight: bold;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px !important;
        color: #79A342 !important;
        margin: 20px 0;
    }
    .personalContact a i{
        font-size: 14px;
        color: #808080;
    }
    .personalContact a:hover{
        color: #31417a !important;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .personalContact{
        margin-top: 25px;
        border-bottom: 1px solid #ccc;
    }
    .personalContact .personalContactImage{
        width: 100%;
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .personalContact h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 16px 0 13px !important;
        line-height: 26px;
    }
    .personalContact h4{
        font-family: "Helvetica Regular", sans-serif !important;
        font-weight: bold;
        line-height: 20px;
        font-size: 16px !important;
        color: #666666 !important;
        margin: 0 0 4px !important;
    }
    .personalContact h5{
        font-family: "Helvetica Regular", sans-serif;
        font-weight: 100;
        line-height: 20px;
        font-size: 16px !important;
        color: #666666 !important;
        margin: 0 !important;
    }
    .personalContact a{
        font-family: "Helvetica Regular", sans-serif;
        font-weight: bold;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px !important;
        color: #79A342 !important;
        margin: 20px 0;
    }
    .personalContact a i{
        font-size: 14px;
        color: #808080;
    }
    .personalContact a:hover{
        color: #31417a !important;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

}

@media screen and (min-width: 350px) and (max-width: 478px) {

}

@media screen and (max-width: 350px){

}