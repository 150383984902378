@media screen and (min-width: 1400px) {
    #topicsPage_blogFeed{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #programmePage #topicsPage_blogFeed{
        min-height: 1000px;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        width: 840px;
        /*min-height: 344px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        margin-bottom: 10px;
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 840px;
        height: 470px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #topicsPage_blogFeed .blogPost{
        width: 380px;
        height: 350px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27,96,127,.6);
        padding: 45px 32px 0;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #topicsPage_blogFeed{
        width: 1100px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #programmePage #topicsPage_blogFeed{
        min-height: 1000px;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        width: 840px;
        /*min-height: 344px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        margin-bottom: 10px;
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 840px;
        height: 470px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #topicsPage_blogFeed .blogPost{
        width: 380px;
        height: 350px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27,96,127,.6);
        padding: 45px 32px 0;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #topicsPage_blogFeed{
        width: 900px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #programmePage #topicsPage_blogFeed{
        min-height: 1000px;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        width: 600px;
        /*min-height: 384px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        line-height: 20px;
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 600px;
        height: 400px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #topicsPage_blogFeed .blogPost{
        width: 288px;
        height: 288px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 30px 22px 10px;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #topicsPage_blogFeed{
        width: 720px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        width: 100%;
        /*min-height: 335px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 25px 0 15px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 100%;
        height: 400px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0 40px;
    }
    #topicsPage_blogFeed .blogPost{
        width: 240px;
        height: 210px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 136px;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 15px 10px;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #topicsPage_blogFeed{
        width: 440px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        /*width: 520px;*/
        /*min-height: 361px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }
    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 25px 0 15px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 100%;
        height: 300px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        margin: 30px 0 20px;
    }
    #topicsPage_blogFeed .blogPost{
        width: 100%;
        height: 260px;
        display: block;
        margin-bottom: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 20px 15px;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #topicsPage_blogFeed{
        width: 340px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        /*width: 520px;*/
        /*min-height: 491px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }
    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 25px 0 15px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 100%;
        height: 240px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        margin: 30px 0 20px;
    }
    #topicsPage_blogFeed .blogPost{
        width: 100%;
        height: 260px;
        display: block;
        margin-bottom: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 20px 15px;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (max-width: 350px){
    #topicsPage_blogFeed{
        width: 300px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        /*width: 520px;*/
        /*min-height: 559px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }
    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 25px 0 15px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 100%;
        height: 300px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        margin: 30px 0 20px;
    }
    #topicsPage_blogFeed .blogPost{
        width: 100%;
        height: 200px;
        display: block;
        margin-bottom: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 20px 15px;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 14px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}
@media print{
    #topicsPage_blogFeed{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_blogFeed_text a {
        color: #79A342;
        font-weight: 800;
        text-decoration: none;
    }
    #topicsPage_blogFeed a:hover {
        text-decoration: underline;
    }
    #programmePage #topicsPage_blogFeed{
        min-height: 1000px;
    }
    #topicsPage_blogFeed_left{
        position: relative;
        width: 840px;
        /*min-height: 344px;*/
    }
    #topicsPage_blogFeed_text{
        text-align: left;
    }

    .sticky-submenu-changed {
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    #topicsPage_blogFeed_text h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #topicsPage_blogFeed_text p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        margin-bottom: 10px;
    }

    /* video */
    #topicsPage_blogFeed iframe{
        margin: 50px 0 70px;
        width: 840px;
        height: 470px;
    }

    /* blog posts */
    #topicsPage_blogFeed #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #topicsPage_blogFeed .blogPost{
        width: 380px;
        height: 350px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27,96,127,.6);
        padding: 45px 32px 0;
        transition: .4s;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostDate{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    #topicsPage_blogFeed .blogPost .blogPostInner .blogPostAuthor{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
    }
    /* hover effects */
    #topicsPage_blogFeed .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}