[unselectable="on"] {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer 10+/Edge */
    user-select: none; /* Standard */
}

@media screen and (min-width: 1400px) {
    #homePageSliderSearch {
        position: absolute;
        bottom: 32px;
        width: 1000px;
        left: 50%;
        margin-left: -500px;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 18px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }

    #homePageSliderSearch li:nth-of-type(2) {
        min-width: 156px;
    }
    #homePageSliderSearch li:nth-of-type(4) {
        min-width: 143px;
    }
    #homePageSliderSearch li:nth-of-type(6) {
        min-width: 168px;
    }

    #homePageSliderSearch li {
        display: inline-block;
        margin: 0 2px;
    }
    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
    }
    #homePageSliderSearch p {
        display: inline-block;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch p.dropdown-title {
        color: #666;
    }
    #homePageSliderSearch i {
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        min-width: 220px;
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 6px 10px;
        font-size: 18px;
        margin-left: 15px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }

    #homePageSliderSearch #homePageSliderSearch_submitButton:hover {
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
    .homePageSliderSearch_greyBG:hover {
        cursor: pointer;
    }
    /*.homePageSliderSearch_greyBG:hover i {*/
        /*transform: rotate(180deg);*/
        /*transition: 0.4s;*/
    /*}*/
    .homePageSliderSearch_dropdown {
        width: 254px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: 1px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }

    #homePageSlider_allTheme{
        width: 838px;
        margin-left: -350px;
        padding: 20px 42px;
    }
    #homePageSlider_allTheme .checkBoxSquare{
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll{
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul{
        width: 100%;
        text-align: left;
        vertical-align: top;
        list-style: none !important;
    }
    #homePageSlider_allTheme li{
        display: inline-block !important;
        width: 264px;
        padding-left: 10px;
        vertical-align: top;
        list-style: none !important;
    }
    .homePageSliderSearch_dropdown li:hover {
        cursor: pointer;
    }
    /*.homePageSliderSearch_dropdown li:hover p {*/
        /*color: #79a342 !important;*/
    /*}*/

    /* form */
    #homePageSlider form {
        display: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #homePageSliderSearch {
        position: absolute;
        bottom: 32px;
        width: 1000px;
        left: 50%;
        margin-left: -500px;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 18px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }
    #homePageSliderSearch li {
        display: inline-block;
        margin: 0 2px;
        background: #fff;
    }

    #homePageSliderSearch li:nth-of-type(2) {
        min-width: 156px;
    }
    #homePageSliderSearch li:nth-of-type(4) {
        min-width: 143px;
    }
    #homePageSliderSearch li:nth-of-type(6) {
        min-width: 168px;
    }

    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
    }
    #homePageSliderSearch p {
        display: inline;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch i{
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        min-width: 220px;
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 6px 10px;
        font-size: 18px;
        margin-left: 15px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton:hover {
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
    .homePageSliderSearch_greyBG:hover {
        cursor: pointer;
    }
    /*.homePageSliderSearch_greyBG:hover i {*/
        /*transform: rotate(180deg);*/
        /*transition: 0.4s;*/
    /*}*/
    .homePageSliderSearch_dropdown {
        width: 254px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: 1px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666 !important;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }

    #homePageSlider_allTheme{
        width: 838px;
        margin-left: -350px;
        padding: 20px 42px;
    }
    #homePageSlider_allTheme .checkBoxSquare{
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll{
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul{
        width: 100%;
        text-align: left;
        vertical-align: top;
    }
    #homePageSlider_allTheme li{
        display: inline-block !important;
        width: 274px;
        vertical-align: top;
        list-style-type: none !important;
    }
    #homePageSliderSearch li:hover .homePageSliderSearch_dropdown {
        display: block;
    }
    .homePageSliderSearch_dropdown li:hover {
        cursor: pointer;
    }
    .homePageSliderSearch_dropdown li:hover p {
        color: #79a342 !important;
    }

    /* form */
    #homePageSlider form {
        display: none;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #homePageSliderSearch {
        position: absolute;
        bottom: 32px;
        width: 900px;
        left: 50%;
        margin-left: -450px;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }
    #homePageSliderSearch li {
        display: inline-block;
        margin: 0;
    }

    #homePageSliderSearch li:nth-of-type(2) {
        min-width: 156px;
    }
    #homePageSliderSearch li:nth-of-type(4) {
        min-width: 143px;
    }
    #homePageSliderSearch li:nth-of-type(6) {
        min-width: 168px;
    }

    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
    }
    #homePageSliderSearch p {
        display: inline;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch i{
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        min-width: 170px;
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 6px;
        font-size: 14px;
        margin-left: 5px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }

    #homePageSliderSearch #homePageSliderSearch_submitButton:hover {
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
    .homePageSliderSearch_greyBG:hover {
        cursor: pointer;
    }
    /*.homePageSliderSearch_greyBG:hover i {*/
        /*transform: rotate(180deg);*/
        /*transition: 0.4s;*/
    /*}*/

    .homePageSliderSearch_dropdown {
        width: 254px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: 1px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666 !important;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }
    #homePageSlider_allTheme{
        width: 720px;
        margin-left: -326px;
        padding: 20px 30px;
    }
    #homePageSlider_allTheme .checkBoxSquare{
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll{
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul{
        width: 100%;
        text-align: left;
        vertical-align: top;
    }
    #homePageSlider_allTheme li{
        display: inline-block !important;
        width: 250px;
        vertical-align: top;
        list-style-type: none !important;
    }
    #homePageSliderSearch li:hover .homePageSliderSearch_dropdown {
        display: block;
    }
    .homePageSliderSearch_dropdown li:hover {
        cursor: pointer;
    }
    .homePageSliderSearch_dropdown li:hover p {
        color: #79a342 !important;
    }

    /* form */
    #homePageSlider form {
        display: none;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #homePageSliderSearch {
        position: absolute;
        top: 460px;
        width: 330px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }
    #homePageSliderSearch li {
        display: block;
        margin: 0;
        text-align: left;
    }
    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
        margin-bottom: 14px;
    }
    #homePageSliderSearch p {
        display: inline;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch i {
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        margin-top: 40px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }
    .homePageSliderSearch_dropdown {
        width: 298px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: -13px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666 !important;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }
    #homePageSlider_allTheme .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll {
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul {
        width: 100%;
        text-align: left;
        vertical-align: top;
    }
    #homePageSlider_allTheme li {
        display: inline-block !important;
        width: 274px;
        vertical-align: top;
        list-style-type: none !important;
    }

    /* form */
    #homePageSlider form {
        display: none;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #homePageSliderSearch {
        position: absolute;
        top: 350px;
        width: 330px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }
    #homePageSliderSearch li {
        display: block;
        margin: 0;
        text-align: left;
    }
    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
        margin-bottom: 14px;
    }
    #homePageSliderSearch p {
        display: inline;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch i{
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        margin-top: 40px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }
    .homePageSliderSearch_dropdown {
        width: 298px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: -13px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666 !important;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }
    #homePageSlider_allTheme .checkBoxSquare{
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll{
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul{
        width: 100%;
        text-align: left;
        vertical-align: top;
    }
    #homePageSlider_allTheme li{
        display: inline-block !important;
        width: 274px;
        vertical-align: top;
        list-style-type: none !important;
    }

    /* form */
    #homePageSlider form {
        display: none;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #homePageSliderSearch {
        position: absolute;
        top: 260px;
        width: 320px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }
    #homePageSliderSearch li {
        display: block;
        margin: 0;
        text-align: left;
    }
    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
        margin-bottom: 14px;
    }
    #homePageSliderSearch p {
        display: inline;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch i{
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }
    .homePageSliderSearch_dropdown {
        width: 288px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: -13px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666 !important;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }

    #homePageSlider_allTheme{
        width: 280px;
        padding: 20px;
    }
    #homePageSlider_allTheme .checkBoxSquare{
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll{
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul{
        width: 100%;
        text-align: left;
        vertical-align: top;
    }
    #homePageSlider_allTheme li{
        display: inline-block !important;
        width: 274px;
        vertical-align: top;
        list-style-type: none !important;
    }

    /* form */
    #homePageSlider form {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    #homePageSliderSearch {
        position: absolute;
        top: 260px;
        width: 300px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    #homePageSliderSearch h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #homePageSliderSearch ul {
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        color: #666;
    }
    #homePageSliderSearch li {
        display: block;
        margin: 0;
        text-align: left;
    }
    .homePageSliderSearch_greyBG {
        background-color: #f4f5f8;
        padding: 7px;
        margin-bottom: 14px;
    }
    #homePageSliderSearch p {
        display: inline;
        color: #79a342;
        margin-right: 4px;
    }
    #homePageSliderSearch i{
        display: inline-block;
        color: #79a342;
    }
    #homePageSliderSearch #homePageSliderSearch_submitButton {
        background-color: #79a342;
        text-align: center;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
    }
    .homePageSliderSearch_dropdown {
        width: 268px;
        position: absolute;
        display: none;
        background-color: #f4f5f8;
        text-align: left !important;
        padding: 16px;
        z-index: 100;
        margin-top: -13px;
        font-size: 16px;
    }
    .homePageSliderSearch_dropdown li {
        display: block !important;
        margin: 6px 0 !important;
    }
    .homePageSliderSearch_dropdown li .checkBoxSquare {
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    .homePageSliderSearch_dropdown li i {
        visibility: hidden;
    }
    .homePageSliderSearch_dropdown li p {
        color: #666666 !important;
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
    }

    #homePageSlider_allTheme{
        width: 268px;
        padding: 16px;
    }
    #homePageSlider_allTheme .checkBoxSquare{
        border: 1px solid #31417a;
        display: inline-block;
        height: 15px;
        width: 14px;
        margin-right: 6px;
    }
    #homePageSlider_allThemeAll{
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
    }
    #homePageSlider_allTheme ul{
        width: 100%;
        text-align: left;
        vertical-align: top;
    }
    #homePageSlider_allTheme li{
        display: inline-block !important;
        width: 274px;
        vertical-align: top;
        list-style-type: none !important;
    }


    /* form */
    #homePageSlider form {
        display: none;
    }
}