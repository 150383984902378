@media screen and (min-width: 1400px){
    .news-item {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px dotted rgb(179,179,179);
    }
    
    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 1300px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 222px;
        width: 372px;
        display: inline-block;
    }

    .news-text {
        position: relative;
        display: inline-block;
        width: calc(1300px - 400px);
        height: 222px;
        padding-left: 20px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-subtitle {
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 20px;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .news-item {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 1100px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 222px;
        width: 372px;
        display: inline-block;
    }

    .news-text {
        position: relative;
        display: inline-block;
        width: calc(1100px - 400px);
        height: 222px;
        padding-left: 20px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-subtitle {
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 20px;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    .news-item {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 922px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 222px;
        width: 372px;
        display: inline-block;
    }

    .news-text {
        position: relative;
        display: inline-block;
        width: calc(922px - 400px);
        height: 222px;
        padding-left: 20px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-subtitle {
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 20px;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .news-item {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 666px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 351px;
        width: 100%;
        display: block;
        margin: 0 auto;
    }

    .news-text {
        position: relative;
        display: block;
        width: 100%;
        height: 160px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 22px;
        color: #31417a;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: left;
    }

    .news-subtitle {
        display: none;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 0;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .news-item {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 400px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 222px;
        width: 100%;
        display: block;
        margin: 0 auto;
    }

    .news-text {
        position: relative;
        display: block;
        width: 100%;
        height: 160px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 22px;
        color: #31417a;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: left;
    }

    .news-subtitle {
        display: none;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 0;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .news-item {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 320px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 180px;
        width: 100%;
        display: block;
        margin: 0 auto;
    }

    .news-text {
        position: relative;
        display: block;
        width: 100%;
        height: 160px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 20px;
        color: #31417a;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: left;
    }

    .news-subtitle {
        display: none;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 20px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 0;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@media screen and (max-width: 350px){
    .news-item {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 300px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 180px;
        width: 100%;
        display: block;
        margin: 0 auto;
    }

    .news-text {
        position: relative;
        display: block;
        width: 100%;
        height: 160px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 20px;
        color: #31417a;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: left;
    }

    .news-subtitle {
        display: none;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 20px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        vertical-align: bottom;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 14px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 0;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@media print {
    .news-item {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px dotted rgb(179,179,179);
    }

    .news-item:nth-child(even) {
        background: #F4F5F8;
    }

    .inner-news-container {
        width: 1300px;
        margin: 0 auto;
        cursor: pointer;
    }

    .news-image {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 222px;
        width: 372px;
        display: inline-block;
    }

    .news-text {
        position: relative;
        display: inline-block;
        width: calc(1300px - 400px);
        height: 222px;
        padding-left: 20px;
        vertical-align: top;
    }

    .news-title {
        font-family: "Frutiger 67 Bold Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-subtitle {
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 0 0 10px;
    }

    .news-date {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
    }

    .news-description {
        width: 100%;
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 20px;
        position: absolute;
        bottom: 10px;
        left: 20px;

        /* Refactor for IE11 */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}