body{
    background-color: #fff;
}

#tracking-pixel {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
}

.main-color {
    color: #31417A;
}

.main-dark-gray {
    color: #666666;
}

.menu-dropdown {
    display: none;
}

@media screen and (min-width: 1400px){
    #menu-dropdown-for-about .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -65px !important;;
    }

    #menu-dropdown-for-about .individuals-arrow-up {
        margin-left: 14px !important;;
    }

    #menu-dropdown-for-about .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-about a:hover {
        color: #79a342;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -40px !important;;
    }

    #menu-dropdown-for-companies .individuals-arrow-up {
        margin-left: 40px !important;;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-companies a:hover {
        color: #79a342;
    }

    #mobileHeader{
        display: none;
    }
    #mobileMenu{
        display: none;
    }
    #mobileLangBar{
        display: none;
    }

    /*
    | Header Nav Bar
    */
    #header{
        padding: 21px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        background: #fff;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #headerInner{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    /* on scroll change header height animation */
    .smallHeader {
        transition: 0.4s;
        padding: 17px 0 0 !important;
    }
    .smallHeader #headerLogo svg .bottom-line-logo {
        opacity: 0;
        transition: 0.4s;
    }

    /* the logo */
    #headerLogo {
        margin-left: 75px;
        display: inline-block;
    }
    #headerLogo svg {
        transition: 0.2s;
        height: 60px;
    }
    #headerLogo svg .bottom-line-logo {
        opacity: 1;
        transition: 0.4s;
    }
    #headerLogo svg .cls-1 {
        fill: #31417A;
    }
    #headerLogo svg .cls-2 {
        fill: #fff;
    }
    #headerLogo svg .cls-3 {
        fill: #333;
    }

    /* links (right part) */
    #header .menu {
        position: absolute;
        top: 21px;
        right: 0;
    }
    #header .menu-item {
        font-size: 18px;
        position: relative;
        display: inline-block;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        font-family: "Frutiger 57", sans-serif;
    }

    #header .menu-item:nth-child(1) {
        min-width: 130px;
    }

    #header .menu-item:nth-child(2) {
        min-width: 136px;
    }

    #header .menu-item:nth-child(3) {
        min-width: 76px;
    }

    #header .menu-item:hover {
        cursor: pointer;
    }
    #header .menu-item a{
        text-decoration: none;
    }
    #header .menu-item:hover a{
        /*font-weight: bold;*/
    }
    /* blue links */
    #header .blue-menu-item{
        margin-right: 25px;
    }
    #header .blue-menu-item a{
        color: #31417a;
    }
    #header .blue-menu-item:hover a{
        font-weight: bold;
    }
    #header .activeBlueMenuItem {
        font-weight: bold;
    }
    /* Safari 10.1+ */
    @media not all and (min-resolution:.001dpcm) { @media {
        #header .blue-menu-item:hover a{
            font-weight: bold;
        }
        #header .activeBlueMenuItem{
            font-weight: bold;
        }
    }  }
    /* font awesome icons in the menu */
    #header .menu i{
        font-size: 18px;
        color: #31417a;
    }
    #header .menu i>svg {
        height: 18px;
        width:18px;
        vertical-align: middle;
        padding-bottom: 5px;
        fill: #31417a;
    }

    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }
    #header .menu-item .-globe-inverted{
        display:none;
    }
    #header .menu-item .-search-inverted{
        display:none;
    }
    #header .menu-item:hover .-globe{
        display:none;
    }
    #header .menu-item:hover .-search{
        display:none;
    }
    #header .menu-item:hover .-globe-inverted{
        display:inline;
    }
    #header .menu-item:hover .-search-inverted{
        display:inline;
    }
    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }

    /* grey links */
    #header .grey-menu-item-buttons {
        width: 50px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-weight: 100;
    }
    #header .grey-menu-item-buttons:hover {
        cursor: pointer;
        transition:  0.2s;
        font-weight: 700;
    }
    #header .grey-menu-item-buttons a{
        text-decoration: none;
        color: #666666;
    }
    #header .menu-item-border-left {
        border-left: 1px solid #31417A;
        padding-left: 22px;
        padding-right: 18px;
    }
    #header .menu-item-border-right {
        border-right: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    #header .menu-item-both-sites-border {
        border-right: 1px solid #31417A;
        border-left: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    /* language menu item */
    #header .language-menu-item{

    }
    #header .language-menu-item a{
        color: #666;
        margin-left: 14px;
    }

    /*
    | Drop Downs
    */
    #header .menu-item:hover .menu-dropdown{
        display: block;
        position: absolute;
    }
    #header .menu-item:hover .menu-dropdown a{
        font-weight: 100 !important;
        text-shadow: none;
    }
    /* language dropdown */
    #header .language-submenu-dropdown {
        background-color: #F4F5F8;
    }
    #header .language-submenu-dropdown li {
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding: 16px 24px;
        border-bottom: 1px solid white;
    }
    #header .language-submenu-dropdown li:last-child {
        border-bottom: none;
    }
    #header .language-submenu-dropdown a {
        margin-left: 0;
        color: #31417A;
        font-weight: 100;
    }
    #header .language-submenu-dropdown a:hover {
        color: #79A342;
    }
    #header .language-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        margin: 24px 0 0 18px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        #header .language-arrow-up {
            margin-top: 25px;
        }
    }
    /* For Individuals dropdown */
    #header #menu-dropdown-for-individuals {
        font-family: "Frutiger 57", sans-serif;
        z-index: 1000;
        opacity: 0;
        transition: opacity 0.2s;
    }
    #header .menu-item:hover #menu-dropdown-for-individuals {
        display: block;
        opacity: 1;
        transition: opacity 0.7s;
        /* Don't hide the menu */
        margin-left: -400px;
        width: 1000px;
    }
    #header .individual-submenu-dropdown {
        width: 900px;
        display: block;
        margin-left: 0;
        vertical-align: top;
        background: #F4F5F8;
        padding: 10px 0;
    }
    #header .individual-submenu-dropdown li {
        display: inline-block;
        width: 264px;
        background: #F4F5F8;
        text-align: left;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-left: 20px;
        color: #666666;
    }
    #header .individuals-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        /* Don't hide the menu */
        margin-left: 432px;
        margin-top: 24px;
    }
    #header #menu-dropdown-for-individuals a {
        text-decoration: none;
        color: #666;
    }
    #header .individual-submenu-dropdown li a {
        text-decoration: none;
        color: #666;
        font-weight: 100;
    }
    #header #menu-dropdown-for-individuals a:hover {
        color: #79A342;
    }

    /*
    | Search Bar
    */
    #search-bar {
        width: 0;
        position: absolute;
        top: 10px;
        right: 227px;
        display: none;
        min-height: 24px;
        vertical-align: top;
        border: 1px solid #31417A;
        padding: 10px;
        background: #EAECF1;
    }
    #search-bar input{
        color:#000;
    }
    #search-bar input::placeholder {
        color:#757575;
    }
    #searchIcon {
        display: none;
        margin-left: 8px;
    }
    #searchIcon i {
        font-size: 18px;
    }
    #searchIcon:hover {
        cursor: pointer;
    }
    #searchField {
        display: none;
        width: 85%;
        border: none;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        background: #EAECF1;
        margin-left: 16px;
        outline: none;
    }
    #closeButton {
        display: none;
    }
    #closeButton i {
        font-size: 18px;
        font-weight: 400;
        margin-left: 48px;
    }
    #closeButton:hover {
        cursor: pointer;
    }
    #closeButton i:hover {
        cursor: pointer;
        font-weight: bold;
    }

    /* on scroll change margin top of the dropdowns animation */
    .smallHeader .menu{
        top: 14px !important;
        transition: 0.4s;
    }
    .smallHeader .menu-dropdown {
        transition: 0.4s;
        margin-top: 0 !important;
    }
    .smallHeader .individuals-arrow-up,
    .smallHeader .language-arrow-up {
        margin-top: 10px !important;
    }
    .smallHeader #search-bar{
        top: 2px !important;
        transition: 0.4s;
    }
    #site-search-autocomplete {
        width: 100%;
        position: absolute;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background: #fff;
        top: 45px;
        left: 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #menu-dropdown-for-about .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -65px !important;;
    }

    #menu-dropdown-for-about .individuals-arrow-up {
        margin-left: 14px !important;;
    }

    #menu-dropdown-for-about .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-about a:hover {
        color: #79a342;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -42px !important;;
    }

    #menu-dropdown-for-companies .individuals-arrow-up {
        margin-left: 36px !important;;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-companies a:hover {
        color: #79a342;
    }

    #mobileHeader{
        display: none;
    }
    #mobileMenu{
        display: none;
    }
    #mobileLangBar{
        display: none;
    }

    /*
    | Header Nav Bar
    */
    #header{
        padding: 21px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        background: #fff;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #headerInner{
        width: 1100px;
        margin: 0 auto;
        position: relative;
    }
    /* on scroll change header height animation */
    .smallHeader {
        transition: 0.4s;
        padding: 17px 0 0 !important;
    }
    .smallHeader #headerLogo svg .bottom-line-logo {
        opacity: 0;
        transition: 0.4s;
    }
    /* on scroll change margin top of the dropdowns animation */
    .smallHeader .menu{
        top: 14px !important;
        transition: 0.4s;
    }
    .smallHeader .menu-dropdown {
        transition: 0.4s;
        margin-top: 0 !important;
    }
    .smallHeader .individuals-arrow-up,
    .smallHeader .language-arrow-up{
        margin-top: 10px !important;
    }
    .smallHeader #search-bar{
        top: 2px !important;
        transition: 0.4s;
    }

    /* the logo */
    #headerLogo {
        display: inline-block;
    }
    #headerLogo svg {
        transition: 0.2s;
        height: 60px;
    }
    #headerLogo svg .bottom-line-logo {
        opacity: 1;
        transition: 0.4s;
    }
    #headerLogo svg .cls-1 {
        fill: #31417A;
    }
    #headerLogo svg .cls-2 {
        fill: #fff;
    }
    #headerLogo svg .cls-3 {
        fill: #333;
    }

    /* links (right part) */
    #header .menu {
        position: absolute;
        top: 21px;
        right: 0;
    }
    #header .menu-item {
        font-size: 18px;
        position: relative;
        display: inline-block;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        font-family: "Frutiger 57", sans-serif;
    }

    #header .menu-item:nth-child(1) {
        min-width: 130px;
    }

    #header .menu-item:nth-child(2) {
        min-width: 136px;
    }

    #header .menu-item:nth-child(3) {
        min-width: 76px;
    }

    #header .menu-item:hover {
        cursor: pointer;
    }
    #header .menu-item a{
        text-decoration: none;
    }
    #header .menu-item:hover a{
        /*font-weight: bold;*/
    }
    /* blue links */
    #header .blue-menu-item{
        margin-right: 25px;
    }
    #header .blue-menu-item a{
        color: #31417a;
    }
    #header .blue-menu-item:hover a{
        font-weight: bold;
    }
    /* Safari 10.1+ */
    @media not all and (min-resolution:.001dpcm) { @media {
        #header .blue-menu-item:hover a{
            font-weight: bold;
        }
    }  }
    /* font awesome icons in the menu */
    #header .menu i>svg {
        height: 18px;
        width:18px;
        vertical-align: middle;
        padding-bottom: 5px;
        fill: #31417a;
    }

    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }
    #header .menu-item .-globe-inverted{
        display:none;
    }
    #header .menu-item .-search-inverted{
        display:none;
    }
    #header .menu-item:hover .-globe{
        display:none;
    }
    #header .menu-item:hover .-search{
        display:none;
    }
    #header .menu-item:hover .-globe-inverted{
        display:inline;
    }
    #header .menu-item:hover .-search-inverted{
        display:inline;
    }
    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }
    /* grey links */
    #header .grey-menu-item-buttons {
        width: 50px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-weight: 100;
    }
    #header .grey-menu-item-buttons:hover {
        cursor: pointer;
        transition:  0.2s;
        font-weight: 700;
    }
    #header .grey-menu-item-buttons a{
        text-decoration: none;
        color: #666666;
    }
    #header .menu-item-border-left {
        border-left: 1px solid #31417A;
        padding-left: 22px;
        padding-right: 18px;
    }
    #header .menu-item-border-right {
        border-right: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    #header .menu-item-both-sites-border {
        border-right: 1px solid #31417A;
        border-left: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    /* language menu item */
    #header .language-menu-item{

    }
    #header .language-menu-item a{
        color: #666;
        margin-left: 14px;
    }

    /*
    | Drop Downs
    */
    #header .menu-item:hover .menu-dropdown{
        display: block;
        position: absolute;
    }
    #header .menu-item:hover .menu-dropdown a{
        font-weight: 100 !important;
        text-shadow: none;
    }
    /* language dropdown */
    #header .language-submenu-dropdown {
        background-color: #F4F5F8;
    }
    #header .language-submenu-dropdown li {
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding: 16px 24px;
        border-bottom: 1px solid white;
    }
    #header .language-submenu-dropdown li:last-child {
        border-bottom: none;
    }
    #header .language-submenu-dropdown a {
        margin-left: 0;
        color: #31417A;
        font-weight: 100;
    }
    #header .language-submenu-dropdown a:hover {
        color: #79A342;
    }
    #header .language-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        margin: 24px 0 0 18px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        #header .language-arrow-up {
            margin-top: 25px;
        }
    }
    /* For Individuals dropdown */
    #header #menu-dropdown-for-individuals {
        font-family: "Frutiger 57", sans-serif;
        z-index: 1000;
        opacity: 0;
        transition: opacity 0.2s;
    }
    #header .menu-item:hover #menu-dropdown-for-individuals {
        display: block;
        opacity: 1;
        transition: opacity 0.7s;
        margin-left: -330px;
        width: 1000px;
    }
    #header .individual-submenu-dropdown {
        width: 900px;
        display: block;
        margin-left: 0;
        vertical-align: top;
        background: #F4F5F8;
        padding: 10px 0;
    }
    #header .individual-submenu-dropdown li {
        display: inline-block;
        width: 264px;
        background: #F4F5F8;
        text-align: left;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-left: 20px;
        color: #666666;
    }
    #header .individuals-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        margin-left: 366px;
        margin-top: 24px;
    }
    #header #menu-dropdown-for-individuals a {
        text-decoration: none;
        color: #666;
    }
    #header .individual-submenu-dropdown li a {
        text-decoration: none;
        color: #666;
        font-weight: 100;
    }
    #header #menu-dropdown-for-individuals a:hover {
        color: #79A342;
    }

    /*
    | Search Bar
    */
    #search-bar {
        width: 0;
        position: absolute;
        box-sizing: border-box;
        top: 10px;
        right: 227px;
        display: none;
        min-height: 44px;
        vertical-align: top;
        border: 1px solid #31417A;
        padding: 10px;
        background: #EAECF1;
    }
    #search-bar input::placeholder {
        color:#757575;
    }
    #searchIcon {
        display: none;
        margin-left: 8px;
    }
    #searchIcon i {
        font-size: 18px;
    }
    #searchIcon:hover {
        cursor: pointer;
    }
    #searchField {
        display: none;
        width: 85%;
        border: none;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        background: #EAECF1;
        margin-left: 16px;
        outline: none;
    }
    #closeButton {
        display: none;
    }
    #closeButton i {
        font-size: 18px;
        font-weight: 400;
        margin-left: 38px;
    }
    #closeButton i:hover {
        cursor: pointer;
        font-weight: bold;
    }
    #site-search-autocomplete {
        width: 100%;
        position: absolute;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background: #fff;
        top: 45px;
        left: 0;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    #menu-dropdown-for-about .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -65px !important;;
    }

    #menu-dropdown-for-about .individuals-arrow-up {
        margin-left: 14px !important;;
    }

    #menu-dropdown-for-about .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-about a:hover {
        color: #79a342;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -40px !important;;
    }

    #menu-dropdown-for-companies .individuals-arrow-up {
        margin-left: 40px !important;;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-companies a:hover {
        color: #79a342;
    }

    #mobileHeader{
        display: none;
    }
    #mobileMenu{
        display: none;
    }
    #mobileLangBar{
        display: none;
    }

    /*
    | Header Nav Bar
    */
    #header{
        padding: 21px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        background: #fff;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #headerInner{
        width: 900px;
        margin: 0 auto;
        position: relative;
    }
    /* on scroll change header height animation */
    .smallHeader {
        transition: 0.4s;
        padding: 17px 0 0 !important;
    }
    .smallHeader #headerLogo svg .bottom-line-logo {
        opacity: 0;
        transition: 0.4s;
    }
    /* on scroll change margin top of the dropdowns animation */
    .smallHeader .menu{
        top: 14px !important;
        transition: 0.4s;
    }
    .smallHeader .menu-dropdown {
        transition: 0.4s;
        margin-top: 0 !important;
    }
    .smallHeader .individuals-arrow-up,
    .smallHeader .language-arrow-up{
        margin-top: 10px !important;
    }
    .smallHeader #search-bar{
        top: 2px !important;
        transition: 0.4s;
    }

    /* the logo */
    #headerLogo {
        display: inline-block;
    }
    #headerLogo svg {
        transition: 0.2s;
        height: 60px;
    }
    #headerLogo svg .bottom-line-logo {
        opacity: 1;
        transition: 0.4s;
    }
    #headerLogo svg .cls-1 {
        fill: #31417A;
    }
    #headerLogo svg .cls-2 {
        fill: #fff;
    }
    #headerLogo svg .cls-3 {
        fill: #333;
    }

    /* links (right part) */
    #header .menu {
        position: absolute;
        top: 21px;
        right: 0;
    }
    #header .menu-item {
        font-size: 18px;
        position: relative;
        display: inline-block;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        font-family: "Frutiger 57", sans-serif;
    }

    #header .menu-item:nth-child(1) {
        min-width: 130px;
    }

    #header .menu-item:nth-child(2) {
        min-width: 136px;
    }

    #header .menu-item:nth-child(3) {
        min-width: 76px;
    }

    #header .menu-item:hover {
        cursor: pointer;
    }
    #header .menu-item a{
        text-decoration: none;
    }
    #header .menu-item:hover a{
        /*font-weight: bold;*/
    }
    /* blue links */
    #header .blue-menu-item{
        margin-right: 18px;
    }
    #header .blue-menu-item a{
        color: #31417a;
    }
    #header .blue-menu-item:hover a{
        font-weight: bold;
    }
    /* Safari 10.1+ */
    @media not all and (min-resolution:.001dpcm) { @media {
        #header .blue-menu-item:hover a{
            font-weight: bold;
        }
    }  }
    /* font awesome icons in the menu */
    #header .menu i{
        font-size: 18px;
        color: #31417a;
    }
    #header .menu i>svg {
        height: 18px;
        width:18px;
        vertical-align: middle;
        padding-bottom: 2px;
        fill: #31417a;
    }

    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }

    #header .menu-item .-globe-inverted{
        display:none;
    }
    #header .menu-item .-search-inverted{
        display:none;
    }
    #header .menu-item:hover .-globe{
        display:none;
    }
    #header .menu-item:hover .-search{
        display:none;
    }
    #header .menu-item:hover .-globe-inverted{
        display:inline;
    }
    #header .menu-item:hover .-search-inverted{
        display:inline;
    }

    /* grey links */
    #header .grey-menu-item-buttons {
        width: 50px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-weight: 100;
    }
    #header .grey-menu-item-buttons:hover {
        cursor: pointer;
        transition:  0.2s;
        font-weight: 700;
    }
    #header .grey-menu-item-buttons a{
        text-decoration: none;
        color: #666666;
    }
    #header .menu-item-border-left {
        border-left: 1px solid #31417A;
        padding-left: 22px;
        padding-right: 18px;
    }
    #header .menu-item-border-right {
        border-right: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    #header .menu-item-both-sites-border {
        border-right: 1px solid #31417A;
        border-left: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    /* language menu item */
    #header .language-menu-item{

    }
    #header .language-menu-item a{
        color: #666;
        margin-left: 14px;
    }

    /*
    | Drop Downs
    */
    #header .menu-item:hover .menu-dropdown{
        display: block;
        position: absolute;
    }
    #header .menu-item:hover .menu-dropdown a{
        font-weight: 100 !important;
        text-shadow: none;
    }
    /* language dropdown */
    #header .language-submenu-dropdown {
        background-color: #F4F5F8;
    }
    #header .language-submenu-dropdown li {
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding: 16px 24px;
        border-bottom: 1px solid white;
    }
    #header .language-submenu-dropdown li:last-child {
        border-bottom: none;
    }
    #header .language-submenu-dropdown a {
        margin-left: 0;
        color: #31417A;
        font-weight: 100;
    }
    #header .language-submenu-dropdown a:hover {
        color: #79A342;
    }
    #header .language-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        margin: 24px 0 0 18px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        #header .language-arrow-up {
            margin-top: 25px;
        }
    }
    /* For Individuals dropdown */
    #header #menu-dropdown-for-individuals {
        font-family: "Frutiger 57", sans-serif;
        z-index: 1000;
        opacity: 0;
        transition: opacity 0.2s;
    }
    #header .menu-item:hover #menu-dropdown-for-individuals {
        display: block;
        opacity: 1;
        transition: opacity 0.7s;
        margin-left: -260px;
    }
    #header .individual-submenu-dropdown {
        width: 900px;
        display: block;
        margin-left: 0;
        vertical-align: top;
        background: #F4F5F8;
        padding: 10px 0;
    }
    #header .individual-submenu-dropdown li {
        display: inline-block;
        width: 264px;
        background: #F4F5F8;
        text-align: left;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-left: 20px;
        color: #666666;
    }
    #header .individuals-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        margin-left: 296px;
        margin-top: 24px;
    }
    #header #menu-dropdown-for-individuals a {
        text-decoration: none;
        color: #666;
    }
    #header .individual-submenu-dropdown li a {
        text-decoration: none;
        color: #666;
        font-weight: 100;
    }
    #header #menu-dropdown-for-individuals a:hover {
        color: #79A342;
    }

    /*
    | Search Bar
    */
    #search-bar {
        width: 0;
        position: absolute;
        box-sizing: border-box;
        top: 10px;
        right: 227px;
        display: none;
        min-height: 44px;
        vertical-align: top;
        border: 1px solid #31417A;
        padding: 10px;
        background: #EAECF1;
    }
    #search-bar input{
        color:#000;
    }
    #search-bar input::placeholder {
        color:#757575;
    }
    #searchIcon {
        display: none;
        margin-left: 8px;
    }
    #searchIcon i {
        font-size: 18px;
    }
    #searchIcon:hover {
        cursor: pointer;
    }
    #searchField {
        display: none;
        width: 85%;
        border: none;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        background: #EAECF1;
        margin-left: 16px;
        outline: none;
    }
    #closeButton {
        display: none;
    }
    #closeButton i {
        font-size: 18px;
        font-weight: 400;
        margin-left: 20px;
    }
    #closeButton i:hover {
        cursor: pointer;
        font-weight: bold;
    }
    #site-search-autocomplete {
        width: 100%;
        position: absolute;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background: #fff;
        top: 45px;
        left: 0;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #header{
        display: none;
    }

    #mobileHeader{
        background-color: #fff;
        padding: 25px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 720px;
        height: 52px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 260px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader_icons li{
        display: inline-block;
        padding-left: 20px;
        padding-right: 20px;
    }
    #mobileHeader_icons #mobileSearch{
        /*width: 544px;*/
        background-color: transparent;
        height: 52px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        /*border-bottom: 1px solid #666666;*/
        border-bottom: none;
        font-size: 18px;
        width: 400px;
        background-color: #f4f5f8;
        margin: 14px 0 0 30px;
        position: relative;
        bottom: 5px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch .-times{
        display: none;
        fill: #31417a;
        height: 22px;
        width: 22px;
        margin-top: 16px;
    }
    #mobileHeader_icons #mobileSearch .-times svg{
     fill: #31417a;
     height: 22px;
     width: 22px;
    }
    #mobileHeader_icons #mobileSearch .-search{
        display: inline-block;
        fill: #31417a;
        height: 22px;
        width: 22px;
        float: right;
        margin-top: 16px;
        fill:#31417a;
    }

    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 12px 20px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        vertical-align: top;
        margin-right: 4px;
        margin-top: 6px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 30px;
        width: 30px;
        height: 30px;
    }
    #mobileHeader_icons #mobileLang svg{
        height: 30px;
        width: 30px;
        fill: #31417a;
    }
    #mobileHeader_icons #mobileBurger{
        color: #31417a;
        font-size: 32px;
        width: 32px;
        height: 32px;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 32px;
        width: 32px;
    }

    /*
    *   mobile menu

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 102px;
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 102px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 28px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 24px;
        color: #666;
        text-decoration: none;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 102px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 20px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }

    #site-search-autocomplete-mobile {
        width: 580px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 77px;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #header{
        display: none;
    }

    #mobileHeader{
        background-color: #fff;
        padding: 25px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 440px;
        height: 42px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 216px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader_icons li{
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
    }
    #mobileHeader_icons #mobileSearch{
        /*width: 544px;*/
        background-color: transparent;
        height: 42px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        font-size: 18px;
        width: 200px;
        background-color: #f4f5f8;
        margin: 10px 0 0 8px;
        position: relative;
        bottom: 5px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch .-times{
        display: none;
        fill: #31417a;
        height: 24px;
        width: 24px;
        margin-top: 9px;
    }
    #mobileHeader_icons #mobileSearch .-times svg{
        fill:#31417a;
        height: 24px;
        width: 24px;
    }
    #mobileHeader_icons #mobileSearch .-search{
        display: inline-block;
        fill: #31417a;
        height: 21px;
        width: 21px;
        float: right;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch .-search svg{
        fill:#31417a;
        height: 21px;
        width: 21px;
    }
    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 8px 20px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        vertical-align: top;
        margin-right: 4px;
        margin-top: 5px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 26px;
    }
    #mobileHeader_icons #mobileLang svg{
        height: 26px;
        width: 26px;
        fill: #31417a;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 30px;
        width: 30px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 92px;
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 92px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 28px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 24px;
        color: #666;
        text-decoration: none;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 92px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 20px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }

    #site-search-autocomplete-mobile {
        width: 308px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 66px;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #header{
        display: none;
    }

    #mobileHeader{
        background-color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 340px;
        height: 42px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 130px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader_icons li{
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
    }
    #mobileHeader_icons #mobileSearch{
        /*width: 544px;*/
        background-color: transparent;
        height: 39px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        /*border-bottom: 1px solid #666666;*/
        border-bottom: none;
        font-size: 16px;
        width: 138px;
        background-color: #f4f5f8;
        margin: 0;
        position: relative;
        bottom: 5px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch .-times{
        display: none;
        fill: #31417a;
        height: 20px;
        width: 20px;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch .-times svg{
        fill:#31417a;
        height: 20px;
        width: 20px;
    }
    #mobileHeader_icons #mobileSearch .-search{
        display: inline-block;
        fill: #31417a;
        height: 18px;
        width: 18px;
        float: right;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch .-search svg{
        fill: #31417a;
        height: 18px;
        width: 18px;
    }

    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 8px 12px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 4px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 22px;
    }
    #mobileHeader_icons #mobileLang svg{
        fill: #31417a;
        height: 22px;
        width: 22px;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 24px;
        width: 24px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 62px;
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 62px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 26px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
        padding: 10px 0 10px 15px;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 20px;
        color: #666;
        text-decoration: none;
        line-height: 28px;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 62px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 15px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }

    #site-search-autocomplete-mobile {
        width: 308px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 49px;
        right: 0;
        left: 0;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 12px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

@media screen and (max-width: 350px){
    #header{
        display: none;
    }

    #mobileHeader{
        background-color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 300px;
        height: 42px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 130px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader_icons li{
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
    }
    #mobileHeader_icons #mobileSearch{
        /*width: 544px;*/
        background-color: transparent;
        height: 39px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        /*border-bottom: 1px solid #666666;*/
        border-bottom: none;
        font-size: 14px;
        width: 100px;
        background-color: #f4f5f8;
        margin: 0;
        position: relative;
        bottom: 5px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch .-times{
        display: none;
        fill: #31417a;
        height: 20px;
        width: 20px;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch .-times svg{
        fill: #31417a;
        height: 20px;
        width: 20px;
    }
    #mobileHeader_icons #mobileSearch .-search{
        display: inline-block;
        fill: #31417a;
        height: 18px;
        float: right;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch .-search svg{
        fill: #31417a;
        height: 18px;
        width: 18px;
    }
    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 8px 12px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 4px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 22px;
    }
    #mobileHeader_icons #mobileLang svg{
        height: 22px;
        width: 22px;
        fill: #31417a;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 24px;
        width: 24px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 62px;
        right: -100%;
        width: 100vw;
        max-height: calc(100vh - 62px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 26px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
        padding: 10px 0 10px 15px;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 20px;
        color: #666;
        text-decoration: none;
        line-height: 28px;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 62px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 15px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }

    #site-search-autocomplete-mobile {
        width: 300px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 66px;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

@media print {
    #menu-dropdown-for-about .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -65px !important;;
    }

    #menu-dropdown-for-about .individuals-arrow-up {
        margin-left: 14px !important;;
    }

    #menu-dropdown-for-about .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-about a:hover {
        color: #79a342;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown {
        width: 200px !important;
        margin-left: -40px !important;;
    }

    #menu-dropdown-for-companies .individuals-arrow-up {
        margin-left: 40px !important;;
    }

    #menu-dropdown-for-companies .individual-submenu-dropdown li {
        width: 180px !important;
        margin-left: 0 !important;
        text-align: center !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    #header #menu-dropdown-for-companies a:hover {
        color: #79a342;
    }

    #mobileHeader{
        display: none;
    }
    #mobileMenu{
        display: none;
    }
    #mobileLangBar{
        display: none;
    }

    /*
    | Header Nav Bar
    */
    #header{
        padding: 21px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        background: #fff;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #headerInner{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    /* on scroll change header height animation */
    .smallHeader {
        transition: 0.4s;
        padding: 17px 0 0 !important;
    }
    .smallHeader #headerLogo svg .bottom-line-logo {
        opacity: 0;
        transition: 0.4s;
    }

    /* the logo */
    #headerLogo {
        margin-left: 75px;
        display: inline-block;
    }
    #headerLogo svg {
        transition: 0.2s;
        height: 60px;
    }
    #headerLogo svg .bottom-line-logo {
        opacity: 1;
        transition: 0.4s;
    }
    #headerLogo svg .cls-1 {
        fill: #31417A;
    }
    #headerLogo svg .cls-2 {
        fill: #fff;
    }
    #headerLogo svg .cls-3 {
        fill: #333;
    }

    /* links (right part) */
    #header .menu {
        position: absolute;
        top: 21px;
        right: 0;
    }
    #header .menu-item {
        font-size: 18px;
        position: relative;
        display: inline-block;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        font-family: "Frutiger 57", sans-serif;
    }
    #header .menu-item:hover {
        cursor: pointer;
    }
    #header .menu-item a{
        text-decoration: none;
    }
    #header .menu-item:hover a{
        /*font-weight: bold;*/
    }
    /* blue links */
    #header .blue-menu-item{
        margin-right: 25px;
    }
    #header .blue-menu-item a{
        color: #31417a;
    }
    #header .blue-menu-item:hover a{
        font-weight: bold;
    }
    #header .activeBlueMenuItem {
        font-weight: bold;
    }
    /* Safari 10.1+ */
    @media not all and (min-resolution:.001dpcm) { @media {
        #header .blue-menu-item:hover a{
            font-weight: bold;
        }
        #header .activeBlueMenuItem{
            font-weight: bold;
        }
    }  }
    /* font awesome icons in the menu */
    #header .menu i{
        font-size: 18px;
        color: #31417a;
    }
    #header .menu i>svg {
        height: 18px;
        width:18px;
        vertical-align: middle;
        padding-bottom: 5px;
        fill: #31417a;
    }

    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }
    #header .menu-item .-globe-inverted{
        display:none;
    }
    #header .menu-item .-search-inverted{
        display:none;
    }
    #header .menu-item:hover .-globe{
        display:none;
    }
    #header .menu-item:hover .-search{
        display:none;
    }
    #header .menu-item:hover .-globe-inverted{
        display:inline;
    }
    #header .menu-item:hover .-search-inverted{
        display:inline;
    }
    #header .menu-item:hover i{
        font-weight: 600;
        cursor: pointer;
    }

    /* grey links */
    #header .grey-menu-item-buttons {
        width: 50px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-weight: 100;
    }
    #header .grey-menu-item-buttons:hover {
        cursor: pointer;
        transition:  0.2s;
        font-weight: 700;
    }
    #header .grey-menu-item-buttons a{
        text-decoration: none;
        color: #666666;
    }
    #header .menu-item-border-left {
        border-left: 1px solid #31417A;
        padding-left: 22px;
        padding-right: 18px;
    }
    #header .menu-item-border-right {
        border-right: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    #header .menu-item-both-sites-border {
        border-right: 1px solid #31417A;
        border-left: 1px solid #31417A;
        padding-left: 14px;
        padding-right: 14px;
    }
    /* language menu item */
    #header .language-menu-item{

    }
    #header .language-menu-item a{
        color: #666;
        margin-left: 14px;
    }

    /*
    | Drop Downs
    */
    #header .menu-item:hover .menu-dropdown{
        display: block;
        position: absolute;
    }
    #header .menu-item:hover .menu-dropdown a{
        font-weight: 100 !important;
        text-shadow: none;
    }
    /* language dropdown */
    #header .language-submenu-dropdown {
        background-color: #F4F5F8;
    }
    #header .language-submenu-dropdown li {
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding: 16px 24px;
        border-bottom: 1px solid white;
    }
    #header .language-submenu-dropdown li:last-child {
        border-bottom: none;
    }
    #header .language-submenu-dropdown a {
        margin-left: 0;
        color: #31417A;
        font-weight: 100;
    }
    #header .language-submenu-dropdown a:hover {
        color: #79A342;
    }
    #header .language-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        margin: 24px 0 0 18px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        #header .language-arrow-up {
            margin-top: 25px;
        }
    }
    /* For Individuals dropdown */
    #header #menu-dropdown-for-individuals {
        font-family: "Frutiger 57", sans-serif;
        z-index: 1000;
        opacity: 0;
        transition: opacity 0.2s;
    }
    #header .menu-item:hover #menu-dropdown-for-individuals {
        display: block;
        opacity: 1;
        transition: opacity 0.7s;
        /* Don't hide the menu */
        margin-left: -400px;
        width: 1000px;
    }
    #header .individual-submenu-dropdown {
        width: 900px;
        display: block;
        margin-left: 0;
        vertical-align: top;
        background: #F4F5F8;
        padding: 10px 0;
    }
    #header .individual-submenu-dropdown li {
        display: inline-block;
        width: 264px;
        background: #F4F5F8;
        text-align: left;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-left: 20px;
        color: #666666;
    }
    #header .individuals-arrow-up {
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 18px solid #F4F5F8;
        /* Don't hide the menu */
        margin-left: 432px;
        margin-top: 24px;
    }
    #header #menu-dropdown-for-individuals a {
        text-decoration: none;
        color: #666;
    }
    #header .individual-submenu-dropdown li a {
        text-decoration: none;
        color: #666;
        font-weight: 100;
    }
    #header #menu-dropdown-for-individuals a:hover {
        color: #79A342;
    }

    /*
    | Search Bar
    */
    #search-bar {
        width: 0;
        position: absolute;
        top: 10px;
        right: 227px;
        display: none;
        min-height: 24px;
        vertical-align: top;
        border: 1px solid #31417A;
        padding: 10px;
        background: #EAECF1;
    }
    #search-bar input{
        color:#000;
    }
    #search-bar input::placeholder {
        color:#757575;
    }
    #searchIcon {
        display: none;
        margin-left: 8px;
    }
    #searchIcon i {
        font-size: 18px;
    }
    #searchIcon:hover {
        cursor: pointer;
    }
    #searchField {
        display: none;
        width: 85%;
        border: none;
        font-family: "Frutiger 57", sans-serif;
        font-size: 18px;
        background: #EAECF1;
        margin-left: 16px;
        outline: none;
    }
    #closeButton {
        display: none;
    }
    #closeButton i {
        font-size: 18px;
        font-weight: 400;
        margin-left: 48px;
    }
    #closeButton:hover {
        cursor: pointer;
    }
    #closeButton i:hover {
        cursor: pointer;
        font-weight: bold;
    }

    /* on scroll change margin top of the dropdowns animation */
    .smallHeader .menu{
        top: 14px !important;
        transition: 0.4s;
    }
    .smallHeader .menu-dropdown {
        transition: 0.4s;
        margin-top: 0 !important;
    }
    .smallHeader .individuals-arrow-up,
    .smallHeader .language-arrow-up {
        margin-top: 10px !important;
    }
    .smallHeader #search-bar{
        top: 2px !important;
        transition: 0.4s;
    }

}
#site-search-autocomplete {
    width: 100%;
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #fff;
    top: 50px;
    left: 0;
}

.site-search-autocomplete-term {
    padding: 5px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #eeede9;
    color: #666;
}

.site-search-autocomplete-term:hover {
    color: #72993E;
}

.site-search-autocomplete-term a {
    color: #72993E;
    text-decoration: none;
}
