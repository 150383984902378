@media screen and (min-width: 1400px){
    #mobileHeader-OS {
        display: none;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1400px){
    #mobileHeader-OS {
        display: none;
    }
}
@media screen and (min-width: 1022px) and (max-width: 1200px){
    #mobileHeader-OS {
        display: none;
    }
}
@media screen and (min-width: 766px) and (max-width: 1022px) {
    #header-OS{
        display: none;
    }

    #mobileHeader-OS{
        background-color: #fff;
        padding: 25px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 720px;
        height: 52px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 260px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader_icons li{
        display: inline-block;
        padding-left: 20px;
        padding-right: 20px;
    }
    #mobileHeader_icons #mobileSearch-OS{
        /*width: 544px;*/
        background-color: transparent;
        height: 52px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch-OS input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        font-size: 18px;
        width: 400px;
        background-color: #f4f5f8;
        margin: 14px 0 0 30px;
        position: relative;
        bottom: 5px;
        padding: 1px 2px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch-OS input::placeholder{
        color:#757575;
    }
    #mobileHeader_icons #mobileSearch-OS input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch-OS .-times{
        display: none;
        fill: #31417a;
        height: 22px;
        width:22px;
        margin-top: 16px;
    }
    #mobileHeader_icons #mobileSearch-OS .-times svg{
        fill: #31417a;
        height: 22px;
        width:22px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search{
        display: inline-block;
        fill: #31417a;
        height: 22px;
        width:22px;
        float: right;
        margin-top: 16px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search svg{
        height: 22px;
        width: 22px;
        fill:#31417a;
    }
    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 12px 20px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        vertical-align: top;
        margin-right: 4px;
        margin-top: 6px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 30px;
    }
    #mobileHeader_icons #mobileLang svg{
        height: 30px;
        width:30px;
        fill: #31417a;
    }
    #mobileHeader_icons #mobileBurger{
        color: #31417a;
        font-size: 32px;
        display: initial;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 32px;
        width: 32px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 102px;
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 102px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 28px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 24px;
        color: #666;
        text-decoration: none;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 102px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 20px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileHeader-OS #site-search-autocomplete-mobile {
        width: 540px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 77px;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #header-OS{
        display: none;
    }

    #mobileHeader-OS{
        background-color: #fff;
        padding: 25px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 440px;
        height: 42px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 216px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader-OS #mobileHeader_icons{
        position: absolute;
        right: 0;
        top:0;
        bottom:0;
    }
    #mobileHeader-OS #mobileHeader_icons li{
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    #mobileHeader_icons #mobileSearch-OS{
        /*width: 544px;*/
        background-color: transparent;
        height: 42px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch-OS input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        font-size: 18px;
        width: 190px;
        background-color: #f4f5f8;
        margin: 10px 0 0 8px;
        position: relative;
        bottom: 5px;
        padding:1px 2px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch-OS input::placeholder{
        color:#757575;
    }
    #mobileHeader_icons #mobileSearch-OS input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch-OS .-times{
        display: none;
        fill: #31417a;
        height: 24px;
        width: 24px;
        margin-top: 9px;
    }
    #mobileHeader_icons #mobileSearch-OS .-times svg{
        fill:#31417a;
        height: 24px;
        width:24px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search{
        display: inline-block;
        fill: #31417a;
        height: 21px;
        width: 21px;
        float: right;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search svg{
        fill:#31417a;
        height: 21px;
        width: 21px;
    }
    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 8px 20px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        vertical-align: top;
        margin-right: 4px;
        margin-top: 5px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 26px;
    }
    #mobileHeader_icons #mobileLang svg{
        height: 26px;
        width:26px;
        fill: #31417a;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 30px;
        width: 30px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 92px;
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 92px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 28px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 24px;
        color: #666;
        text-decoration: none;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 92px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 20px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 27px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileHeader-OS #site-search-autocomplete-mobile {
        width: 440px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 66px;
        margin: 0 auto;
    }
    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #header-OS{
        display: none;
    }

    #mobileHeader-OS{
        background-color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 340px;
        height: 42px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 130px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader-OS #mobileHeader_icons li{
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
    }
    #mobileHeader-OS #mobileHeader_icons #mobileSearch-OS{
        background-color: transparent;
        height: 39px;
        vertical-align: top;
        box-sizing: content-box
    }
    #mobileHeader_icons #mobileSearch-OS input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        /*border-bottom: 1px solid #666666;*/
        border-bottom: none;
        font-size: 16px;
        width: 132px;
        background-color: #f4f5f8;
        margin: 0;
        position: relative;
        bottom: 5px;
        padding:1px 2px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch-OS input::placeholder{
        color:#757575;
    }
    #mobileHeader_icons #mobileSearch-OS input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch-OS .-times{
        display: none;
        fill: #31417a;
        height: 20px;
        width: 20px;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch-OS .-times svg{
        fill:#31417a;
        height: 20px;
        width: 20px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search{
        display: inline-block;
        fill: #31417a;
        height: 18px;
        width: 18px;
        float: right;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search svg{
        fill: #31417a;
        height: 18px;
        width: 18px;
    }

    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 8px 12px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 4px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 22px;
    }
    #mobileHeader_icons #mobileLang svg{
        fill: #31417a;
        height: 22px;
        width: 22px;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 24px;
        width: 24px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 62px;
        right: -100%;
        width: 100%;
        max-height: calc(100vh - 62px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 26px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
        padding: 10px 0 10px 15px;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 20px;
        color: #666;
        text-decoration: none;
        line-height: 28px;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 62px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 15px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileHeader-OS #site-search-autocomplete-mobile {
        width: 286px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 49px;
        right: 0;
        left: 0;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 12px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}

@media screen and (max-width: 350px){
    #header-OS{
        display: none;
    }

    #mobileHeader-OS{
        background-color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        transition: 0.4s;
        position: fixed;
        z-index: 9999;
        width: 100%;
        top: 0;
        /*box-shadow: rgba(0, 0, 0, 0.701961) 0px 0px 28px 2px;*/
        border-bottom: 1px solid #b2b2b2;
    }
    #mobileHeader_inner{
        width: 300px;
        height: 42px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    #mobileHeader_logo{
        position: absolute;
        width: 130px;
    }
    #mobileHeader_logo .cls-1{
        fill: #31417a;
    }
    #mobileHeader_logo .cls-2{
        fill: #fff;
    }
    #mobileHeader_logo .cls-3{
        fill: #333;
    }

    #mobileHeader_icons{
        position: absolute;
        right: 0;
    }
    #mobileHeader-OS #mobileHeader_icons li{
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
    }
    #mobileHeader_icons #mobileSearch-OS{
        /*width: 544px;*/
        background-color: transparent;
        height: 39px;
        vertical-align: top;
    }
    #mobileHeader_icons #mobileSearch-OS input{
        display: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        font-size: 14px;
        width: 100px;
        background-color: #f4f5f8;
        margin: 0;
        position: relative;
        bottom: 5px;
        padding:1px 2px;
        color:#000;
    }
    #mobileHeader_icons #mobileSearch-OS input::placeholder{
        color:#757575;
    }
    #mobileHeader_icons #mobileSearch-OS input:focus{
        outline: none;
    }
    .mobileHeaderShortInput{

    }
    .mobileHeaderLongInput{

    }
    #mobileHeader_icons #mobileSearch-OS .-times{
        display: none;
        fill: #31417a;
        height: 20px;
        width: 20px;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch-OS .-times svg{
        fill: #31417a;
        height: 20px;
        width: 20px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search{
        display: inline-block;
        fill: #31417a;
        height: 18px;
        width: 18px;
        float: right;
        margin-top: 11px;
    }
    #mobileHeader_icons #mobileSearch-OS .-search svg{
        fill: #31417a;
        height: 18px;
        width: 18px;
    }
    #mobileHeader_icons #mobileLang{
        border-left: 1px solid #31417a;
        border-right: 1px solid #31417a;
        padding: 8px 12px;
    }
    #mobileHeader_icons #mobileLang p{
        color: #666;
        display: inline-block;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 16px;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 4px;
    }
    #mobileHeader_icons #mobileLang i{
        display: inline-block;
        color: #31417a;
        font-size: 22px;
    }
    #mobileHeader_icons #mobileLang svg{
        height: 22px;
        width: 22px;
        fill: #31417a;
    }
    #mobileHeader_icons #mobileBurger svg{
        fill: #31417a;
        height: 24px;
        width: 24px;
    }

    /*
    *   mobile menu
    */
    #mobileMenu{
        position: fixed;
        top: 62px;
        right: -100%;
        width: 100vw;
        max-height: calc(100vh - 62px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #f4f5f8;
        z-index: 999;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #mobileMenu li{
        padding: 20px 0 20px 45px;
        border-bottom: 1px solid #fff;
    }
    #mobileMenu .mobileMenu_cat{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileMenu .mobileMenu_catArrow{
        font-size: 26px;
        color: #31417a;
        float: right;
        margin-right: 50px;
        transition: 0.4s;
    }
    #mobileMenu .mobileMenu_subCatContainer{
        height: 0;
        overflow-y: hidden;
    }
    #mobileMenu .mobileMenu_subCatContainer li{
        border-bottom: none;
        padding: 10px 0 10px 15px;
    }
    #mobileMenu .mobileMenu_subCatContainer a{
        font-size: 20px;
        color: #666;
        text-decoration: none;
        line-height: 28px;
    }
    #mobileMenu .rotateArrow{
        transform: rotate(180deg);
        transition: 0.4s;
    }
    /*
    *   mobile language bar
    */
    #mobileLangBar{
        font-family: "Frutiger 57 Condensed", sans-serif;
        position: fixed;
        top: 62px;
        right: -100%;
        width: 120px;
        background-color: #f4f5f8;
        z-index: 999;
        padding: 10px;
        text-align: center;
    }
    #mobileLangBar li{
        padding: 15px 0;
        border-bottom: 1px solid #fff;
    }
    #mobileLangBar a{
        font-size: 22px;
        color: #31417a;
        text-decoration: none;
        font-weight: bold;
    }
    #mobileHeader-OS #site-search-autocomplete-mobile {
        width: 300px;
        position: fixed;
        z-index: 999;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        background: #fff;
        top: 49px;
        margin: 0 auto;
    }

    #site-search-autocomplete-mobile > .site-search-autocomplete-term {
        padding: 10px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eeede9;
        color: #666;
    }
}