/* Add media queries for desktop */
@media screen and (min-width: 1400px) {

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right: 8px;
    }

    .execed-cookie-bar-modal-content span {
        width:16px;
        height:12px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:16px;
        height:12px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:16px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width:900px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right:8px;
    }

    .execed-cookie-bar-modal-content span {
        width:16px;
        height:12px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:16px;
        height:12px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:16px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width:900px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right: 8px;
    }

    .execed-cookie-bar-modal-content span {
        width:16px;
        height:12px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:16px;
        height:12px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:16px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width:900px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right: 8px;
    }

    .execed-cookie-bar-modal-content span {
        width:16px;
        height:12px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:16px;
        height:12px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:16px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right: 8px;
    }

    .execed-cookie-bar-modal-content span {
        width:16px;
        height:12px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:16px;
        height:12px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:16px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 50px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 10px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right: 8px;
    }

    .execed-cookie-bar-modal-content span {
        width:12px;
        height:8px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:12px;
        height:8px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 12px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 5px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 12px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 5px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:12px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }
}

@media screen and (max-width: 350px){

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 50px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content .execed-cookie-redirect-link {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 10px;
        color: #666666;
        text-decoration: none;
        font-weight: 600;
        margin-right: 8px;
    }

    .execed-cookie-bar-modal-content span {
        width:12px;
        height:8px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:12px;
        height:8px;
        color:#666666;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 12px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 5px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container {
        text-align: right;
    }
    .execed-cookie-buttons-upper-container a {
        display: inline-block;
        font-size: 12px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 5px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 1px solid #79A342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        border-radius: 4px;
    }
    .execed-cookie-buttons-upper-container .execed-cookie-bar-settings-link {
        background-color:transparent;
        color: #31417a;
        border:none;
        font-family: 'Helvetica Regular', sans-serif;
        font-weight:600;
        font-size:12px;
        text-decoration: none;
        cursor:pointer;
    }

    .execed-cookie-bar-settings-link>span>svg {
        width: 16px;
        height: 12px;
        color: #31417a;
        vertical-align:middle;
    }

    .execed-cookie-bar-settings-link:hover {
        color:#31417a;
        cursor:pointer;
    }
    .execed-cookie-buttons-container > #execed-cookie-bar-modal-save-link {
        background-color: transparent !important;
        color: #31417a;
        font-weight: 600;
        border-color:#31417a;
    }
    .execed-cookie-bar-modal .execed-cookie-bar-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }
}

@media print {

    /* The Modal (background) */
    .execed-cookie-bar-modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        box-sizing: border-box;
    }

    .execed-top-container {
        top: 0;
        z-index: 2147483002;
        position: fixed;
        width: 100%;
    }

    .execed-cookie-bar-container-inner .cookie-title {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        font-family: "Helvetica Bold", sans-serif;
        padding-bottom: 8px;
    }

    .execed-cookie-bar-container-inner .cookie-subtitle {
        font-size: 14px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cookie-bar-modal-content span {
        width:16px;
        height:12px;
    }

    .execed-cookie-bar-modal-content > span > svg {
        width:16px;
        height:12px;
    }

    .execed-cookie-buttons-container a {
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        padding: 13px 10px 12px;
        margin-right: 20px;
        position: relative;
        min-height: 1px;
        cursor: pointer;
        color: #fff;
        border: 3px solid #79a342;
        outline: none !important;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        text-align: right;
        border-radius: 4px;
    }
}

/* Modal Content */


.execed-cookie-bar-modal .execed-modal-cookie-title {
    color: #31417a;
    font-size: 24px;
    font-family: "Helvetica Bold",sans-serif;
    margin-bottom: 8px;
}

.execed-cookie-bar-modal .execed-modal-cookie-title-second {
    box-sizing: border-box;
    color: #31417a;
    cursor: default;
    display: inline-block;
    font-family: "Helvetica Bold", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

.execed-cookie-bar-modal .execed-modal-cookie-subtitle {
    font-family: "Helvetica Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    color: rgb(102, 102, 102);
    display: block;
    margin-bottom: 15px;
    margin-top: 0px;
}


.cookie-link-see-more {
    color: rgb(114, 153, 62);
    cursor: auto;
    display: inline;
    font-family: "Helvetica Regular", sans-serif;
    font-size: 14px;
    font-weight: bold;
    height: auto;
    line-height: 20px;
    text-decoration: none;
}
.execed-cookie-buttons-container {
    text-align: right;
}

.execed-cookie-bar-is-hidden {
    display: none;
}
.execed-rotate-cookie-chevron {
    vertical-align: text-bottom;
    transition: .2s ease-in;
    transform: rotate(-180deg);
}
