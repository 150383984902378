@media screen and (min-width: 1400px) {
    #singleProfileHeader{
        width: 100%;
        margin-top: 105px;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 50px 0;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 1300px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 46px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 80px;
        display: inline-block;
        width: calc((100% - 1300px)/2);
    }
    #singleProfileHeader #darkTriangle{
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 80px 50px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        display: inline-block;
        margin-left: -54px;
        width: 150px;
        height: 80px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        /*margin: 0 0 0 -53px;*/
        border-width: 0 0 80px 50px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 400px;
        vertical-align: top;
        margin: 38px 0 0 40px;
    }
    #singleProfileHeader #breadcrumbsInner{

    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #singleProfileHeader{
        width: 100%;
        margin-top: 105px;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 50px 0;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 1100px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 46px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 60px;
        display: inline-block;
        width: calc((100% - 1100px)/2);
    }
    #singleProfileHeader #darkTriangle{
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 60px 50px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        display: inline-block;
        margin-left: -54px;
        width: 150px;
        height: 60px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        /*margin: 0 0 0 -53px;*/
        border-width: 0 0 60px 50px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 400px;
        vertical-align: top;
        margin: 26px 0 0 30px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #singleProfileHeader{
        width: 100%;
        margin-top: 105px;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 50px 0;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 900px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 46px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 60px;
        display: inline-block;
        width: calc((100% - 900px)/2);
    }
    #singleProfileHeader #darkTriangle{
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 60px 50px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        display: inline-block;
        margin-left: -54px;
        width: 150px;
        height: 60px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        /*margin: 0 0 0 -53px;*/
        border-width: 0 0 60px 50px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 400px;
        vertical-align: top;
        margin: 26px 0 0 30px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #singleProfileHeader{
        width: 100%;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 150px 0 50px 0px;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 720px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 46px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 60px;
        display: inline-block;
        width: calc((100% - 720px)/2);
    }
    #singleProfileHeader #darkTriangle{
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 60px 50px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        display: inline-block;
        margin-left: -54px;
        width: 150px;
        height: 60px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        /*margin: 0 0 0 -53px;*/
        border-width: 0 0 60px 50px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 400px;
        vertical-align: top;
        margin: 26px 0 0 30px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #singleProfileHeader{
        width: 100%;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 150px 0 50px 0px;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 470px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 46px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 60px;
        display: inline-block;
        width: calc((100% - 470px)/2);
    }
    #singleProfileHeader #darkTriangle{
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 60px 50px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        display: inline-block;
        margin-left: -54px;
        width: 150px;
        height: 60px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        /*margin: 0 0 0 -53px;*/
        border-width: 0 0 60px 50px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 400px;
        vertical-align: top;
        margin: 26px 0 0 30px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #singleProfileHeader{
        width: 100%;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 100px 0px 40px;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 340px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 50px;
        display: inline-block;
        width: calc((100% - 470px)/2);
    }
    #singleProfileHeader #darkTriangle{
        vertical-align: top;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 50px 40px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        vertical-align: top;
        display: inline-block;
        margin-left: -54px;
        width: 95px;
        height: 50px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 50px 40px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 270px;
        vertical-align: top;
        margin: 22px 0 0 -6px;
        font-size: 11px;
    }
}

@media screen and (max-width: 350px) {
    #singleProfileHeader{
        width: 100%;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter{
        background-color: #31417a;
        padding: 90px 0px 30px 0;
        box-sizing: border-box;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter #singleProfileHeaderText{
        width: 300px;
        margin: 0 auto;
    }
    #singleProfileHeader #singleProfileHeaderTextOuter h1{
        color: #fff;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    /* breadcrumbs row */
    #singleProfileHeader #breadcrumbsOuter{
        position: relative;
        width: 100%;
        margin: -1px 0 0;
    }
    #singleProfileHeader #breadcrumbsDarkBox{
        background-color: #31417a;
        height: 50px;
        display: inline-block;
        width: calc((100% - 470px)/2);
    }
    #singleProfileHeader #darkTriangle{
        vertical-align: top;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        margin: -1px 0 0 -5px;
        border-width: 50px 40px 0 0;
        border-color: #31417a transparent transparent transparent;
    }
    #singleProfileHeader #lightRectangle{
        vertical-align: top;
        display: inline-block;
        margin-left: -54px;
        width: 95px;
        height: 50px;
        background-color: rgba(49, 65, 122, 0.5);
        position: relative;
    }
    #singleProfileHeader #lightTriangle{
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 50px 40px;
        border-color: transparent transparent #fff transparent;
    }
    #singleProfileHeader #breadcrumbs{
        display: inline-block;
        position: initial;
        width: 230px;
        vertical-align: top;
        margin: 22px 0 0 -6px;
        font-size: 11px;
    }
}