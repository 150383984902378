/*Normalize all the paddings, remove all effects on hover just the colour change and a transition of .4s*/

@media screen and (min-width: 1400px) {
    .sticky-call-out .content .contentButtonContainer:first-child {
        margin-top:20px;
    }
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:inline-block;
        min-width: 160px;
        max-width: 100%;
        word-break: break-word;
        padding:13px 16px 13px;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        outline: none;
        text-decoration: none;
        text-align: center;
        position: relative;
    }

    .contentButtonContainer > a:hover{
        cursor: pointer;
        transition: .4s;
    }

    /*sidebar main fs de*/
    .callout-aside .sticky-call-out .contentButtonContainer {
        padding: 4px 0px;
    }

    .callout-aside .sticky-call-out .contentButtonContainer a {
        display: block;
        width: 100%;
        word-break: break-word;
        font-size: 16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        outline: none;
        text-decoration: none;
        text-align: center;
        word-break: break-word;
    }
    /*sidebar main fs de ends*/
    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
    }
    .transparent-content-button:hover {
        background-color: #79A342;
        border-color: #79A342;
        color:#FFF;
        padding:13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;
    }
    .green-content-button:hover {
        background-color: transparent;
        border-color: #79A342;
        color:#79A342;
        padding: 13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .sticky-call-out .content .contentButtonContainer:first-child {
        margin-top:20px;
    }
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:inline-block;
        min-width: 160px;
        max-width: 100%;
        word-break: break-word;
        padding:13px 16px 13px;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        outline: none;
        text-decoration: none;
        text-align: center;
        position: relative;
    }

    .contentButtonContainer > a:hover{
        cursor: pointer;
        transition: .4s;
    }

    /*sidebar main fs de*/
    .callout-aside .sticky-call-out .contentButtonContainer {
        padding: 4px 0px;
    }

    .callout-aside .sticky-call-out .contentButtonContainer a {
        display: block;
        width: 100%;
        word-break: break-word;
        font-size: 16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        outline: none;
        text-decoration: none;
        text-align: center;
        word-break: break-word;
    }
    /*sidebar main fs de ends*/
    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button:hover {
        background-color: #79A342;
        border-color: #79A342;
        color:#FFF;
        padding:13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;

    }

    .green-content-button:hover {
        background-color: transparent;
        border-color: #79A342;
        color:#79A342;
        padding: 13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;

    }
}
/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .sticky-call-out .content .contentButtonContainer:first-child {
        margin-top:20px;
    }
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:inline-block;
        min-width: 160px;
        max-width: 100%;
        word-break: break-word;
        padding:13px 16px 13px;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        outline: none;
        text-decoration: none;
        text-align: center;
        position: relative;
    }

    .contentButtonContainer > a:hover{
        cursor: pointer;
        transition: .4s;
    }

    /*sidebar main fs de*/
    .callout-aside .sticky-call-out .contentButtonContainer {
        padding: 4px 0px;
    }

    .callout-aside .sticky-call-out .contentButtonContainer a {
        display: block;
        width: 100%;
        word-break: break-word;
        font-size: 16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        outline: none;
        text-decoration: none;
        text-align: center;
        word-break: break-word;
    }
    /*sidebar main fs de ends*/
    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button:hover {
        background-color: #79A342;
        border-color: #79A342;
        color:#FFF;
        padding:13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;

    }
    .green-content-button:hover {
        background-color: transparent;
        border-color: #79A342;
        color:#79A342;
        padding: 13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;

    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .sticky-call-out-wrapper .sticky-call-out {
        left:76.3333% !important;
    }
    .sticky-call-out .content .contentButtonContainer:first-child {
        margin-top:20px;
    }
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:inline-block;
        min-width: 160px;
        max-width: 100%;
        word-break: break-word;
        padding:13px 16px 13px;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        outline: none;
        text-decoration: none;
        text-align: center;
        position: relative;
    }

    .contentButtonContainer > a:hover{
        cursor: pointer;
        transition: .4s;
    }

    /*sidebar main fs de*/
    .callout-aside .sticky-call-out .contentButtonContainer {
        padding: 4px 0px;
    }

    .callout-aside .sticky-call-out .contentButtonContainer a {
        display: block;
        width: 100%;
        word-break: break-word;
        font-size: 16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        outline: none;
        text-decoration: none;
        text-align: center;
        word-break: break-word;
    }
    /*sidebar main fs de ends*/
    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button:hover {
        background-color: #79A342;
        border-color: #79A342;
        color:#FFF;
        padding:13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;
    }

    .green-content-button:hover {
        background-color: transparent;
        border-color: #79A342;
        color:#79A342;
        padding: 13px 16px 13px;
        font-size:16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        border-radius: 3px;
    }
}
/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:block;
        color:#FFF;
        padding:13px 16px 13px;
        word-break: break-word;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        outline: none;
        text-decoration: none;
        text-align: center;
    }

    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:block;
        word-break: break-word;
        padding:13px 16px 13px;
        font-size:14px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        outline: none;
        text-decoration: none;
        text-align: center;
    }


    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
}

@media screen and (max-width: 350px) {
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:block;
        word-break: break-word;
        padding:13px 16px 13px;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        outline: none;
        text-decoration: none;
        text-align: center;
    }
    /*button variation pairs global colour bg color hover effects*/
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
}

@media print {
    .contentButtonContainer {
        padding:5px 0px;
        max-width:100%;
        display:block;
    }
    .content-CTA{
        width:100%;
    }
    .contentButtonContainer > a {
        display:inline-block;
        min-width: 160px;
        max-width: 100%;
        word-break: break-word;
        color:#FFF;
        padding:13px 16px 13px;
        font-size:16px;
        line-height: 1.2;
        font-family: 'Helvetica Roman';
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        outline: none;
        text-decoration: none;
        text-align: center;
    }
    .green-content-button {
        background-color: #79A342;
        border: 1px solid #79A342;
        color:#FFF;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
    .transparent-content-button {
        background-color: transparent;
        border: 1px solid #79A342;
        color:#79A342;
        word-break: break-word;
        border-radius: 3px;
        padding:13px 16px 13px;
    }
}
