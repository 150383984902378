@media screen and (min-width: 1400px){
    #newsDetails {
        width: 1300px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 840px;
        min-height: 344px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        position: absolute;
        top: 40px;
        right: 80px;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #newsDetails {
        width: 1100px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 840px;
        min-height: 344px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        position: absolute;
        top: 40px;
        right: 40px;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    #newsDetails {
        width: 922px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 640px;
        min-height: 344px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        position: absolute;
        top: 40px;
        right: 40px;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #newsDetails {
        width: 666px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 466px;
        min-height: 344px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        position: absolute;
        top: 55px;
        right: 0;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #newsDetails {
        width: 400px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 400px;
        min-height: 344px;
        margin-bottom: 20px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 0 80px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
        padding-top: 20px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #newsDetails {
        width: 320px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 320px;
        min-height: 344px;
        margin-bottom: 20px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 0 80px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        width: 180px;
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
        padding-top: 20px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        width: 200px;
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

@media screen and (max-width: 350px){
    #newsDetails {
        width: 300px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 300px;
        min-height: 344px;
        margin-bottom: 20px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 0 80px 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        width: 180px;
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
        padding-top: 20px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        width: 200px;
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

@media print {
    #newsDetails {
        width: 1300px;
        position: relative;
        margin: 0 auto 20px;
    }

    .news-details-left-column {
        position: relative;
        width: 840px;
        min-height: 344px;
    }

    .news-details-left-column h2 {
        font-family: "Frutiger 57 Condensed",sans-serif;
        font-size: 30px;
        color: #31417a;
        margin: 40px 0 20px;
    }

    .news-details-left-column p {
        font-family: "Helvetica Regular",sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 1.6em;
    }

    #newsDetails #contactBar{
        position: absolute;
        top: 40px;
        right: 80px;
    }
    /* download */
    #newsDetails #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #newsDetails #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #newsDetails #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #newsDetails #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #newsDetails #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #newsDetails #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #newsDetails #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #newsDetails #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #newsDetails #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #newsDetails #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #newsDetails #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #newsDetails #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #newsDetails #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}