/* Add media queries for desktop */
@media screen and (min-width: 1400px){
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 1300px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 1200px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 26px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }
    
    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 1100px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 1000px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 26px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 922px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 822px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 26px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 666px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 590px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 26px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 400px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 325px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 20px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 13px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 320px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 245px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 20px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 13px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

@media screen and (max-width: 350px){
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 300px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 225px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 20px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 13px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

@media print {
    .execed-cta-bar-inner-container {
        padding: 27px 0 13px;
        width: 1300px;
        margin: 0 auto;
    }

    .execed-cta-bar-content {
        display: inline-block;
        text-decoration: none;
        width: 1200px;
    }

    .execed-cta-bar-close-link {
        display: inline-block;
        width: 60px;
        vertical-align: top;
        padding-left: 10px;
        border-left: 1px solid #fff;
        cursor: pointer;
    }

    .execed-cta-bar-title {
        font-size: 26px;
        line-height: 26px;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        color: #fff;
    }

    .execed-cta-bar-description {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Helvetica Roman', sans-serif;
        font-weight: bold;
        color: #fff;
    }

    .execed-cta-bar-close-link svg {
        stroke: #fff;
        color: #fff;
        display: block;
        text-align: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .execed-cta-bar-close-link span {
        text-align: center;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-family: 'Helvetica Roman', sans-serif;
        display: block;
        margin: 6px auto 0;
    }
}

.execed-cta-bar-content:hover {
    text-decoration: none;
}

.execed-cta-bar-color-info {
    background-color: #79A342;
}

.execed-cta-bar-color-attention {
    background-color: #007290
}

.execed-cta-bar-color-emotion {
     background-color: #991C3E;
}

.execed-cta-bar-color-active {
     background-color: #D84826;
 }