@media screen and (min-width: 1400px) {
    #studies{
        width: 1300px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 840px;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #studies{
        width: 1100px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 840px;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #studies{
        width: 900px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 840px;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #studies{
        width: 720px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 100%;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #studies{
        width: 470px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 100%;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #studies{
        width: 340px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 100%;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px){
    #studies{
        width: 280px;
        margin: 0 auto;
    }
    #studies_inner{
        width: 100%;
        text-align: left;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #studies h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_header{
        margin: 30px 0 20px;
    }
    #studies #studies_header h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        font-size: 22px;
        margin-bottom: 20px;
    }
    #studies #studies_header p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list{

    }
    #studies #studies_list .study{
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
    }
    #studies #studies_list .greyBG{
        background-color: #F4F5F8;
    }
    #studies #studies_list h3{
        font-size: 22px;
        line-height: 26px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #studies #studies_list h4{
        font-size: 22px;
        line-height: 26px;
        margin: 10px 0;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
    }
    #studies #studies_list p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 1.6em;
        color: #666666;
    }
    #studies #studies_list a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-top: 10px;
        font-weight: bold;
        text-align: right;
        text-decoration: none;
    }
    #studies #studies_list i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #studies #studies_list a:hover i{
        margin-left: 10px;
        margin-right: -4px;
        transition: 0.2s;
    }
}