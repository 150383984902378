@media screen and (min-width: 1400px) {
    #sourcingPartner{
        width: 1300px;
        margin: 50px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 840px;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 50px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #sourcingPartner{
        width: 1100px;
        margin: 50px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 840px;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 50px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #sourcingPartner{
        width: 900px;
        margin: 50px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 840px;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 50px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #sourcingPartner{
        width: 720px;
        margin: 50px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 100%;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 50px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #sourcingPartner{
        width: 470px;
        margin: 50px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 100%;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 50px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #sourcingPartner{
        width: 340px;
        margin: 50px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 100%;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 50px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}

@media screen and (max-width: 350px){
    #sourcingPartner{
        width: 280px;
        margin: 20px auto;
    }
    #sourcingPartner #sourcingPartner_inner{
        width: 100%;
        border-top: 2px solid #ccc;
        padding-top: 40px;
        box-sizing: border-box;
    }
    #sourcingPartner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    #sourcingPartner .partner{
        margin: 30px 0;
    }
    #sourcingPartner .partner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        color: #31417a;
    }
    #sourcingPartner .partner p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #sourcingPartner .partner a{
        display: block;
        width: 220px;
        text-align: center;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        background-color: #79a342;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        margin-top: 20px;
        text-decoration: none;
    }
    #sourcingPartner .partner a:hover{
        background-color: #31417a;
        transition: 0.3s;
    }
}