@media screen and (min-width: 1400px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

@media screen and (max-width: 350px) {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}

@media print {

    .product .text ul>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ul>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ol {
        margin-left:15px;
        margin-top: 5px;
    }

    .product .text ol>li>ul {
        margin-left:15px;
        margin-top: 5px;
    }

}