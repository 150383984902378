@media screen and (min-width: 1400px) {

    .executive-seminar-table-container {
        width:1300px;
        margin:40px 0px;
    }

    .seminar-event-table-single-container {
        width: 280px;
        display:inline-block;
        padding-right: 15px;
    }

    .seminar-event-table-single-container .heading-content {
        margin-top:10px;
    }

    .seminar-event-table-single-container .col {
        margin-top: 21px;
    }

    .seminar-event-table-single-container .col .data {
        line-height: 22px;
        font-size: 16px;
        font-family: "Helvetica Roman";
        color:#666;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .executive-seminar-table-container {
        width:1100px;
        margin:40px 0px;
    }

    .seminar-event-table-single-container {
        width: 280px;
        display:inline-block;
        padding-right: 15px;
    }

    .seminar-event-table-single-container .heading-content {
        margin-top:10px;
    }

    .seminar-event-table-single-container .col {
        margin-top: 21px;
    }

    .seminar-event-table-single-container .col .data {
        line-height: 22px;
        font-size: 16px;
        font-family: "Helvetica Roman";
        color:#666;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {

    .executive-seminar-table-container {
        width:900px;
        margin:40px 0px;
    }


    .seminar-event-table-single-container {
        width: 280px;
        display:inline-block;
        padding-right: 10px;
    }

    .seminar-event-table-single-container .heading-content {
        margin-top:10px;
    }

    .seminar-event-table-single-container .col {
        margin-top: 21px;
    }

    .seminar-event-table-single-container .col .data {
        line-height: 22px;
        font-size: 16px;
        font-family: "Helvetica Roman";
        color:#666;
    }

    .seminar-event-table-single-container .col .booking {
        margin-bottom: 20px;
        line-height: 22px;
        font-size: 16px;
        font-family: "Helvetica Roman";
        color:#666;
    }

    .seminar-event-table-single-container .col .reservation {
        border-bottom: 1px solid #B2B2B2;
        color:#666;
        padding-bottom:15px;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    .executive-seminar-table-container {
        width:720px;
        margin:40px 0px;
    }

    .executive-seminar-table-container .heading-content {
        margin-top:10px;
    }

    .seminar-event-table-single-container {
        width: 230px;
        display:inline-block;
        padding-right: 10px;
    }

    .seminar-event-table-single-container .col {
        margin-top: 21px;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .executive-seminar-table-container {
        width:440px;
        margin:40 auto;
    }

    .executive-seminar-table-container .heading-content {
        margin-top:10px;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }

    .executive-seminar-table-container .row-inner-container {
        display:block;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {

    .executive-seminar-table-container {
        width:340px;
        margin:40 auto;
    }

    .executive-seminar-table-container .heading-content {
        margin-top:10px;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }

    .executive-seminar-table-container .row-inner-container {
        display:block;
    }

}

@media screen and (max-width: 350px) {

    .executive-seminar-table-container {
        width:300px;
        margin:40px auto;
    }

    .executive-seminar-table-container .heading-content {
        margin-top:10px;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }

    .executive-seminar-table-container .row-inner-container {
        display:block;
    }

}

@media print {

    .executive-seminar-table-container {
        width:1300px;
        margin:40px 0px;
    }

    .seminar-event-table-single-container {
        width: 280px;
        display:inline-block;
        padding-right: 15px;
    }

    .seminar-event-table-single-container .heading-content {
        margin-top:10px;
    }

    .seminar-event-table-single-container .col {
        margin-top: 21px;
    }

    .seminar-event-table-single-container .col .data {
        line-height: 22px;
        font-size: 16px;
        font-family: "Helvetica Roman";
        color:#666;
    }

    .executive-seminar-table-container .fs-grid .row-inner-container .content-area {
        left:0;
        margin:0;
        padding:0;
    }
}


