@media screen and (min-width: 1400px) { /*1300*/

    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:1300px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
     }

    .execEd-eventTable-row-description-container {
        display:none;
    }


    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:inline-block;
        width: 600px;
        padding-right:25px;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:280px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:280px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;
    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:inline-block;
        width:600px;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display:block;
        padding-left: 600px;
        margin-top:30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover{
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/

    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:1100px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:inline-block;
        width: 500px;
        padding-right:25px;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:230px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:230px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;
    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:inline-block;
        width:500px;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display: block;
        padding-left: 500px;
        margin-top: 30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 230px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 230px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 230px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 230px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover{
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }
    /*contact details styles end*/
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/

    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:900px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:inline-block;
        width: 400px;
        padding-right:25px;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:180px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:180px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;

    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:inline-block;
        width:400px;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display: block;
        padding-left: 400px;
        margin-top: 30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 180px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 180px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 180px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 180px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover{
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:720px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:block;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:360px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:360px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;

    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:block;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display: block;
        margin-top: 30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 360px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 360px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 360px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 360px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover{
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/
    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:470px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:block;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:215px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:220px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;

    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:block;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display: block;
        margin-top: 30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 220px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 220px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 220px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 220px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover{
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:340px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:block;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:160px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:160px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;

    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:block;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display: block;
        margin-top: 30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 160px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 160px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 160px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 160px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a {
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover {
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }

}

@media screen and (max-width: 350px) {/*300*/
    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:300px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:block;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:140px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:140px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;

    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:block;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display: block;
        margin-top: 30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 140px;
        display:inline-block;
        font-size:10px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 140px;
        display:inline-block;
        font-size:10px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 140px;
        display:inline-block;
        font-size:10px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 140px;
        display:inline-block;
        font-size:10px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-fax a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover{
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }

}

@media print {
    .excecEd-eventTable-row {
        margin-top:30px;
    }

    .execEd-eventTable-container {
        width:1300px;
        margin:0 auto;
        margin-top:30px;
    }

    .execEd-eventTable-row-main-container {
        border-bottom: 1px solid #666;
        cursor:pointer;
    }

    .execEd-eventTable-row-description-container {
        display:none;
    }

    .execEd-eventTable-row-title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 5px;
        font-family: 'Helvetica Regular' , sans-serif;
        color: #72993E;
    }

    .execEd-eventTable-row-start-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;

    }

    .execEd-eventTable-row-end-date {
        display:inline-block;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-size: 11px;
        line-height: 14px;
        min-height: 24px;
        padding-bottom: 10px;
    }
    /*Left column styles*/
    .execEd-eventTable-row-description-container-column-left {
        display:inline-block;
        width: 600px;
        padding-right:25px;
    }

    .execEd-eventTable-row-description-container-column-left-title {
        font-size: 22px;
        line-height: 26px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-column-left-image {
        display:inline-block;
        width:280px;
        padding-right:10px;

    }

    .execEd-eventTable-row-description-container-column-left-image img {
        width:100%;
    }

    .execEd-eventTable-row-description-container-column-left-person-details {
        display: inline-block;
        vertical-align:top;
        width:280px;
        padding-left:10px;
    }

    .execEd-eventTable-row-description-container-column-left-name {
        font-size: 16px;
        line-height: 20px;
        color:#666;
        font-weight:bold;
        font-family: 'Helvetica Regular' , sans-serif;
    }

    .execEd-eventTable-row-description-container-column-left-job-description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        font-weight: normal;
        font-size:16px;

    }

    .execEd-eventTable-row-description-container-column-left-person-contact-details {
        display: block;
        margin-top:25px;
    }

    /*Right column styles*/
    .execEd-eventTable-row-description-container-column-right {
        display:inline-block;
        width:600px;
        vertical-align: top;
        margin-top:25px;
    }
    .execEd-eventTable-row-description-container-column-right-description{
        font-size:16px;
        font-family: 'Helvetica Regular' , sans-serif;
        line-height: 20px;
        color:#666;
    }

    .execEd-eventTable-row-description-container-column-right-description-title{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 25px;
        color: #31417A;
        font-family: 'Frutiger 57';
    }

    .execEd-eventTable-row-description-container-registration-btn {
        display:block;
        padding-left: 600px;
        margin-top:30px;
    }

    .execEd-eventTable-row-description-container-registration-btn a{
        box-sizing: border-box;
        padding: 13px 15px 11px;
        background-color: #79A342;
        border-color: #79A342;
        color: #fff;
        font-size:20px;
        line-height:20px;
        margin-top:30px;
        display:inline-block;
        text-decoration:none;
    }

    /*contact details styles start*/
    .execEd-eventTable-row-description-container-column-left-email  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-email a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-email a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-phone  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
    }

    .execEd-eventTable-row-description-container-column-left-phone a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-phone a:hover{
        text-decoration:none;
        color:#666;
    }


    .execEd-eventTable-row-description-container-column-left-download  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-right: 10px;
        padding-top:10px;

    }
    .execEd-eventTable-row-description-container-column-left-download a{
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-download a:hover{
        text-decoration:none;
        color:#666;
    }



    .execEd-eventTable-row-description-container-column-left-fax  {
        width: 280px;
        display:inline-block;
        font-size:12px;
        line-height:20px;
        color: #666;
        font-family: 'Helvetica Regular' , sans-serif;
        word-break: break-all;
        padding-left: 10px;
        padding-top:10px;

    }

    .execEd-eventTable-row-description-container-column-left-fax a {
        text-decoration:none;
        color:#666;
        display:inline-block;
        vertical-align:top;
    }

    .execEd-eventTable-row-description-container-column-left-fax a:hover {
        text-decoration:none;
        color:#666;
    }

    #execEd-eventTable-pagination-container {
        margin:20px 0;
    }

    .execEd-eventTable-pagination-btn {
        border: 1px solid #79A342;
        color: #79A342;
        display: inline-block;
        background: none;
        font-size: 16px;
        height: 35px;
        line-height: 22px;
        list-style: none;
        margin: 5px 10px 5px 0px;
        padding: 5.5px;
        text-align: center;
        width: 35px;

    }

}


/*Utility classes and animations that do not change throughout the device screen sizes */

.-event-description-open{
    display:block;
}

.excecEd-eventTable-row {
    display:none;
}

.executive-spinner {
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    background-color: #31417A;
    height: 50px;
    margin: 70px auto;
    width: 50px;
    display:block;

}
.executive-spinner .svg-blog {
    fill: #FFF;
    height: 50px;
    padding: 5px;
    width: 50px;
}
.executive-spinner .svg-blog >path {
    fill: #FFF;
}
.executive-spinner span{
    width: 100%;
    padding: 0;
    height: 100%;
}
@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}


.isActivePagination {
    border: 1px solid #666;
    pointer-events: none;
    margin-top: 0;
    color:#666;
}

.execEd-eventTable-pagination-btn {
    cursor: pointer;
}