@media screen and (min-width: 1400px) {
    #topicsPage_contact{
        margin: 0 auto;
        width: 1300px;
        padding: 45px 0 85px;
    }
    #topicsPage_contact h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-bottom: 45px;
    }
    #topicsPage_contact #contactBar{
        display: none; 
    }
    #topicsPage_contactContainer{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #topicsPage_contact{
        margin: 0 auto;
        width: 1100px;
        padding: 45px 0 85px;
    }
    #topicsPage_contact h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-bottom: 45px;
    }
    #topicsPage_contact #contactBar{
        display: none;
    }
    #topicsPage_contactContainer{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #topicsPage_contact{
        margin: 0 auto;
        width: 900px;
        padding: 45px 0 85px;
    }
    #topicsPage_contact h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-bottom: 45px;
    }
    #topicsPage_contact #contactBar{
        display: none;
    }
    #topicsPage_contactContainer{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #topicsPage_blogFeed #contactBar {
            display: none;
    }
    #topicsPage_contact{
        margin: 0 auto;
        width: 720px;
        padding: 40px 0;
    }
    #topicsPage_contact .pdf-download-heading-title{
        display: none;
    }
    #topicsPage_contact .contactComponentTitle {
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 0px;
        display: block;
    }
    /*#topicsPage_contact #contactBar{*/
    /*    display: none;*/
    /*}*/
    #topicsPage_contactContainer{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #topicsPage_blogFeed #contactBar {
        display: none;
    }
    #topicsPage_contact{
        margin: 0 auto;
        width: 440px;
        padding: 40px 0;
    }
    #topicsPage_contact .pdf-download-heading-title{
        display: none;
    }
    #topicsPage_contact .contactComponentTitle {
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 0px;
        display: block;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #topicsPage_blogFeed #contactBar {
        display: none;
    }
    #topicsPage_contact{
        margin: 0 auto;
        width: 340px;
        padding: 40px 0;
    }
    #topicsPage_contact .pdf-download-heading-title{
        display: none;
    }
    #topicsPage_contact .contactComponentTitle {
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 0px;
        display: block;
    }
}

@media screen and (max-width: 350px){
    #topicsPage_blogFeed #contactBar {
        display: none;
    }
    #topicsPage_contact{
        margin: 0 auto;
        width: 300px;
        padding: 40px 0;
    }
    #topicsPage_contact .pdf-download-heading-title{
        display: none;
    }
    #topicsPage_contact .contactComponentTitle {
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 0px;
        display: block;
    }
}