@media screen and (min-width: 1400px) {
    #singleProfile{
        width: 1300px;
        margin: 40px auto 100px;
    }
    #singleProfile img{
        display: inline-block;
        width: 240px;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: inline-block;
        margin-left: 70px;
        width: 982px;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: inline-block;
        margin: 0 0 20px;
        width: 200px;
        text-decoration: none;
    }
    #singleProfile #singleProfileDetails a:last-of-type{
        margin-left: 60px;
    }
    #singleProfile #singleProfileDetails i{
        font-size: 20px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: -5px 5px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #singleProfile{
        width: 1100px;
        margin: 40px auto 100px;
    }
    #singleProfile img{
        display: inline-block;
        width: 240px;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: inline-block;
        margin-left: 70px;
        width: 784px;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: inline-block;
        margin: 0 0 20px;
        width: 200px;
        text-decoration: none;
    }
    #singleProfile #singleProfileDetails a:last-of-type{
        margin-left: 60px;
    }
    #singleProfile #singleProfileDetails i{
        font-size: 20px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: -5px 5px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #singleProfile{
        width: 900px;
        min-height: calc(100vh - 700px);
        margin: 40px auto 100px;
    }
    #singleProfile img{
        display: inline-block;
        width: 240px;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: inline-block;
        margin-left: 70px;
        width: 584px;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: inline-block;
        margin: 0 0 20px;
        width: 200px;
        text-decoration: none;
    }
    #singleProfile #singleProfileDetails a:last-of-type{
        margin-left: 60px;
    }
    #singleProfile #singleProfileDetails i{
        font-size: 20px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: -5px 5px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #singleProfile{
        width: 460px;
        margin: 40px auto 100px;
        text-align: left;
    }
    #singleProfile img{
        display: block;
        width: 300px;
        margin: 50px auto 50px 0;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: block;
        width: 100%;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: inline-block;
        margin: 0 0 20px;
        width: 190px;
        text-decoration: none;
    }
    #singleProfile #singleProfileDetails a:last-of-type{
        margin-left: 60px;
    }
    #singleProfile #singleProfileDetails i{
        font-size: 20px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: -5px 5px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #singleProfile{
        width: 460px;
        margin: 40px auto 100px;
        text-align: left;
    }
    #singleProfile img{
        display: block;
        width: 300px;
        margin: 50px auto 50px 0;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: block;
        width: 100%;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: inline-block;
        margin: 0 0 20px;
        width: 190px;
        text-decoration: none;
    }
    #singleProfile #singleProfileDetails a:last-of-type{
        margin-left: 60px;
    }
    #singleProfile #singleProfileDetails i{
        font-size: 20px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: -5px 5px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #singleProfile{
        width: 340px;
        margin: 40px auto 100px;
        text-align: left;
    }
    #singleProfile img{
        display: block;
        width: 280px;
        margin: 40px auto 40px 0;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: block;
        width: 100%;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 17px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 17px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: block;
        margin: 0 0 20px;
        width: 190px;
        text-decoration: none;
    }
    /*#singleProfile #singleProfileDetails a:last-of-type{*/
    /*    margin-left: 60px;*/
    /*}*/
    #singleProfile #singleProfileDetails i{
        font-size: 17px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        margin: -5px 2px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 12px;
        transition: 0.2s;
        bottom: 6px;
        left: 7px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}

@media screen and (max-width: 350px) {
    #singleProfile{
        width: 300px;
        margin: 40px auto 100px;
        text-align: left;
    }
    #singleProfile img{
        display: block;
        width: 260px;
        margin: 30px auto 30px 0;
        height: auto;
    }
    #singleProfile #singleProfileDetails{
        display: block;
        width: 100%;
        vertical-align: top;
        border-bottom: 2px dotted #666666;
    }
    #singleProfile #singleProfileDetails h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 17px;
        color: #31417a;
    }
    #singleProfile #singleProfileDetails h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 17px;
        margin: 20px 0 40px;
        color: #666;
    }
    #singleProfile #singleProfileDetails a{
        display: block;
        margin: 0 0 20px;
        width: 190px;
        text-decoration: none;
    }
    /*#singleProfile #singleProfileDetails a:last-of-type{*/
    /*    margin-left: 60px;*/
    /*}*/
    #singleProfile #singleProfileDetails i{
        font-size: 17px;
        color: #79a342;
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        display: inline-block;
        vertical-align: top;
    }

    #singleProfile #singleProfileDetails .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        margin: -5px 2px 0 0;
        transition: 0.2s;
        display: inline-block;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails .downloadCircle i{
        color: #fff;
        position: absolute;
        font-size: 12px;
        transition: 0.2s;
        bottom: 6px;
        left: 7px;
        vertical-align: top;
    }
    #singleProfile #singleProfileDetails a:hover p{
        color: #79a342;
    }
    #singleProfile #singleProfileDetails .downloadCircleA:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
}