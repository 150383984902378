@media screen and (min-width: 1400px){ /*1300*/

    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 1300px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 1300px;
    }

    .executive-video {
        display: inline-block;
        width: calc(8/12 * 100%);
        position: relative;
    }

    .executive-video iframe {
        width:100%;
        height:420px;
        display:block;
        border:none;
    }

    .executive-description {
        display:inline-block;
        vertical-align: top;
        width: calc(3/12 * 100%);
        padding: 0 15px;
        position: relative;
        left: calc(1/12 * 100%);
    }
    .executive-video-gallery-next {
        display:inline-block;
        float: right;
    }
    .executive-video-gallery-prev {
        display:inline-block;
        position:relative;
        right:20px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/

    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 1100px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 1100px;
    }

    .executive-video {
        display: inline-block;
        width: calc(8/12 * 100%);
        position: relative;
    }

    .executive-video iframe {
        width:100%;
        height:420px;
        display:block;
        border:none;
    }

    .executive-description {
        display:inline-block;
        vertical-align: top;
        width: calc(3/12 * 100%);
        padding: 0 15px;
        position: relative;
    }
    .executive-video-gallery-next {
        display:inline-block;
        float: right;
    }

    .executive-video-gallery-prev {
        display:inline-block;
        position:relative;
        right:20px;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/

    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 900px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 900px;

    }

    .executive-video {
        display: inline-block;
        width: calc(8/12 * 100%);
        position: relative;
    }

    .executive-video iframe {
        width:100%;
        height:320px;
        display:block;
        border:none;
    }

    .executive-description {
        display:inline-block;
        vertical-align: top;
        width: calc(3/12 * 100%);
        padding: 0 15px;
        position: relative;
        left: calc(1/12 * 100%);
    }
    .executive-video-gallery-next{
        display:inline-block;
        float: right;
    }
    .executive-video-gallery-prev {
        display:inline-block;
        position:relative;
        right:20px;
    }
    .fs-ro
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/

    .-video-gallery .executive-background{
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 720px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 720px;
    }

    .executive-video {
        display: inline-block;
        width: calc(8/12 * 100%);
        position: relative;
        left: calc(1/12 * 100%);
    }

    .executive-video iframe {
        width:100%;
        height:260px;
        display:block;
        border:none;
    }

    .executive-description {
        display:inline-block;
        vertical-align: top;
        width: calc(3/12 * 100%);
        padding: 0 15px;
        position: relative;
        left: calc(1/12 * 100%);
    }

    .executive-video-gallery-next{
        display:inline-block;
        float: right;
    }
    .executive-video-gallery-prev{
        display:inline-block;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/

    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 440px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 440px;
    }

    .executive-video {
        display: block;
        width: 100%;

    }

    .executive-video iframe {
        width:100%;
        display:block;
        border:none;
        height: 246px;
    }

    .executive-description {
        display:block;
        width: 100%;
        overflow: hidden;
    }

    .-video-gallery .executive-navigation-container {
        top:110px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/

    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 320px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 320px;
    }

    .executive-video {
        display: block;
        width: 100%;

    }

    .executive-video iframe {
        width:100%;
        display:block;
        border:none;
    }

    .executive-description {
        display:block;
        width: 100%;
        overflow: hidden;
    }

    .-video-gallery .executive-navigation-container {
        top:60px;
    }

    .executive-video-gallery-next {
        display:inline-block;
        float: right;
    }

    .executive-video-gallery-prev {
        display:inline-block;
    }

}

@media screen and (max-width: 350px) {/*300*/
    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 300px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 300px;
    }

    .executive-video {
        display: block;
        width: 100%;
    }

    .executive-video iframe {
        width:100%;
        display:block;
        border:none;
    }

    .executive-description {
        display:block;
        width: 100%;
        overflow: hidden;
    }

    .-video-gallery .executive-navigation-container {
        top:60px;
    }

    .executive-video-gallery-next {
        display:inline-block;
        float: right;
    }

    .executive-video-gallery-prev {
        display:inline-block;
    }

}

@media print {

    .-video-gallery .executive-background {
        background-color: #666;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
    }

    .executive-insert .executive-video-gallery {
        width: 1164px;
        margin: 48px auto 0;
    }

    .executive-video-item {
        width: 1164px;
    }

    .executive-video {
        display: inline-block;
        width: calc(8/12 * 100%);
        position: relative;
        left: calc(1/12 * 100%);
    }

    .executive-video iframe {
        width:100%;
        height:420px;
        display:block;
        border:none;
    }

    .executive-description {
        display:inline-block;
        vertical-align: top;
        width: calc(3/12 * 100%);
        padding: 0 15px;
        position: relative;
        left: calc(1/12 * 100%);
    }

    .executive-video-gallery-next {
        display:inline-block;
        float: right;
    }

    .executive-video-gallery-prev {
        display:inline-block;
    }

}