@media screen and (min-width: 1400px) {
    #mobileDirectoryLetters{
        display: none;
    }

    #directorySearchBar{
        width: 100%;
        padding: 35px 0 45px;
        position: relative;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 45px;
    }
    #directorySearchBar #directorySearchFieldWrap{
        position: relative;
        height: 90px;
    }
    #directorySearchBar #directorySearchField{
        position: absolute;
        top: 0;
        right: 0;
        width: 380px;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 280px;
        height: 26px;
        margin-left: 12px;
        font-size: 22px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #CCCCCC;
        padding-bottom: 20px;
        box-sizing: border-box;
    }
    #directorySearchBar #directoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #666;
    }
    #directorySearchBar #directoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #directorySearchBar .fal{
        display: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #mobileDirectoryLetters{
        display: none;
    }

    #directorySearchBar{
        width: 100%;
        padding: 35px 0 45px;
        position: relative;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 45px;
    }
    #directorySearchBar #directorySearchFieldWrap{
        position: relative;
        height: 90px;
    }
    #directorySearchBar #directorySearchField{
        position: absolute;
        top: 0;
        right: 0;
        width: 380px;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 280px;
        height: 26px;
        margin-left: 12px;
        font-size: 22px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #CCCCCC;
        padding-bottom: 20px;
        box-sizing: border-box;
    }
    #directorySearchBar #directoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #666;
    }
    #directorySearchBar #directoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #directorySearchBar .fal{
        display: none;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #mobileDirectoryLetters{
        display: none;
    }

    #directorySearchBar{
        width: 100%;
        padding: 35px 0 45px;
        position: relative;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 45px;
    }
    #directorySearchBar #directorySearchFieldWrap{
        position: relative;
        height: 80px;
    }
    #directorySearchBar #directorySearchField{
        position: absolute;
        top: 0;
        right: 0;
        width: 380px;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 280px;
        height: 26px;
        font-size: 22px;
        margin-left: 12px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #CCCCCC;
        padding-bottom: 20px;
        box-sizing: border-box;
    }
    #directorySearchBar #directoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #666;
    }
    #directorySearchBar #directoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #directorySearchBar .fal{
        display: none;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #mobileDirectoryLetters{
        display: none;
    }

    #directorySearchBar{
        width: 100%;
        padding: 35px 0 45px;
        position: relative;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 45px;
    }
    #directorySearchBar #directorySearchFieldWrap{
        position: relative;
        height: 70px;
    }
    #directorySearchBar #directorySearchField{
        position: absolute;
        top: 0;
        right: 0;
        width: 380px;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 280px;
        height: 26px;
        margin-left: 12px;
        font-size: 22px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #CCCCCC;
        padding-bottom: 20px;
        box-sizing: border-box;
    }
    #directorySearchBar #directoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #666;
    }
    #directorySearchBar #directoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #directorySearchBar .fal{
        display: none;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #directorySearchBar{
        width: 100%;
        padding: 35px 0 45px;
        position: relative;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 34px;
    }
    #directorySearchBar #directorySearchField{
        width: 100%;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 380px;
        height: 26px;
        margin-left: 12px;
        font-size: 22px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
        position: initial !important;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: none;
    }

    /* letters slider */
    #mobileDirectoryLetters{
        width: 280px;
        margin: 40px auto 0;
        box-sizing: border-box;
        padding-left: 30px;
    }
    #mobileDirectoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #b6b6b6;
    }
    #mobileDirectoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #mobileDirectoryLetters .owl-item{
        opacity: 0;
    }
    #mobileDirectoryLetters .active{
        margin-top: 9px;
        opacity: 1;
    }
    #mobileDirectoryLetters .center{
        border: 1px solid #31417a;
        width: 38px !important;
        height: 38px;
        text-align: center;
        border-radius: 50%;
        box-sizing: border-box;
        padding-top: 7px;
        margin: 0 24px 0 -12px;
    }
    #mobileDirectoryLetters .center li{
        color: #31417a;
    }
    /* letter slider arrows */
    #directorySearchBar .fal{
        position: absolute;
        top: 130px;
        font-size: 18px;
    }
    #directorySearchBar .fa-chevron-left{
        left: 0;
    }
    #directorySearchBar .fa-chevron-right{
        right: 0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #directorySearchBar{
        width: 100%;
        padding: 22px 0 30px;
        position: relative;
        border-bottom: 1px solid #ddd;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 20px;
    }
    #directorySearchBar #directorySearchField{
        width: 100%;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 224px;
        height: 26px;
        font-size: 22px;
        margin-left: 12px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
        position: initial !important;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: none;
    }
    /* letters slider */
    #mobileDirectoryLetters{
        width: 280px;
        margin: 40px auto 0;
        box-sizing: border-box;
        padding-left: 30px;
    }
    #mobileDirectoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #b6b6b6;
    }
    #mobileDirectoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #mobileDirectoryLetters .owl-item{
        opacity: 0;
    }
    #mobileDirectoryLetters .active{
        margin-top: 9px;
        opacity: 1;
    }
    #mobileDirectoryLetters .center{
        border: 1px solid #31417a;
        width: 38px !important;
        height: 38px;
        text-align: center;
        border-radius: 50%;
        box-sizing: border-box;
        padding-top: 7px;
        margin: 0 24px 0 -12px;
    }
    #mobileDirectoryLetters .center li{
        color: #31417a;
    }
    /* letter slider arrows */
    #directorySearchBar .fal{
        position: absolute;
        top: 116px;
        font-size: 20px;
    }
    #directorySearchBar .fa-chevron-left{
        left: 0;
    }
    #directorySearchBar .fa-chevron-right{
        right: 0;
    }
}

@media screen and (max-width: 350px){
    #directorySearchBar{
        width: 100%;
        padding: 22px 0 30px;
        position: relative;
        border-bottom: 1px solid #ddd;
    }
    #directorySearchBar h2{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        text-align: left;
        margin-bottom: 20px;
    }
    #directorySearchBar #directorySearchField{
        width: 100%;
        background-color: #F4F5F8;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 35px;
    }
    #directorySearchBar #directorySearchField input{
        display: inline-block;
        width: 210px;
        height: 26px;
        font-size: 22px;
        margin-left: 12px;
        border: none;
        background-color: #F4F5F8;
        color: #666;
    }
    #directorySearchBar #directorySearchField input:focus{
        outline: none;
    }
    #directorySearchBar #directorySearchField i{
        display: inline-block;
        color: #31417A;
        position: initial !important;
    }
    #directorySearchBar #directorySearchField .fa-times{
        font-size: 18px;
        font-weight: 400;
        margin-left: 8px;
        opacity: 0;
    }
    #directorySearchBar #directorySearchField .fa-search{
        float: right;
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
    }
    #directorySearchBar #directorySearchField i:hover{
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
    }
    #directorySearchBar #directoryLetters{
        display: none;
    }
    /* letters slider */
    #mobileDirectoryLetters{
        width: 280px;
        margin: 40px auto 0;
        box-sizing: border-box;
        padding-left: 30px;
    }
    #mobileDirectoryLetters li{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #b6b6b6;
    }
    #mobileDirectoryLetters li:hover{
        cursor: pointer;
        color: #79A342;
        transition: 0.2s;
    }
    #mobileDirectoryLetters .owl-item{
        opacity: 0;
    }
    #mobileDirectoryLetters .active{
        margin-top: 9px;
        opacity: 1;
    }
    #mobileDirectoryLetters .center{
        border: 1px solid #31417a;
        width: 38px !important;
        height: 38px;
        text-align: center;
        border-radius: 50%;
        box-sizing: border-box;
        padding-top: 7px;
        margin: 0 24px 0 -12px;
    }
    #mobileDirectoryLetters .center li{
        color: #31417a;
    }
    /* letter slider arrows */
    #directorySearchBar .fal{
        position: absolute;
        top: 116px;
        font-size: 20px;
    }
    #directorySearchBar .fa-chevron-left{
        left: 0;
    }
    #directorySearchBar .fa-chevron-right{
        right: 0;
    }
}