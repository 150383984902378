@media screen and (min-width: 1400px) {
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    #topicsPage_mindsInner{
        width: 1300px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 30px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 314px;
        text-align: left;
        margin-bottom: 45px;
        display: inline-block;
        min-height: 400px;
        vertical-align: top;
        margin-right: 10px;
    }
    #topicsPage_mindsList .topicsPage_mind:nth-of-type(4n+4){
        margin-right: 0;
    }
    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 100%;
        height: 200px;
        background-color: #cfcfcf;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 18px 24px;
        box-sizing: border-box;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 20px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 8px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 20px;
        margin-top: 14px;
        color: #666666;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        margin-top: 30px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 22px;
        margin: 0 10px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    #topicsPage_mindsInner{
        width: 1100px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 30px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 300px;
        text-align: left;
        margin-bottom: 45px;
        display: inline-block;
        min-height: 400px;
        vertical-align: top;
        margin-right: 96px;
    }
    #topicsPage_mindsList .topicsPage_mind:nth-of-type(3n+3){
        margin-right: 0;
    }

    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 100%;
        height: 190px;
        background-color: #cfcfcf;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 18px 24px;
        box-sizing: border-box;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 20px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 8px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 20px;
        margin-top: 14px;
        color: #666666;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        margin-top: 30px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 22px;
        margin: 0 10px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    #topicsPage_mindsInner{
        width: 900px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 30px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 288px;
        text-align: left;
        margin-bottom: 45px;
        display: inline-block;
        min-height: 380px;
        vertical-align: top;
        margin-right: 10px;
    }
    #topicsPage_mindsList .topicsPage_mind:nth-of-type(3n+3){
        margin-right: 0;
    }

    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 100%;
        height: 184px;
        background-color: #cfcfcf;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 16px 22px;
        box-sizing: border-box;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 20px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 8px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 20px;
        margin-top: 14px;
        color: #666666;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        margin-top: 30px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 22px;
        margin: 0 10px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 40px 0 15px;
    }
    #topicsPage_mindsInner{
        width: 720px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 25px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 230px;
        text-align: left;
        margin-bottom: 25px;
        display: inline-block;
        min-height: 380px;
        vertical-align: top;
        margin-right: 10px;
    }
    #topicsPage_mindsList .topicsPage_mind:nth-of-type(3n+3){
        margin-right: 0;
    }
    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 100%;
        height: 156px;
        background-color: #cfcfcf;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 16px 18px;
        height: 182px;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 18px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 4px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 15px;
        line-height: 18px;
        margin-top: 10px;
        color: #666666;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        position: absolute;
        bottom: 10px;
        left: 18px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 20px;
        margin: 0 8px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 40px 0 15px;
    }
    #topicsPage_mindsInner{
        width: 470px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 25px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 100%;
        height: 140px;
        text-align: left;
        margin-bottom: 25px;
        position: relative;
    }
    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 140px;
        height: 140px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #cfcfcf;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 16px 18px;
        width: 330px;
        height: 140px;
        top: 0;
        right: 0;
        overflow: hidden;
        box-sizing: border-box;
        position: absolute;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 18px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 10px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        display: none;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        position: absolute;
        bottom: 10px;
        left: 18px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 20px;
        margin: 0 8px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 40px 0 15px;
    }
    #topicsPage_mindsInner{
        width: 340px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 25px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 100%;
        height: 140px;
        text-align: left;
        margin-bottom: 25px;
        position: relative;
    }
    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 140px;
        height: 140px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #cfcfcf;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 10px;
        width: 200px;
        height: 140px;
        top: 0;
        right: 0;
        overflow: hidden;
        box-sizing: border-box;
        position: absolute;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 18px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 10px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        display: none;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 20px;
        margin: 0 8px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px){
    #topicsPage_minds{
        background-color: #e4e4e4;
        padding: 40px 0 15px;
    }
    #topicsPage_mindsInner{
        width: 300px;
        margin: 0 auto;
    }
    #topicsPage_mindsInner h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
    }

    #topicsPage_mindsList{
        margin-top: 25px;
    }
    #topicsPage_mindsList .topicsPage_mind{
        background-color: #fff;
        width: 100%;
        height: 120px;
        text-align: left;
        margin-bottom: 25px;
        position: relative;
    }
    #topicsPage_mindsList .topicsPage_mind .topicsPage_mindImg{
        width: 120px;
        height: 120px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #cfcfcf;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    #topicsPage_mindsList .topicsPage_mindDetails{
        padding: 10px;
        width: 180px;
        height: 120px;
        top: 0;
        right: 0;
        overflow: hidden;
        box-sizing: border-box;
        position: absolute;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h4{
        font-size: 18px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
    }
    #topicsPage_mindsList .topicsPage_mindDetails h5{
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #666666;
        margin: 10px 0;
    }
    #topicsPage_mindsList .topicsPage_mindDetails p{
        display: none;
    }
    #topicsPage_mindsList .topicsPage_mindShare{
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
    #topicsPage_mindsList .topicsPage_mindShare a{
        text-decoration: none;
        color: #79A342;
        font-size: 18px;
        margin: 0 8px;
        transition: 0.2s;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:first-of-type{
        margin-left: 0;
    }
    #topicsPage_mindsList .topicsPage_mindShare a:hover{
        color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}