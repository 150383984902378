@media screen and (min-width: 1400px) {
    #topicsPage_events{
        background-color: #31417a;
        box-sizing: border-box;
        padding: 45px 0 60px;
    }
    #topicsPage_eventsInner{
        width: 1300px;
        height: 480px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_eventsInner h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG{
        width: 650px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails{
        width: 650px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h4{
        font-size: 30px;
        margin: 160px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h5{
        font-size: 22px;
        padding-bottom: 25px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a{
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a:hover{
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList{
        width: 646px;
        height: 416px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        border: 2px solid #fff;
    }
    #topicsPage_eventsList .topicsPage_event{
        display: block;
        margin: 18px 0 18px 65px;
    }
    #topicsPage_eventsList .topicsPage_event:first-of-type{
        margin-top: 34px;
    }
    #topicsPage_eventsList .topicsPage_event:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate{
        border: 1px solid #fff;
        width: 130px;
        text-align: center;
        padding: 15px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5{
        font-size: 22px;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4{
        font-size: 46px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails{
        width: calc(100% - 140px);
        height: 100px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 15px;
        vertical-align: top;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h6{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: 10px 0;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h5{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a{
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        position: absolute;
        bottom: 4px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a:hover{
        color: #cacfde;
        transition: 0.2s;
    }



    #topicsPage_events #topicsPage_eventsViewMore{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_events #topicsPage_eventsViewMore i{
        color: #fff;
        font-size: 14px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover p{
        color: #cacfde;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover i{
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #topicsPage_events{
        background-color: #31417a;
        box-sizing: border-box;
        padding: 45px 0 60px;
    }
    #topicsPage_eventsInner{
        width: 1100px;
        height: 480px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_eventsInner h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG{
        width: 550px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails{
        width: 550px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h4{
        font-size: 30px;
        margin: 160px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h5{
        font-size: 22px;
        padding-bottom: 25px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a{
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a:hover{
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList{
        width: 546px;
        height: 416px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        border: 2px solid #fff;
    }
    #topicsPage_eventsList .topicsPage_event{
        display: block;
        margin: 18px 0 18px 65px;
    }
    #topicsPage_eventsList .topicsPage_event:first-of-type{
        margin-top: 34px;
    }
    #topicsPage_eventsList .topicsPage_event:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate{
        border: 1px solid #fff;
        width: 130px;
        text-align: center;
        padding: 15px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5{
        font-size: 22px;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4{
        font-size: 46px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails{
        width: calc(100% - 140px);
        height: 100px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 15px;
        vertical-align: top;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h6{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: 10px 0;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h5{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a{
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        position: absolute;
        bottom: 4px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a:hover{
        color: #cacfde;
        transition: 0.2s;
    }



    #topicsPage_events #topicsPage_eventsViewMore{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_events #topicsPage_eventsViewMore i{
        color: #fff;
        font-size: 14px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover p{
        color: #cacfde;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover i{
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #topicsPage_events{
        background-color: #31417a;
        box-sizing: border-box;
        padding: 45px 0 60px;
    }
    #topicsPage_eventsInner{
        width: 900px;
        height: 480px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_eventsInner h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG{
        width: 450px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails{
        width: 450px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h4{
        font-size: 30px;
        margin: 160px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h5{
        font-size: 22px;
        padding-bottom: 25px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a{
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a:hover{
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList{
        width: 446px;
        height: 416px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        border: 2px solid #fff;
    }
    #topicsPage_eventsList .topicsPage_event{
        display: block;
        margin: 15px 0 15px 40px;
    }
    #topicsPage_eventsList .topicsPage_event:first-of-type{
        margin-top: 34px;
    }
    #topicsPage_eventsList .topicsPage_event:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate{
        border: 1px solid #fff;
        width: 130px;
        text-align: center;
        padding: 15px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5{
        font-size: 22px;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4{
        font-size: 46px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails{
        width: calc(100% - 140px);
        height: 100px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 15px;
        vertical-align: top;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h6{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: 10px 0;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h5{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a{
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        position: absolute;
        bottom: 4px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a:hover{
        color: #cacfde;
        transition: 0.2s;
    }



    #topicsPage_events #topicsPage_eventsViewMore{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_events #topicsPage_eventsViewMore i{
        color: #fff;
        font-size: 14px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover p{
        color: #cacfde;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover i{
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #topicsPage_events{
        background-color: #31417a;
        box-sizing: border-box;
        padding: 40px 0;
    }
    #topicsPage_eventsInner{
        width: 720px;
        height: 306px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_eventsInner h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG{
        width: 360px;
        height: 260px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails{
        width: 360px;
        height: 260px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h4{
        font-size: 22px;
        margin: 75px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h5{
        font-size: 18px;
        padding-bottom: 25px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a:hover{
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList{
        width: 360px;
        height: 260px;
        position: absolute;
        bottom: 0;
        right: 0px;
        color: #fff;
        border: 2px solid #fff;
        box-sizing: border-box;
    }
    #topicsPage_eventsList .topicsPage_event{
        display: block;
        margin: 10px 0 10px 24px;
    }
    #topicsPage_eventsList .topicsPage_event:first-of-type{
        margin-top: 10px;
    }
    #topicsPage_eventsList .topicsPage_event:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate{
        border: 1px solid #fff;
        width: 100px;
        text-align: center;
        padding: 8px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5{
        font-size: 18px;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4{
        font-size: 28px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails{
        width: calc(100% - 140px);
        height: 62px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 10px;
        vertical-align: top;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h6{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 11px;
        margin: 6px 0;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h5{
        font-size: 18px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a:hover{
        color: #cacfde;
        transition: 0.2s;
    }



    #topicsPage_events #topicsPage_eventsViewMore{
        position: absolute;
        bottom: 10px;
        right: 20px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_events #topicsPage_eventsViewMore i{
        color: #fff;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover{
        right: 15px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover p{
        color: #cacfde;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover i{
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #topicsPage_events {
        background-color: #31417a;
        box-sizing: border-box;
        padding: 40px 0;
    }

    #topicsPage_eventsInner {
        width: 470px;
        height: 670px;
        margin: 0 auto;
        position: relative;
    }
    #topicsPage_eventsInner h2{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG {
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails{
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h4{
        font-size: 22px;
        margin: 90px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails h5{
        font-size: 18px;
        padding-bottom: 25px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsActiveDetails a:hover{
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList{
        width: 100%;
        height: 300px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
    }
    #topicsPage_eventsList .topicsPage_event{
        display: block;
        margin: 18px 0;
    }
    #topicsPage_eventsList .topicsPage_event:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate{
        border: 1px solid #fff;
        width: 100px;
        text-align: center;
        padding: 8px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5{
        font-size: 18px;
    }
    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4{
        font-size: 28px;
    }
    #topicsPage_eventsList .topicsPage_eventDetails{
        width: calc(100% - 110px);
        min-height: 62px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 10px;
        vertical-align: top;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h6{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 11px;
        margin: 6px 0;
    }
    #topicsPage_eventsList .topicsPage_eventDetails h5{
        font-size: 18px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a{
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #topicsPage_eventsList .topicsPage_eventDetails a:hover{
        color: #cacfde;
        transition: 0.2s;
    }



    #topicsPage_events #topicsPage_eventsViewMore{
        position: absolute;
        bottom: 10px;
        right: 20px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_events #topicsPage_eventsViewMore i{
        color: #fff;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover{
        right: 15px;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover p{
        color: #cacfde;
        transition: 0.2s;
    }
    #topicsPage_events #topicsPage_eventsViewMore:hover i{
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {

    #topicsPage_events {
        background-color: #31417a;
        box-sizing: border-box;
        padding: 40px 0;
    }

    #topicsPage_eventsInner {
        width: 340px;
        height: 670px;
        margin: 0 auto;
        position: relative;
    }

    #topicsPage_eventsInner h2 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG {
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails {
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }

    #topicsPage_events #topicsPage_eventsActiveDetails h4 {
        font-size: 22px;
        margin: 90px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails h5 {
        font-size: 18px;
        padding-bottom: 25px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails a {
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails a:hover {
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList {
        width: 100%;
        height: 300px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
    }

    #topicsPage_eventsList .topicsPage_event {
        display: block;
        margin: 18px 0;
    }

    #topicsPage_eventsList .topicsPage_event:last-of-type {
        margin-bottom: 0;
    }

    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate {
        border: 1px solid #fff;
        width: 100px;
        text-align: center;
        padding: 8px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5 {
        font-size: 18px;
    }

    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4 {
        font-size: 28px;
    }

    #topicsPage_eventsList .topicsPage_eventDetails {
        width: calc(100% - 110px);
        min-height: 62px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 10px;
        vertical-align: top;
    }

    #topicsPage_eventsList .topicsPage_eventDetails h6 {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 11px;
        margin: 6px 0;
    }

    #topicsPage_eventsList .topicsPage_eventDetails h5 {
        font-size: 18px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #topicsPage_eventsList .topicsPage_eventDetails a {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }

    #topicsPage_eventsList .topicsPage_eventDetails a:hover {
        color: #cacfde;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore {
        position: absolute;
        bottom: 10px;
        right: 20px;
        text-decoration: none;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore p {
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }

    #topicsPage_events #topicsPage_eventsViewMore i {
        color: #fff;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore:hover {
        right: 15px;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore:hover p {
        color: #cacfde;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore:hover i {
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }

}

@media screen and (max-width: 350px) {

    #topicsPage_events {
        background-color: #31417a;
        box-sizing: border-box;
        padding: 40px 0;
    }

    #topicsPage_eventsInner {
        width: 300px;
        height: 710px;
        margin: 0 auto;
        position: relative;
    }

    #topicsPage_eventsInner h2 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #fff;
        text-align: left;
    }

    /*
    | event with image (left box)
    */
    #topicsPage_events #topicsPage_eventsActiveBG {
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails {
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 10;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right, rgba(121, 163, 66, 0.55), rgba(49, 65, 122, 0.55));
    }

    #topicsPage_events #topicsPage_eventsActiveDetails h4 {
        font-size: 22px;
        margin: 90px 0 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails h5 {
        font-size: 18px;
        padding-bottom: 25px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails a {
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        box-sizing: border-box;
        padding: 8px 20px;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsActiveDetails a:hover {
        cursor: pointer;
        background-color: #fff;
        color: #31417a;
        transition: 0.2s;
    }

    /*
    | event list (right box)
    */
    #topicsPage_events #topicsPage_eventsList {
        width: 100%;
        height: 350px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
    }

    #topicsPage_eventsList .topicsPage_event {
        display: block;
        margin: 18px 0;
    }

    #topicsPage_eventsList .topicsPage_event:last-of-type {
        margin-bottom: 0;
    }

    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate {
        border: 1px solid #fff;
        width: 100px;
        text-align: center;
        padding: 8px 0;
        display: inline-block;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h5 {
        font-size: 14px;
        line-height: 25px;
    }

    #topicsPage_eventsList .topicsPage_event .topicsPage_eventDate h4 {
        font-size: 28px;
        line-height: 38px;
    }

    #topicsPage_eventsList .topicsPage_eventDetails {
        width: calc(100% - 110px);
        min-height: 82px;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #fff;
        box-sizing: border-box;
        padding-left: 10px;
        vertical-align: top;
    }

    #topicsPage_eventsList .topicsPage_eventDetails h6 {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 11px;
        margin: 6px 0;
    }

    #topicsPage_eventsList .topicsPage_eventDetails h5 {
        font-size: 18px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #topicsPage_eventsList .topicsPage_eventDetails a {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }

    #topicsPage_eventsList .topicsPage_eventDetails a:hover {
        color: #cacfde;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore {
        position: absolute;
        bottom: 10px;
        right: 20px;
        text-decoration: none;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore p {
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
    }

    #topicsPage_events #topicsPage_eventsViewMore i {
        color: #fff;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore:hover {
        right: 15px;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore:hover p {
        color: #cacfde;
        transition: 0.2s;
    }

    #topicsPage_events #topicsPage_eventsViewMore:hover i {
        margin-left: 5px;
        color: #cacfde;
        transition: 0.2s;
    }

}