.dropdown-menu-sort-date-CF {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.188235) 0px 6px 20px 0px;
}
@media screen and (min-width: 1400px){
    .courseFinderSearchBar-CF {
        width: 1300px;
        position: relative;
        height: 50px;
        border-bottom: 2px solid rgb(159, 159, 159);
        margin: 0 auto 24px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }

    #resultCounter-CF {
        display: inline-block;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .result-counter-CF {
        display: inline-block;
        color: #31417A;
        font-size: 14px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 320px;
        height: 30px;
        margin-left: 14px;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        padding-top:4px;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 80%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
    }
    .see-favourites-CF {
        position: absolute;
        right: 320px;
        margin-top: 10px;
        color: #B2B2B2;
        display: none;
    }
    .see-favourites-CF p,
    #see-all-favourite-button-CF {
        display: inline-block;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        bottom: -3px;
        left: 0;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: inline-block;
        position: absolute;
        right: 0;
        top: -4px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 166px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 168px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }
    .start-date-dropdown-selected p {
        color: #fff !important;
    }
    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 336px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 18px;
        margin-bottom: 20px;
        cursor: pointer;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .courseFinderSearchBar-CF {
        width: 1100px;
        position: relative;
        height: 50px;
        border-bottom: 2px solid rgb(159, 159, 159);
        margin: 0 auto 24px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }

    #resultCounter-CF {
        display: inline-block;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .result-counter-CF {
        display: inline-block;
        color: #31417A;
        font-size: 14px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 320px;
        height: 30px;
        margin-left: 14px;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        padding-top:4px;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 80%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
    }
    .see-favourites-CF {
        position: absolute;
        right: 320px;
        margin-top: 10px;
        color: #B2B2B2;
        display: none;
    }
    .see-favourites-CF p,
    #see-all-favourite-button-CF {
        display: inline-block;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        bottom: -3px;
        left: 0;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: inline-block;
        position: absolute;
        right: 0;
        top: -5px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 166px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 168px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }
    .start-date-dropdown-selected p {
        color: #fff !important;
    }
    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 336px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 18px;
        margin-bottom: 20px;
        cursor: pointer;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .courseFinderSearchBar-CF {
        width: 922px;
        position: relative;
        height: 50px;
        border-bottom: 2px solid rgb(159, 159, 159);
        margin: 0 auto 24px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }

    #resultCounter-CF {
        display: inline-block;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .result-counter-CF {
        display: inline-block;
        color: #31417A;
        font-size: 14px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 320px;
        height: 30px;
        margin-left: 14px;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        padding-top:4px;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 80%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
    }
    .see-favourites-CF {
        position: absolute;
        right: 320px;
        margin-top: 10px;
        color: #B2B2B2;
        display: none;
    }
    .see-favourites-CF p,
    #see-all-favourite-button-CF {
        display: inline-block;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        bottom: -3px;
        left: 0;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: inline-block;
        position: absolute;
        right: 0;
        top: -4px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 166px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 168px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }
    .start-date-dropdown-selected p {
        color: #fff !important;
    }
    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 336px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 18px;
        margin-bottom: 20px;
        cursor: pointer;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .courseFinderSearchBar-CF {
        width: 666px;
        position: relative;
        height: 50px;
        border-bottom: 2px solid rgb(159, 159, 159);
        margin: 0 auto 24px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }

    #resultCounter-CF {
        display: inline-block;
        position: absolute;
        bottom: 15px;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .result-counter-CF {
        color: #31417A;
        font-size: 14px;
        display: inline;
        position: relative;
        top: 8px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 200px;
        height: 30px;
        margin-left: 0;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        padding-top:4px;
        vertical-align: middle;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 70%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
    }
    .see-favourites-CF {
        position: absolute;
        right: 300px;
        margin-top: 10px;
        color: #B2B2B2;
        display: none;
    }
    .see-favourites-CF p,
    #see-all-favourite-button-CF {
        display: inline-block;
        font-size: 14px;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        bottom: -3px;
        left: 0;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: inline-block;
        position: absolute;
        right: 0;
        margin-top: 1px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 166px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
        font-size: 14px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 168px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }
    .start-date-dropdown-selected p {
        color: #fff !important;
    }
    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 336px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 18px;
        margin-bottom: 20px;
        cursor: pointer;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #resultCounter-CF {
        display: inline-block;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .courseFinderSearchBar-CF {
        width: 428px;
        position: relative;
        height: 156px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding-top: 14px;
        margin: 20px auto;
    }
    .result-counter-CF {
        color: #31417A;
        font-size: 14px;
        display: inline;
        position: relative;
        top: 6px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 428px;
        height: 30px;
        margin-left: 0;
        margin-top: 12px;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        padding-top:4px;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 70%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
        vertical-align: middle;
    }
    .see-favourites-CF {
        position: absolute;
        top: 122px;
        left: 0;
        margin-top: 8px;
        color: #B2B2B2;
        display: none;
    }

    .see-favourites-CF p {
        display: block;
        font-size: 14px;
    }

    #see-all-favourite-button-CF {
        display: inline-block;
        font-size: 14px;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        left: 400px;
        bottom: 10px;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: block;
        margin-top: 20px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
        padding-top:14px;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 426px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
        font-size: 14px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 428px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }
    .start-date-dropdown-selected p {
        color: #fff !important;
    }
    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 20px;
        width: 348px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 18px;
        margin-bottom: 0;
        cursor: pointer;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #resultCounter-CF {
        display: inline-block;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .courseFinderSearchBar-CF {
        width: 320px;
        position: relative;
        height: 156px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding-top: 16px;
        margin: 20px auto;
    }
    .result-counter-CF {
        color: #31417A;
        font-size: 14px;
        display: inline;
        position: relative;
        top: 6px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 320px;
        height: 30px;
        margin-left: 0;
        margin-top: 12px;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        padding-top:4px;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 70%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
        vertical-align: middle;
    }
    .see-favourites-CF {
        position: absolute;
        top: 122px;
        left: 0;
        margin-top: 8px;
        color: #B2B2B2;
        display: none;
    }

    .see-favourites-CF p {
        display: block;
        font-size: 14px;
    }

    #see-all-favourite-button-CF {
        display: inline-block;
        font-size: 14px;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        left: 292px;
        bottom: 10px;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: block;
        margin-top: 20px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
        padding-top:14px;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 318px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
        font-size: 14px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 320px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-CF:hover .dropdown-menu-sort-date-CF {
        display: block;
    }
    .start-date-dropdown-CF:hover .dropdown-arrow-CF {
        transform: rotate(180deg);
        transition: 0.4s;
        padding-bottom: 12px;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }

    .start-date-dropdown-selected p {
        color: #fff !important;
    }

    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 20px;
        width: 320px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 18px;
        margin-bottom: 0;
        cursor: pointer;
    }
}

@media screen and (max-width: 350px){
    #resultCounter-CF {
        display: inline-block;
    }

    #resultCounterText-CF {
        display: inline-block;
    }

    .courseFinderSearchBar-CF {
        width: 300px;
        position: relative;
        height: 156px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding-top: 10px;
        margin: 20px auto;
    }
    .result-counter-CF {
        color: #31417A;
        font-size: 14px;
        display: inline;
        position: relative;
        top: 6px;
    }
    .search-bar-CF {
        display: inline-block;
        width: 100%;
        height: 30px;
        margin-left: 0;
        margin-top: 12px;
        background-color: #F4F5F8;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    #searchButton-CF {
        display: inline-block;
        cursor: pointer;
        vertical-align:middle;
        padding-top:4px;
    }
    .search-bar-CF i {
        display: inline-block;
        margin-left: 14px;
    }
    #search-field-CF {
        display: inline-block;
        width: 70%;
        border: none;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        background: #F4F5F8;
        margin-left: 16px;
        outline: 0;
        vertical-align: middle;
    }
    .see-favourites-CF {
        position: absolute;
        top: 122px;
        left: 0;
        margin-top: 8px;
        color: #B2B2B2;
        display: none;
    }

    .see-favourites-CF p {
        display: block;
        font-size: 14px;
    }

    #see-all-favourite-button-CF {
        display: inline-block;
        font-size: 14px;
    }
    #see-all-favourite-button-CF {
        position: relative;
        color: #31417a;
        cursor: pointer;
    }
    #see-all-favourite-button-CF i {
        position: absolute;
        left: 274px;
        bottom: 10px;
        font-size: 24px;
    }
    #see-all-favourite-button-CF .full-star {
        visibility: hidden;
    }
    .start-date-filter-CF {
        display: block;
        margin-top: 26px;
    }
    .start-date-dropdown-CF {
        display: inline-block;
        margin: 0;
        padding-top: 14px;
    }
    .start-date-dropdown-CF .course-finder-dropdown-CF {
        width: 298px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #B2B2B2;
    }
    .start-date-filter-CF p {
        display: inline-block;
        color: #B2B2B2;
        margin-right: 12px;
        font-size: 14px;
    }
    .start-date-dropdown-CF:hover .course-finder-dropdown-CF p {
        color: #79A342 !important;
    }
    .dropdown-menu-sort-date-CF {
        display: none;
        width: 300px;
        position: absolute;
        background-color: #f4f5f8;
        text-align: left !important;
        z-index: 100;
        font-size: 16px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #707070;
        padding-bottom: 0 !important;
        cursor: pointer;
    }
    .dropdown-menu-sort-date-CF li {
        padding: 16px 24px 16px 12px;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover p {
        color: #fff;
    }
    .start-date-filter-CF .start-date-dropdown-CF .dropdown-menu-sort-date-CF li:hover {
        background-color: #79A342;
    }

    .start-date-dropdown-CF:hover .dropdown-menu-sort-date-CF {
        display: block;
    }
    .start-date-dropdown-CF:hover .dropdown-arrow-CF {
        transform: rotate(180deg);
        transition: 0.4s;
        padding-bottom: 12px;
    }

    .start-date-dropdown-selected {
        background-color: #79A342 !important;
    }

    .start-date-dropdown-selected p {
        color: #fff !important;
    }

    #contactMeButton {
        display: none;
        position: absolute;
        right: 0;
        bottom: 20px;
        width: 300px;
        text-align: center;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #79A342;
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 14px;
        margin-bottom: 0;
        cursor: pointer;
    }
}