
.newTopicPageTmpl #herospaceTexts h2 {
    font-size: 25px;
    font-family: "Frutiger 47 Light Condensed", sans-serif;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .newTopicPageTmpl #contactBar .contactComponentTitle{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
    .newTopicPageTmpl #topicsPage_mindsInner h2{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {

    .newTopicPageTmpl #contactBar .contactComponentTitle{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
    .newTopicPageTmpl #topicsPage_mindsInner h2{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }

}

@media screen and (max-width: 350px){

    .newTopicPageTmpl #contactBar .contactComponentTitle{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
    .newTopicPageTmpl #topicsPage_mindsInner h2{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }

}

