#emptyInternalMenu {
    height: 88px;
    display: none;
}

@media screen and (min-width: 1400px) {
    #pageInternalMenu {
        width: 100%;
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenu a {
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular",sans-serif;
    }

    #pageInternalMenu a:hover {
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 64px;
        left: 0;
        padding-left: calc((100% - 1300px) / 2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #pageInternalMenu{
        width: 100%;
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenu a{
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }
    #pageInternalMenu a:hover{
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 64px;
        left: 0;
        padding-left: calc((100% - 1100px)/2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #pageInternalMenu{
        width: 100%;
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenu a{
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }
    #pageInternalMenu a:hover{
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 64px;
        left: 0;
        padding-left: calc((100% - 900px)/2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #pageInternalMenu{
        width: 100%;
        padding: 30px 0 12px;
        border-bottom: 1px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenu a{
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 25px;
        font-size: 12px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }
    #pageInternalMenu a:hover{
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 86px;
        left: 0;
        padding-left: calc((100% - 720px)/2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #pageInternalMenu{
        width: 100%;
        padding: 6px 0px 6px;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        word-break: break-word;
        background: #fff;
        z-index: 998;
    }

    #pageInternalMenu a{
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 28px;
        font-size: 13px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }
    #pageInternalMenu a:hover{
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 93px;
        left: 0;
        padding-left: calc((100% - 440px)/2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #pageInternalMenu{
        width: 100%;
        padding: 6px 0;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        word-break: break-word;
        background: #fff;
        z-index: 998;
    }

    #pageInternalMenu a{
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 28px;
        font-size: 13px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }
    #pageInternalMenu a:hover{
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 63px;
        left: 0;
        padding-left: calc((100% - 340px)/2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 120px;
        margin: -120px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (max-width: 350px){
    #pageInternalMenu{
        width: calc(100% - 4px);
        padding: 6px 0;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        word-break: break-word;
        background: #fff;
        z-index: 998;
    }

    #pageInternalMenu a{
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 28px;
        font-size: 13px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }
    #pageInternalMenu a:hover{
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 63px;
        left: 0;
        padding-left: calc((100% - 300px)/2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media print{
    #pageInternalMenu {
        width: 100%;
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenu a {
        text-decoration: none;
        margin: 8px 16px 8px 0px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular",sans-serif;
    }

    #pageInternalMenu a:hover {
        color: #31417a;
    }

    .stickySubmenu {
        position: fixed;
        top: 64px;
        left: 0;
        padding-left: calc((100% - 1300px) / 2) !important;
    }

    :target:before {
        content: "";
        display: block;
        height: 150px;
        margin: -150px 0 0;
    }

    .active-menu {
        color: #31417a !important;
    }
}