@media screen and (min-width: 1400px) {
    /* business solutions */
    #businessSolutions{
        width: 1300px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* business solutions */
    #businessSolutions{
        width: 1100px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* business solutions */
    #businessSolutions{
        width: 900px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /* business solutions */
    #businessSolutions{
        width: 720px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /* business solutions */
    #businessSolutions{
        width: 470px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /* business solutions */
    #businessSolutions{
        width: 340px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

@media screen and (max-width: 350px) {
    /* business solutions */
    #businessSolutions{
        width: 300px;
        margin: 0 auto;
        padding: 55px 0 0px;
        text-align: center;
    }
    #businessSolutions h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

@media print{
    /* business solutions */
    #businessSolutions{
        width: 1300px;
        margin: 0 auto;
        padding: 55px 0 0px;
    }
    #businessSolutions h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #businessSolutions a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: block;
        width: 160px;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    #businessSolutions a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

#businessSolutionsText .businessSolutionsLeftAlign {
    text-align: left !important;
}

.businessSolutionsLeftAlign {
    text-align: left !important;
}

#businessSolutions .businessSolutionsLeftAlign h3{
    text-align: left;
}