@media screen and (min-width: 1400px) { /*1300*/

  .exec-content-page #pageInternalMenu .subNavLinksContainer{
    width: 840px;
    padding: 40px 0 20px;
    border-bottom: 1px solid #ccc;
  }

  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 1300px;
    padding:0;
    border-bottom: none;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 64px;
    left: 0;
    border-bottom: 1px solid #ccc !important;
    width: 100% !important;
    padding-left: calc((100% - 1300px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    border-bottom: none !important;
    width:1300px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) { /*1100*/
  .exec-content-page #pageInternalMenu .subNavLinksContainer{
    width: 840px;
    padding: 40px 0 20px;
    border-bottom: 1px solid #ccc;
  }

  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 1100px;
    padding:0;
    border-bottom: none;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 64px;
    left: 0;
    border-bottom: 1px solid #ccc !important;
    width: 100% !important;
    padding-left: calc((100% - 1100px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    border-bottom: none !important;
    width:1100px;
  }
}
@media screen and (min-width: 1022px) and (max-width: 1200px) {
  .exec-content-page #pageInternalMenu .subNavLinksContainer{
    width: 600px;
    padding: 40px 0 20px;
    border-bottom: 1px solid #ccc;
  }

  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 900px;
    padding:0;
    border-bottom: none;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 64px;
    left: 0;
    border-bottom: 1px solid #ccc !important;
    width: 100% !important;
    padding-left: calc((100% - 900px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    border-bottom: none !important;
    width:900px;
  }
}
@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/

  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 720px;
    padding:0;
    border-bottom: 1px solid #ccc;
    padding: 30px 0 12px;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 86px;
    left: 0;
    width: 100% !important;
    padding-left: calc((100% - 720px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    width:720px;
  }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/


  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 440px;
    border-bottom: 1px solid #ccc;
    padding: 6px 0;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 93px;
    left: 0;
    width: 100% !important;
    padding-left: calc((100% - 440px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    width:440px;
  }


}

@media screen and (min-width: 350px) and (max-width: 478px) {/*320*/

  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 340px;
    border-bottom: 1px solid #ccc;
    padding: 6px 0;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 63px;
    left: 0;
    width: 100% !important;
    padding-left: calc((100% - 340px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    width:340px;
  }
}

@media screen and (max-width: 350px){/*320*/
  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 300px;
    border-bottom: 1px solid #ccc;
    padding: 6px 0;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 63px;
    left: 0;
    width: 100% !important;
    padding-left: calc((100% - 300px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    width:300px;
  }
}

@media print {

  .exec-content-page #pageInternalMenu .subNavLinksContainer{
    width: 840px;
    padding: 40px 0 20px;
    border-bottom: 1px solid #ccc;
  }

  .exec-content-page #pageInternalMenu .subNavLinksContainer a:first-child{
    margin-left:0px;
  }

  .exec-content-page #pageInternalMenu {
    background: #fff;
    word-break: break-word;
    z-index: 998;
    margin: 0 auto;
    width: 1300px;
    padding:0;
    border-bottom: none;
  }
  .exec-content-page .stickySubmenu {
    position: fixed;
    top: 64px;
    left: 0;
    border-bottom: 1px solid #ccc !important;
    width: 100% !important;
    padding-left: calc((100% - 1300px)/ 2)!important;
  }

  .exec-content-page .stickySubmenu>.subNavLinksContainer {
    border-bottom: none !important;
    width:1300px;
  }

}