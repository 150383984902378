@media screen and (min-width: 1400px) {
    /*
    | in contact block
    */
    #topicsPage_contact #contactBar{
        display: none;
    }

    /*
    | in block feed
    */
    #topicsPage_blogFeed #contactBar{
        position: absolute;
        width: 220px;
        top: 40px;
        right: 80px;
    }
    /* download */
    #topicsPage_blogFeed #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_blogFeed #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /*
    | in contact block
    */
    #topicsPage_contact #contactBar{
        display: none;
    }

    /*
    | in block feed
    */
    #topicsPage_blogFeed #contactBar{
        position: absolute;
        width: 220px;
        top: 40px;
        right: 0;
    }
    /* download */
    #topicsPage_blogFeed #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_blogFeed #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    .downloadCircle_line{
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #fff;
        bottom: 6px;
        left: 82%;
        margin-left: -17px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /*
    | in contact block
    */
    #topicsPage_contact #contactBar{
        display: none;
    }

    /*
    | in block feed
    */
    #topicsPage_blogFeed #contactBar{
        position: absolute;
        width: 220px;
        top: 40px;
        right: 0;
    }
    /* download */
    #topicsPage_blogFeed #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 6px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_blogFeed #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417A;
        margin: 22px 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 21px;
        margin-right: 5px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /*
    | in contact block
    */


    /*
    | in block feed
    */
    #topicsPage_contact #contactBar{
        display: block;
    }
    #topicsPage_contact #contactBar h2 {
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        color: #31417a;
        margin-bottom: 40px;
    }
    #topicsPage_contact h2 {
        display: none;
    }
    #topicsPage_contact #contactBar h4 {
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        color: #31417a;
        margin-bottom: 40px;
    }
    #topicsPage_contact #contactBar .contactBarElement {
        display:none;
    }
    /* download */
    #topicsPage_blogFeed #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_blogFeed #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_blogFeed #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_blogFeed #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    /* download */
    #topicsPage_contact #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        padding-bottom: 12px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_contact #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_contact #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /*
    | in block feed
    */
    #topicsPage_blogFeed #contactBar{
        display: none;
    }

    /*
    | in contact block
    */
    #topicsPage_contact #contactBar{
        margin: 0;
        box-sizing: border-box;
    }
    /* download */
    #topicsPage_contact #contactBar #contactBarDownload{
        display: block;
        text-decoration: none;
        padding-bottom: 12px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_contact #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_contact #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_contact #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin:  0;
    }
    #topicsPage_contact #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_contact #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_contact #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #topicsPage_contact #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #topicsPage_contact #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_contact #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_contact #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_contact #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    #contactBar>.contactBarElement {
        display:none !important;
    }
    #topicsPage_contactContainer > .contactBlock {
        padding:30px 0px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /*
    | in block feed
    */
    #topicsPage_blogFeed #contactBar{
        display: none;
    }

    /*
    | in contact block
    */
    #topicsPage_contact #contactBar{
        margin: 0;
        box-sizing: border-box;
    }
    /* download */
    #topicsPage_contact #contactBar #contactBarDownload{
        width: 180px;
        display: block;
        text-decoration: none;
        padding-bottom: 12px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_contact #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_contact #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_contact #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #topicsPage_contact #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_contact #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_contact #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #topicsPage_contact #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #topicsPage_contact #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_contact #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_contact #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_contact #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    #contactBar>.contactBarElement {
        display:none !important;
    }
}

@media screen and (max-width: 350px){
    /*
    | in block feed
    */
    #topicsPage_blogFeed #contactBar{
        display: none;
    }

    /*
    | in contact block
    */
    #topicsPage_contact #contactBar{
        margin: 0;
        box-sizing: border-box;
    }
    /* download */
    #topicsPage_contact #contactBar #contactBarDownload{
        width: 180px;
        display: block;
        text-decoration: none;
        padding-bottom: 12px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover{
        cursor: pointer;
    }
    #topicsPage_contact #contactBar #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 8px;
    }
    #topicsPage_contact #contactBar #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }
    #topicsPage_contact #contactBar #contactBarDownload p{
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 15px;
        color: #31417A;
    }
    /* contact details */
    #topicsPage_contact #contactBar h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417A;
        margin: 18px 0;
    }
    #topicsPage_contact #contactBar .contactBarElement{
        display: block;
        margin-bottom: 12px;
        text-decoration: none;
    }
    #topicsPage_contact #contactBar .contactBarElement:last-of-type{
        margin-bottom: 0;
    }
    #topicsPage_contact #contactBar .contactBarElement i{
        display: inline-block;
        color: #79a342;
        font-size: 18px;
        margin-right: 5px;
    }
    #topicsPage_contact #contactBar .contactBarElement:nth-of-type(2) p{
        margin-top: 1px;
    }
    #topicsPage_contact #contactBar .contactBarElement:nth-of-type(3) p{
        margin-top: 1px;
    }
    #topicsPage_contact #contactBar .contactBarElement:last-of-type p{
        margin-top: 2px;
    }
    #topicsPage_contact #contactBar .contactBarElement p{
        display: inline-block;
        vertical-align: top;
        color: #666666;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    #topicsPage_contact #contactBar .contactBarElement:hover p{
        color: #31417A;
        transition: 0.2s;
    }
    #topicsPage_contact #contactBar .contactBarElement:hover i{
        color: #31417A;
        transition: 0.2s;
    }

    #contactBar>.contactBarElement {
        display:none !important;
    }
}
