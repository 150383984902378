@media screen and (min-width: 1400px) {
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{

    }
    #topicsPage_institutesSlider li{

    }
    #topicsPage_institutesSlider .owl-item{
        width: 450px;
        height: 390px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 128px;
        height: 128px;
        margin: 80px auto 40px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 14px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

/* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 55px;
        color: #666666;
        position: absolute;
        top: 280px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 22px;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 22px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{

    }
    #topicsPage_institutesSlider li{

    }
    #topicsPage_institutesSlider .owl-item{
        width: 450px;
        height: 390px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 128px;
        height: 128px;
        margin: 80px auto 40px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 14px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

    /* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 55px;
        color: #666666;
        position: absolute;
        top: 275px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 22px;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 22px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{

    }
    #topicsPage_institutesSlider li{

    }
    #topicsPage_institutesSlider .owl-item{
        width: 450px;
        height: 390px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 128px;
        height: 128px;
        margin: 80px auto 40px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 14px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

    /* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 55px;
        color: #666666;
        position: absolute;
        top: 275px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 22px;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 22px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{
        width: 690px;
        margin: 0 auto;
    }
    #topicsPage_institutesSlider li{

    }
    #topicsPage_institutesSlider .owl-item{
        width: 330px;
        height: 260px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 80px;
        height: 80px;
        margin: 40px auto 25px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        font-weight: bold;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

    /* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 45px;
        color: #666666;
        position: absolute;
        top: 196px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 0;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{
        width: 470px;
        margin: 0 auto;
    }
    #topicsPage_institutesSlider li{

    }
    /*#topicsPage_institutesSlider .owl-carousel{*/
    /*    transform: rotate(90deg);*/
    /*    width: 270px;*/
    /*    margin-top:100px;*/
    /*}*/
    /*#topicsPage_institutesSlider .item{*/
    /*    transform: rotate(-90deg);*/
    /*}*/
    #topicsPage_institutesSlider .owl-item{
        width: 330px;
        height: 260px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 80px;
        height: 80px;
        margin: 40px auto 25px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        font-weight: bold;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

    /* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 45px;
        color: #666666;
        position: absolute;
        top: 190px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 5px;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 5px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{
        width: 300px;
        margin: 0 auto;
    }
    #topicsPage_institutesSlider li{

    }
    #topicsPage_institutesSlider .owl-item{
        width: 330px;
        height: 260px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 80px;
        height: 80px;
        margin: 40px auto 25px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        font-weight: bold;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

    /* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 45px;
        color: #666666;
        position: absolute;
        top: 190px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 5px;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 5px;
    }
}

@media screen and (max-width: 350px){
    #topicsPage_institutes{
        background-color: #e4e4e4;
        position: relative;
    }
    #topicsPage_institutesSlider{
        width: 260px;
        margin: 0 auto;
    }
    #topicsPage_institutesSlider li{

    }
    #topicsPage_institutesSlider .owl-item{
        width: 260px;
        height: 260px;
        background-color: #fff;
    }
    #topicsPage_institutesSlider img{
        width: 80px;
        height: 80px;
        margin: 30px auto 20px;
    }
    #topicsPage_institutesSlider h3{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #31417a;
        text-align: center;
    }
    #topicsPage_institutesSlider a{
        position: absolute;
        bottom: 14px;
        right: 25px;
        text-decoration: none;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        display: inline;
        color: #79A342;
        font-weight: bold;
        transition: 0.2s;
        margin-right: 10px;
    }
    #topicsPage_institutesSlider i{
        color: #B2B2B2;
        font-size: 12px;
        display: inline;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover{
        right: 20px;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover p{
        color: #666666;
        transition: 0.2s;
    }
    #topicsPage_institutesSlider a:hover i{
        margin-left: 5px;
        transition: 0.2s;
    }

    /* arrows */
    #topicsPage_institutes .fa-caret-left,
    #topicsPage_institutes .fa-caret-right{
        font-size: 40px;
        color: #666666;
        position: absolute;
        top: 194px;
    }
    #topicsPage_institutes .fa-caret-left:hover,
    #topicsPage_institutes .fa-caret-right:hover{
        cursor: pointer;
        transition: 0.2s;
        color: #7a7a7a;
    }
    #topicsPage_institutes .fa-caret-left{
        left: 3px;
    }
    #topicsPage_institutes .fa-caret-right{
        right: 3px;
    }
}