@media screen and (min-width: 1400px) {
    #newTeasers{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    /* blog posts */
    #newTeasers #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #newTeasers #postsContainer .blogPost{
        width: 380px;
        height: 350px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27,96,127,.6);
        padding: 45px 32px 0;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #newTeasers{
        width: 1100px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }

    /* blog posts */
    #newTeasers #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #newTeasers #postsContainer .blogPost{
        width: 355px;
        height: 350px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27,96,127,.6);
        padding: 45px 32px 0;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #newTeasers{
        width: 900px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }

    /* blog posts */
    #newTeasers #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #newTeasers #postsContainer .blogPost{
        width: 288px;
        height: 288px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 30px 22px 10px;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #newTeasers{
        width: 720px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }

    /* blog posts */
    #newTeasers #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0 40px;
    }
    #newTeasers #postsContainer .blogPost{
        width: 235px;
        height: 210px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 136px;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 15px 10px;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #newTeasers{
        width: 440px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }

    /* blog posts */
    #newTeasers #postsContainer{
        margin: 30px 0 20px;
    }
    #newTeasers #postsContainer .blogPost{
        width: 100%;
        height: 260px;
        display: block;
        margin-bottom: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 20px 15px;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #newTeasers{
        width: 340px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    /* blog posts */
    #newTeasers #postsContainer{
        margin: 30px 0 20px;
    }
    #newTeasers #postsContainer .blogPost{
        width: 100%;
        height: 260px;
        display: block;
        margin-bottom: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 20px 15px;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}

@media screen and (max-width: 350px){
    #newTeasers{
        width: 300px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    /* video */

    /* blog posts */
    #newTeasers #postsContainer{
        margin: 30px 0 20px;
    }
    #newTeasers #postsContainer .blogPost{
        width: 100%;
        height: 200px;
        display: block;
        margin-bottom: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background-color: rgba(27, 96, 127, 0.6);
        padding: 20px 15px;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin: 8px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}
@media print{
    #newTeasers{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }

    /* blog posts */
    #newTeasers #postsContainer{
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    #newTeasers #postsContainer .blogPost{
        width: 380px;
        height: 350px;
        background-position: center;
        background-size: cover;
        position: relative;
        color: #fff;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner{
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        min-height: 175px;
        bottom: 0;
        background-color: rgba(27,96,127,.6);
        padding: 45px 32px 0;
        transition: .4s;
    }
    #newTeasers #postsContainer .blogPost .blogPostInner .blogPostTitle{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0;
    }
    /* hover effects */
    #newTeasers #postsContainer .blogPost:hover .blogPostInner{
        min-height: 264px;
        padding-top: 90px;
        transition: 0.4s;
    }
}