.programmeCalendarWrapper {
    background: #e4e4e4;
}

@media screen and (min-width: 1400px) {
    /* business solutions */
    #programmeCalendar{
        width: 1300px;
        margin: 0 auto;
        padding: 55px 0 30px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }

    .programmeCalendarInner div a{
        width: 216px !important;
        height: 42px !important;
        min-height: 42px !important;
        box-sizing: border-box;
        padding-top: 11px;
        text-align: center;
        margin: 20px auto;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* business solutions */
    #programmeCalendar{
        width: 1100px;
        margin: 0 auto;
        padding: 55px 0 40px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    .programmeCalendarInner div a{
        width: 216px !important;
        height: 42px !important;
        min-height: 42px !important;
        box-sizing: border-box;
        padding-top: 11px;
        text-align: center;
        margin: 20px auto;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* business solutions */
    #programmeCalendar{
        width: 900px;
        margin: 0 auto;
        padding: 55px 0 40px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }

    .programmeCalendarInner div a{
        width: 216px !important;
        height: 42px !important;
        min-height: 42px !important;
        box-sizing: border-box;
        padding-top: 11px;
        text-align: center;
        margin: 20px auto;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /* business solutions */
    #programmeCalendar{
        width: 720px;
        margin: 0 auto;
        padding: 55px 0 55px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    .programmeCalendarInner div a{
        width: 210px !important;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /* business solutions */
    #programmeCalendar{
        width: 470px;
        margin: 0 auto;
        padding: 55px 0 55px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }

    .programmeCalendarInner div a{
        width: 240px;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /* business solutions */
    #programmeCalendar{
        width: 340px;
        margin: 0 auto;
        padding: 55px 0 55px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    .programmeCalendarInner div a{
        width: 240px;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px) {
    /* business solutions */
    #programmeCalendar{
        width: 300px;
        margin: 0 auto;
        padding: 55px 0 55px;
        text-align: center;
    }
    .programmeCalendarInner h2{
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 10px 6px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: inline-block;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    .programmeCalendarInner div a{
        width: 240px;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media print{
    /* business solutions */
    #programmeCalendar{
        width: 1300px;
        margin: 0 auto;
        padding: 55px 0 55px;
    }
    .programmeCalendarInner h2{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .programmeCalendarInner div a{
        text-decoration: none;
        padding: 15px 8px;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        display: block;
        width: 160px;
        text-align: center;
        margin: 24px auto 24px;
        transition: 0.2s;
    }
    .programmeCalendarInner div a:hover{
        background-color: #31417a;
        transition: 0.2s;
        cursor: pointer;
    }
    .programmeCalendarInner h3{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666;
    }
    #businessSolutions #businessSolutionsText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
}

#businessSolutionsText .businessSolutionsLeftAlign {
    text-align: left !important;
}

.businessSolutionsLeftAlign {
    text-align: left !important;
}

#businessSolutions .businessSolutionsLeftAlign h3{
    text-align: left;
}
