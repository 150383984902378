@media screen and (min-width: 1400px) {
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
    #inhouseProgramme .inhouse_programe a:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
    #inhouseProgramme .inhouse_programe a:hover {
        text-decoration: underline;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
    #inhouseProgramme .inhouse_programe a:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
    #inhouseProgramme .inhouse_programe a:hover {
        text-decoration: underline;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
}

@media screen and (max-width: 350px){
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
}

@media print {
    #inhouseProgramme{

    }
    #inhouseProgramme .inhouse_programe{
        padding: 30px 0;
        box-sizing: border-box;
        border-bottom: 2px solid #cccccc;
    }
    #inhouseProgramme .inhouse_programe h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #inhouseProgramme .inhouse_programe ul{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 22px;
    }
    #inhouseProgramme .inhouse_programe li:before{
        content: "-";
        margin-right: 4px;
    }
    #inhouseProgramme .inhouse_programe p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    #inhouseProgramme .inhouse_programe a {
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
        color:#79a342;
        font-weight: bold;
    }
}