#emptyInternalMenuSeminar {
    height: 88px;
    display: none;
}

@media screen and (min-width: 1400px){
    #pageInternalMenuSeminar {
        position:relative;
        right:100px;
        margin: 0 auto;
        min-width: 738px;
        max-width: 738px;
        width: calc(100% / 1.5);
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 64px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 1300px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #pageInternalMenuSeminar {
        position:relative;
        right:100px;
        margin: 0 auto;
        min-width: 738px;
        max-width: 738px;
        width: calc(100% / 1.5);
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 64px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 1100px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #pageInternalMenuSeminar {
        position:relative;
        right:100px;
        margin-left: 19.33%;
        min-width: 738px;
        max-width: 738px;
        width: calc(100% / 1.5);
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 64px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 900px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #pageInternalMenuSeminar {
        position:relative;
        margin-left: 11.33%;
        min-width: 508px;
        max-width: 508px;
        width: calc(100% / 1.5);
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 86px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 720px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #pageInternalMenuSeminar {
        position:relative;
        margin-left: 11.33%;
        min-width: 400px;
        max-width: 478px;
        width: calc((100% / 1.5) - 11.33%);
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 68px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 460px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #pageInternalMenuSeminar {
        position:relative;
        margin-left: 11.33%;
        min-width: 310px;
        max-width: 310px;
        width: calc((100% / 1.5) - 11.33%);
        padding: 6px 0;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 63px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 350px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

@media screen and (max-width: 350px) {
    #pageInternalMenuSeminar {
        position:relative;
        margin-left: 11.33%;
        min-width: 276px;
        max-width: 276px;
        width: calc((100% / 1.5) - 11.33%);
        padding: 6px 0;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 63px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 350px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

/* print */
@media print {
    #pageInternalMenuSeminar {
        position:relative;
        right:100px;
        margin-left: 11.33%;
        min-width: 738px;
        max-width: 738px;
        width: calc(100% / 1.5);
        padding: 40px 0 20px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        word-break: break-word;
        z-index: 998;
    }

    #pageInternalMenuSeminar a {
        display: inline-block;
        text-decoration: none;
        margin: 8px;
        line-height: 26px;
        font-size: 14px;
        color: #b2b2b2;
        font-family: "Helvetica Regular", sans-serif;
    }

    #pageInternalMenuSeminar a:hover {
        color: #31417a;
    }

    .stickySubmenu-OS {
        position: fixed !important;
        top: 64px;
        left: 0;
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding-left: calc((100% - 960px) / 2) !important;
    }

    .active-menu {
        color: #31417a !important;
    }
}

/* Testimonials slider */
@media screen and (min-width:1400px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }
    .exec-content-page #homePageSliderBar p {
        top: 10px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }
    .exec-content-page #homePageSliderBar p {
        top: 10px !important;
    }
}

@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }
    .exec-content-page #homePageSliderBar p {
        top: 10px !important;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }


    .exec-content-page #homePageSliderBar p {
        top: 10px !important;
    }
}

@media screen and (min-width: 478px) and (max-width: 766px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }
}

@media screen and (max-width: 350px) {
    .insert.-testimonial-slider .background .owl-stage {
        height: auto;
    }

    .insert.-blog-slider .background .owl-stage {
        height: auto;
    }
}