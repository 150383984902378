@media screen and (min-width: 1400px) { /*1300*/
    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }
    .executive-insert .executive-background:after {
        width: 30px;
        height: 120px;
        right: -15px;
        bottom: -19px;
        background:#FFF;
    }
    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;
    }

    .executive-insert .executive-fs-grid {
        width:1300px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:1300px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 1300px;
        margin: 48px auto 0;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }

    .executive-author{
        width: calc(4/12 * 100%);
        border-right: 1px solid #fff;
    }

    .executive-author-thumbnail{
        width: 100%;
        padding: 0 15px;
        position: relative;

    }

    .executive-author .executive-author-meta {
        display:none;
    }

    /**/

    .executive-content{
        width: calc(7/12 * 100%);
        padding:0 15px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

    .executive-content .executive-author-meta {
        margin-top: 22px;
        display: block;
    }

    .executive-content .executive-author-meta .executive-heading-author {
        font-size: 20px;
        line-height: 22px;
    }

    .executive-content .executive-author-meta .executive-heading-author-sub {
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/
    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;
    }

    .executive-insert .executive-fs-grid {
        width:1100px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:1100px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 1164px;
        margin: 48px auto 0;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }

    .executive-author{
        width: calc(4/12 * 100%);
        border-right: 1px solid #fff;
    }

    .executive-author-thumbnail{
        width: 100%;
        padding: 0 15px;
        position: relative;

    }

    .executive-author .executive-author-meta {
        display:none;
    }

    /**/

    .executive-content{
        width: calc(7/12 * 100%);
        padding:0 15px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

    .executive-content .executive-author-meta {
        margin-top: 22px;
        display: block;
    }

    .executive-content .executive-author-meta .executive-heading-author {
        font-size: 20px;
        line-height: 22px;
    }

    .executive-content .executive-author-meta .executive-heading-author-sub {
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/
    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;
    }

    .executive-insert .executive-fs-grid {
        width:900px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:900px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 900px;
        margin: 48px auto 0;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }

    .executive-author{
        width: calc(4/12 * 100%);
        border-right: 1px solid #fff;
    }

    .executive-author-thumbnail{
        width: 100%;
        padding: 0 15px;
        position: relative;

    }

    .executive-author .executive-author-meta {
        display:none;
    }

    .executive-content{
        width: calc(7/12 * 100%);
        padding:0px 15px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

    .executive-content .executive-author-meta {
        margin-top: 22px;
        display: block;
    }

    .executive-content .executive-author-meta .executive-heading-author {
        font-size: 20px;
        line-height: 22px;
    }

    .executive-content .executive-author-meta .executive-heading-author-sub {
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;
    }

    .executive-insert .executive-fs-grid {
        width:720px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:720px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 720px;
        margin: 48px auto 0;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }

    .executive-author{
        width: 266px;
        border-right: 1px solid #fff;
    }

    .executive-author-thumbnail{
        padding: 0 15px;
        position: relative;
    }

    .executive-author .executive-author-meta {
        display:none;
    }

    .executive-content{
        width: calc(7/12 * 100%);
        padding:0px 15px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

    .executive-content .executive-author-meta {
        margin-top: 22px;
        display: block;
    }

    .executive-content .executive-author-meta .executive-heading-author {
        font-size: 20px;
        line-height: 22px;
    }

    .executive-content .executive-author-meta .executive-heading-author-sub {
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/

    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }
    .executive-testimonial{
        overflow:hidden;
    }
    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;

    }

    .executive-insert .executive-fs-grid {
        width:470px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:470px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 470px;
        margin: 48px auto 0;
        padding:0 15px;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }
    .executive-content .executive-author-meta{
        display:none; /*hides the desktop content for author meta*/
    }
    .executive-author{
        display: block;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
    }

    .executive-author-thumbnail{
        display:inline-block;
        width:225px;
        padding-right:20px;
    }

    .executive-author .executive-author-meta{
        display: inline-block;
        width: 225px;
    }
    .executive-author .executive-author-meta .executive-heading-author {
        margin-bottom: 0;
        font-family: 'Helvetica Roman';
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }


    .executive-author-meta .executive-heading-author-sub {
        font-size: 11px;
        line-height: 14px;
        font-weight:700;
        font-family: 'Helvetica Roman';
    }

    .executive-content{
        display: inline-block;
        padding: 10px 0px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/

    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }
    .executive-testimonial{
        overflow:hidden;
    }
    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;

    }

    .executive-insert .executive-fs-grid {
        width:340px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:340px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 340px;
        margin: 48px auto 0;
        padding:0 15px;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }
    .executive-content .executive-author-meta{
        display:none; /*hides the desktop content for author meta*/
    }
    .executive-author{
        display: block;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;

    }

    .executive-author-thumbnail{
        display: inline-block;
        width: 150px;
        padding-right: 10px;
        box-sizing: content-box;
    }

    .executive-author .executive-author-meta{
        display: inline-block;
        width: 150px;
        box-sizing: content-box;

    }
    .executive-author .executive-author-meta .executive-heading-author {
        margin-bottom: 0;
        font-family: 'Helvetica Roman';
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }


    .executive-author-meta .executive-heading-author-sub {
        font-size: 11px;
        line-height: 14px;
        font-weight:700;
        font-family: 'Helvetica Roman';
    }

    .executive-content{
        display: inline-block;
        padding: 10px 0px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }
}

@media screen and (max-width: 350px){/*300*/

    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }
    .executive-testimonial{
        overflow:hidden;
    }
    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;

    }

    .executive-insert .executive-fs-grid {
        width:300px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:300px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 300px;
        margin: 48px auto 0;
        padding:0 10px;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }
    .executive-content .executive-author-meta{
        display:none; /*hides the desktop content for author meta*/
    }
    .executive-author{
        display: block;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;

    }

    .executive-author-thumbnail{
        display: inline-block;
        width: 135px;
        padding-right: 10px;
        box-sizing: content-box;
    }

    .executive-author .executive-author-meta{
        display: inline-block;
        width: 135px;
        box-sizing: content-box;

    }
    .executive-author .executive-author-meta .executive-heading-author {
        margin-bottom: 0;
        font-family: 'Helvetica Roman';
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }


    .executive-author-meta .executive-heading-author-sub {
        font-size: 11px;
        line-height: 14px;
        font-weight:700;
        font-family: 'Helvetica Roman';
    }

    .executive-content{
        display: inline-block;
        padding: 10px 0px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

}

@media print {
    .executive-insert {
        color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
    }
    .executive-insert .executive-background:after {
        width: 30px;
        height: 120px;
        right: -15px;
        bottom: -19px;
        background:#FFF;
    }
    .executive-insert .executive-background {
        background-color: #79A342;
        position: relative;
        overflow: hidden;
        padding-bottom: 57px;
        padding-top:30px;

    }

    .executive-insert .executive-fs-grid {
        width:1300px;
        margin:0 auto;
    }

    .executive-insert .executive-slider {
        width:1300px;
        margin:0 auto;
        position: relative;
    }

    .executive-insert .executive-testimonials {
        width: 1164px;
        margin: 48px auto 0;
    }

    .executive-insert .executive-navigation-container {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9;
    }

    .executive-insert .executive-navigation-container .testimonial-next {
        float:right;
    }

    .executive-insert .executive-navigation-container .testimonial-prev {
        float:left;
    }

    .executive-author{
        width: calc(4/12 * 100%);
        border-right: 1px solid #fff;
    }

    .executive-author-thumbnail{
        width: 100%;
        padding: 0 15px;
        position: relative;
    }

    .executive-author .executive-author-meta {
        display:none;
    }

    /**/

    .executive-content{
        width: calc(7/12 * 100%);
        padding:0 15px;
    }

    .executive-content .executive-heading-callout {
        font-style:italic;
        font-size: 26px;
        line-height: 30px;
        font-family: 'Frutiger 57';
        font-weight: normal;
    }

    .executive-content .executive-author-meta {
        margin-top: 22px;
        display: block;
    }

    .executive-content .executive-author-meta .executive-heading-author {
        font-size: 20px;
        line-height: 22px;
    }

    .executive-content .executive-author-meta .executive-heading-author-sub {
        font-size: 14px;
        line-height: 20px;
        font-weight:700;
    }
}