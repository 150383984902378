@media screen and (min-width: 1400px) {
    #qualificationConcept{
        width: 1300px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 840px;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }

    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #qualificationConcept{
        width: 1100px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 840px;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }
    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }
    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #qualificationConcept{
        width: 900px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 840px;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }
    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }
    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #qualificationConcept{
        width: 720px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 100%;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }
    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }
    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #qualificationConcept{
        width: 470px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 100%;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }
    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }
    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #qualificationConcept{
        width: 340px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 100%;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }
    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }
    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}

@media screen and (max-width: 350px){
    #qualificationConcept{
        width: 300px;
        margin: 0 auto;
    }
    #qualificationConceptInner{
        width: 100%;
    }
    #qualificationConcept h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        text-align: left;
        margin: 66px 0 0;
    }
    #qualificationConcept h3 {
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }

    #qualificationConcept h6{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        text-align: left;
        margin: 34px 0 0;
    }
    #qualificationConcept p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 20px 0;
    }
    #qualificationConcept ul li {
        list-style: disc inside;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        text-align: left;
        line-height: 1.6em;
        margin: 10px 0;
    }
}