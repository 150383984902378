#newCertificationPrograms {
    background: #e4e4e4;
}
.newCertProgramsWrapper h4 {
    color: #666;
}
.newCertProgramsWrapper .certLinksWrapper .certLinks a{
    text-decoration: none;
}
.newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
    font-weight: bold;
}
.certLinksWrapper:hover{
    cursor: pointer;
}
.imgGreenArrow i {
    font-size: 30px;
    color: #79A342;
}
.new_topicsPage_certificationPrograms_inner h3{
    font-size: 19px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    color: #31417a;
    margin-top: 15px;
    margin-left: 10px;
}


@media screen and (min-width: 1400px) {
    .new_topicsPage_certificationPrograms_inner{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover{
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .new_topicsPage_certificationPrograms_inner h3{
        margin-left: 0px;
    }

    .newCertProgramsWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 20px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
        height:40px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 13px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 30%;
    }
    .imgGreenArrow i {
        width: 50px;
        float: right;
        right: -10px;
        margin-bottom: 10px;
    }
    .certLinksWrapper:hover .imgGreenArrow i {
        position: relative;
        right: 0px;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        transform: translateX(15px);
        transition: transform 0.3s ease-in-out;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .new_topicsPage_certificationPrograms_inner{
        width: 1100px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    .newCertProgramsWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 20px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
        height: 40px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 13px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 30%;
    }
    .imgGreenArrow i {
        width: 50px;
        float: right;
        right: -10px;
        margin-bottom: 10px;
    }
    .imgGreenArrow i:hover {
        position: relative;
        right: 0px;
        transition: all 1s ease-in;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        transform: translateX(15px);
        transition: transform 0.3s ease-in-out;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .new_topicsPage_certificationPrograms_inner{
        width: 900px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    .newCertProgramsWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 20px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
        height: 40px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 11px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 30%;
    }
    .imgGreenArrow i {
        width: 50px;
        float: right;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .new_topicsPage_certificationPrograms_inner{
        width: 720px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 15px;
    }
    .newCertProgramsWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 18px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
        height: 54px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 35px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 11px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 30%;
    }
    .imgGreenArrow i {
        width: 50px;
        float: right;
        margin-bottom: 10px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .new_topicsPage_certificationPrograms_inner{
        max-width: 470px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 15px;
    }
    .newCertProgramsWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 15px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
        height: 45px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 25px 0 20px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 11px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 30%;
    }
    .imgGreenArrow i {
        width: 45px;
        float: right;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .new_topicsPage_certificationPrograms_inner{
        width: 340px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 15px;
    }
    .newCertProgramsWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 15px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
        height: 45px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 25px 0 20px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 11px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 48%;
    }
    .imgGreenArrow i {
        width: 45px;
        float: right;
        margin-bottom: 10px;
    }
    .imgGreenArrow i {
        font-size: 28px;
    }
}

@media screen and (max-width: 350px){
    .new_topicsPage_certificationPrograms_inner{
        width: 300px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks a h3 {
        font-size: 15px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
        text-decoration: none;
        margin-top: 18px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 15px;
    }
    .newCertProgramsWrapper{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 25px 0 20px;
    }
    .newCertProgramsWrapper h4 {
        font-size: 11px;
        color: #666;
        font-family: "Helvetica Regular",sans-serif;
        margin-left: 10px;
        margin-top: 10px;
    }
    .newCertProgramsWrapper .certLinksWrapper .certLinks {
        background: #fff;
        height: 80px;
        margin: 0 5% 3% 0;
        text-decoration: none;
    }
    .certLinksWrapper {

        background: #fff;
        margin: 0 2% 3% 0;
        width: 100%;
    }
    .imgGreenArrow i {
        width: 50px;
        float: right;
        margin-bottom: 10px;
    }
    .imgGreenArrow i {
        font-size: 28px;
    }
}
@media print{
    .new_topicsPage_certificationPrograms_inner{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #newTeasers #postsContainer:hover {
        text-decoration: underline;
    }
    .new_topicsPage_certificationPrograms_inner h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        margin-top: 12px;
        margin-left: 10px;
    }
    .new_topicsPage_certificationPrograms_inner{
        background-color: #e4e4e4;
        padding: 45px 0 15px;
    }
}