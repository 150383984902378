
.remodal-wrapper-new {
    display: none;
    padding: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-color: #333;
    opacity: 0;
}
.remodal-new {
    text-align: left;
    padding: 0 15px !important;
    margin: 0 auto;
    vertical-align: top;
    width: 100%;
    display: inline-block;
    outline: 0;
    box-sizing: border-box;
}

.login-background {
    background: #333 !important;
}

@media screen and (min-width: 1200px) {
    .fs-row-new {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }

    .remodal-wrapper-new .fs-row-new .inner {
        position: relative;
        display: block;
        padding: 0 15px !important;
    }
    .remodal-wrapper-new .fs-row-new .inner:first-of-type {
        width: 100%;
    }
    .remodal-wrapper-new .fs-row-new .inner:last-of-type {
        width: 873px;
        margin-top: 143px;
    }

    /* close button */
    .remodal-wrapper-new .close-link {
        float: right;
        margin-top: 25px;
        padding-left: 24px;
        font-size: 11px;
        line-height: 11px;
        margin-left: auto;
        border-left: 1px solid #666;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon {
        width: 24px;
        height: 24px;
        padding-left: 0;
        display: inline-block;
        flex: 0 0 40px;
        flex-basis: auto;
        color: #ccc;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon svg {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .remodal-wrapper-new .close-link .icon line {
        fill: none;
        stroke: #79a342;
    }

    .remodal-wrapper-new .close-link .description {
        display: block;
        color: #ccc;
        margin-top: 6px;
        margin-bottom: -2px;
        font-family: "Helvetica Roman", sans-serif;
    }

    .remodal-wrapper-new .close-link:hover {
        cursor: pointer;
    }

    .remodal-wrapper-new .close-link:hover .description {
        color: #fff;
    }

    /* section */
    .remodal-new {
        min-width: 738px;
        max-width: 1164px;
    }

    .remodal-new .modal-section {
        margin-top: 143px;
    }

    .login-links .modal-section {
        position: relative;
        min-height: 1px;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        padding: 0;
    }

    .modal-section h4 {
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        color: #fff;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        border-bottom: 1px solid #B2B2B2;
    }

    .modal-section .links {
        vertical-align: top;
        margin: 35px auto 0;
        display: flex;
        justify-content: space-between;
    }

    .modal-section .links .item {
        width: 49%;
        position: relative;
        font-family: 'Helvetica Roman', sans-serif;
    }

    .modal-section .links .item a {
        text-decoration: none;
        color: #ccc;
        display: block;
        border-left: 1px solid #ccc;
        height: 100%;
        padding-bottom: 6px;
        padding-left: 16px;
        max-height: 160px;
    }

    .modal-section .links .item span {
        display: block;
    }

    .modal-section .links .item .title {
        font-size: 22px;
        line-height: 25px;
        color: #79A342;
        display: block;
    }

    .modal-section .links .item .link {
        margin-top: 11px;
        font-size: 14px;
        line-height: 17px;
    }

    .modal-section .links .item span:nth-of-type(3) {
        margin-top: 22px;
    }

    .modal-section .links .item span:nth-of-type(4) {
        margin-top: 22px;
    }

    .modal-section .links .item span:last-of-type {
        margin-top: 22px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .fs-row-new{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }

    .remodal-wrapper-new .fs-row-new .inner {
        position: relative;
        display: block;
        padding: 0 15px !important;;
    }
    .remodal-wrapper-new .fs-row-new .inner:first-of-type {
        width: 100%;
    }
    .remodal-wrapper-new .fs-row-new .inner:last-of-type {
        width: 768px;
        margin-top: 143px;
    }

    /* close button */
    .remodal-wrapper-new .close-link {
        float: right;
        margin-top: 25px;
        padding-left: 24px;
        font-size: 11px;
        line-height: 11px;
        margin-left: auto;
        border-left: 1px solid #666;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon {
        width: 24px;
        height: 24px;
        padding-left: 0;
        display: inline-block;
        flex: 0 0 40px;
        flex-basis: auto;
        color: #ccc;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon svg {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .remodal-wrapper-new .close-link .icon line {
        fill: none;
        stroke: #79a342;
    }

    .remodal-wrapper-new .close-link .description {
        display: block;
        color: #ccc;
        margin-top: 6px;
        margin-bottom: -2px;
        font-family: "Helvetica Roman", sans-serif;
    }

    .remodal-wrapper-new .close-link:hover {
        cursor: pointer;
    }

    .remodal-wrapper-new .close-link:hover .description {
        color: #fff;
    }

    /* section */
    .remodal-new {
        min-width: 738px;
        max-width: 1164px;
    }

    .remodal-new .modal-section {
        margin-top: 143px;
    }

    .login-links .modal-section {
        position: relative;
        min-height: 1px;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        padding: 0;
    }

    .modal-section h4 {
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        color: #fff;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        border-bottom: 1px solid #B2B2B2;
    }

    .modal-section .links {
        vertical-align: top;
        margin: 35px auto 0;
        display: flex;
        justify-content: space-between;
    }

    .modal-section .links .item {
        width: 49%;
        position: relative;
        font-family: 'Helvetica Roman', sans-serif;
    }

    .modal-section .links .item a {
        text-decoration: none;
        color: #ccc;
        display: block;
        border-left: 1px solid #ccc;
        height: 100%;
        padding-bottom: 6px;
        padding-left: 16px;
        max-height: 160px;
    }

    .modal-section .links .item span {
        display: block;
    }

    .modal-section .links .item .title {
        font-size: 22px;
        line-height: 25px;
        color: #79A342;
        display: block;
    }

    .modal-section .links .item .link {
        margin-top: 11px;
        font-size: 14px;
        line-height: 17px;
    }

    .modal-section .links .item span:nth-of-type(3) {
        margin-top: 22px;
    }

    .modal-section .links .item span:nth-of-type(4) {
        margin-top: 22px;
    }

    .modal-section .links .item span:last-of-type {
        margin-top: 22px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .fs-row-new{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }

    .remodal-wrapper-new .fs-row-new .inner {
        position: relative;
        display: block;
        padding: 0 15px !important;;
    }
    .remodal-wrapper-new .fs-row-new .inner:first-of-type {
        width: 100%;
    }
    .remodal-wrapper-new .fs-row-new .inner:last-of-type {
        width: 576px;
        margin-top: 143px;
    }

    /* close button */
    .remodal-wrapper-new .close-link {
        float: right;
        margin-top: 25px;
        padding-left: 24px;
        font-size: 11px;
        line-height: 11px;
        margin-left: auto;
        border-left: 1px solid #666;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon {
        width: 24px;
        height: 24px;
        padding-left: 0;
        display: inline-block;
        flex: 0 0 40px;
        flex-basis: auto;
        color: #ccc;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon svg {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .remodal-wrapper-new .close-link .icon line {
        fill: none;
        stroke: #79a342;
    }

    .remodal-wrapper-new .close-link .description {
        display: block;
        color: #ccc;
        margin-top: 6px;
        margin-bottom: -2px;
        font-family: "Helvetica Roman", sans-serif;
    }

    .remodal-wrapper-new .close-link:hover {
        cursor: pointer;
    }

    .remodal-wrapper-new .close-link:hover .description {
        color: #fff;
    }

    /* section */
    .remodal-new {
        min-width: 738px;
        max-width: 1164px;
    }

    .remodal-new .modal-section {
        margin-top: 143px;
    }

    .login-links .modal-section {
        position: relative;
        min-height: 1px;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        padding: 0;
    }

    .modal-section h4 {
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        color: #fff;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        border-bottom: 1px solid #B2B2B2;
    }

    .modal-section .links {
        vertical-align: top;
        margin: 35px auto 0;
        display: flex;
        justify-content: space-between;
    }

    .modal-section .links .item {
        width: 49%;
        position: relative;
        font-family: 'Helvetica Roman', sans-serif;
    }

    .modal-section .links .item a {
        text-decoration: none;
        color: #ccc;
        display: block;
        border-left: 1px solid #ccc;
        height: 100%;
        padding-bottom: 6px;
        padding-left: 16px;
        max-height: 160px;
    }

    .modal-section .links .item span {
        display: block;
    }

    .modal-section .links .item .title {
        font-size: 22px;
        line-height: 25px;
        color: #79A342;
        display: block;
    }

    .modal-section .links .item .link {
        margin-top: 11px;
        font-size: 14px;
        line-height: 17px;
    }

    .modal-section .links .item span:nth-of-type(3) {
        margin-top: 22px;
    }

    .modal-section .links .item span:nth-of-type(4) {
        margin-top: 22px;
    }

    .modal-section .links .item span:last-of-type {
        margin-top: 22px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .fs-row-new{
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        width: 465px;
    }

    .remodal-wrapper-new .fs-row-new .inner {
        position: relative;
        display: block;
        padding: 0 15px !important;;
    }
    .remodal-wrapper-new .fs-row-new .inner:first-of-type {
        width: 100%;
    }
    .remodal-wrapper-new .fs-row-new .inner:last-of-type {
        width: 100%;
        margin-top: 84px;
    }

    /* close button */
    .remodal-wrapper-new .close-link {
        float: right;
        margin-top: 20px;
        padding-left: 10px;
        font-size: 9px;
        line-height: 9px;
        margin-left: auto;
        border-left: 1px solid #666;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon {
        width: 20px;
        height: 20px;
        padding-left: 0;
        display: inline-block;
        flex: 0 0 40px;
        flex-basis: auto;
        color: #ccc;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon svg {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .remodal-wrapper-new .close-link .icon line {
        fill: none;
        stroke: #79a342;
    }

    .remodal-wrapper-new .close-link .description {
        display: block;
        color: #ccc;
        margin-top: 6px;
        margin-bottom: -2px;
        font-family: "Helvetica Roman", sans-serif;

    }

    .remodal-wrapper-new .close-link:hover {
        cursor: pointer;
    }

    .remodal-wrapper-new .close-link:hover .description {
        color: #fff;
    }

    /* section */
    .remodal-new .modal-section {
        margin-top: 143px;
    }

    .login-links .modal-section {
        position: relative;
        min-height: 1px;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        padding: 0;
    }

    .modal-section h4 {
        font-size: 22px;
        line-height: 22px;
        margin-top: 40px;
        padding-bottom: 10px;
        color: #fff;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        border-bottom: 1px solid #B2B2B2;
    }

    .modal-section .links {
        vertical-align: top;
        margin: 35px auto 0;
    }

    .modal-section .links .item {
        width: 100%;
        position: relative;
        font-family: 'Helvetica Roman', sans-serif;
        margin-bottom: 20px;
    }

    .modal-section .links .item a {
        text-decoration: none;
        color: #ccc;
        display: block;
        border-left: 1px solid #ccc;
        height: 100%;
        padding-bottom: 6px;
        padding-left: 16px;
        max-height: 160px;
    }

    .modal-section .links .item span {
        display: block;
    }

    .modal-section .links .item .title {
        font-size: 16px;
        line-height: 21px;
        color: #79A342;
        display: block;
    }

    .modal-section .links .item .link {
        margin-top: 2px;
        font-size: 12px;
        line-height: 15px;
    }

    .modal-section .links .item span:nth-of-type(3) {
        margin-top: 22px;
    }

    .modal-section .links .item span:nth-of-type(4) {
        margin-top: 22px;
    }

    .modal-section .links .item span:last-of-type {
        margin-top: 22px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .fs-row-new{
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
        width: 320px;
    }

    .remodal-wrapper-new .fs-row-new .inner {
        position: relative;
        display: block;
        /*padding: 0 15px;*/
    }
    .remodal-wrapper-new .fs-row-new .inner:first-of-type {
        width: 100%;
    }
    .remodal-wrapper-new .fs-row-new .inner:last-of-type {
        width: 100%;
        margin-top: 84px;
    }

    /* close button */
    .remodal-wrapper-new .close-link {
        float: right;
        margin-top: 20px;
        padding-left: 10px;
        font-size: 9px;
        line-height: 9px;
        margin-left: auto;
        border-left: 1px solid #666;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon {
        width: 20px;
        height: 20px;
        padding-left: 0;
        display: inline-block;
        flex: 0 0 40px;
        flex-basis: auto;
        color: #ccc;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon svg {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .remodal-wrapper-new .close-link .icon line {
        fill: none;
        stroke: #79a342;
    }

    .remodal-wrapper-new .close-link .description {
        display: block;
        color: #ccc;
        margin-top: 6px;
        margin-bottom: -2px;
        font-family: "Helvetica Roman", sans-serif;

    }

    .remodal-wrapper-new .close-link:hover {
        cursor: pointer;
    }

    .remodal-wrapper-new .close-link:hover .description {
        color: #fff;
    }

    /* section */
    .remodal-new .modal-section {
        margin-top: 143px;
    }

    .login-links .modal-section {
        position: relative;
        min-height: 1px;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        padding: 0;
    }

    .modal-section h4 {
        font-size: 22px;
        line-height: 22px;
        margin-top: 40px;
        padding-bottom: 10px;
        color: #fff;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        border-bottom: 1px solid #B2B2B2;
    }

    .modal-section .links {
        vertical-align: top;
        margin: 35px auto 0;
    }

    .modal-section .links .item {
        width: 100%;
        position: relative;
        font-family: 'Helvetica Roman', sans-serif;
        margin-bottom: 20px;
    }

    .modal-section .links .item a {
        text-decoration: none;
        color: #ccc;
        display: block;
        border-left: 1px solid #ccc;
        height: 100%;
        padding-bottom: 6px;
        padding-left: 10px;
        max-height: 160px;
    }

    .modal-section .links .item span {
        display: block;
    }

    .modal-section .links .item .title {
        font-size: 16px;
        line-height: 21px;
        color: #79A342;
        display: block;
    }

    .modal-section .links .item .link {
        margin-top: 2px;
        font-size: 12px;
        line-height: 15px;
    }

    .modal-section .links .item span:nth-of-type(3) {
        margin-top: 22px;
    }

    .modal-section .links .item span:nth-of-type(4) {
        margin-top: 22px;
    }

    .modal-section .links .item span:last-of-type {
        margin-top: 22px;
    }
}

@media screen and (max-width: 350px) {
    .fs-row-new{
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
        width: 280px;
    }

    .remodal-wrapper-new .fs-row-new .inner {
        position: relative;
        display: block;
        /*padding: 0 15px;*/
    }
    .remodal-wrapper-new .fs-row-new .inner:first-of-type {
        width: 100%;
    }
    .remodal-wrapper-new .fs-row-new .inner:last-of-type {
        width: 100%;
        margin-top: 84px;
    }

    /* close button */
    .remodal-wrapper-new .close-link {
        float: right;
        margin-top: 20px;
        padding-left: 10px;
        font-size: 9px;
        line-height: 9px;
        margin-left: auto;
        border-left: 1px solid #666;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon {
        width: 20px;
        height: 20px;
        padding-left: 0;
        display: inline-block;
        flex: 0 0 40px;
        flex-basis: auto;
        color: #ccc;
        text-align: center;
    }

    .remodal-wrapper-new .close-link .icon svg {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .remodal-wrapper-new .close-link .icon line {
        fill: none;
        stroke: #79a342;
    }

    .remodal-wrapper-new .close-link .description {
        display: block;
        color: #ccc;
        margin-top: 6px;
        margin-bottom: -2px;
        font-family: "Helvetica Roman", sans-serif;
    }
    .remodal-wrapper-new .close-link:hover {
        cursor: pointer;
    }
    .remodal-wrapper-new .close-link:hover .description {
        color: #fff;
    }

    /* section */
    .remodal-new .modal-section {
        margin-top: 143px;
    }

    .login-links .modal-section {
        position: relative;
        min-height: 1px;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        padding: 0;
    }

    .modal-section h4 {
        font-size: 22px;
        line-height: 22px;
        margin-top: 40px;
        padding-bottom: 10px;
        color: #fff;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        border-bottom: 1px solid #B2B2B2;
    }

    .modal-section .links {
        vertical-align: top;
        margin: 35px auto 0;
    }

    .modal-section .links .item {
        width: 100%;
        position: relative;
        font-family: 'Helvetica Roman', sans-serif;
        margin-bottom: 20px;
    }

    .modal-section .links .item a {
        text-decoration: none;
        color: #ccc;
        display: block;
        border-left: 1px solid #ccc;
        height: 100%;
        padding-bottom: 6px;
        padding-left: 10px;
        max-height: 160px;
    }

    .modal-section .links .item span {
        display: block;
    }

    .modal-section .links .item .title {
        font-size: 16px;
        line-height: 21px;
        color: #79A342;
        display: block;
    }

    .modal-section .links .item .link {
        margin-top: 2px;
        font-size: 12px;
        line-height: 15px;
    }

    .modal-section .links .item span:nth-of-type(3) {
        margin-top: 22px;
    }

    .modal-section .links .item span:nth-of-type(4) {
        margin-top: 22px;
    }

    .modal-section .links .item span:last-of-type {
        margin-top: 22px;
    }
}