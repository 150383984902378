@media screen and (min-width: 1400px) {
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 20px 50px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 364px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 50px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 132px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 222px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 20px 50px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 364px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 50px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 132px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 222px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 20px 50px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 247px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 50px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 132px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin-bottom: 10px;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 222px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 20px 50px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 320px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 50px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 132px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin-bottom: 10px;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 222px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 197px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 50px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 132px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin-bottom: 10px;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 202px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 10px 10px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 152px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 20px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 150px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin-bottom: 10px;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 150px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

@media screen and (max-width: 350px){
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 27px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 6px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 10px 10px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 132px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 20px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 130px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin-bottom: 10px;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 130px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}

@media print {
    #productInformation{
        padding: 30px 0 60px;
        box-sizing: border-box;
    }

    #productInformation h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
        display: inline;
    }
    /* download */
    #productInformation #contactBarDownload{
        display: inline;
        text-decoration: none;
        margin-left: 15px;
    }
    #productInformation #contactBarDownload:hover{
        cursor: pointer;
    }
    #productInformation #contactBarDownload .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        margin: 0 10px 0 0;
        transition: 0.2s;
        display: inline-block;
    }
    #productInformation #contactBarDownload:hover .downloadCircle{
        background-color: #31417A;
        transition: 0.2s;
    }
    #productInformation #contactBarDownload i{
        color: #fff;
        position: absolute;
        font-size: 15px;
        transition: 0.2s;
        bottom: 7px;
        left: 7px;
    }
    #productInformation #contactBarDownload:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.4s;
    }

    #productInformation h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #productInformation #datesAndLocations{
        font-family: "Helvetica Regular", sans-serif;
    }
    #productInformation #datesAndLocations .location_outer{
        margin: 20px 0 40px;
    }
    #productInformation #datesAndLocations .location{
        background-color: #F4F5F8;
        padding: 20px 0 10px 10px;
        box-sizing: border-box;
    }
    #productInformation #datesAndLocations .location .location_details{
        width: 152px;
        display: inline-block;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 2px;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(odd){
        border-right: 2px dotted #b2b2b2;
    }
    #productInformation #datesAndLocations .location .location_details:nth-child(even){
        margin-left: 20px;
    }
    #productInformation #datesAndLocations .location .location_title{
        width: 150px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin-bottom: 10px;
    }
    #productInformation #datesAndLocations .location .location_detail{
        width: 150px;
        display: inline-block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        font-weight: bold;
    }
    #productInformation #datesAndLocations .location_outer .registerButton{
        text-align: right;
        padding: 13px 0;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a{
        padding: 12px 28px;
        background-color: #79a342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        text-decoration: none;
    }
    #productInformation #datesAndLocations .location_outer .registerButton a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #productInformation p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        text-align: right;
    }
}