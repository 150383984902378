@media screen and (min-width: 1400px) {
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
    }
    #upcomingSeminars {

    }

    .anchor{
        display: block;
        height: 80px; /*same height as header*/
        margin-top: -80px; /*same height as header*/
        visibility: hidden;
    }

    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 45px 0 60px;
    }
    .topicsPage_certificationPrograms_inner{
        width: 1397px;
        margin: 0 auto;
        position: relative;
        display: block;
        /*flex-wrap: wrap;*/
        /*flex-shrink: 1;*/
        /*justify-content: space-between;*/
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
        margin-left: 50px;
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 359px;
        margin: 10px 52px;
        vertical-align: top;
    }
    #certificationPrograms a{
        min-height: 160px;
    }
    #upcomingSeminars a{
        /*min-height: 124px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 92px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 12px;
        font-family: "Helvetica Regular", sans-serif;
        margin: 12px 0;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 216px !important;
        height: 42px !important;
        min-height: 42px !important;
        box-sizing: border-box;
        padding-top: 11px;
        text-align: center;
        margin: 20px auto;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
    }
    #upcomingSeminars{

    }
    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 45px 0 60px;
    }
    .topicsPage_certificationPrograms_inner{
        width: 1112px;
        margin: 0 auto;
        position: relative;
        display: block;
        /*flex-wrap: wrap;*/
        /*flex-shrink: 1;*/
        /*justify-content: space-between;*/
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
        /*margin-left: 10px;*/
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 348px;
        /*margin-bottom: 8px;*/
        vertical-align: top;
        margin: 10px 10px;
    }
    #certificationPrograms a{
        min-height: 160px;
    }
    #upcomingSeminars a{
        /*min-height: 124px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 92px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 12px;
        font-family: "Helvetica Regular", sans-serif;
        margin: 12px 0;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 216px !important;
        height: 42px !important;
        min-height: 42px !important;
        box-sizing: border-box;
        padding-top: 11px;
        text-align: center;
        margin: 20px auto;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
    }
    #upcomingSeminars{

    }
    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 45px 0 60px;
    }
    .topicsPage_certificationPrograms_inner{
        width: 932px;
        margin: 0 auto;
        position: relative;
        display: block;
        /*flex-wrap: wrap;*/
        /*flex-shrink: 1;*/
        /*justify-content: space-between;*/
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
        /*margin-left: 10px;*/
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 278px;
        vertical-align: top;
        margin: 10px 15px;
    }
    #certificationPrograms a{
        min-height: 160px;
    }
    #upcomingSeminars a{
        /*min-height: 124px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 92px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 12px;
        font-family: "Helvetica Regular", sans-serif;
        margin: 12px 0;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 216px !important;
        height: 42px !important;
        min-height: 42px !important;
        box-sizing: border-box;
        padding-top: 11px;
        text-align: center;
        margin: 20px auto;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 20px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
        padding-bottom: 0!important;
    }
    #upcomingSeminars{

    }
    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 40px 0;
    }
    .topicsPage_certificationPrograms_inner{
        width: 720px;
        margin: 0 auto;
        position: relative;
        display: block;
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
        /*margin-left: 5px;*/
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 210px;
        margin: 10px 5px;
        vertical-align: top;
    }
    #certificationPrograms a{
        min-height: 160px;
    }
    #upcomingSeminars a{
        /*min-height: 94px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 68px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 11px;
        line-height: 15px;
        font-family: "Helvetica Regular", sans-serif;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 18px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin: 10px 0;
        line-height: 20px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 210px !important;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
        padding-bottom: 0!important;
    }
    #upcomingSeminars{

    }
    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 40px 0;
    }
    .topicsPage_certificationPrograms_inner{
        width: 440px;
        margin: 0 auto;
        position: relative;
        display: block;
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 210px;
        vertical-align: top;
        margin: 10px 1px;
    }
    #certificationPrograms a{
        min-height: 160px;
    }
    #upcomingSeminars a{
        /*min-height: 94px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 68px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 11px;
        line-height: 15px;
        font-family: "Helvetica Regular", sans-serif;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 18px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin: 10px 0;
        line-height: 20px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 240px;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
        padding-bottom: 0!important;
    }
    #upcomingSeminars{

    }
    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 40px 0;
    }
    .topicsPage_certificationPrograms_inner{
        width: 340px;
        margin: 0 auto;
        position: relative;
        display: block;
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 166px;
        vertical-align: top;
        margin: 10px 1px;
    }
    #certificationPrograms a{
        min-height: 120px;
    }
    #upcomingSeminars a{
        /*min-height: 94px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 52px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 10px;
        line-height: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 14px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin: 10px 0;
        line-height: 20px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 240px;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px){
    .topic-seminar-background-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #certificationPrograms{
        background-color: #e4e4e4;
        padding-bottom: 0!important;
    }
    #upcomingSeminars{

    }
    .topicsPage_certificationPrograms{
        width: 100%;
        padding: 40px 0;
    }
    .topicsPage_certificationPrograms_inner{
        width: 300px;
        margin: 0 auto;
        position: relative;
        display: block;
    }
    .topicsPage_certificationPrograms h2{
        flex: 0 0 100%;
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        text-align: left;
        margin-bottom: 24px;
    }
    #upcomingSeminars a, #certificationPrograms a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 2px dotted gray;
        width: 146px;
        margin: 10px 1px;
        vertical-align: top;
    }
    #certificationPrograms a{
        min-height: 140px;
    }
    #upcomingSeminars a{
        /*min-height: 94px;*/
    }
    .topicsPage_certificationPrograms .imageCroppingBox{
        width: 100%;
        height: 52px;
        overflow: hidden;
    }
    .topicsPage_certificationPrograms img{
        transition: 0.2s;
        width: 100%;
        height: auto;
    }
    .topicsPage_certificationPrograms h4{
        color: #666666;
        font-size: 10px;
        line-height: 14px;
        font-family: "Helvetica Regular", sans-serif;
    }
    .topicsPage_certificationPrograms h3{
        color: #31417a;
        font-size: 14px;
        font-family: "Frutiger 67 Condensed",sans-serif;
        margin: 10px 0;
        line-height: 20px;
    }
    /* hover effects */
    .topicsPage_certificationPrograms a:hover img{
        transform: scale(1.3);
        transition: 0.4s;
    }
    .topicsPage_certificationPrograms a:hover h3{
        color: #666666;
        transition: 0.3s;
    }

    #upcomingSeminars .loadMoreButton{
        width: 240px;
        min-height: auto !important;
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
        margin: 10px auto 0;
        display: block;
        background-color: #79A342;
        color: #fff;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        border-bottom: none !important;
    }
    #upcomingSeminars .loadMoreButton:hover{
        background-color: #31417a;
        cursor: pointer;
        transition: 0.2s;
    }
}