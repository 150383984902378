@media screen and (min-width: 1400px){ /*1300*/
     /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:1300px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 280px;
        margin-right: 28px;
        margin-bottom: 20px;
    }

    .executive-image-gallery .executive-image:nth-child(4n+4) {
        margin-right:0px;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }

    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 400px;
    }

     /*Overlay Styles*/
     .executive-lightbox-container {
         position: fixed;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         z-index: 9999;
         background-color: #000;
         opacity: 1;
         -webkit-transition: opacity .15s ease 0s;
         -o-transition: opacity .15s ease 0s;
         transition: opacity .15s ease 0s;
         width: 100%;
         height: 100%;
         visibility:hidden;
     }

     #execEd-lightbox-gallery {
         margin-top:100px;
     }

     .executive-lightbox-toolbar {
         width: 1300px;
         margin:0 auto;
     }
     #closeLightBoxButton {
         position: relative;
         right: 0;
         top: 10px;
         padding: 10px;
         font-size: 20px;
         line-height: 20px;
         float: right;
         text-align: center;
         border-left: 1px solid #666;
     }
     .executive-lightbox-gallery {
         width:1200px;
         margin: 0 auto;
         overflow-x: hidden;
         -webkit-transition:  .4s ease 0s;
         -o-transition:  .4s ease 0s;
         transition:  .4s ease 0s;
     }
     .executive-lightbox-gallery .executive-lightbox-image {
         width:800px;
         margin:0 auto;
         list-style: none;
     }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top:300px;
        z-index: 9;
    }
    .executive-lightbox-navigation {
        width: 1200px;
        top:300px;
        margin: 0 auto;
    }
    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }
    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:1100px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 250px;
        margin-right: 28px;
        margin-bottom: 20px;
    }
    .executive-image-gallery .executive-image:nth-child(4n+4){
        margin-right:0px;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding:0;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding:0;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 400px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 1100px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:1000px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:800px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top:300px;
        z-index: 9;
    }
    .executive-lightbox-navigation {
        width: 1000px;
        top:300px;
        margin: 0 auto;
    }
    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }
    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:900px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 200px;
        margin-right: 28px;
        margin-bottom: 20px;
    }

    .executive-image-gallery .executive-image:nth-child(4n+4){
        margin-right:0px;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding:0;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding:0;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 400px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 900px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:860px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:720px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top:300px;
        z-index: 9;
    }
    .executive-lightbox-navigation {
        width: 900px;
        top:300px;
        margin: 0 auto;
    }
    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }
    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:720px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 340px;
        margin-bottom: 20px;
    }
    .executive-image-gallery .executive-image:nth-child(2n+2){
        margin-right:0px;
        float:right;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding:0;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding:0;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 340px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 720px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:720px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:720px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top:300px;
        z-index: 9;
    }
    .executive-lightbox-navigation {
        width: 720px;
        top:300px;
        margin: 0 auto;
    }
    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }
    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:470px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 220px;
        margin-bottom: 20px;
    }
    .executive-image-gallery .executive-image:nth-child(2n+2){
        margin-right:0px;
        float:right;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding:0;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding:0;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 220px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 470px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:440px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:440px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top: 180px;
        z-index: 9;
    }
    .executive-lightbox-navigation {
        width: 470px;
        top:300px;
        margin: 0 auto;
    }
    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }
    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:340px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 160px;
        margin-bottom: 20px;
    }
    .executive-image-gallery .executive-image:nth-child(2n+2){
        margin-right:0px;
        float:right;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding:0;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding:0;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 160px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 340px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:300px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:300px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top: 180px;
        z-index: 9;
    }

    .executive-lightbox-navigation {
        width: 340px;
        top:170px;
        margin: 0 auto;
    }

    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }

    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }

}

@media screen and (max-width: 350px){/*300*/
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
    }

    .executive-image-gallery {
        width:300px;
        margin:0 auto;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 140px;
        margin-bottom: 20px;
    }
    .executive-image-gallery .executive-image:nth-child(2n+2){
        margin-right:0px;
        float:right;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding:0;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding:0;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 140px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 300px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:300px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:300px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top: 150px;
        z-index: 9;
    }

    .executive-lightbox-navigation {
        width: 320px;
        top:150px;
        margin: 0 auto;
    }

    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }

    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }

}

@media print {
    /*Component styles*/
    .-image-gallery .executive-background {
        background: #666;
    }
    .executive-inner .executive-image-gallery-heading-content {
        display:block;
        font-family:"Frutiger 47" , sans-serif;
        font-size:40px;
        line-height: 42px;
        color:#FFF;
        padding-left:70px;
    }

    .executive-image-gallery {
        width:1300px;
        margin:0 auto;
        padding-left: 75px;
    }

    .executive-image-gallery .executive-image {
        display: inline-block;
        width: 280px;
        margin-right: 28px;
        margin-bottom: 20px;
    }

    .executive-image-gallery .executive-image:nth-child(4n+4) {
        margin-right:0px;
    }

    .executive-image-gallery .executive-image>img {
        max-width:100%;
    }
    .executive-image-gallery-copy {
        padding-left:75px;
    }
    .executive-gallery-link  .link-content {
        margin: 0;
        display:block;
        padding-left: 75px;
    }
    .executive-gallery-link  .link-content a {
        display: block;
        padding-bottom: 10px;
        text-decoration: none;
        color: #79A342;
        border-bottom: 1px solid #B2B2B2;
        font-family: 'Frutiger 57' , sans-serif;
        font-size: 22px;
        line-height: 22px;
    }

    .executive-gallery-link .link-content>.content{
        padding: 0px;
        margin-top: 20px;
        width: 400px;
    }

    /*Overlay Styles*/
    .executive-lightbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
        opacity: 1;
        -webkit-transition: opacity .15s ease 0s;
        -o-transition: opacity .15s ease 0s;
        transition: opacity .15s ease 0s;
        width: 100%;
        height: 100%;
        visibility:hidden;

    }

    #execEd-lightbox-gallery {
        margin-top:100px;
    }

    .executive-lightbox-toolbar {
        width: 1300px;
        margin:0 auto;
    }
    #closeLightBoxButton {
        position: relative;
        right: 0;
        top: 10px;
        padding: 10px;
        font-size: 20px;
        line-height: 20px;
        float: right;
        text-align: center;
        border-left: 1px solid #666;
    }
    .executive-lightbox-gallery {
        width:1200px;
        margin: 0 auto;
        overflow-x: hidden;
        -webkit-transition:  .4s ease 0s;
        -o-transition:  .4s ease 0s;
        transition:  .4s ease 0s;
    }
    .executive-lightbox-gallery .executive-lightbox-image {
        width:800px;
        margin:0 auto;
        list-style: none;
    }
    .executive-lightbox-gallery .executive-lightbox-image img{
        max-width:100%;
    }
    .executive-lightbox-navigation-container {
        width: 100%;
        position: absolute;
        top:300px;
        z-index: 9;
    }
    .executive-lightbox-navigation {
        width: 1200px;
        top:300px;
        margin: 0 auto;
    }
    .executive-lightbox-navigation .executive-lightbox-navigation-previous-item {
        position: relative;
        display:inline-block;

    }
    .executive-lightbox-navigation .executive-lightbox-navigation-next-item {
        position:relative;
        float:right;
        display:inline-block
    }
}