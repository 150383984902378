@keyframes arrowAnimation {
    0%   {margin-bottom: 0px;}
    70%  {margin-bottom: 6px;}
    100% {margin-bottom: 2px;}
}

@media screen and (min-width: 1400px) {
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 0;
    }
    #homePageBrochuresInner{
        width: 1300px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: inline-block;
        width: 380px;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 55px 22px;
    }
    #homePageBrochuresInner .downloadCircle{
        position: relative;
        background-color: #79a342;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        margin: 0 auto 30px;
        transition: 0.2s;
    }
    #homePageBrochuresInner i{
        color: #fff;
        position: absolute;
        font-size: 38px;
        transition: 0.2s;
        bottom: 29px;
        left: 50%;
        margin-left: -16px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 36px;
        height: 3px;
        background-color: #fff;
        bottom: 25px;
        left: 50%;
        margin-left: -17px;
    }
    #homePageBrochuresInner h4{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 30px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        background-color: #31417a;
        width: 0;
        height: 1px;
        margin: 10px auto 0;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 0;
    }
    #homePageBrochuresInner{
        width: 1100px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: inline-block;
        width: 362px;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 55px 0;
    }
    #homePageBrochuresInner .downloadCircle{
        background-color: #79a342;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin: 0 auto 30px;
        transition: 0.2s;
        position: relative;
    }
    #homePageBrochuresInner i{
        position: absolute;
        color: #fff;
        font-size: 36px;
        transition: 0.2s;
        bottom: 24px;
        left: 50%;
        margin-left: -16px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 34px;
        height: 3px;
        background-color: #fff;
        bottom: 21px;
        left: 50%;
        margin-left: -17px;
    }
    #homePageBrochuresInner h4{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 30px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        background-color: #31417a;
        width: 0;
        height: 1px;
        margin: 10px auto 0;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 0;
    }
    #homePageBrochuresInner{
        width: 900px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: inline-block;
        width: 295px;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 55px 0;
    }
    #homePageBrochuresInner .downloadCircle{
        background-color: #79a342;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin: 0 auto 30px;
        transition: 0.2s;
        position: relative;
    }
    #homePageBrochuresInner i{
        position: absolute;
        color: #fff;
        font-size: 36px;
        transition: 0.2s;
        bottom: 24px;
        left: 50%;
        margin-left: -16px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 34px;
        height: 3px;
        background-color: #fff;
        bottom: 21px;
        left: 50%;
        margin-left: -17px;
    }
    #homePageBrochuresInner h4{
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 30px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        background-color: #31417a;
        width: 0;
        height: 1px;
        margin: 10px auto 0;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 0;
    }
    #homePageBrochuresInner{
        width: 720px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: block;
        width: 100%;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 40px 0 20px;
    }
    #homePageBrochuresInner .downloadCircle{
        background-color: #79a342;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin: 0 auto 30px;
        transition: 0.2s;
        position: relative;
        display: inline-block;
    }
    #homePageBrochuresInner i{
        position: absolute;
        color: #fff;
        font-size: 36px;
        transition: 0.2s;
        bottom: 24px;
        left: 50%;
        margin-left: -16px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 34px;
        height: 3px;
        background-color: #fff;
        bottom: 21px;
        left: 50%;
        margin-left: -17px;
    }
    #homePageBrochuresInner h4{
        width: 350px;
        display: inline-block;
        text-align: left;
        vertical-align: top;
        margin: 24px 0 0 15px;
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 30px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        display: none;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 0;
    }
    #homePageBrochuresInner{
        width: 470px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: inline-block;
        width: 100%;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 40px 0 20px;
    }
    #homePageBrochuresInner .downloadCircle{
        background-color: #79a342;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: inline-block;
        transition: 0.2s;
        position: relative;
    }
    #homePageBrochuresInner i{
        position: absolute;
        color: #fff;
        font-size: 36px;
        transition: 0.2s;
        bottom: 24px;
        left: 50%;
        margin-left: -16px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 34px;
        height: 3px;
        background-color: #fff;
        bottom: 21px;
        left: 50%;
        margin-left: -17px;
    }
    #homePageBrochuresInner h4{
        text-align: left;
        display: inline-block;
        vertical-align: top;
        margin-top: 24px;
        margin-left: 20px;
        width: 310px;
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 30px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        display: none;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 30px;
    }
    #homePageBrochuresInner{
        width: 340px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: block;
        width: 100%;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 40px 0 20px;
    }
    #homePageBrochuresInner .downloadCircle{
        background-color: #79a342;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        display: inline-block;
        transition: 0.2s;
        position: relative;
    }
    #homePageBrochuresInner i{
        position: absolute;
        color: #fff;
        font-size: 25px;
        transition: 0.2s;
        bottom: 22px;
        left: 50%;
        margin-left: -11px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 26px;
        height: 2px;
        background-color: #fff;
        bottom: 18px;
        left: 50%;
        margin-left: -13px;
    }
    #homePageBrochuresInner h4{
        text-align: left;
        display: inline-block;
        vertical-align: top;
        margin-top: 14px;
        margin-left: 10px;
        width: 240px;
        color: #31417a;
        font-size: 16px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 24px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        display: none;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}

@media screen and (max-width: 350px){
    /* Brochures */
    #homePageBrochures{
        font-family: "Frutiger 57 Condensed", sans-serif;
        background-color: #f4f5f8;
        padding: 55px 0 30px;
    }
    #homePageBrochuresInner{
        width: 300px;
        margin: 0 auto;
        text-align: center;
    }
    #homePageBrochuresInner h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
    }
    #homePageBrochuresInner #homePageBrochuresText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
    }
    #homePageBrochuresInner a{
        display: block;
        width: 100%;
        vertical-align: top;
        text-align: center;
        text-decoration: none;
        margin: 40px 0 20px;
    }
    #homePageBrochuresInner .downloadCircle{
        background-color: #79a342;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        display: inline-block;
        transition: 0.2s;
        position: relative;
    }
    #homePageBrochuresInner i{
        position: absolute;
        color: #fff;
        font-size: 25px;
        transition: 0.2s;
        bottom: 22px;
        left: 50%;
        margin-left: -11px;
    }
    #homePageBrochuresInner .downloadCircle .downloadCircle_line{
        position: absolute;
        width: 26px;
        height: 2px;
        background-color: #fff;
        bottom: 18px;
        left: 50%;
        margin-left: -13px;
    }
    #homePageBrochuresInner h4{
        text-align: left;
        display: inline-block;
        vertical-align: top;
        margin-top: 16px;
        margin-left: 10px;
        width: 200px;
        color: #31417a;
        font-size: 16px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        transition: 0.2s;
        line-height: 20px;
    }
    #homePageBrochuresInner .downloadUnderScore{
        display: none;
    }
    #homePageBrochuresInner a:hover .downloadCircle{
        background-color: #31417a;
        transition: 0.2s;
    }
    #homePageBrochuresInner a:hover .downloadUnderScore{
        width: 100px;
        transition: 0.3s;
    }
    #homePageBrochuresInner a:hover i{
        animation-name: arrowAnimation;
        animation-duration: 0.5s;
    }
}