.rankingSubtitle{
    font-size: 28px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    color: #31417a;
    margin-bottom: 35px;
    margin-top: 35px;
}
.newTeasersOuter{
    padding: 20px 0;
    background: #31417a;
}
.blogPostDate{
    display: none;
}
.rankingLinks a{
    font-size: 22px;
    font-family: "Frutiger 57 Condensed", sans-serif;
    color: #31417a;
    text-decoration: none;
}
.rankingTitle {
}
.rankingFullLink a i {
    color: #79a342;
    font-size: 25px;
    position: relative;
    top: 2px;
}
.rankingFullLink a span {
    position: relative;
    left: 15px;
}
.rankingFullLink a i:hover {
    -webkit-transition: transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    -ms-transition: transform 0.2s ease-in-out;
    transform: translateX(12px);
    transition: transform 0.2s ease-in-out;
}
.rankingFullLink:hover a i {
    -webkit-transition: transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    -ms-transition: transform 0.2s ease-in-out;
    transform: translateX(12px);
    transition: transform 0.2s ease-in-out;
}



@media screen and (min-width: 1400px) {
    .rankingSection{
        width: 1200px;
        padding: 30px 0;
    }
    #rankingComponent{
        width: 1300px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 42px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingImgAndLinksWrapper{
        display: flex;
        margin-top: 25px;
    }
    .rankingImage {
        margin-right: 180px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .rankingSection{
        width: 1040px;
        padding: 30px 0;
    }
    #rankingComponent{
        width: 1100px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 42px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingImgAndLinksWrapper{
        display: flex;
        margin-top: 25px;
    }
    .rankingImage {
        margin-right: 180px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .rankingSection{
        width: 800px;
        padding: 30px 0;
    }
    #rankingComponent{
        width: 900px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }

    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingImgAndLinksWrapper{
        display: flex;
        margin-top: 25px;
    }
    .rankingImage {
        margin-right: 60px;

    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .rankingSection{
        width: 100%;
        padding: 30px 0;
    }
    #rankingComponent{
        width: 720px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 36px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingImgAndLinksWrapper{
        display: flex;
        margin-top: 25px;
    }
    .rankingImage {
        margin-right: 20px;
        max-width: 270px;
    }
    .rankingImage img {
        max-width: 270px;
        margin-bottom: 20px;
    }
    #emptyInternalMenu {
        display: none !important;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .rankingSection{
        padding: 30px 0;
    }
    #rankingComponent{
        width: 470px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingSubtitle{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
    .rankingImage img {
        max-width: 270px;
        margin-bottom: 20px;
    }
    #emptyInternalMenu {
        display: none !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .rankingSection{
        padding: 30px 0;
    }
    #rankingComponent{
        width: 340px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 15px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingSubtitle{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
    .rankingImage img {
        max-width: 250px;
        margin-bottom: 20px;
    }
    #emptyInternalMenu {
        display: none !important;
    }
}

@media screen and (max-width: 350px){
    .rankingSection{
        padding: 30px 0;
    }
    #rankingComponent{
        width: 300px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
    .rankingSubtitle{
        font-size: 28px;
        font-family: "Frutiger 57 Condensed",sans-serif;
        color: #31417a;
    }
    .rankingImage img{
        max-width: 270px;
        margin-bottom: 20px;
    }
    #emptyInternalMenu {
        display: none !important;
    }
}

@media print {
    #rankingComponent{
        width: 1300px;
        margin: 0 auto;
        padding: 1px 0 0px;
    }
    .rankingTitle{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    .rankingRichText p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 1.6em;
    }
}