@media screen and (min-width: 1400px){ /*1300*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

@media screen and (max-width: 350px){/*300*/
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}

@media print {
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer{
        height: auto;
    }
    .-video-gallery>.background>.fs-grid>.slider>.video-gallery>.owl-stage-outer>.owl-stage{
        height: auto;
    }
}