
@media screen and (min-width: 1400px){ /*1300*/

    .exec-content-page .-blog-slider .fs-grid{
        width:1300px;
        max-width:1300px;
        margin:0 auto;
    }

    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/

    .exec-content-page .-blog-slider .fs-grid{
        width:1100px;
        max-width:1100px;
        margin:0 auto;
    }


    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/

    .exec-content-page .-blog-slider .fs-grid{
        width:900px;
        max-width:900px;
        margin:0 auto;
    }

    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    .exec-content-page .-blog-slider .fs-grid{
        width:720px;
        max-width:720px;
        margin:0 auto;
    }

    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/

    .exec-content-page .-blog-slider .fs-grid{
        width:470px;
        max-width:470px;
        margin:0 auto;
    }

    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    .exec-content-page .-blog-slider .fs-grid{
        width:340px;
        max-width:340px;
        margin:0 auto;
    }

    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

@media screen and (max-width: 350px) {/*300*/
    .exec-content-page .-blog-slider .fs-grid{
        width:300px;
        max-width:300px;
        margin:0 auto;
    }

    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}

@media print {
    .exec-content-page .-blog-slider .fs-grid{
        width:1300px;
        max-width:1300px;
        margin:0 auto;
    }


    .exec-content-page .-blog-slider .fs-grid .slider{
        margin:0;
    }
}