@media screen and (min-width: 1400px) {
    .contactPage {
        width: 1300px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    /*.contactPage fieldset:nth-of-type(1) .fields-row-contact-form .first-field-contact-form{*/
        /*text-align: left;*/
        /*width: 100%;*/
    /*}*/
    
    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 47%;
        display: inline-block;
        text-align: left;
        padding-right: 6%;
    }

    /*.first-field-contact-form p {*/
        /*text-align: left;*/
    /*}*/

    .second-field-contact-form {
        width: 47%;
        display: inline-block;
        float: right;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .contactPage {
        width: 1100px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 47%;
        display: inline-block;
        text-align: left;
        padding-right: 6%;
    }

    .second-field-contact-form {
        width: 47%;
        display: inline-block;
        float: right;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .contactPage {
        width: 922px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 47%;
        display: inline-block;
        text-align: left;
        padding-right: 6%;
    }

    .second-field-contact-form {
        width: 47%;
        display: inline-block;
        float: right;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .contactPage {
        width: 700px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 47%;
        display: inline-block;
        text-align: left;
        padding-right: 6%;
    }

    .second-field-contact-form {
        width: 47%;
        display: inline-block;
        float: right;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .contactPage {
        width: 400px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 20px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 100%;
        display: block;
    }

    .second-field-contact-form {
        width: 100%;
        display: block;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        display: block;
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .contactPage {
        width: 320px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 20px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 100%;
        display: block;
    }

    .second-field-contact-form {
        width: 100%;
        display: block;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        /*text-align: left;*/
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        display: block;
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (max-width: 350px){
    .contactPage {
        width: 300px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 20px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 100%;
        display: block;
    }

    .second-field-contact-form {
        width: 100%;
        display: block;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        /*text-align: left;*/
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        display: block;
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

@media print {
    .contactPage {
        width: 1300px;
        margin: 40px auto;
    }

    .contactPage h2 {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .legend-contact-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .contactPage fieldset:nth-of-type(2) .fields-row-contact-form .first-field-contact-form{
        float: left;
        width: 100%;
    }

    .contactPage fieldset:nth-of-type(3) .fields-row-contact-form .first-field-contact-form{
        /*float: left;*/
        /*width: 100%;*/
    }

    .contact-page-info {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
    }

    .blue-contact-label {
        font-size: 22px;
        line-height: 30px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417A;
        padding-top: 40px;
        padding-bottom: 14px;
        text-align: left;
    }

    .input-contact-label {
        background-color: #F4F5F8;
        border: none;
        border-radius: 20px;
        padding-left: 15px;
        height: 34px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        box-sizing: border-box;
    }

    .first-field-contact-form {
        width: 47%;
        display: inline-block;
        text-align: left;
        padding-right: 6%;
    }

    .second-field-contact-form {
        width: 47%;
        display: inline-block;
        float: right;
    }

    .text-area-contact-form {
        background-color: #F4F5F8;
        border: none;
        padding: 15px;
        height: 200px;

        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        width: 100%;
        border-radius: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Radio button */
    /* The container */
    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark-radio-button-contact-form {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 9px;
        border: 1px solid #31417A;
        background-color: #F4F5F8;
    }

    /* On mouse-over, add a grey background color */
    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .submit-button-cotanct-form {
        color: white;
        background-color: #79A342;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        height: 40px;
        padding-top: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        display: block;
        margin: 0 auto;
    }
}

#third-field-contact-form {
    display: none;
}

.checkbox-error {
    color: red;
    font-family: "Helvetica Regular", sans-serif;
    font-size: 12px;
    line-height: 22px;
    margin-top: -13px;
    margin-bottom: 13px;
}