@media screen and (min-width: 1400px) {
    #programmeInterview{
        width: 100%;
        padding: 80px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 1300px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 850px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 850px;
        height: 470px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 850px;
        height: 470px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 300px;
        font-size: 70px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 30px 0 45px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #programmeInterview{
        width: 100%;
        padding: 80px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 1100px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 850px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 850px;
        height: 470px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 850px;
        height: 470px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 300px;
        font-size: 70px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 30px 0 45px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #programmeInterview{
        width: 100%;
        padding: 80px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 900px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 800px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 800px;
        height: 470px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 850px;
        height: 470px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 322px;
        font-size: 60px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 30px 0 45px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #programmeInterview{
        width: 100%;
        padding: 80px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 720px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 720px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 600px;
        height: 320px;
        margin: 0 auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 600px;
        height: 320px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 255px;
        font-size: 50px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 30px 0 45px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #programmeInterview{
        width: 100%;
        padding: 80px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 470px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 470px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 400px;
        height: 280px;
        margin: 0 auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 400px;
        height: 280px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 234px;
        font-size: 50px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 30px 0 45px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #programmeInterview{
        width: 100%;
        padding: 80px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 340px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 340px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 300px;
        height: 220px;
        margin: 0 auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 300px;
        height: 220px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 212px;
        font-size: 42px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 30px 0 45px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}

@media screen and (max-width: 350px){
    #programmeInterview{
        width: 100%;
        padding: 40px 0;
        background-color: #F4F5F8;
    }
    #programmeInterviewInner{
        width: 300px;
        margin: 0 auto;
        position: relative;
    }
    #programmeInterviewInner h2{
        color: #31417a;
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #programmeInterviewInner #programmeInterviewInternal{
        width: 300px;
        margin: 0 auto;
    }
    #programmeInterviewInner #programmeInterviewInternal .interviewSlide{
        width: 260px;
        height: 200px;
        margin: 0 auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #programmeInterviewInner #programmeInterviewInternal iframe{
        width: 260px;
        height: 200px;
    }
    #programmeInterviewInner i{
        position: absolute;
        top: 154px;
        font-size: 42px;
        color: #666666;
    }
    #programmeInterviewInner .fa-caret-left{
        left: 0;
    }
    #programmeInterviewInner .fa-caret-right{
        right: 0;
    }
    #programmeInterviewInner i:hover{
        cursor: pointer;
    }
    #programmeInterviewTexts{
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        margin: 10px 0 10px;
    }
    #programmeInterviewTexts li{
        display: none;
    }
    #programmeInterviewTexts li:first-of-type{
        display: block;
    }
}