
@media screen and (min-width: 1400px){

    .exec-content-page .-logos .fs-grid{
        width:1300px;
        max-width:1300px;
        margin:0 auto;
    }
    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
        padding-right:0px;
        padding-right:0px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
        padding-right:0px;
        margin-right: 30px;
        left:0px;
    }
    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){

    .exec-content-page .-logos .fs-grid{
        width:1100px;
        max-width:1100px;
        margin:0 auto;
    }
    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
        padding-right:0px;
        padding-right:0px;
    }
    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
        padding-right:0px;
        margin-right: 30px;
        left:0px;
    }
    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {

    .exec-content-page .-logos .fs-grid{
        width:900px;
        max-width:900px;
        margin:0 auto;
    }
    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
        padding-right:0px;
        padding-right:0px;
    }
    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
        padding-right:0px;
        margin-right: 30px;
        left:0px;
    }
    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    .exec-content-page .-logos .fs-grid{
        width:720px;
        max-width:720px;
        margin:0 auto;
    }

    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
padding-right:0px;
        padding-right:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
padding-right:0px;
        margin-right: 30px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/

    .exec-content-page .-logos .fs-grid{
        width:470px;
        max-width:470px;
        margin:0 auto;
    }

    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
padding-right:0px;
        padding-right:0px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
padding-right:0px;
        margin-right: 30px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {

    .exec-content-page .-logos .fs-grid{
        width:340px;
        max-width:340px;
        margin:0 auto;
    }

    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
padding-right:0px;
        padding-right:0px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
padding-right:0px;
        margin-right: 30px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }

}

@media screen and (max-width: 350px) {

    .exec-content-page .-logos .fs-grid{
        width:300px;
        max-width:300px;
        margin:0 auto;
    }

    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
padding-right:0px;
        padding-right:0px;
        left:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        left:0px;
        margin-right: 30px;
        padding-left:0px;
padding-right:0px;
    }

}

@media print {
    .exec-content-page .-logos .fs-grid{
        width:1300px;
        max-width:1300px;
        margin:0 auto;
    }
    .exec-content-page .-logos .fs-grid .inner .heading-content {
        padding-left:0px;
padding-right:0px;
        padding-right:0px;
    }

    .exec-content-page .-logos .fs-grid .inner .logos .logo {
        padding-left:0px;
padding-right:0px;
        margin-right: 30px;
        left:0px;
    }
    .exec-content-page .-logos .fs-grid .inner .logos .goto {
        right:0;
        left:0;
    }
}