.curriculumChartSection{
    position: relative;
    z-index: 999;
    overflow: visible;
}
.curriculumChartSection .content-area{
    position: relative;
    width: 100%;
    left: 0;
}

@media screen and (min-width: 1400px) {
    .curriculumChartsContainer {
        max-width: 1134px;
        margin: 0 auto;
        margin-top:69px;
        padding-left:8.3333%;
    }
    .curriculumCharts{
        position: relative;
        z-index: 9;
        background-color: inherit;
    }
    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 28px;
        line-height: 35px;
        width: 200px;
        vertical-align: top;
        text-align: left;
        color: #666;
        align-self: center;
    }
    .cc-row {
        display: flex;
        align-items: stretch;
    }

    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }

    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 205px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 14px;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        list-style-type:none;
        padding: 20px 10px 15px 55px;

    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;

    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles for the CC tile End*/

    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles in the description popup End*/
    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        width: 100%;
        top:44px;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
        color: rgb(102,102,102);
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 22px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        width: 160px;
        background-color: #666;
        margin-left: 40px;
    }


    .cc-single-column-description-description h1 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h2 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h3 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h4 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h5 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h6 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 10px;
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 16px;
    }

    .cc-single-column-description-description a {
        color: #79A342;
    }

    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }
    .curriculumChartsContainerTitle {
        margin-bottom: 10px;
        color: #31417A;
        font-family: 'Frutiger 57',sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        border-bottom: 1px solid #B2B2B2;
    }

    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }

    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:200px;
        margin-top: 18px;
    }

    .curriculumCharts-asterixRow {
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        display:block;
        padding: 4px 0;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:200px;
        margin-top:20px;
    }

    .curriculumCharts-legendRow {
        padding: 4px 0;
    }

    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }

    .curriculumCharts-legendRow-color {
        width:24px;
        height:24px;
        display:inline-block;
        vertical-align:middle;
        border: 1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        vertical-align:middle;
        color:#666;
    }

    /*Asterix and Legend styles end*/
    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 55px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:16px;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:16px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .curriculumChartsContainer {
        max-width: 1134px;
        margin: 0 auto;
        margin-top:69px;
        padding-left:8.3333%;
    }
    .curriculumCharts{
        position: relative;
        z-index: 9;
        background-color: inherit;
    }
    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 28px;
        line-height: 35px;
        width: 200px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }
    .cc-row {
        display: flex;
        align-items: stretch;
    }

    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }

    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 205px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 14px;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 55px;

    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;

    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
    }
    /*Table styles for the CC tile End*/
    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
        word-break: break-word;
    }
    /*Table styles in the description popup End*/
    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        top:44px;
        width: 100%;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
        color: rgb(102,102,102);
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 22px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        width: 160px;
        background-color: #666;
        margin-left: 40px;
    }

    .cc-single-column-description-description h1 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h2 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h3 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h4 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h5 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h6 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 10px;
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 16px;
    }

    .cc-single-column-description-description a {
        color: #79A342;
    }

    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }

    .curriculumChartsContainerTitle {
        margin-bottom: 10px;
        color: #31417A;
        font-family: 'Frutiger 57',sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        border-bottom: 1px solid #B2B2B2;
    }

    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }

    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:200px;
        margin-top: 18px;
    }

    .curriculumCharts-asterixRow {
        font-family:  "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        display:block;
        padding: 4px 0;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:200px;
        margin-top:20px;
    }

    .curriculumCharts-legendRow {
        padding: 4px 0;
    }
    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }
    .curriculumCharts-legendRow-color {
        width:24px;
        height:24px;
        display:inline-block;
        vertical-align:middle;
        border: 1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        vertical-align:middle;
        color:#666;
    }

    /*Asterix and Legend styles end*/
    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type:none;
        padding: 20px 10px 15px 55px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:16px;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:16px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .curriculumChartsContainer {
        min-width: 738px;
        max-width: 1164px;
        padding-left: 8.3333%;
        margin-top:69px;
    }
    .curriculumCharts{
        position: relative;
        z-index: 9;
        background-color: inherit;
    }

    .cc-row {
        display: flex;
        align-items: stretch;
    }

    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 28px;
        line-height: 35px;
        width: 200px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }

    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }


    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 205px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 14px;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 55px;

    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;

    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
    }
    /*Table styles for the CC tile End*/
    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
        word-break: break-word;
    }
    /*Table styles in the description popup End*/

    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        width: 100%;
        top:44px;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
        color: rgb(102,102,102);
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 22px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        width: 160px;
        background-color: #666;
        margin-left: 40px;
    }

    .cc-single-column-description-description h1 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h2 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h3 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h4 {
        text-align: left;
        margin-left: 40px;
    }

    .cc-single-column-description-description  h5 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h6 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 10px;
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 16px;
    }

    .cc-single-column-description-description a {
        color: #79A342;
    }

    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }

    .curriculumChartsContainerTitle {
        margin-bottom: 10px;
        color: #31417A;
        font-family: 'Frutiger 57',sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        border-bottom: 1px solid #B2B2B2;
    }

    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }

    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:200px;
        margin-top: 18px;
    }

    .curriculumCharts-asterixRow {
        font-family:  "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        display:block;
        padding: 4px 0;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:200px;
        margin-top:20px;
    }

    .curriculumCharts-legendRow {
        padding: 4px 0;
    }
    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }
    .curriculumCharts-legendRow-color {
        width:24px;
        height:24px;
        display:inline-block;
        vertical-align:middle;
        border: 1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        vertical-align:middle;
        color:#666;
    }

    /*Asterix and Legend styles end*/
    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type:none;
        padding: 20px 10px 15px 55px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:16px;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:16px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .curriculumChartsContainer {
        margin-top:69px;
        padding-left:8.333%;

    }

    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 24px;
        line-height: 30px;
        width: 150px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }

    .cc-row {
        display: flex;
        align-items: stretch;
    }

    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }


    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 155px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        word-break: break-word;
        padding: 0 0 0 16px;
    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        word-break: break-word;
        padding: 0 0 0 2px;
    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: fixed;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        font-size:12px;
        word-break: break-word;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
        font-size:12px;
    }
    /*Table styles for the CC tile End*/
    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
        word-break: break-word;
    }
    /*Table styles in the description popup End*/

    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        width: 100%;
        top:44px;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
        color: rgb(102,102,102);
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 22px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        width: 160px;
        background-color: #666;
        margin-left: 40px;
    }

    .cc-single-column-description-description h1 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h2 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h3 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h4 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h5 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description  h6 {
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description a {
        color: #79A342;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 10px;
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 16px;
    }

    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }

    .curriculumChartsContainerTitle {
        margin-bottom: 10px;
        color: #31417A;
        font-family: 'Frutiger 57',sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 32px;
        margin-top: 41px;
        padding-bottom: 14px;
        border-bottom: 1px solid #B2B2B2;
    }

    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }

    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:150px;
        margin-top:10px;
    }

    .curriculumCharts-asterixRow {
        font-family:  "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        display:block;
        padding: 4px 0;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:150px;
        margin-top:20px;
    }

    .curriculumCharts-legendRow {
        padding: 4px 0;
    }
    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }
    .curriculumCharts-legendRow-color {
        width:24px;
        height:24px;
        display:inline-block;
        vertical-align:middle;
        border: 1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        vertical-align:middle;
        color:#666;
    }

    /*Asterix and Legend styles end*/
    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 55px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:16px;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:16px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .curriculumChartsContainer {
        margin-top:38px;
        padding-left: 8.333%;
        min-width: 460px;
        max-width: 760px;
    }
    .curriculumCharts{
        position: relative;
        z-index: 9;
        background-color: inherit;
    }
    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 14px;
        line-height: 26px;
        width: 80px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }
    .cc-row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: stretch;
        align-items: stretch;
    }
    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }

    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 84px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 20px;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        word-break: break-word;
        padding: 0 0 0 16px;
    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        word-break: break-word;
        padding: 0 0 0 2px;
    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: fixed;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        font-size:12px;
        word-break: break-word;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        font-size: 12px;
        word-break: break-word;
    }
    /*Table styles for the CC tile End*/

    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles in the description popup End*/

    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        top:44px;
        width: 100%;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
        color: rgb(102,102,102);
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 20px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        width: 120px;
        background-color: #666;
        margin-left: 40px;
    }

    .cc-single-column-description-description h1 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h2 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h3 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h4 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h5 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h6 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description a {
        color: #79A342;
        font-size: 16px;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 10px;
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 14px;
    }

    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }
    .curriculumChartsContainerTitle{
        color: #31417A;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        font-size: 22px;
        line-height: 22px;
        padding-bottom: 10px;
        margin-top: 41px;
        border-bottom: 1px solid #B2B2B2;
    }


    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:80px;
        margin-top:10px;
    }

    .curriculumCharts-asterixRow {
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:12px;
        line-height: 26px;
        text-align: left;
        display:block;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:80px;
        margin-top:10px;
    }

    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }

    .curriculumCharts-legendRow-color {
        width:18px;
        height:18px;
        display:inline-block;
        vertical-align:middle;
        border:1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: 'Helvetica Roman', sans-serif;
        font-size:12px;
        line-height: 26px;
        text-align: left;
        vertical-align:middle;
        color: #666;
    }

    /*Asterix and Legend styles end*/
    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 55px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:14px;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 40px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:14px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .curriculumChartsContainer {
        margin-top: 38px;
        padding-left: 8.333%;
        min-width: 312px;
        max-width: 759px;
    }

    .curriculumCharts{
        position: relative;
        z-index: 9;
        background-color: inherit;
    }
    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 10px;
        line-height: 35px;
        width: 60px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }

    .cc-row {
        display: flex;
        align-items: stretch;
    }

    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }

    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 64px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 10px;
        text-align: center;
        font-size: 8px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        word-break: break-word;
        padding: 0 0 0 10px;
        list-style-type: none;
    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        word-break: break-word;
        padding: 0 0 0 2px;
    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: fixed;
        width:calc(100% - 30px);
        margin: 20px 10px 15px 20px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        word-break:break-word;
        font-size:8px;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
        font-size:8px;
    }
    /*Table styles for the CC tile End*/
    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: fixed;
        width:calc(100% - 30px);
        margin: 20px 10px 15px 20px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
        word-break:break-word;
        font-size:8px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
        font-size:8px;
    }
    /*Table styles in the description popup End*/
    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        top:44px;
        left: 0;
        width: 100%;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        color: #666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 16px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        background-color: #666;
        margin-left: 20px;
    }

    .cc-single-column-description-description h1 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h2 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h3 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h4 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h5 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description  h6 {
        text-align: left;
        margin-left:20px;
    }

    .cc-single-column-description-description a {
        color: #79A342;
    }
    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 10px;
        color: #666;
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 18px;
        font-size: 10px;
    }

    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }

    .curriculumChartsContainerTitle{
        color: #31417A;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        font-size: 22px;
        line-height: 22px;
        margin-top:41px;
        padding-bottom: 10px;
        border-bottom: 1px solid #B2B2B2;
    }


    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:60px;
        margin-top:10px;
    }

    .curriculumCharts-asterixRow {
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:12px;
        line-height: 26px;
        text-align: left;
        display:block;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:60px;
        margin-top:10px;
    }


    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }
    .curriculumCharts-legendRow-color {
        width:18px;
        height:18px;
        display:inline-block;
        vertical-align:middle;
        border:1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:12px;
        line-height: 26px;
        text-align: left;
        vertical-align:middle;
        color:#666;
    }

    /*Asterix and Legend styles end*/

    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 28px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:10px;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 20px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:10px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

@media screen and (max-width: 350px){
    .curriculumChartsContainer {
        margin-top: 38px;
        padding-left: 8.333%;
        min-width: 312px;
        max-width: 759px;
    }
    .curriculumCharts {
        position: relative;
        z-index: 9;
        background-color: inherit;
    }

    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 10px;
        line-height: 35px;
        width: 60px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }

    .cc-row {
        display: flex;
        align-items: stretch;
    }

    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }

    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 62px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 10px;
        text-align: center;
        font-size: 8px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style-position: inside;
        word-break: break-word;
        padding: 0 0 0 10px;
        list-style-type: none;
    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
        word-break: break-word;
        padding: 0 0 0 2px;
    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: fixed;
        width:calc(100% - 30px);
        margin: 20px 10px 15px 20px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        word-break:break-word;
        font-size:8px;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
        font-size:8px;
    }
    /*Table styles for the CC tile End*/

    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: fixed;
        width:calc(100% - 30px);
        margin: 20px 10px 15px 20px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
        word-break:break-word;
        font-size:8px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
        font-size:8px;
    }
    /*Table styles in the description popup End*/

    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        top:44px;
        width: 100%;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        color: #666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 16px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        background-color: #666;
        margin-left: 20px;
    }

    .cc-single-column-description-description h1{
        text-align: left;
        margin-left:40px;
    }
    .cc-single-column-description-description  h2{
        text-align: left;
        margin-left:40px;
    }
    .cc-single-column-description-description  h3{
        text-align: left;
        margin-left:40px;
    } .cc-single-column-description-description  h4{
          text-align: left;
          margin-left:40px;
      }
    .cc-single-column-description-description  h5{
        text-align: left;
        margin-left:40px;
    }
    .cc-single-column-description-description  h6{
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 10px;
        color: #666;
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 18px;
        font-size: 10px;
    }
    .cc-single-column-description-description a {
        color: #79A342;
    }
    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }
    .curriculumChartsContainerTitle{
        color: #31417A;
        font-family: 'Frutiger 57', sans-serif;
        font-weight: normal;
        font-size: 22px;
        line-height: 22px;
        margin-top:41px;
        padding-bottom: 10px;
        border-bottom: 1px solid #B2B2B2;
    }


    .curriculumChartsContainerSubtitle {
        padding-bottom: 48px;
        color: #666;
        font-family: 'Helvetica Roman' , sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    .curriculumCharts {
        padding: 0px 10px !important;
    }
    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:70px;
        margin-top:10px;
    }

    .curriculumCharts-asterixRow {
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size:10px;
        line-height: 22px;
        text-align: left;
        display:block;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:70px;
        margin-top:10px;
    }


    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }
    .curriculumCharts-legendRow-color {
        width:18px;
        height:18px;
        display:inline-block;
        vertical-align:middle;
        border: 1px solid #000;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;;
        font-size:10px;
        line-height: 24px;
        text-align: left;
        vertical-align:middle;
        color:#666;
    }

    /*Asterix and Legend styles end*/

    /*RTF column description normalization styles*/

    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 28px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:10px;
    }
    .cc-single-column-description-description  ul li:before {
        content: "–";
        position: absolute;
        /*change margin to move dash around*/
        margin-left: -1em;
    }
    .cc-single-column-description-description ol {
        text-align:left;
        list-style-position: inside;
        padding: 20px 10px 15px 20px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        font-size:10px;
        color:#666;
    }
    /*RTF column description normalization styles*/
}

@media print {
    .curriculumChartsContainer {
        max-width: 1134px;
        margin: 0 auto;
        margin-top:69px;
        padding-left:8.3333%;
    }
    .curriculumCharts{
        position: relative;
        z-index: 9;
        background-color: inherit;
    }
    .cc-row-title {
        display: inline-block;
        font-family: "Frutiger 67 Bold Condensed", sans-serif;
        font-size: 28px;
        line-height: 35px;
        width: 200px;
        vertical-align: middle;
        text-align: left;
        color: #666;
        align-self: center;
    }
    .cc-row {
        display: flex;
        align-items: stretch;
    }
    .cc-row-small {
        margin-bottom: 4px;
        height:44px;
    }

    .cc-row-big {
        min-height: 84px;
        margin-bottom: 3px;
    }

    .cc-row-big .cc-columnContainer {
        min-height: 84px;
        vertical-align: middle
    }

    .cc-row-small .cc-columnContainer {
        vertical-align: middle;
        height:44px;
    }

    .cc-columnContainer {
        display: inline-block;
        position: relative;
        width: calc(100% - 205px);
    }

    .cc-single-column {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        height: 100%;
        cursor: pointer;
    }

    .cc-single-column-title {
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 14px;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
    }

    .cc-single-column-title ul {
        text-align:left;
        list-style: none inside none;
    }

    .cc-single-column-title ul li:before {
        content: "–";
        position: absolute;
        /*change margin to move dash around*/
        margin-left: -1em;
    }

    .cc-single-column-title ol {
        text-align:left;
        list-style-position: inside;
    }

    .cc-single-column-title ol > li p {
        display: inline-block;
    }

    .cc-single-column-title a {
        color: #79A342;
    }
    .cc-black .cc-single-column-title {
        color: #fff;
    }
    /*Table styles for the CC tile Start*/
    .cc-single-column-title table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-title table thead th {
        text-align: center;
        border: 1px solid;
        padding: 10px 5px;
        word-break: break-word;
        font-weight: 600;
    }
    .cc-single-column-title table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        text-align: left;
        word-break: break-word;
    }
    /*Table styles for the CC tile End*/

    /*Table styles in the description popup Start*/
    .cc-single-column-description-description table  {
        table-layout: auto;
        width:calc(100% - 50px);
        margin: 20px 10px 15px 40px;
        border: 1px solid;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .cc-single-column-description-description table thead th {
        text-align: center;
        border: 1px solid;
        padding: 0px 5px;
    }
    .cc-single-column-description-description table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles in the description popup End*/

    .cc-row-big .cc-single-column-info-container {
        top: 100%;
    }
    .cc-single-column-info-container {
        display: none;
        background: rgb(242, 243, 244);
        position: absolute;
        left: 0;
        top:44px;
        width: 100%;
        cursor: default;
        z-index: 9;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .cc-single-column-info-container i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #666;
        cursor: pointer;
    }

    .cc-single-column-description-title {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
        color: rgb(102,102,102);
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 26px;
        font-size: 24px;
    }

    .cc-single-column-description-separator {
        height: 2px;
        width: 160px;
        background-color: #666;
        margin-left: 40px;
    }

    .cc-single-column-description-description h1{
        text-align: left;
        margin-left:40px;
    }
    .cc-single-column-description-description  h2{
        text-align: left;
        margin-left:40px;
    }
    .cc-single-column-description-description  h3{
        text-align: left;
        margin-left:40px;
    } .cc-single-column-description-description  h4{
          text-align: left;
          margin-left:40px;
      }
    .cc-single-column-description-description  h5{
        text-align: left;
        margin-left:40px;
    }
    .cc-single-column-description-description  h6{
        text-align: left;
        margin-left:40px;
    }

    .cc-single-column-description-description p {
        text-align: left;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 10px;
        color: rgb(102,102,102);
        font-family: "Frutiger 47 Condensed", sans-serif;
        line-height: 26px;
        font-size: 18px;
    }
    .cc-single-column-description-description a {
        color: #79A342;
    }
    .cc-single-column-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #666;
        position: absolute;
        bottom: -20px;
        z-index: 99;
        text-align: center;
    }

    .cc-show-info-container {
        display: block;
    }
    .curriculumChartsContainerTitle {
        margin-top: 41px;
        margin-bottom: 10px;
        color: #31417A;
        font-family: 'Frutiger 57',sans-serif;
        font-weight: normal;
        font-size: 30px;
        line-height: 32px;
        border-bottom: 1px solid #B2B2B2;
        padding-bottom: 14px;
    }

    /*Asterix and Legend styles start*/

    .curriculumCharts-asterixContainer {
        margin-left:204px;
        margin-top:28px;
    }

    .curriculumCharts-asterixRow {
        font-family: 'Helvetica Roman', sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        display:block;
        padding: 4px 0;
        color:#666;
    }

    .curriculumCharts-legend {
        margin-left:204px;
        margin-top:20px;
    }

    .curriculumCharts-legendRow {
        padding: 4px 0;
    }

    .curriculumCharts-legend-column-1 {
        display:inline-block;
        vertical-align: top;

    }
    .curriculumCharts-legend-column-2 {
        display: inline-block;
        vertical-align: top;
        padding-left:16px;
    }

    .curriculumCharts-legendRow-color {
        width:24px;
        height:24px;
        display:inline-block;
        vertical-align:middle;
    }

    .curriculumCharts-legendRow-title {
        display:inline-block;
        font-family: 'Helvetica Roman', sans-serif;
        font-size:16px;
        line-height: 22px;
        text-align: left;
        vertical-align:middle;
    }

    /*Asterix and Legend styles end*/
    .cc-single-column-description-description ul {
        text-align:left;
        list-style-position: inside;
        list-style-type: none;
        padding: 20px 10px 15px 55px;
        font-family: "Frutiger 47 Condensed", sans-serif;
        color:#666;
        font-size:18px;
    }

}


/*Column and row colours utility classes Starts*/
.cc-cell-selected {
    background: #666 !important;
}
.font-color-white {
    color:#FFF;
}
.font-color-white .copy {
    color: #FFF;
}
.font-color-white caption {
    color:#FFF;
}

.font-color-black {
    color :#000;
}
.font-color-black .copy {
    color: #000;
}

.font-color-black caption {
    color:#000;
}

.invert-to-light-font-color{
    color:#FFF;
}
.invert-to-light-font-color .copy {
    color: #FFF;
}
.invert-to-light-font-color caption {
    color: #FFF;
}

.invert-to-light-font-color table {
    border-color:#FFF;
}
.invert-to-light-font-color table thead th {
    border-color:#FFF;
}
.invert-to-light-font-color table tbody tr td {
    border-color:#FFF;
}

.invert-to-dark-font-color{
    color:#666;
    border-color:#666;
}
.invert-to-dark-font-color table {
    border-color:#666;
}
.invert-to-dark-font-color table thead th {
    border-color:#666;
}
.invert-to-dark-font-color table tbody tr td {
    border-color:#666;
}
.cc-cell-selected-color,.cc-cell-selected-color p {
    color: #fff;
}

.cc-mbgrey1 {
    background-color: #ededed;
}

.cc-mbgrey2 {
    background-color: #c6c6c6;
}

.cc-blue1 {
    background-color: #00869a;
}

.cc-blue2 {
    background-color: #7ebfcc;
}

.cc-blue3 {
    background-color: #cfe5eb;
}
.cc-blue4 {
    background-color: #b5d9e8;
}

.cc-blue5 {
    background-color: #cfe6f0;
}

.cc-blue6 {
    background-color: #e7f3f8;
}

.cc-mgrey1 {
    background-color: #ededed;
}

.cc-mgrey2 {
    background-color: #dadada;
}

.cc-green1 {
    background-color: #dbe9d7;
}
.cc-green1 .cc-single-column-title a {
    color: #31417A;
}
.cc-green2 {
    background-color: #c8dec2;
}
.cc-green2 .cc-single-column-title a {
    color: #31417A;
}
.cc-green3 {
    background-color: #a8cca2;
}
.cc-green3 .cc-single-column-title a {
    color: #31417A;
}
.cc-green4 {
    background-color: #85bb82;
}
.cc-green5 {
    background-color: #eff4ef;
}
.cc-green4 .cc-single-column-title a {
    color: #31417A;
}
.cc-black {
    background-color: #3c3c3b;
    color: #fff;
}

.cc-bgrey1 {
    background-color: #c6c6c6;
}

.cc-bgrey2 {
    background-color: #ededed;
}

.cc-red1 {
    background-color: #e3b8b7;
}
.cc-red1 .cc-single-column-title a {
    color: #31417A;
}
.cc-red2 {
    background-color: #cc7d80;
}
.cc-red2 .cc-single-column-title a {
    color: #31417A;
}

.cc-red3 {
    background-color: #a50d36;
}

.curriculumCharts {
    position:relative;
    z-index: 9;
    padding:0px;
    margin:0px;
}

.cc-min-height-small-row {
    min-height: 124px;
}

.cc-single-column {
    overflow: hidden;
}
/*Column and row colours utility classes Ends*/

.cc-single-column-description-description  ul li:before {
    content: "–";
    position: absolute;
    /*change margin to move dash around*/
    margin-left: -1em;
}

.cc-single-column-title ul li:before {
    content: "–";
    position: absolute;
    /*change margin to move dash around*/
    margin-left: -1em;
}