@media screen and (min-width: 1400px) {
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 400px;
        display: inline-block;
        margin: 0 14px;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 340px;
        display: inline-block;
        margin: 0 11px;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 290px;
        display: inline-block;
        margin: 0 10px;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 350px;
        display: inline-block;
        margin: 0 12px;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 100%;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 100%;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 17px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 17px;
        line-height: 20px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px){
    #directoryPageContacts{
        width: 100%;
    }
    #directoryPageContacts .singleContact{
        width: 100%;
        padding: 35px 0;
        box-sizing: border-box;
        border-bottom: 2px dotted #B2B2B2;
    }
    #directoryPageContacts .singleContact .singleContactName{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 17px;
        color: #31417a;
        transition: 0.2s;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactName:hover{
        color: #666666;
        transition: 0.2s;
    }
    #directoryPageContacts .singleContact h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 17px;
        line-height: 20px;
        color: #666;
        margin: 10px 0 20px;
    }
    #directoryPageContacts .singleContact .singleContactContacts{
        display: block;
        text-decoration: none;
    }
    #directoryPageContacts .singleContact .singleContactContacts:last-of-type{
        margin-top: 10px;
    }
    #directoryPageContacts .singleContact i{
        display: inline;
        color: #79A342;
        font-size: 18px;
        margin-right: 6px;
    }
    #directoryPageContacts .singleContact p{
        display: inline;
        transition: 0.2s;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
    }
    #directoryPageContacts .singleContact .singleContactContacts:hover p{
        color: #79A342;
        transition: 0.2s;
    }
}