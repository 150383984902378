@media screen and (min-width: 1400px){
    #forCompaniesPage{
        margin-top: 105px;
    }
    #forCompaniesPage #homePageSlider {
        height: 540px;
    }
    #forCompaniesPage #homePageSlider #sliderContainer {
        height: 100%;
    }
    /* text block in slider */
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 950px;
        text-align: center;
        margin: 30px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 1.6em;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 50px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 325px;
        text-align: center;
        text-decoration: none;
        border-right: 2px solid #f4f5f8;
        padding: 70px 20px 30px;
        box-sizing: border-box;
        transition: 0.2s;
        position: relative;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:first-of-type{
        border-left: 2px solid #f4f5f8;
    }
    /* this shows on hover */
    #bigIconBlock .singleBigIconInner_1{
        display: inline-block;
        vertical-align: top;
        position: relative;
    }
    #bigIconBlock .singleBigIconInner_1 img{
        width: 110px;
        height: 110px;
        transition: 0.2s;
        vertical-align: top;
    }

    #bigIconBlock .singleBigIconInner_1 .companies-blockIconBlock-hover-image {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }

    #bigIconBlock .singleBigIconInner_1:hover .companies-blockIconBlock-hover-image {
        display: inline;
    }

    #bigIconBlock .singleBigIconInner_1 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 58px 0 20px;
    }
    #bigIconBlock .singleBigIconInner_1 h4{
        display: none;
        font-size: 16px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 14px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        width: 1200px !important;
        padding-top: 30px !important;
        padding-bottom: 0 !important;
        text-align: left !important;
        transition: 0.3s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 128px;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 100px !important;
        height: 100px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 500px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        transition: opacity 0.8s;
    }


    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #forCompaniesPage{
        margin-top: 105px;
    }
    #forCompaniesPage #homePageSlider {
        height: 540px;
    }
    #forCompaniesPage #homePageSlider #sliderContainer {
        height: 100%;
    }
    /* text block in slider */
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 900px;
        text-align: center;
        margin: 30px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 1.6em;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 50px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 1100px;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 325px;
        text-align: center;
        text-decoration: none;
        border-right: 2px solid #f4f5f8;
        padding: 70px 20px 30px;
        box-sizing: border-box;
        transition: 0.2s;
        position: relative;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:first-of-type{
        border-left: 2px solid #f4f5f8;
    }
    /* this shows on hover */
    #bigIconBlock .singleBigIconInner_1{
        display: inline-block;
        vertical-align: top;
        position: relative;
    }
    #bigIconBlock #bigIconBlockInner img{
        width: 110px;
        height: 110px;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_1 .companies-blockIconBlock-hover-image {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }

    #bigIconBlock .singleBigIconInner_1:hover .companies-blockIconBlock-hover-image {
        display: inline;
    }
    #bigIconBlock #bigIconBlockInner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 40px 0 20px;
    }
    #bigIconBlock #bigIconBlockInner h4{
        font-size: 16px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 14px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        width: 1200px !important;
        padding-top: 30px !important;
        padding-bottom: 20px !important;
        text-align: left !important;
        transition: 0.3s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 100px;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 100px !important;
        height: 100px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 394px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        transition: opacity 0.8s;
    }

    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    #forCompaniesPage{
        margin-top: 105px;
    }
    #forCompaniesPage #homePageSlider {
        height: 390px;
    }
    #forCompaniesPage #homePageSlider #sliderContainer {
        height: 100%;
    }
    /* text block in slider */
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 650px;
        text-align: center;
        margin: 30px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 1.6em;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 100px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 325px;
        text-align: center;
        text-decoration: none;
        border-right: 2px solid #f4f5f8;
        padding: 70px 20px 30px;
        box-sizing: border-box;
        position: relative;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:first-of-type{
        border-left: 2px solid #f4f5f8;
    }
    #bigIconBlock .singleBigIconInner_1{
        display: inline-block;
        vertical-align: top;
        position: relative;
    }
    #bigIconBlock #bigIconBlockInner img{
        width: 110px;
        height: 110px;
        transition: 0.2s;
    }

    #bigIconBlock .singleBigIconInner_1 .companies-blockIconBlock-hover-image {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }

    #bigIconBlock .singleBigIconInner_1:hover .companies-blockIconBlock-hover-image {
        display: inline;
    }

    #bigIconBlock #bigIconBlockInner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 32px 0 12px;
    }
    #bigIconBlock #bigIconBlockInner h4{
        display: none;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 14px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        width: 1200px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        text-align: left !important;
        transition: 0.3s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 90px;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 80px !important;
        height: 80px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 290px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        transition: opacity 0.8s;
    }

    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #forCompaniesPage{
        margin-top: 102px;
    }

    #forCompaniesPage #homePageSlider {
        height: 390px;
    }
    #forCompaniesPage #homePageSlider #sliderContainer {
        height: 100%;
    }
    /*!* text block in slider *!*/
    /*#forCompaniesPage #homePageSlider{*/
    /*    min-height: 650px;*/
    /*    height: 0;*/
    /*}*/
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 650px;
        text-align: center;
        margin: 40px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 1.6em;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 10px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 600px;
        margin: 0 auto;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 100%;
        text-align: left;
        display: block;
        text-decoration: none;
        border-bottom: 2px solid #f4f5f8;
        padding: 20px;
        box-sizing: border-box;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:last-of-type{
        border-bottom: none;
    }
    #bigIconBlock #bigIconBlockInner img{
        width: 100px;
        height: 100px;
        display: inline-block;
        transition: 0.2s;
    }

    #bigIconBlock #bigIconBlockInner img.companies-blockIconBlock-hover-image {
        display: none !important;
    }

    #bigIconBlock #bigIconBlockInner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 20px;
        color: #31417a;
        margin: 38px 0 0 40px;
        vertical-align: top;
    }
    #bigIconBlock #bigIconBlockInner h4{
        display: none;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    #bigIconBlock .singleBigIconInner_1{
        text-align: center;
    }

    #bigIconBlock .singleBigIconInner_1 .companies-blockIconBlock-hover-image {
        display: none;
    }

    #bigIconBlock .singleBigIconInner_1:hover .companies-blockIconBlock-hover-image {
        display: none;
    }

    #bigIconBlock .singleBigIconInner_1 h3{
        display: inline-block;
    }
    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
        display: block;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 15px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        text-align: left !important;
        transition: 0.3s;
        position: relative;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 100px;
        display: inline-block;
        vertical-align: top;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 80px !important;
        height: 80px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 370px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h3{
        margin: 32px 0 10px !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        transition: opacity 0.8s;
    }

    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #forCompaniesPage{
        margin-top: 93px;
    }

    /* text block in slider */
    #forCompaniesPage #homePageSlider{
        height:auto;
        min-height:auto;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 470px;
        text-align: center;
        margin: 60px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 1.6em;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 10px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 470px;
        margin: 0 auto;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 100%;
        text-align: left;
        display: block;
        text-decoration: none;
        border-bottom: 2px solid #f4f5f8;
        padding: 30px 0;
        box-sizing: border-box;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner a:last-of-type{
        border-bottom: none;
    }
    #bigIconBlock #bigIconBlockInner img{
        width: 100px;
        height: 100px;
        display: inline-block;
        transition: 0.2s;
    }

    #bigIconBlock #bigIconBlockInner img.companies-blockIconBlock-hover-image {
        display: none !important;
    }

    #bigIconBlock #bigIconBlockInner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 34px;
        color: #31417a;
        margin: 38px 0 0 60px;
        vertical-align: top;
    }
    #bigIconBlock #bigIconBlockInner h4{
        display: none;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    #bigIconBlock .singleBigIconInner_1{
        text-align: center;
    }
    #bigIconBlock .singleBigIconInner_1 h3{
        display: inline-block;
    }
    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
        display: block;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 15px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        text-align: left !important;
        transition: 0.3s;
        position: relative;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 100px;
        display: inline-block;
        vertical-align: top;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 80px !important;
        height: 80px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 370px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h3{
        margin: 32px 0 10px !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        transition: opacity 0.8s;
    }

    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #forCompaniesPage{
        margin-top: 63px;
    }

    /* text block in slider */
    #forCompaniesPage #homePageSlider{
        height:auto;
        min-height:auto;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 340px;
        text-align: center;
        margin: 60px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 20px;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 10px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 320px;
        margin: 0 auto;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 100%;
        text-align: left;
        display: block;
        text-decoration: none;
        border-bottom: 2px solid #f4f5f8;
        padding: 30px 0;
        box-sizing: border-box;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:last-of-type{
        border-bottom: none;
    }
    #bigIconBlock #bigIconBlockInner img{
        width: 80px;
        height: 80px;
        display: inline-block;
        transition: 0.2s;
    }

    #bigIconBlock #bigIconBlockInner img.companies-blockIconBlock-hover-image {
        display: none !important;
    }

    #bigIconBlock #bigIconBlockInner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 26px;
        color: #31417a;
        margin: 30px 0 0 30px;
        vertical-align: top;
    }
    #bigIconBlock #bigIconBlockInner h4{
        display: none;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    #bigIconBlock .singleBigIconInner_1{
        text-align: center;
    }
    #bigIconBlock .singleBigIconInner_1 h3{
        display: inline-block;
    }
    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
        display: block;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 15px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        margin-top: 10px;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        text-align: left !important;
        transition: 0.3s;
        position: relative;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 100px;
        display: inline-block;
        vertical-align: top;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 80px !important;
        height: 80px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 320px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h3{
        margin: 32px 0 10px !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        display: block;
        transition: opacity 0.8s;
    }

    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

@media screen and (max-width: 350px){
    #forCompaniesPage{
        margin-top: 63px;
    }

    /* text block in slider */
    #forCompaniesPage #homePageSlider{
        height:auto;
        min-height:auto;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 300px;
        text-align: center;
        margin: 60px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 20px;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 10px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 300px;
        margin: 0 auto;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 100%;
        text-align: left;
        display: block;
        text-decoration: none;
        border-bottom: 2px solid #f4f5f8;
        padding: 30px 0;
        box-sizing: border-box;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:last-of-type{
        border-bottom: none;
    }
    #bigIconBlock #bigIconBlockInner img{
        width: 60px;
        height: 60px;
        display: inline-block;
        transition: 0.2s;
    }

    #bigIconBlock #bigIconBlockInner img.companies-blockIconBlock-hover-image {
        display: none !important;
    }

    #bigIconBlock #bigIconBlockInner h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 26px;
        color: #31417a;
        margin: 17px 0 0 12px;
        vertical-align: top;
    }
    #bigIconBlock #bigIconBlockInner h4{
        display: none;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    #bigIconBlock .singleBigIconInner_1{
        text-align: center;
    }
    #bigIconBlock .singleBigIconInner_1 h3{
        display: inline-block;
    }
    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
        display: block;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 15px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        margin-top: 10px;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        text-align: left !important;
        transition: 0.3s;
        position: relative;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 100px;
        display: inline-block;
        vertical-align: top;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 50px !important;
        height: 50px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 300px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h3{
        margin: 32px 0 10px !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        display: block;
        transition: opacity 0.8s;
    }

    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

@media print {
    #forCompaniesPage{
        margin-top: 105px;
    }

    /* text block in slider */
    #forCompaniesPage #homePageSlider #sliderTextBlock{
        width: 950px;
        text-align: center;
        margin: 30px auto 0;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        margin-bottom: 10px;
        color: #31417a;
    }
    #forCompaniesPage #homePageSlider #sliderTextBlock p{
        color: #666666;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        text-align: center;
        line-height: 20px;
    }

    /* bottom blue line in slider */
    #forCompaniesPage #homePageSliderBottomLine{
        display: none !important;
    }

    /* big icons block */
    #bigIconBlock{
        background-color: #f4f5f8;
        box-sizing: border-box;
        margin-top: 50px;
    }
    #bigIconBlock #bigIconBlockInner{
        width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon{
        width: 325px;
        text-align: center;
        text-decoration: none;
        border-right: 2px solid #fff;
        padding: 70px 20px 30px;
        box-sizing: border-box;
        transition: 0.2s;
        position: relative;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:first-of-type{
        border-left: 2px solid #fff;
    }
    /* this shows on hover */
    #bigIconBlock .singleBigIconInner_1{
        display: inline-block;
        vertical-align: top;
    }
    #bigIconBlock .singleBigIconInner_1 img{
        width: 110px;
        height: 110px;
        transition: 0.2s;
        vertical-align: top;
    }
    #bigIconBlock .singleBigIconInner_1 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 58px 0 20px;
    }
    #bigIconBlock .singleBigIconInner_1 h4{
        display: none;
        font-size: 16px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock #bigIconBlockInner .singleBigIcon:hover{
        cursor: pointer;
    }

    /* this shows on click */
    #bigIconBlock .singleBigIconInner_2{
        width: 0;
        margin-top: 0;
        display: none;
        margin-left: 0;
    }
    #bigIconBlock .singleBigIconInner_2 i{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 22px;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 i:hover{
        cursor: pointer;
        color: #31417a;
        transition: color 2s;
    }
    #bigIconBlock .singleBigIconInner_2 h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
        margin: 0 0 20px;
    }
    #bigIconBlock .singleBigIconInner_2 h4{
        font-size: 18px;
        line-height: 20px;
        font-family: "Helvetica Regular", sans-serif;
        color: #31417a;
        opacity: 0;
        transition: 0.2s;
    }
    #bigIconBlock .singleBigIconInner_2 p{
        opacity: 0;
        color: #666;
        font-size: 16px;
        font-family: "Helvetica Regular",sans-serif;
        margin: 8px 0;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links{
        text-decoration: none;
        color: #31417a;
        display: inline-block;
        padding: 8px 20px 8px 0;
        font-size: 14px;
        font-family: "Helvetica Regular",sans-serif;
    }
    #bigIconBlock .singleBigIconInner_2 .singleBigIconInner_2_links:hover{
        cursor: pointer;
        color: #79a342;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn{
        position: absolute;
        bottom: 30px;
        right: 20px;
        opacity: 0;
        text-decoration: none;
        padding: 12px 18px;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        text-align: center;
        transition: .2s;
    }
    #bigIconBlock .singleBigIconInner_2 .bigIconMoreBtn:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* open a big menu */
    #bigIconBlock .openBigIcon{
        width: 1200px !important;
        padding-top: 30px !important;
        padding-bottom: 0 !important;
        text-align: left !important;
        transition: 0.3s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1{
        width: 128px;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 img{
        width: 100px !important;
        height: 100px !important;
        transition: 0.2s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h3{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_1 h4{
        display: none;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 {
        width: 500px;
        margin-top: 0;
        transition: 0.2s !important;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 i{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 h4{
        opacity: 1!important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 p{
        line-height: 1.6em;
        opacity: 1 !important;
        transition: opacity 0.8s;
    }
    #bigIconBlock .openBigIcon .singleBigIconInner_2 a{
        opacity: 1 !important;
        transition: opacity 0.8s;
    }


    /* search block */
    #forCompaniesPage #homePageSliderSearch{
        position: relative !important;
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}