@media screen and (min-width: 1400px) {
    #niceWords{
        width: 1300px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 840px;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 100px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: inline-block;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: inline-block;
        width: 642px;
        margin-left: 28px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #niceWords{
        width: 1100px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 840px;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 100px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: inline-block;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: inline-block;
        width: 642px;
        margin-left: 28px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #niceWords{
        width: 900px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 840px;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 100px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: inline-block;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: inline-block;
        width: 642px;
        margin-left: 28px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #niceWords{
        width: 720px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 100%;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 100px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: block;
        margin-left: 30px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 30px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #niceWords{
        width: 470px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 100%;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 100px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: block;
        margin-left: 20px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #niceWords{
        width: 340px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 100%;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 10px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: block;
        margin-left: 20px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}

@media screen and (max-width: 350px){
    #niceWords{
        width: 300px;
        margin: 60px auto 0;
    }
    #niceWordsInner{
        width: 100%;
        border-top: 2px solid #ccc;
    }
    #niceWords h2{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 30px;
        color: #31417a;
        text-align: left;
        margin: 60px 0 30px;
    }
    #niceWords .niceWordsRow{
        margin-bottom: 10px;
    }
    #niceWords .niceWordsImg{
        width: 164px;
        height: 164px;
        display: block;
        margin-left: 20px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        vertical-align: top;
    }
    #niceWords .niceWordsData{
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
    }
    #niceWords .niceWordsData h3{
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        color: #31417a;
        margin-top: 10px;
    }
    #niceWords .niceWordsData h4{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 15px 0;
        line-height: 24px;
    }
    #niceWords .niceWordsData p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        color: #666666;
    }
    #niceWords .niceWordsData a{
        font-family: "Helvetica Regular",sans-serif;
        font-weight: 700;
        line-height: 20px;
        display: block;
        text-align: right;
        text-decoration: none;
        font-size: 16px!important;
        color: #79a342!important;
        margin: 20px 0;
    }
    #niceWords .niceWordsData a i{
        font-size: 12px;
        margin-left: 4px;
        color: #b2b2b2;
    }
}