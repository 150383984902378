@media screen and (min-width: 1400px) {
    .contactBlock{
        width: 50%;
    }
    .contactBlock .contactBlockImg{
        width: 220px;
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: top;
    }
    .contactBlock .contactBlockDetails{
        width: 300px;
        border-left: 2px dotted #9f9f9f;
        display: inline-block;
        margin-left: 20px;
        padding-left: 30px;
        box-sizing: border-box;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 18px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 15px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 20px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 10px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .contactBlock{
        width: 50%;
    }
    .contactBlock .contactBlockImg{
        width: 220px;
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: top;
    }
    .contactBlock .contactBlockDetails{
        width: 300px;
        border-left: 2px dotted #9f9f9f;
        display: inline-block;
        margin-left: 20px;
        padding-left: 30px;
        box-sizing: border-box;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 18px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 15px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 20px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 10px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .contactBlock{
        width: 50%;
    }
    .contactBlock .contactBlockImg{
        width: 125px;
        height: 125px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: top;
    }
    .contactBlock .contactBlockDetails{
        width: 300px;
        border-left: 2px dotted #9f9f9f;
        display: inline-block;
        margin-left: 15px;
        padding-left: 20px;
        box-sizing: border-box;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 18px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 15px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 20px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 10px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .contactBlock{
        width: 50%;
    }
    .contactBlock .contactBlockImg{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        vertical-align: top;
    }
    .contactBlock .contactBlockDetails{
        width: 250px;
        border-left: 2px dotted #9f9f9f;
        display: inline-block;
        margin-left: 10px;
        padding-left: 14px;
        box-sizing: border-box;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 16px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 12px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 18px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 6px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .contactBlock{
        width: 100%;
        padding: 30px 80px;
        box-sizing: border-box;
    }
    .contactBlock:first-of-type{
        border-bottom: 2px dotted #9f9f9f;
    }
    .contactBlock .contactBlockImg{
        display: none;
    }
    .contactBlock .contactBlockDetails{
        display: block;
        box-sizing: border-box;
    }
    .contactBlock:first-of-type .contactBlockDetails{
        margin-left: 0;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 16px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 12px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 18px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 6px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .contactBlock{
        width: 100%;
        padding: 30px 0;
        box-sizing: border-box;
    }
    .contactBlock:first-of-type{
        border-bottom: 2px dotted #9f9f9f;
    }
    .contactBlock .contactBlockImg{
        display: none;
    }
    .contactBlock .contactBlockDetails{
        display: block;
        box-sizing: border-box;
    }
    .contactBlock:first-of-type .contactBlockDetails{
        margin-left: 0;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 16px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 12px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 18px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 6px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}

@media screen and (max-width: 350px){
    .contactBlock{
        width: 100%;
        padding: 30px 0;
        box-sizing: border-box;
    }
    .contactBlock:first-of-type{
        border-bottom: 2px dotted #9f9f9f;
    }
    .contactBlock .contactBlockImg{
        display: none;
    }
    .contactBlock .contactBlockDetails{
        display: block;
        box-sizing: border-box;
    }
    .contactBlock:first-of-type .contactBlockDetails{
        margin-left: 0;
    }
    .contactBlock .contactBlockDetails h4{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 18px;
        color: #31417a;
    }
    .contactBlock .contactBlockDetails h4:first-of-type{
        margin: 0 0 12px;
    }
    .contactBlock .contactBlockDetails h4:last-of-type{
        font-size: 16px;
        margin: 0 0 5px;
    }
    .contactBlock .contactBlockDetails p{
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        line-height: 1.6em;
    }
    .contactBlock .contactBlockDetails a{
        display: block;
        text-decoration: none;
        margin-top: 12px;
    }
    .contactBlock .contactBlockDetails a i{
        display: inline;
        font-size: 18px;
        color: #79A342;
    }
    .contactBlock .contactBlockDetails a p{
        display: inline;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        color: #666666;
        vertical-align: top;
        margin-left: 6px;
    }
    .contactBlock .contactBlockDetails a:hover i{
        color: #31417a;
    }
    .contactBlock .contactBlockDetails a:hover p{
        color: #31417a;
    }
}