@media screen and (min-width: 1400px) { /*1300*/

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:1300px;
        padding-left: 75px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }


    .content .stickySubmenu{
        padding-left:0 !important;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) { /*1100*/

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:1100px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }


    .content .stickySubmenu{
        padding-left:0 !important;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:900px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }


    .content .stickySubmenu{
        padding-left:0 !important;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:720px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }

    .content .stickySubmenu{
        padding-left:0 !important;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:470px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }

    .content .stickySubmenu{
        padding-left:0 !important;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {/*320*/

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:300px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }

    .content .stickySubmenu{
        padding-left:0 !important;
    }
}

@media screen and (max-width: 350px){/*320*/
    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:300px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }

    .content .stickySubmenu{
        padding-left:0 !important;
    }
}

@media print {

    .content #pageInternalMenu .subNavLinksContainer{
        margin: 0 auto;
        width:1300px;
        padding-left: 75px;
    }

    .content #pageInternalMenu .subNavLinksContainer a:first-child{
        margin-left:0px;
    }


    .content .stickySubmenu{
        padding-left:0 !important;
    }

}