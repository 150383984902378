@media screen and (min-width: 1400px){
    /* articles */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 1300px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    /* articles styling */
    #financialMetropolisesBlocks{
        width: 100%;
        margin: 55px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }


    /* buttons */
    #financialMetropolisesButtons{
        width: 1300px;
        margin: 20px auto;
    }
    #financialMetropolisesButtons a{
        display: inline-block;
        width: 270px;
        background-color: #79A342;
        text-align: center;
        margin: 0 79px 12px;
        text-decoration: none;
        padding: 15px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* financial metropolises */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 1100px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    #financialMetropolisesBlocks{
        width: 100%;
        margin: 55px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesButtons{
        width: 1100px;
        margin: 20px auto;
    }
    #financialMetropolisesButtons a{
        display: inline-block;
        width: 270px;
        background-color: #79A342;
        text-align: center;
        margin: 0 45px 12px;
        text-decoration: none;
        padding: 15px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    /* financial metropolises */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 900px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    #financialMetropolisesBlocks{
        width: 100%;
        margin: 55px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    /* buttons */
    #financialMetropolisesButtons{
        width: 900px;
        margin: 20px auto;
    }
    #financialMetropolisesButtons a{
        display: inline-block;
        width: 270px;
        background-color: #79A342;
        text-align: center;
        margin: 0 11px 12px;
        text-decoration: none;
        padding: 15px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px){
    /* financial metropolises */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 720px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    #financialMetropolisesBlocks{
        width: 100%;
        margin: 55px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    /* buttons */
    #financialMetropolisesButtons{
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }
    #financialMetropolisesButtons a{
        display: block;
        width: 270px;
        background-color: #79A342;
        text-align: center;
        margin: 32px auto;
        text-decoration: none;
        padding: 15px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px){
    /* financial metropolises */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 470px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    #financialMetropolisesBlocks{
        width: 100%;
        height: 220px;
        margin: 30px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .fmBlock{

    }
    .fmBlock:hover{

    }
    .twoColumnsBlock{
        position: absolute;
        width: 470px;
        height: 220px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 35%;
    }
    .twoRowsBlock{
        position: absolute;
        width: 244px;
        height: 502px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 40%;
    }
    #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    #financialMetropolisesBlocks_2{
        display: none;
        top: 0;
        right: 0;
    }
    #financialMetropolisesBlocks_3{
        display: none;
        top: 258px;
        left: 0;
    }
    #financialMetropolisesBlocks_4{
        display: none;
        position: absolute;
        top: 244px;
        left: 244px;
        width: 244px;
        height: 244px;
        margin: 14px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    #financialMetropolisesBlocks_4 .financialMetropolisesBlock_inner{
        top: 0;
    }
    #financialMetropolisesBlocks_5{
        display: none;
        bottom: 0;
        right: 0;
    }
    .financialMetropolisesBlock_inner{
        display: none;
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 30px;
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
    }
    #financialMetropolisesBlocks_4 .financialMetropolisesBlock_innerText{
        max-height: 178px;
    }
    .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 230px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .twoColumnsBlock .financialMetropolisesBlock_innerText{
            max-height: 168px;
        }
    }
    .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 580px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .twoRowsBlock .financialMetropolisesBlock_innerText{
            max-height: 472px;
        }
    }
    .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 15px 0 0;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: left;
        color: #fff;
    }
    .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        bottom: 20px;
        left: 30px;
        width: 145px;
        padding: 15px 0;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }
    .twoRowsBlock h4{
        margin-top: 40px;
    }
    .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #financialMetropolisesBlocks_4:hover .financialMetropolisesBlock_inner{
        top: 0;
    }
    .fmBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .fmBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .fmBlock:hover h4{
        margin-top: 0;
    }
    .financialMetropolisesBlock_inner:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.4s;
    }
    .financialMetropolisesBlock_innerButton{

    }
    #financialMetropolisesButtons{
        width: 300px;
        margin: 10px auto;
        text-align: center;
    }
    #financialMetropolisesButtons a{
        display: block;
        width: 280px;
        background-color: #79A342;
        text-align: center;
        margin: 0 auto;
        text-decoration: none;
        padding: 15px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }
    #financialMetropolisesButtons a{
        margin-top: 30px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px){
    /* financial metropolises */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 340px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    #financialMetropolisesBlocks{
        width: 100%;
        height: 190px;
        margin: 55px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .fmBlock{

    }
    .fmBlock:hover{

    }
    .twoColumnsBlock{
        position: absolute;
        width: 340px;
        height: 184px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 35%;
    }
    .twoRowsBlock{
        position: absolute;
        width: 244px;
        height: 502px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 40%;
    }
    #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    #financialMetropolisesBlocks_2{
        display: none;
        top: 0;
        right: 0;
    }
    #financialMetropolisesBlocks_3{
        display: none;
        top: 258px;
        left: 0;
    }
    #financialMetropolisesBlocks_4{
        display: none;
        position: absolute;
        top: 244px;
        left: 244px;
        width: 244px;
        height: 244px;
        margin: 14px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    #financialMetropolisesBlocks_4 .financialMetropolisesBlock_inner{
        top: 0;
    }
    #financialMetropolisesBlocks_5{
        display: none;
        bottom: 0;
        right: 0;
    }
    .financialMetropolisesBlock_inner{
        display: none;
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 30px;
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
    }
    #financialMetropolisesBlocks_4 .financialMetropolisesBlock_innerText{
        max-height: 178px;
    }
    .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 230px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .twoColumnsBlock .financialMetropolisesBlock_innerText{
            max-height: 168px;
        }
    }
    .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 580px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .twoRowsBlock .financialMetropolisesBlock_innerText{
            max-height: 472px;
        }
    }
    .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 15px 0 0;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: left;
        color: #fff;
    }
    .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        bottom: 20px;
        left: 30px;
        width: 145px;
        padding: 15px 0;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }
    .twoRowsBlock h4{
        margin-top: 40px;
    }
    .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #financialMetropolisesBlocks_4:hover .financialMetropolisesBlock_inner{
        top: 0;
    }
    .fmBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .fmBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .fmBlock:hover h4{
        margin-top: 0;
    }
    .financialMetropolisesBlock_inner:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.4s;
    }
    .financialMetropolisesBlock_innerButton{

    }
    #financialMetropolisesButtons{
        width: 340px;
        margin: 10px auto;
        text-align: center;
    }
    #financialMetropolisesButtons a{
        display: block;
        width: 100%;
        background-color: #79A342;
        text-align: center;
        margin: 0 auto;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }
    #financialMetropolisesButtons a{
        margin-top: 30px;
    }
}

@media screen and (max-width: 350px){
    /* financial metropolises */
    #financialMetropolises{
        background-color: #f4f5f8;
        padding: 55px 0;
    }
    #financialMetropolisesInner{
        width: 300px;
        margin: 0 auto;
    }
    #financialMetropolises h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    #financialMetropolisesText{
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
    }
    #financialMetropolisesBlocks{
        width: 100%;
        height: 190px;
        margin: 55px 0;
        position: relative;
        font-family: "Frutiger 57 Condensed", sans-serif;
    }
    .fmBlock{

    }
    .fmBlock:hover{

    }
    .twoColumnsBlock{
        position: absolute;
        width: 300px;
        height: 184px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 35%;
    }
    .twoRowsBlock{
        position: absolute;
        width: 244px;
        height: 502px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 40%;
    }
    #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    #financialMetropolisesBlocks_2{
        display: none;
        top: 0;
        right: 0;
    }
    #financialMetropolisesBlocks_3{
        display: none;
        top: 258px;
        left: 0;
    }
    #financialMetropolisesBlocks_4{
        display: none;
        position: absolute;
        top: 244px;
        left: 244px;
        width: 244px;
        height: 244px;
        margin: 14px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    #financialMetropolisesBlocks_4 .financialMetropolisesBlock_inner{
        top: 0;
    }
    #financialMetropolisesBlocks_5{
        display: none;
        bottom: 0;
        right: 0;
    }
    .financialMetropolisesBlock_inner{
        display: none;
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 30px;
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
    }
    #financialMetropolisesBlocks_4 .financialMetropolisesBlock_innerText{
        max-height: 178px;
    }
    .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 230px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .twoColumnsBlock .financialMetropolisesBlock_innerText{
            max-height: 168px;
        }
    }
    .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 580px;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .twoRowsBlock .financialMetropolisesBlock_innerText{
            max-height: 472px;
        }
    }
    .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 15px 0 0;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: left;
        color: #fff;
    }
    .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        bottom: 20px;
        left: 30px;
        width: 145px;
        padding: 15px 0;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }
    .twoRowsBlock h4{
        margin-top: 40px;
    }
    .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    #financialMetropolisesBlocks_4:hover .financialMetropolisesBlock_inner{
        top: 0;
    }
    .fmBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .fmBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .fmBlock:hover h4{
        margin-top: 0;
    }
    .financialMetropolisesBlock_inner:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.4s;
    }
    .financialMetropolisesBlock_innerButton{

    }
    #financialMetropolisesButtons{
        width: 300px;
        margin: 10px auto;
        text-align: center;
    }
    #financialMetropolisesButtons a{
        display: block;
        width: 100%;
        background-color: #79A342;
        text-align: center;
        margin: 0 auto 26px;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
    }

}

#financialMetropolisesInner .financialMetropolisesInnerLeftAlign h3 {
    text-align: left;
}

#financialMetropolisesInner .financialMetropolisesInnerLeftAlign p {
    text-align: left;
}

#financialMetropolisesInner .financialMetropolisesInnerLeftAlign {
    text-align: left;
}