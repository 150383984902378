/*
* 6 articles
*/
@media screen and (min-width: 1400px){
    .six-articles{
        height: 1300px;
    }
    .six-articles .twoColumnsBlock{
        position: absolute;
        width: 860px;
        height: 420px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 210px;
    }
    .six-articles .twoRowsBlock{
        position: absolute;
        width: 420px;
        height: 860px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 650px;
    }
    .six-articles .squareBlock{
        position: absolute;
        width: 420px;
        height: 420px;
        margin: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .squareBlock .financialMetropolisesBlock_inner{
        top: 210px;
    }
    .six-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .six-articles #financialMetropolisesBlocks_3{
        top: 440px;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_4{
        top: 420px;
        left: 420px;
    }
    .six-articles #financialMetropolisesBlocks_5{
        top: 860px;
        left: 420px;
    }
    .six-articles #financialMetropolisesBlocks_6{
        position: absolute;
        top: 860px;
        left: 860px;
    }
    .six-articles .financialMetropolisesBlock_inner{
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 50px;
        height: calc(100% - 100px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .six-articles .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
        max-height: 90%;
    }
    .six-articles #financialMetropolisesBlocks_4 .financialMetropolisesBlock_innerText{
        max-height: 90%;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 90%;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 90%;
    }
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{
            max-height: 90%;
        }
    }
    .six-articles .financialMetropolisesBlock_inner h4{
        font-size: 30px;
        text-align: left;
        line-height: 35px;
    }
    .six-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 20px 0 0;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: left;
        color: #fff;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        /*bottom: 50px;*/
        margin-top: 10px;
        left: 50px;
        /*width: 145px;*/
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    .six-articles #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }

    .six-articles .fmBlock:hover h4{
        margin-top: 0;
    }
    /* two columns block - hover animation */
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
    /* two rows block - hover animation */
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.9s;
    }
    /* two square block - hover animation */
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .six-articles{
        height: 1100px;
    }
    .six-articles .twoColumnsBlock{
        position: absolute;
        width: 725px;
        height: 350px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 175px;
    }
    .six-articles .twoRowsBlock{
        position: absolute;
        width: 350px;
        height: 725px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 550px;
    }
    .six-articles .squareBlock{
        position: absolute;
        width: 350px;
        height: 350px;
        margin: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .squareBlock .financialMetropolisesBlock_inner{
        top: 175px;
    }
    .six-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .six-articles #financialMetropolisesBlocks_3{
        top: 375px;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_4{
        top: 355px;
        left: 355px;
    }
    .six-articles #financialMetropolisesBlocks_5{
        top: 730px;
        left: 355px;
    }
    .six-articles #financialMetropolisesBlocks_6{
        position: absolute;
        top: 730px;
        left: 730px;
    }
    .six-articles .financialMetropolisesBlock_inner{
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 40px;
        height: calc(100% - 80px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .six-articles .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
        max-height: 90%;
    }
    .six-articles #financialMetropolisesBlocks_4 .financialMetropolisesBlock_innerText{
        max-height: 90%;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 90%;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 90%;
    }
    /* Chrome 29+ */
    /*@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {*/
    /*    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{*/
    /*        max-height: 692px;*/
    /*    }*/
    /*}*/
    .six-articles .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .six-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 5px 0 0;
        font-size: 15px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 18px;
        text-align: left;
        color: #fff;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        /*bottom: 40px;*/
        margin-top: 10px;
        left: 40px;
        /*width: 145px;*/
        margin-top: 10px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    .six-articles #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }

    .six-articles .fmBlock:hover h4{
        margin-top: 0;
    }
    /* two columns block - hover animation */
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
    /* two rows block - hover animation */
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.9s;
    }
    /* two square block - hover animation */
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    .six-articles{
        height: 900px;
    }
    .six-articles .twoColumnsBlock{
        position: absolute;
        width: 595px;
        height: 290px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 125px;
    }
    .six-articles .twoRowsBlock{
        position: absolute;
        width: 290px;
        height: 595px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 430px;
    }
    .six-articles .squareBlock{
        position: absolute;
        width: 290px;
        height: 290px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .squareBlock .financialMetropolisesBlock_inner{
        top: 125px;
    }
    .six-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .six-articles #financialMetropolisesBlocks_3{
        top: 305px;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_4{
        top: 305px;
        left: 305px;
    }
    .six-articles #financialMetropolisesBlocks_5{
        top: 610px;
        left: 305px;
    }
    .six-articles #financialMetropolisesBlocks_6{
        position: absolute;
        top: 610px;
        left: 610px;
    }
    .six-articles .financialMetropolisesBlock_inner{
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 25px;
        height: calc(100% - 50px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .six-articles .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
        max-height: 86%;
    }
    .six-articles .squareBlock .financialMetropolisesBlock_innerText{
        max-height: 86%;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 86%;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 86%;
    }
    /* Chrome 29+ */
    /*@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {*/
    /*    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{*/
    /*        max-height: 692px;*/
    /*    }*/
    /*}*/
    .six-articles .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .six-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 5px 0 0;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 17px;
        text-align: left;
        color: #fff;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        /*bottom: 25px;*/
        margin-top: 10px;
        left: 25px;
        /*width: 145px;*/
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 1;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    .six-articles #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    .six-articles .fmBlock:hover h4{
        margin-top: 0;
    }
    /* two columns block - hover animation */
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
    /* two rows block - hover animation */
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.9s;
    }
    /* two square block - hover animation */
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px){
    .six-articles{
        height: 760px;
    }
    .six-articles .twoColumnsBlock{
        position: absolute;
        width: 482px;
        height: 244px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_inner{
        top: 125px;
    }
    .six-articles .twoRowsBlock{
        position: absolute;
        width: 224px;
        height: 502px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_inner{
        top: 362px;
    }
    .six-articles .squareBlock{
        position: absolute;
        width: 224px;
        height: 244px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .six-articles .squareBlock .financialMetropolisesBlock_inner{
        top: 105px;
    }
    .six-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .six-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .six-articles #financialMetropolisesBlocks_3{
        top: 258px;
        left: 0;
        width:244px;
    }
    .six-articles #financialMetropolisesBlocks_4{
        top: 258px;
        left: 258px;
    }
    .six-articles #financialMetropolisesBlocks_5{
        bottom: 0;
        left: 258px;
    }
    .six-articles #financialMetropolisesBlocks_6{
        position: absolute;
        bottom: 0;
        left: 496px;
    }
    .six-articles .financialMetropolisesBlock_inner{
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 20px;
        height: calc(100% - 40px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .six-articles .financialMetropolisesBlock_innerText{
        overflow-y: hidden;
        max-height: 80%;
    }
    .six-articles .squareBlock .financialMetropolisesBlock_innerText{
        max-height: 80%;
    }
    .six-articles .twoColumnsBlock .financialMetropolisesBlock_innerText{
        max-height: 80%;
    }
    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{
        max-height: 80%;
    }
    /* Chrome 29+ */
    /*@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {*/
    /*    .six-articles .twoRowsBlock .financialMetropolisesBlock_innerText{*/
    /*        max-height: 692px;*/
    /*    }*/
    /*}*/
    .six-articles .financialMetropolisesBlock_inner h4{
        font-size: 25px;
        text-align: left;
        line-height: 30px;
    }
    .six-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 5px 0 0;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 17px;
        text-align: left;
        color: #fff;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        /*bottom: 25px;*/
        margin-top: 10px;
        left: 25px;
        /*width: 145px;*/
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 1;
    }
    .six-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    .six-articles #financialMetropolisesButtons a:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /*.six-articles #financialMetropolisesBlocks_4:hover .financialMetropolisesBlock_inner{*/
    /*    top: 0;*/
    /*}*/
    .six-articles .fmBlock:hover h4{
        margin-top: 0;
    }
    /* two columns block - hover animation */
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .twoColumnsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
    /* two rows block - hover animation */
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.9s;
    }
    .six-articles .twoRowsBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.9s;
    }
    /* two square block - hover animation */
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .six-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px){

}

@media screen and (min-width: 350px) and (max-width: 478px){

}

@media screen and (max-width: 350px){

}