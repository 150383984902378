.inhouse-container {
    display: block;
    margin: 0 auto;
    border-top: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;
    width: 1300px;
}

.inhouse-title {
    font-size: 30px;
    text-align: left;
    margin-bottom: 30px;
    color: #31417a;
    font-family: "Frutiger 57 Condensed",sans-serif;
    padding-top: 50px;
}

#inhouse-dropdown-container {
    position: relative;
    display: inline-block;
    width: 320px;
}

.inhouse-dropdown-menu {
    background: #f4f5f8;
    height: 33px;
    border-bottom: 3px solid #79a342;
}

.inhouse-dropdown-menu {
    cursor: pointer;
}

#inhouse-dropdown-menu-title {
    display: inline-block;
    padding-top: 12px;
    padding-left: 6px;
    font-size: 14px;
    font-family: "Frutiger 57 Condensed",sans-serif;
}

.inhouse-dropdown-menu i {
    position: absolute;
    right: 10px;
    top: 6px;
    color: #b2b2b2;
}

#inhouse-dropdown {
    display: none;
    position: absolute;
    background: #f4f5f8;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);
    height: 380px;
    overflow: auto;
    padding-top: 6px;
    z-index: 9;
}

#inhouse-dropdown li {
    display: block;
    padding: 8px 2px 8px 12px;
    color: #707070;
    cursor: pointer;
}

#inhouse-dropdown li a {
    color: #666;
    text-decoration: none;
    font-size: 14px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    display: block;
    min-height: 14px;
}

#inhouse-dropdown li a:hover {
    color: #79a342;
    cursor: pointer;
}

.inhouse-search-input {
    vertical-align: top;
    display: inline-block;
    width: 320px;
    height: 36px;
    margin-left: 14px;
    background-color: #f4f5f8;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.inhouse-search-button {
    display: inline-block;
    cursor: pointer;
    padding-left: 12px;
    vertical-align: middle;
}

.inhouse-search-input input {
    display: inline-block;
    width: 80%;
    border: none;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 14px;
    background: #f4f5f8;
    margin-left: 4px;
    outline: 0;
    padding-top: 8px;
    color: #666
}


.inhouse-product {
    display: block;
    border-bottom: 3px solid #b2b2b2;
    position: relative;
}

.inhouse-product-top-container {
    cursor: pointer;
}

.inhouse-product i {
    position: absolute;
    right: 8px;
    top: 32px;
    color: #666;
    transform: translateX(0px) rotate(0deg);
}

.inhouse-product-title {
    color: #31417a;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 22px;
    display: inline-block;
    width: 70%;
    margin-top: 28px;
    margin-bottom: 28px;
}

.inhouse-product-title a {
    text-decoration: none;
    color: #31417a;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 22px;
}

.inhouse-product-edType {
    display: inline-block;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 16px;
    color: #666;
}

.inhouse-product-additionalInfo {
    background: #f4f5f8;
    position: relative;
    display: none;
}

.inhouse-product-info {
    padding: 30px;
    width: 70%;
    line-height: 21px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 16px;
    color: #666;
}

.inhouse-product-topics {
    display: inline-block;
    padding-left: 30px;
    padding-right: 12px;
    line-height: 21px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 16px;
    color: #666;
}

.inhouse-product-language {
    display: inline-block;
    padding-left: 12px;
    line-height: 21px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 16px;
    padding-bottom: 24px;
    color: #666;
}

.inhouse-product-loadMore {
    position: absolute;
    top: 40px;
    right: 30px;
    background: #79a342;
    padding: 14px 6px;
    text-decoration: none;
    color: white;
    line-height: 21px;
    font-family: "Frutiger 57 Condensed",sans-serif;
    font-size: 22px;
}

.inhouse-product-separator {
    display: inline-block;
    color: #666;
}

.inhouse-5d70d6ae60cb65630e000001,
.inhouse-5d70d6ae60cb65630e000002,
.inhouse-5d70d6ae60cb65630e000003,
.inhouse-5d70d6ae60cb65630e000004,
.inhouse-5d70d6ae60cb65630e000005,
.inhouse-5d70d6ae60cb65630e000006,
.inhouse-5d70d6ae60cb65630e000007,
.inhouse-5d70d6af60cb65630e000008,
.inhouse-5d70d6af60cb65630e000009,
.inhouse-5d70d6af60cb65630e00000a,
.inhouse-5d70d6af60cb65630e00000b,
.inhouse-5d70d6af60cb65630e00000c,
.inhouse-5d70d6af60cb65630e00000d,
.inhouse-5d70d6af60cb65630e00000e,
.inhouse-5d70d6af60cb65630e00000f,
.inhouse-5dee91edd1b7647fd800030d,
.inhouse-5dee920872bbfd68e10000c3,
.inhouse-5e42e1bd72bbfd1d7500067c,
.inhouse-5f3e8b4af13d733ab60007ed,
.inhouse-6141c5e93b5d005c6e00041e {
    display: none;
}

#inhouse-page-navigation .inhouse-pagination-prev-next {
    display: inline-block;
    vertical-align: top;
    margin-top: 19px;
}

#inhouse-page-navigation .inhouse-pagination-prev-next i {
    font-size: 30px;
    color: #666;
}

#inhouse-page-navigation .inhouse-pagination-prev-next i:hover {
    cursor: pointer;
    color: #79a342;
}

.inhouse-pagination-next {
    margin-top: 4px;
}

#inhouse-paginationBottom {
    display: inline-block;
    vertical-align: top;
    padding-left: 8px;
    padding-bottom: 20px;
}

.inhouse-page-pagination-btn {
    display: inline-block;
    width: 20px;
    height: 22px;
    border: 1px solid #79A342;
    color: #79A342;
    background: none;
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 22px;
    list-style: none;
    padding: 5.5px;
    text-align: center;
    cursor: pointer;
    margin: 16px 10px 0;
}

.inhouse-page-pagination-btn:hover {
    color: #79A342;
    text-decoration: underline;
}

.inhouse-page-pagination-btn-active {
    display: inline-block;
    width: 20px;
    height: 22px;
    color: #666;
    background: none;
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 22px;
    list-style: none;
    padding: 5.5px;
    text-align: center;
    border: 1px solid #666;
    pointer-events: none;
    margin: 16px 10px 0;
}

.inhouse-page-pagination-btn-dots {
    display: inline-block;
    width: 20px;
    height: 22px;
    color: #79A342;
    background: none;
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 22px;
    list-style: none;
    padding: 5.5px;
    text-align: center;
    border: 1px solid #79A342;
    cursor: text;
    text-decoration: none;
    margin: 16px 10px 0;
}

@media screen and (min-width: 1400px){

}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .inhouse-container {
        width: 1100px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    .inhouse-product {
        border-bottom: 1px solid #b2b2b2;
    }

    .inhouse-container {
        width: 922px;
    }

    .inhouse-product-title {
        font-size: 16px;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .inhouse-product-title a {
        font-size: 16px;
    }

    .inhouse-product-edType {
        font-size: 14px;
        vertical-align: top;
        margin-top: 16px;
    }

    .inhouse-product i {
        top: 15px;
    }

    .inhouse-product-info,
    .inhouse-product-topics,
    .inhouse-product-separator,
    .inhouse-product-language {
        font-size: 13px;
    }

    .inhouse-product-loadMore {
        font-size: 16px;
        padding: 10px 6px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .inhouse-product {
        border-bottom: 1px solid #b2b2b2;
    }

    .inhouse-container {
        width: 720px;
    }

    .inhouse-product-title {
        font-size: 16px;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .inhouse-product-title a {
        font-size: 16px;
    }

    .inhouse-product-edType {
        font-size: 14px;
        vertical-align: top;
        margin-top: 16px;
    }

    .inhouse-product i {
        top: 15px;
    }

    .inhouse-product-info,
    .inhouse-product-topics,
    .inhouse-product-separator,
    .inhouse-product-language {
        font-size: 13px;
    }

    .inhouse-product-loadMore {
        font-size: 16px;
        padding: 10px 6px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .inhouse-container {
        width: 470px;
    }

    .inhouse-product {
        border-bottom: 1px solid #b2b2b2;
    }

    #inhouse-dropdown-container {
        display: block;
        margin: 0 auto 20px;
    }

    .inhouse-search-input {
        margin: 0 auto;
        display: block;
    }

    .inhouse-product-title {
        margin-top: 14px;
        margin-bottom: 14px;
        font-size:14px;
    }

    .inhouse-product-title a {
        font-size: 14px;
    }

    .inhouse-product-edType {
        font-size: 12px;
        vertical-align: top;
        margin-top: 16px;
    }

    .inhouse-product i {
        top: 14px;
    }

    .inhouse-product-additionalInfo {
        padding-bottom: 12px;
    }

    .inhouse-product-info {
        width: auto;
    }

    .inhouse-product-language {
        padding-bottom: 10px;
    }

    .inhouse-product-info,
    .inhouse-product-topics,
    .inhouse-product-separator,
    .inhouse-product-language {
        font-size: 12px;
    }

    .inhouse-product-loadMore {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        font-size: 18px;
        margin-left: 30px;
        width: 100px;
        padding: 10px 6px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .inhouse-container {
        width: 340px;
    }

    .inhouse-product {
        border-bottom: 1px solid #b2b2b2;
    }

    #inhouse-dropdown-container {
        display: block;
        margin: 0 auto 20px;
    }

    .inhouse-search-input {
        margin: 0 auto;
        display: block;
    }

    .inhouse-product-title {
        width: 60%;
        margin-top: 14px;
        margin-bottom: 14px;
        font-size:14px;
    }

    .inhouse-product-title a {
        font-size: 14px;
    }

    .inhouse-product-edType {
        font-size: 12px;
        vertical-align: top;
        margin-top: 16px;
    }

    .inhouse-product i {
        top: 14px;
    }

    .inhouse-product-additionalInfo {
        padding-bottom: 12px;
    }

    .inhouse-product-info {
        width: auto;
    }

    .inhouse-product-language {
        padding-bottom: 10px;
    }

    .inhouse-product-info,
    .inhouse-product-topics,
    .inhouse-product-separator,
    .inhouse-product-language {
        font-size: 12px;
    }

    .inhouse-product-loadMore {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        font-size: 18px;
        margin-left: 30px;
        width: 100px;
        padding: 10px 6px;
    }
}

@media screen and (max-width: 350px){
    .inhouse-container {
        width: 310px;
    }

    .inhouse-product {
        border-bottom: 1px solid #b2b2b2;
    }

    #inhouse-dropdown-container {
        display: block;
        margin: 0 auto 20px;
    }

    .inhouse-search-input {
        margin: 0 auto;
        display: block;
    }

    .inhouse-product-title {
        width: 56%;
        margin-top: 14px;
        margin-bottom: 14px;
        font-size:14px;
    }

    .inhouse-product-title a {
        font-size: 14px;
    }

    .inhouse-product-edType {
        font-size: 12px;
        vertical-align: top;
        margin-top: 16px;
    }

    .inhouse-product i {
        top: 14px;
    }

    .inhouse-product-additionalInfo {
        padding-bottom: 12px;
    }

    .inhouse-product-info {
        width: auto;
    }

    .inhouse-product-language {
        padding-bottom: 10px;
    }

    .inhouse-product-info,
    .inhouse-product-topics,
    .inhouse-product-separator,
    .inhouse-product-language {
        font-size: 12px;
    }

    .inhouse-product-loadMore {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        font-size: 18px;
        margin-left: 30px;
        width: 100px;
        padding: 10px 6px;
    }
}
.inhouse-seminars-container {
    margin-top: 30px;
    padding-bottom: 8px;
    overflow: inherit !important;
}

@media print {

}