@media screen and (min-width: 1400px){
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 600px;
        margin: 0 auto;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline;
        text-decoration: none;
        color: #31417A;
        font-size: 35px;
        margin: 0 18px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 600px;
        border-left: 1px solid #868686;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 325px;
        padding-left: 25px;
        font-size: 18px;
        vertical-align: top;
        height: 40px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 12px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
        margin-left: 15px;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 590px;
        margin: 0 auto;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline;
        text-decoration: none;
        color: #31417A;
        font-size: 35px;
        margin: 0 18px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 590px;
        border-left: 1px solid #868686;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 325px;
        padding-left: 25px;
        font-size: 18px;
        vertical-align: top;
        height: 40px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 12px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
        margin-left: 15px;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 500px;
        margin: 0 auto;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline;
        text-decoration: none;
        color: #31417A;
        font-size: 35px;
        margin: 0 18px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 500px;
        border-left: 1px solid #868686;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 325px;
        padding-left: 25px;
        font-size: 18px;
        vertical-align: top;
        height: 40px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 12px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
        margin-left: 15px;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px){
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 500px;
        margin: 0 auto;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline;
        text-decoration: none;
        color: #31417A;
        font-size: 35px;
        margin: 0 18px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 500px;
        margin: 20px auto 0;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 325px;
        padding-left: 25px;
        font-size: 18px;
        vertical-align: top;
        height: 40px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 12px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
        margin-left: 15px;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 470px;
        margin: 0 auto;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline-block;
        width: 40px;
        text-decoration: none;
        color: #31417a;
        font-size: 35px;
        margin: 0 18px;
        padding-bottom: 20px;
        text-align: center;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 470px;
        margin: 20px auto 0;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 320px;
        padding-left: 25px;
        font-size: 18px;
        vertical-align: top;
        height: 40px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 12px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
        margin-left: 15px;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 320px;
        margin: 0 auto;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline-block;
        vertical-align: center;
        text-decoration: none;
        color: #31417A;
        font-size: 30px;
        margin: 0px 8px 8px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
        margin-left:0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 320px;
        margin: 20px auto 0;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 205px;
        padding-left: 5px;
        font-size: 16px;
        vertical-align: top;
        height: 34px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px){
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 320px;
        margin: 0 auto;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline-block;
        text-decoration: none;
        color: #31417a;
        font-size: 30px;
        margin: 0px 8px 8px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
        margin-left:0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 320px;
        margin: 20px auto 0;
        display: block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 205px;
        padding-left: 5px;
        font-size: 16px;
        vertical-align: top;
        height: 34px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}

@media print{
    #footerSocial_outer{
        width: 100%;
        padding: 50px 0;
        background-color: #F4F5F8;
        text-align: center;
        font-family: "Frutiger 57", sans-serif;
        margin-bottom: 5px;
    }

    /* social bar */
    #footerSocial{
        width: 600px;
        margin: 0 auto;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #footerSocial p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #footerSocial a{
        display: inline;
        text-decoration: none;
        color: #31417A;
        font-size: 35px;
        margin: 0 18px;
    }
    #footerSocial a:first-of-type{
        margin-left: 0;
    }
    #footerSocial a:last-of-type{
        margin-right: 0;
    }
    #footerSocial a:hover{
        color: #79a342;
    }

    /* sign up */
    #homePage_signUp{
        width: 600px;
        border-left: 1px solid #868686;
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;
    }
    #homePage_signUp p{
        display: block;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417A;
    }
    #homePage_signUpInput{
        display: inline-block;
        width: 325px;
        padding-left: 25px;
        font-size: 18px;
        vertical-align: top;
        height: 40px;
        border: 1px solid #888;
    }
    #homePage_signUpButton{
        display: inline-block;
        text-decoration: none;
        padding: 12px 0;
        font-size: 20px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        background-color: #79A342;
        width: 120px;
        text-align: center;
        vertical-align: top;
        margin-left: 15px;
    }
    #homePage_signUpButton:hover{
        cursor: pointer;
        background-color: #31417a;
        transition: 0.2s;
    }
}