@media screen and (min-width: 1400px) {
    /* main container */
    #herospace {
        width: 100%;
        height: 422px;
        position: relative;
        text-align: center;
        color: #fff;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 105px;
        height: 360px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts{
        position: absolute;
        top: 125px;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #herospaceTexts h1 {
        font-size: 52px;
        line-height: 52px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #herospaceTexts h2 {
        font-size: 46px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }

    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 26px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 30px;
        padding-top: 8px;
        letter-spacing: 2px;
    }

    .herospaceTextSecondSpan {
        font-size: 32px;
        line-height: 50px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 30px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 34px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }

    #breadcrumbsInner{
        width: 1300px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 150px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
        font-size:15px;
    }

    .breadcrumb:hover {
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus {
        color: #31417a;
        text-decoration: none;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    /* main container */
    #herospace {
        width: 100%;
        height: 422px;
        position: relative;
        text-align: center;
        color: #fff;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 105px;
        height: 360px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts {
        position: absolute;
        top: 125px;
        left: 0;
        width: 100%;
        z-index: 100;
    }
    #herospaceTexts h1 {
        font-size: 46px;
        line-height: 46px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }
    #herospaceTexts h2 {
        font-size: 42px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 26px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 30px;
        padding-top: 8px;
        letter-spacing: 2px;
    }

    .herospaceTextSecondSpan {
        font-size: 32px;
        line-height: 50px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 30px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 34px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }
    #breadcrumbsInner {
        width: 1100px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 150px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
        font-size:15px;
    }

    .breadcrumb:hover {
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus {
        color: #31417a;
        text-decoration: none;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* main container */
    #herospace {
        width: 100%;
        height: 422px;
        position: relative;
        text-align: center;
        color: #fff;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 105px;
        height: 360px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts {
        position: absolute;
        top: 125px;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #herospaceTexts h1 {
        font-size: 46px;
        line-height: 52px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #herospaceTexts h2 {
        font-size: 42px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 26px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 30px;
        padding-top: 8px;
        letter-spacing: 2px;
    }

    .herospaceTextSecondSpan {
        font-size: 32px;
        line-height: 50px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 30px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 34px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }

    #breadcrumbsInner {
        width: 900px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 150px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
        font-size:15px;
    }

    .breadcrumb:hover{
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus{
        color: #31417a;
        text-decoration: none;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /* main container */
    #herospace {
        width: 100%;
        height: 360px;
        position: relative;
        text-align: center;
        color: #fff;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 103px;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts {
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #herospaceTexts h1 {
        font-size: 46px;
        line-height: 52px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #herospaceTexts h2 {
        font-size: 42px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 26px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 30px;
        padding-top: 8px;
        letter-spacing: 2px;
    }

    .herospaceTextSecondSpan {
        font-size: 32px;
        line-height: 50px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 30px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 34px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }

    #breadcrumbsInner {
        width: 720px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 150px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
        font-size:15px;
    }

    .breadcrumb:hover {
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus {
        color: #31417a;
        text-decoration: none;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /* main container */
    #herospace {
        width: 100%;
        height: 350px;
        position: relative;
        text-align: center;
        color: #fff;
        margin-bottom: 30px;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 92px;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts {
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #herospaceTexts h1 {
        font-size: 42px;
        line-height: 46px;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #herospaceTexts h2 {
        font-size: 38px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }

    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 24px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 30px;
        padding-top: 8px;
        letter-spacing: 2px;
    }

    .herospaceTextSecondSpan {
        font-size: 28px;
        line-height: 50px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 30px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 44px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }

    #breadcrumbsInner {
        width: 470px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 130px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
        font-size:15px;
    }

    .breadcrumb:hover {
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus {
        color: #31417a;
        text-decoration: none;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /* main container */
    #herospace {
        width: 100%;
        height: 260px;
        position: relative;
        text-align: center;
        color: #fff;
        margin-bottom:30px;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 62px;
        height: 220px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts {
        position: absolute;
        top: 75px;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #herospaceTexts h1 {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 10px;
        font-family: "Frutiger 67 Condensed", sans-serif;
    }

    #herospaceTexts h2 {
        font-size: 25px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }

    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 18px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 20px;
        padding-top: 8px;
        letter-spacing: 2px;
    }

    .herospaceTextSecondSpan {
        font-size: 20px;
        line-height: 30px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 30px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }

    #breadcrumbsInner {
        width: 340px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 100px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
         font-size:10px;
     }

    .breadcrumb:hover{
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus{
        color: #31417a;
        text-decoration: none;
    }

}

@media screen and (max-width: 350px){
    /* main container */
    #herospace {
        width: 100%;
        height: 260px;
        position: relative;
        text-align: center;
        color: #fff;
        margin-bottom:40px;
    }

    /* background image */
    #herospaceContainer {
        margin-top: 62px;
        height: 220px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* left, blue triangle */
    #herospaceImg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
        z-index: 10;
    }

    .herospace-layer {
        background-color: rgba(102, 106, 126, 0.7);
        width: 100%;
        height: 100%;
    }

    /* slider texts */
    #herospaceTexts {
        position: absolute;
        top: 75px;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #herospaceTexts h1 {
        font-size: 26px;
        line-height: 28px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin-bottom: 8px;
    }

    #herospaceTexts h2 {
        font-size: 24px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    .herospaceTextsSpan {
        display: block;
        box-sizing: border-box;
        font-size: 16px;
        font-family: "Frutiger 57 Light Condensed",sans-serif;
        line-height: 17px;
        padding-top: 8px;
    }

    .herospaceTextSecondSpan {
        font-size: 18px;
        line-height: 28px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
        color: white;
        padding-top: 20px;
        display: block;
    }

    /* breadcrumbs */
    #breadcrumbs {
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        z-index: 150;
        font-size: 15px;
        font-family: "Frutiger 47 Condensed", sans-serif;
    }

    #breadcrumbsInner {
        width: 300px;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        padding-left: 100px;
        position: absolute;
    }

    .breadcrumb, .breadcrumb-separator {
        display: inline-block;
        color: #B2B2B2;
        margin: 0 2px;
        text-decoration: none;
    }

    .breadcrumb {
        font-size:10px;
    }

    .breadcrumb:hover{
        color: #31417a;
        text-decoration: none;
    }

    .breadcrumb:focus{
        color: #31417a;
        text-decoration: none;
    }

}