@media screen and (min-width: 1400px){
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 150px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
        height: 440px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
        height: 505px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 620px!important;
        height: 46px!important;
        margin: 0 auto!important;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: block;
        width: 100%;
        height: 30px;
        text-align: left;
        margin-right: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button{
        display: inline-block;
        width: 150px;
        height: 30px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        border-style: none;
        margin-top: 6px;
        padding-top: 10px;
        cursor: pointer;
    }

    .newsletter-button{
        display: inline-block;
        width: 150px;
        height: 35px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        border-style: none;
        margin-top: 15px;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        /*width: 570px;*/
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        float: left;
    }

    .policy-label{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: black;
        line-height: 18px;
        text-align: left;
        padding-bottom: 10px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        position: relative;
        padding-top: 10px;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 16px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content {
        height: 475px;
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
        height: 425px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 620px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 400px;
        height: 30px;
        text-align: left;
        margin-right: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button,
    .newsletter-button{
        display: inline-block;
        width: 150px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        padding-top: 10px;
        cursor: pointer;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 435px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
        padding-top: 10px;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        float: left;
    }

    .policy-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        display: inline-block;
        width: 470px;
        text-align: left;
        margin-left: 35px;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 16px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 150px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
        height: 475px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
        height: 490px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 620px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 400px;
        height: 30px;
        text-align: left;
        margin-right: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button{
        display: inline-block;
        width: 150px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        padding-top: 10px;
        cursor: pointer;
    }

    .newsletter-button{
        display: inline-block;
        width: 150px;
        height: 35px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        border: none;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 435px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .policy-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        display: inline-block;
        width: 470px;
        text-align: left;
        margin-left: 35px;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 16px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 600px;
        height: 475px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 600px;
        height: 510px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 500px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 320px;
        height: 30px;
        text-align: left;
        margin-right: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button{
        display: inline-block;
        width: 120px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        padding-top: 10px;
        cursor: pointer;
    }

    .newsletter-button{
        display: inline-block;
        width: 120px;
        height: 35px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        border: none;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 355px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .policy-label{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        display: inline-block;
        width: 390px;
        text-align: left;
        margin-left: 35px;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 16px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 40px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 400px;
        height: 490px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 400px;
        height: 470px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 400px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 290px;
        height: 30px;
        text-align: left;
        margin-right: 10px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button{
        display: inline-block;
        width: 80px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        padding-top: 10px;
    }

    .newsletter-button{
        display: inline-block;
        width: 80px;
        height: 35px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        border: none;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 315px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .policy-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        display: inline-block;
        width: 350px;
        text-align: left;
        margin-left: 35px;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 12px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 40px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 300px;
        height: 510px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 300px;
        height: 520px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 300px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 240px;
        height: 30px;
        text-align: left;
        margin-right: 10px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button{
        display: inline-block;
        width: 80px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        padding-top: 10px;
    }

    .newsletter-button{
        display: inline-block;
        width: 80px;
        height: 35px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        border: none;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 265px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .policy-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        line-height: 16px;
        color: black;
        padding-bottom: 10px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        display: inline-block;
        width: 262px;
        text-align: left;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 12px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: 350px){
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 40px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 270px;
        height: 560px;
    }

    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 270px;
        height: 490px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 270px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 170px;
        height: 30px;
        text-align: left;
        margin-right: 10px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        padding-left: 10px;
        background-color: #F4F5F8;
        margin-bottom: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button{
        display: inline-block;
        width: 70px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: white;
        padding-top: 10px;
    }

    .newsletter-button{
        display: inline-block;
        width: 70px;
        height: 35px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 14px;
        color: white;
        border: none;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 195px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
        text-align: left;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .policy-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        color: black;
        padding-bottom: 10px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .policy-container{
        display: inline-block;
        width: 197px;
        text-align: left;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 10px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

/* print */
@media print {
    /* The Modal (background) */
    #pdf-download-modal,
    #newsletter-modal{
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(49,65,122); /* Fallback color */
        background-color: rgba(49,65,122,0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .pdf-download-modal-content,
    .newsletter-modal-content{
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 800px;
        height: 220px;
    }

    /* The Close Button */
    .pdf-download-close,
    .newsletter-close{
        color: #31417A;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .pdf-download-close:hover,
    .pdf-download-close:focus,
    .newsletter-close:hover,
    .newsletter-close:focus{
        color: #31417A;
        text-decoration: none;
        cursor: pointer;
    }

    /* Style of the pdf */
    .pdf-download-title,
    .newsletter-title{
        color: #31417A;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
        font-size: 30px;
        text-align: center;
        margin-top: 40px;
    }

    .pdf-download-subtitle {
        color: #666666;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .pdf-download-input-container,
    .newsletter-input-container{
        text-align: center;
        width: 620px;
        height: 46px;
        margin: 0 auto;
    }

    .pdf-download-input-field,
    .newsletter-input-field{
        display: inline-block;
        width: 400px;
        height: 30px;
        text-align: left;
        margin-right: 20px;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        padding-left: 10px;
        border: 1px solid #6666;
    }

    .pdf-download-button,
    .newsletter-button{
        display: inline-block;
        width: 150px;
        height: 25px;
        text-align: center;
        background: #79A342;
        vertical-align: top;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: white;
        padding-top: 10px;
    }

    .pdf-download-invalid-email,
    .pdf-download-invalid-first-name,
    .pdf-download-invalid-last-name,
    .pdf-download-invalid-policy {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .pdf-download-error {
        width: 570px;
        color: #D84826;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 12px;
        margin: -5px auto 0;
        display: none;
    }

    .policy-label {
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: black;
        padding-bottom: 16px;
    }

    .container-radio-buttons-contact-form {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        cursor: pointer;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        text-align: left;
    }

    .container-radio-buttons-contact-form input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .container-radio-buttons-contact-form:hover input ~ .checkmark-radio-button-contact-form {
        background-color: #ccc;
    }

    .container-radio-buttons-contact-form input:checked ~ .checkmark-radio-button-contact-form {
        background-color: #31417A;
    }

    .pdf-popup-download-link {
        color: #79a342;
        font-size: 16px;
        text-decoration: none;
        font-family: "Helvetica Regular",sans-serif;
    }

    .pdf-popup-download-link:hover {
        text-decoration: underline;
    }
}

#pdf-download-modal{
    display: none;
}

.newsletter-input-field-honey {
    display: none;
}