@media screen and (min-width: 1400px){
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }
    .topic-container {
        width: 1300px;
        margin: 30px auto 55px;
    }

    .topic-container h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }

    .topic-description {
        width: 90%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }

    .topic-item {
        display: inline-block;
        margin-right: 107px;
        border-bottom: 2px dotted gray;
        margin-top: 20px;
        overflow: hidden;
    }

    .topic-item:nth-child(3n+3) {
        margin-right: 0px;
    }

    .topic-item h2 {
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }

    .topic-item .topic-image {
        width: 359px;
        height: 92px;
        overflow: hidden;
    }

    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }

    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }

    .topic-item a {
        text-decoration: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }

    .topic-container {
        width: 1100px;
        margin: 30px auto 55px;
    }

    .topic-container h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }

    .topic-description {
        width: 90%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }

    .topic-item {
        display: inline-block;
        margin-right: 21px;
        border-bottom: 2px dotted gray;
        margin-top: 20px;
        overflow: hidden;
    }

    .topic-item:nth-child(3n+3) {
        margin-right: 0px;
    }

    .topic-item h2 {
        color: #31417a;
        font-size: 21px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }

    .topic-item .topic-image {
        width: 350px;
        height: 92px;
        overflow: hidden;
    }

    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }

    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }

    .topic-item a {
        text-decoration: none;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }

    .topic-container {
        width: 900px;
        margin: 30px auto 55px;
    }
    .topic-container h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }
    .topic-description {
        width: 90%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }
    .topic-item {
        display: inline-block;
        margin-right: 65px;
        border-bottom: 2px dotted gray;
        margin-top: 30px;
        overflow: hidden;
    }
    .topic-item:nth-child(2n+2) {
        margin-right: 0;
    }
    .topic-item h2 {
        color: #31417a;
        font-size: 21px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin: 12px 0;
        line-height: 15px;
    }
    .topic-item .topic-image {
        width: 413px;
        height: 106px;
        overflow: hidden;
    }
    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }
    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }
    .topic-item a {
        text-decoration: none;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }

    .topic-container {
        width: 720px;
        margin: 75px auto 55px;
    }
    .topic-container h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }
    .topic-description {
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }
    .topic-item {
        display: inline-block;
        margin-right: 15px;
        border-bottom: 2px dotted gray;
        margin-top: 30px;
        overflow: hidden;
    }
    .topic-item:nth-child(2n+2) {
        margin-right: 0;
    }
    .topic-item h2 {
        color: #31417a;
        font-size: 21px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin: 12px 0;
        line-height: 15px;
    }
    .topic-item .topic-image {
        width: 350px;
        height: 94px;
        overflow: hidden;
    }
    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }
    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }
    .topic-item a {
        text-decoration: none;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }

    .topic-container {
        width: 470px;
        margin: 75px auto 55px;
    }
    .topic-container h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }
    .topic-description {
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }
    .topic-item {
        display: inline-block;
        margin-right: 10px;
        border-bottom: 2px dotted gray;
        margin-top: 28px;
        overflow: hidden;
        width: 210px;
    }
    .topic-item:nth-child(2n+2) {
        margin-right: 0;
    }
    .topic-item h2 {
        color: #31417a;
        font-size: 16px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin: 12px 0;
        line-height: 15px;
    }
    .topic-item .topic-image {
        width: 210px;
        height: 54px;
        overflow: hidden;
    }
    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }
    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }
    .topic-item a {
        text-decoration: none;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }

    .topic-container {
        width: 340px;
        margin: 75px auto 55px;
    }
    .topic-container h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }
    .topic-description {
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }
    .topic-list {
        text-align: left;
    }
    .topic-item {
        display: inline-block;
        border-bottom: 2px dotted gray;
        margin-top: 30px;
        overflow: hidden;
        text-align: left;
        width: 162px;
        vertical-align: top;
    }
    .topic-item:nth-child(2n+2) {
        margin-left: 10px;
    }
    .topic-item h2 {
        display: block;
        line-height: 19px;
        color: #31417a;
        font-size: 18px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin: 8px 0;
    }
    .topic-item .topic-image {
        width: 162px;
        height: 42px;
        overflow: hidden;
    }
    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }
    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }
    .topic-item a {
        text-decoration: none;
    }
}

@media screen and (max-width: 350px){
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }


    .topic-container {
        width: 300px;
        margin: 75px auto 55px;
    }
    .topic-container h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }
    .topic-description {
        width: 100%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 1.6em;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }
    .topic-list {
        text-align: center;
    }
    .topic-item {
        display: inline-block;
        border-bottom: 2px dotted gray;
        margin-top: 30px;
        overflow: hidden;
        text-align: left;
        width: 300px;
    }
    .topic-item:nth-child(2n+2) {
        margin-right: 0;
    }
    .topic-item h2 {
        color: #31417a;
        font-size: 20px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin: 12px 0;
        line-height: 15px;
    }
    .topic-item .topic-image {
        width: 300px;
        height: 106px;
        overflow: hidden;
    }
    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }
    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }
    .topic-item a {
        text-decoration: none;
    }
}

@media print {
    .topic-seminar-background-image-home-page {
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .topic-seminar-background-image-home-page:hover {
        transform: scale(1.2);
    }
    .topic-container {
        width: 1300px;
        margin: 30px auto 55px;
    }

    .topic-container h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #31417a;
        font-family: "Frutiger 57 Condensed", sans-serif;
        line-height: 32px;
    }

    .topic-description {
        width: 90%;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #666666;
        margin: 0 auto 20px;
    }

    .topic-item {
        display: inline-block;
        margin-right: 107px;
        border-bottom: 2px dotted gray;
        margin-top: 20px;
        overflow: hidden;
    }

    .topic-item:nth-child(3n+3) {
        margin-right: 0px;
    }

    .topic-item h2 {
        color: #31417a;
        font-size: 22px;
        font-family: "Frutiger 67 Condensed", sans-serif;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }

    .topic-item .topic-image {
        width: 359px;
        height: 92px;
        overflow: hidden;
    }

    .topic-item .topic-image img {
        -webkit-transition: 0.2s ;
        -moz-transition: 0.2s ;
        -ms-transition: 0.2s ;
        -o-transition: 0.2s ;
        transition: 0.2s ;
        width: 100%;
        height: auto;
    }

    .topic-item:hover .topic-image img {
        cursor: pointer;
        -webkit-transition: 0.5s ;
        -moz-transition: 0.5s ;
        -ms-transition: 0.5s ;
        -o-transition: 0.5s ;
        transition: 0.5s ;
        transform: scale(1.2);
    }

    .topic-item a {
        text-decoration: none;
    }
}