/* Add media queries for desktop */
@media screen and (min-width: 1400px){
    .cookie-bar {
        position: fixed;
        top: 0;
        background-color: #333;
        padding: 22px 0 17px;
        display: flex;
        justify-content: center;
    }

    .cookie-bar .fs-grid {
        width: 1300px;
        margin: 0 15px;
    }

    .cookie-bar .fs-row {
        display: block;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){

}

@media screen and (min-width: 766px) and (max-width: 1022px) {

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

}

@media screen and (min-width: 350px) and (max-width: 478px) {

}

@media screen and (max-width: 350px){

}

@media print {

}

/* The Modal (background) */
.cookie-bar-modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 200px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.cookie-bar-modal .cookie-bar-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.cookie-bar-modal .modal-cookie-title {
    color:#666666;
    margin-bottom: 0px;
}

.cookie-bar-modal p {
    margin-top: 0px !important;
}
