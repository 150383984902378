/*
* 3 articles
*/
@media screen and (min-width: 1400px){
    .three-articles{
        height: 420px;
    }
    .three-articles .squareBlock{
        position: absolute;
        width: 420px;
        height: 420px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .three-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .three-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .three-articles #financialMetropolisesBlocks_3{
        top: 0;
        left: 440px;
    }
    .three-articles .financialMetropolisesBlock_inner{
        position: absolute;
        top: 200px;
        color: #fff;
        padding: 50px;
        height: calc(100% - 100px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .three-articles .squareBlock .financialMetropolisesBlock_innerText{
        max-height: 90%;
        overflow-y: hidden;
    }
    .three-articles .financialMetropolisesBlock_inner h4{
        font-size: 30px;
        text-align: left;
        line-height: 35px;
    }
    .three-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 20px 0 0;
        font-size: 16px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 22px;
        text-align: left;
        color: #fff;
    }
    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        /*bottom: 50px;*/
        margin-top: 10px;
        left: 50px;
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }

    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* two square block - hover animation */
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .three-articles{
        height: 350px;
    }
    .three-articles .squareBlock{
        position: absolute;
        width: 350px;
        height: 350px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .three-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .three-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .three-articles #financialMetropolisesBlocks_3{
        top: 0;
        left: 370px;
    }
    .three-articles .financialMetropolisesBlock_inner{
        position: absolute;
        top: 155px;
        color: #fff;
        padding: 40px;
        height: calc(100% - 80px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .three-articles .squareBlock .financialMetropolisesBlock_innerText{
        max-height: 80%;
        overflow-y: hidden;
    }
    .three-articles .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .three-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 5px 0 0;
        font-size: 15px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 18px;
        text-align: left;
        color: #fff;
    }
    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        margin-top: 10px;
        left: 40px;
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }

    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* two square block - hover animation */
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px){
    .three-articles{
        height: 290px;
    }
    .three-articles .squareBlock{
        position: absolute;
        width: 290px;
        height: 290px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .three-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .three-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .three-articles #financialMetropolisesBlocks_3{
        top: 0;
        left: 305px;
    }
    .three-articles .financialMetropolisesBlock_inner{
        position: absolute;
        top: 115px;
        color: #fff;
        padding: 25px;
        height: calc(100% - 50px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .three-articles .squareBlock .financialMetropolisesBlock_innerText{
        max-height: 80%;
        overflow-y: hidden;
    }
    .three-articles .financialMetropolisesBlock_inner h4{
        font-size: 28px;
        text-align: left;
        line-height: 32px;
    }
    .three-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 5px 0 0;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 17px;
        text-align: left;
        color: #fff;
    }
    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        /*bottom: 25px;*/
        margin-top: 10px;
        left: 25px;
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }

    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* two square block - hover animation */
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px){
    .three-articles{
        height: 244px;
    }
    .three-articles .squareBlock{
        position: absolute;
        width: 232px;
        height: 244px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .three-articles #financialMetropolisesBlocks_1{
        top: 0;
        left: 0;
    }
    .three-articles #financialMetropolisesBlocks_2{
        top: 0;
        right: 0;
    }
    .three-articles #financialMetropolisesBlocks_3{
        top: 0;
        left: 244px;
    }
    .three-articles .financialMetropolisesBlock_inner{
        position: absolute;
        top: 100px;
        color: #fff;
        padding: 20px;
        height: calc(100% - 40px);
        background-color: rgba(49, 65, 122, .65);
        transition: .4s;
        overflow: hidden;
    }
    .three-articles .squareBlock .financialMetropolisesBlock_innerText{
        max-height: 80%;
        overflow-y: hidden;
    }
    .three-articles .financialMetropolisesBlock_inner h4{
        font-size: 25px;
        text-align: left;
        line-height: 30px;
    }
    .three-articles .financialMetropolisesBlock_inner p{
        opacity: 0;
        margin: 5px 0 0;
        font-size: 14px;
        font-family: "Helvetica Regular", sans-serif;
        line-height: 17px;
        text-align: left;
        color: #fff;
    }
    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton{
        position: absolute;
        margin-top: 10px;
        left: 20px;
        max-width: 180px;
        padding: 15px 12px;
        background-color: #79A342;
        text-align: center;
        text-decoration: none;
        font-size: 17px;
        color: #fff;
        font-family: "Helvetica Regular", sans-serif;
        transition: 0.2s;
        opacity: 0;
    }

    .three-articles .financialMetropolisesBlock_inner .financialMetropolisesBlock_innerButton:hover{
        background-color: #31417a;
        transition: 0.2s;
    }
    /* two square block - hover animation */
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner{
        top: 0;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_inner p{
        opacity: 1;
        transition: 0.4s;
    }
    .three-articles .squareBlock:hover .financialMetropolisesBlock_innerButton{
        opacity: 1;
        transition: 0.5s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px){

}

@media screen and (min-width: 350px) and (max-width: 478px){

}

@media screen and (max-width: 350px){

}