@media screen and (min-width: 1400px) {
    #speakers{
        width: 1300px;
        margin: 50px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #speakersList .speaker{
        width: 370px;
        border-bottom: 2px dotted #9f9f9f;
        margin: 50px 0;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #speakers{
        width: 1100px;
        margin: 50px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #speakersList .speaker{
        width: 370px;
        border-bottom: 2px dotted #9f9f9f;
        margin: 50px 0;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #speakers{
        width: 900px;
        margin: 50px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #speakersList .speaker{
        width: 370px;
        border-bottom: 2px dotted #9f9f9f;
        margin: 50px 0;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #speakers{
        width: 720px;
        margin: 50px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #speakersList .speaker{
        width: 370px;
        border-bottom: 2px dotted #9f9f9f;
        margin: 50px 0;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #speakers{
        width: 470px;
        margin: 50px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{

    }
    #speakersList .speaker{
        width: 370px;
        border-bottom: 2px dotted #9f9f9f;
        margin: 50px auto;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #speakers{
        width: 340px;
        margin: 50px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{

    }
    #speakersList .speaker{
        width: 340px;
        border-bottom: 2px dotted #9f9f9f;
        margin: 50px auto;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}

@media screen and (max-width: 350px){
    #speakers{
        width: 280px;
        margin: 30px auto;
    }
    #speakers h2{
        font-size: 30px;
        font-family: "Frutiger 57 Condensed", sans-serif;
        color: #31417a;
    }
    #speakersList{

    }
    #speakersList .speaker{
        width: 100%;
        border-bottom: 2px dotted #9f9f9f;
        margin: 30px auto;
    }
    #speakersList .speaker h3{
        font-family: "Frutiger 67 Condensed", sans-serif;
        font-size: 22px;
        color: #31417a;
    }
    #speakersList .speakerTitle{
        font-family: "Frutiger 57 Condensed", sans-serif;
        font-size: 22px;
        color: #666666;
        margin: 26px 0 12px;
        line-height: 30px;
        min-height: 62px;
    }
    #speakersList .speaker a{
        display: block;
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
    }
    #speakersList .speaker i{
        color: #b2b2b2;
        font-size: 12px;
        margin-left: 6px;
        transition: 0.1s;
    }
    #speakersList .speaker a:hover i{
        margin-left: 10px;
        transition: 0.2s;
    }

    #speakersSeeAll{
        width: 100px;
        margin: 20px auto 0;
        text-align: center;
        height: 50px;
    }
    #speakersSeeAll p{
        font-family: "Helvetica Regular", sans-serif;
        font-size: 16px;
        color: #79a342;
        font-weight: bold;
    }
    #speakersSeeAll i{
        font-size: 24px;
        color: #79a342;
    }
    #speakersSeeAll:hover{
        cursor: pointer;
    }
    #speakersSeeAll:hover i{
        margin-top: 4px;
        transition: 0.2s;
    }
}