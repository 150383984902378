@media screen and (min-width: 1400px) {
    /* slider - block */
    #homePageSlider {
        height: calc(80vh - 90px);
        position: relative;
        margin-top: 106px;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        /*margin-top: 105px;*/
        height: calc(100% - 150px);
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        position: absolute;
        bottom: 20px;
        width: 100%;
        margin: 0 auto;
        text-align:center;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed",sans-serif;
    }
    #sliderContainer h1 .slideText {
        font-weight: normal;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
    }


    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    /*cta Buttons*/
    .ctaButtonMobile {
        display:none;
    }
    .ctaButton {
        color: #fff;
        background-color: #79a342;
        text-decoration: none;
        cursor: pointer;
        padding: 14px 0;
        width: 160px;
        font-size: 20px;
        font-family: "Helvetica Regular",sans-serif;
        border: 1px solid #79a342;
        outline: 0;
        display: block;
        line-height: 1;
        margin: 24px auto 24px;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    /* slider - block */
    #homePageSlider {
        height: calc(80vh - 90px);
        position: relative;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        margin-top: 106px;
        height: calc(100% - 150px);
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        position: absolute;
        bottom: 20px;
        width: 100%;
        margin: 0 auto;
        text-align:center;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 40px;
        padding-top: 20%;
        line-height: 44px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
    }
    #sliderContainer h1 span {
        font-weight: 700;
        letter-spacing: 1px;
    }
    #sliderContainer h1 .slideText {
        font-weight: normal;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    /*cta Buttons*/
    .ctaButtonMobile {
        display:none;
    }
    .ctaButton {
        color: #fff;
        background-color: #79a342;
        text-decoration: none;
        cursor: pointer;
        padding: 14px 0;
        width: 160px;
        font-size: 20px;
        font-family: "Helvetica Regular",sans-serif;
        border: 1px solid #79a342;
        outline: 0;
        display: block;
        margin: 24px auto 24px;
        line-height: 1;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    /* slider - block */
    #homePageSlider {
        height: 600px;
        position: relative;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        margin-top: 105px;
        height: calc(100% - 150px);
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        position: absolute;
        bottom: 20px;
        width: 100%;
        margin: 0 auto;
        text-align:center;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 30px;
        padding-top: 20%;
        line-height: 44px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
    }
    #sliderContainer h1 span {
        font-weight: 700;
        letter-spacing: 1px;
    }
    #sliderContainer h1 .slideText {
        font-weight: normal;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    /*cta Buttons*/
    .ctaButtonMobile {
        display:none;
    }
    .ctaButton {
        color: #fff;
        background-color: #79a342;
        text-decoration: none;
        cursor: pointer;
        padding: 14px 0;
        width: 160px;
        font-size: 20px;
        font-family: "Helvetica Regular",sans-serif;
        border: 1px solid #79a342;
        outline: 0;
        display: block;
        line-height:1;
        margin: 24px auto 24px;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .owl-nav {
        display: none;
    }
    /* slider - block */
    #homePageSlider {
        height: 750px;
        position: relative;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 399px;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        height: 400px;
        margin-top: 102px;
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        position: absolute;
        bottom: 20px;
        width: 100%;
        margin: 0 auto;
        text-align:center;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    #sliderContainer h1>span {
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom:10px;
        font-size:30px;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1>.slideText {
        font-size: 20px;
        line-height: 28px;
        padding: 0 10px;
        font-weight:normal;
    }

    #sliderContainer h1 {
        display: block;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        color:#FFF;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 10px;

    }
    #sliderContainer h1 .slideText {
        display: block;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        padding: 0 10px;
        color:#FFF;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
        font-weight:normal;
    }
    #sliderOuterTexts li .slideText {
        font-size:20px;
        line-height: 28px;
        padding: 0 10px;
    }
    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 2px;
        background-color: #31417a;
        position: absolute;
        bottom: -50px;
    }
    /*cta Buttons*/
    .ctaButton{
        display:none;
    }
    .ctaButtonMobile {
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        display: block;
        width: 330px;
        text-align: center;
        margin: 24px auto 24px;
        transition: .2s;
        line-height:1;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .owl-nav {
        display: none;
    }
    /* slider - block */
    #homePageSlider {
        height: 680px;
        position: relative;
        min-height: 680px;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 299px;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        height: 300px;
        margin-top: 92px;
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 100%;
        position: absolute;
        bottom: 8px;
        text-align: center;
        margin: 0 auto;
    }
    #sliderContainer .owl-dot {
        width: 8px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 8px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        position: absolute;
        top: 102px;
        left: 0;
        width: 100%;
        z-index: 500;
        display:none;
    }
    #sliderContainer h1 {
        display: block;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color:#FFF;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 10px;

    }
    #sliderContainer h1 .slideText {
        display: block;
        text-align: center;
        font-size:18px;
        line-height: 26px;
        padding: 0 10px;
        color:#FFF;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
        font-weight:normal;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: -30px;
        right: 0;
    }
    /*cta Buttons*/
    .ctaButton {
        display:none;
    }

    .ctaButtonMobile {
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        display: block;
        width: 330px;
        text-align: center;
        margin: 18px auto 18px;
        line-height:1;
        transition: .2s;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .owl-nav {
        display: none;
    }
    /* slider - block */
    #homePageSlider {
        height: 520px;
        position: relative;
        min-height: 520px;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 220px;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        height: 220px;
        margin-top: 62px;
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 100%;
        position: absolute;
        bottom: 8px;
        text-align: center;
        margin: 0 auto;
    }
    #sliderContainer .owl-dot {
        width: 8px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 8px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 500;
        display:none;
    }
    #sliderContainer h1 {
        display: block;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color:#FFF;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
        font-weight: normal;
        letter-spacing: 1px;
        margin-bottom: 10px;

    }
    #sliderContainer h1 .slideText {
        display: block;
        text-align: center;
        font-size:12px;
        line-height: 20px;
        padding: 0 10px;
        font-weight: normal;
        color:#FFF;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: -48px;
        right: 0;
    }
    /*cta Buttons*/
    .ctaButton {
        display:none;
    }

    .ctaButtonMobile {
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        display: block;
        width: 320px;
        text-align: center;
        margin: 6px auto;
        line-height:1;
        transition: .2s;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .owl-nav {
        display: none;
    }
    /* slider - block */
    #homePageSlider {
        height: 520px;
        position: relative;
        min-height: 520px;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 219px;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        height: 220px;
        margin-top: 62px;
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 100%;
        position: absolute;
        bottom: 8px;
        text-align: center;
        margin: 0 auto;
    }
    #sliderContainer .owl-dot {
        width: 8px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 8px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 500;
    }
    #sliderOuterTexts li{
        display: none;
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
        z-index: 200;
    }
    #sliderOuterTexts li:first-of-type {
        display: block;
    }
    #sliderOuterTexts li span {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 18px;
        display:block;
        margin-bottom:4px;
    }
    #sliderOuterTexts li .slideText {
        font-size:12px;
        line-height: 18px;
        padding: 0 10px;
    }
    #sliderOuterTexts li .slideText {
        font-size:12px;
        line-height: 20px;
    }
    #sliderContainer h1 {
        display: none;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: -48px;
        right: 0;
    }
    /*cta Buttons*/
    .ctaButton {
        display:none;
    }

    .ctaButtonMobile {
        text-decoration: none;
        padding: 10px 0;
        font-size: 18px;
        color: #fff;
        font-family: "Helvetica Regular",sans-serif;
        background-color: #79a342;
        display: block;
        width: 300px;
        text-align: center;
        margin: 6px auto;
        line-height:1;
        transition: .2s;
    }

    /* overlaying anchor */
    .executive-linked-area {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor:pointer;
        width: 100%;
    }
}

@media print {
    /* slider - block */
    #homePageSlider {
        height: calc(80vh - 90px);
        position: relative;
        margin-top: 106px;
    }
    #homePageSlider img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 100;
    }

    /* slider - images */
    #sliderContainer {
        /*margin-top: 105px;*/
        height: calc(100% - 150px);
    }
    #sliderContainer .owl-stage-outer {
        height: 100%;
    }
    .owl-stage {
        height: 100%;
    }
    #sliderContainer .owl-item {
        height: 100%;
    }
    #sliderContainer .singleSlide {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed",sans-serif;
    }


    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: 100%;
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}