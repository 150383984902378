@media screen and (min-width: 1400px) { /*1300*/

    .exec-content-page #searchField {
        padding:0px;
        color:black;
    }
    /*Section Styles Start*/
    .exec-content-page > .content-section > .fs-grid{
        max-width: 1300px ;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        padding: 0;
        width: 840px;
        left: 0;
    }
    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }


    /*Table styles for the Section component tables End*/
    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 207px;
    }


    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 0;
        padding-right:0;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) { /*1100*/
    /*Search Styles Start*/
    .exec-content-page #searchField {
        padding:0px;
        color:black;
    }
    /*Search Styles End*/

    /*Section Styles Start*/
    .exec-content-page > .content-section > .fs-grid {
        max-width: 1100px;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        left:0px;
        padding:0px;
        width: 840px;
    }
    /*Section Styles End*/

    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles for the Section component tables End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 207px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 0;
        padding-right:0;
    }
}
/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/
    /*Search Styles Start*/

    .exec-content-page #searchField {
        padding:0px;
        color:black;
    }

    /*Search Styles End*/

    /*Section Styles Start*/

    .exec-content-page > .content-section > .fs-grid {
        max-width: 900px;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        left:0px;
        padding:0px;
        width:600px;
    }

    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles for the Section component tables End*/

    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 207px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 0;
        padding-right:0;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    /*Section Styles Start*/

    .exec-content-page > .content-section {
        margin-top: 30px;
    }

    .exec-content-page > .content-section > .fs-grid {
        max-width: 720px;
        min-width:0px;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        left: 0px;
        padding: 0;
        width: 100%;
    }

    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles for the Section component tables End*/

    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 147px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 0;
        padding-right:0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/
    /*Section Styles Start*/
    .exec-content-page > .content-section {
        margin-top: 30px;
    }

    .exec-content-page > .content-section > .fs-grid {
        max-width: 440px;
        min-width:0px;
        margin:0 auto;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        left:0px;
        padding:0px;
        width:100%;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col {
        width:48%;
        padding: 0;

    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col:nth-child(even) {
        margin-left:10px;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row {
        margin-left:0;
        margin-right:0;
    }

    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles for the Section component tables End*/

    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 127px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
        padding-right:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 10px;
        padding-left:0;
        padding-right:0;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area > .two-column-content {
        margin-right: 0;
        margin-left:0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    /*Section Styles Start*/
    .exec-content-page > .content-section {
        margin-top: 30px;
    }
    .exec-content-page > .content-section > .fs-grid{
        max-width: 340px;
        min-width:0px;
        margin: 0 auto;
        padding:0;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        padding: 0;
        left: 0;
        width: 100%;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col {
        width:48% !important;
        padding: 0 !important;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col .text  {
        font-size: 12px;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col:nth-child(even) {
        margin-left: 10px;
    }
    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-size: 10px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
        font-size: 10px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td p{
        font-size: 10px;
        margin-top:0;
    }
    /*Table styles for the Section component tables End*/

    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 127px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
        padding-right:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 10px;
        padding-left:0;
        padding-right:0;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area > .two-column-content {
        margin-right: 0;
        margin-left:0;
    }
}

@media screen and (max-width: 350px){/*300*/
    /*Section Styles Start*/
    .exec-content-page .fs-row .two-column-content {
        margin: 0;
    }
    .exec-content-page .fs-row .link-content {
        margin: 0;
    }
    .exec-content-page > .content-section {
        margin-top: 30px;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col {
        width:48% !important;
        padding: 0 !important;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col .text  {
        font-size: 12px;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area .two-column-content .col:nth-child(even) {
        margin-left: 10px;
    }

    .exec-content-page > .content-section > .fs-grid {
        max-width: 300px;
        min-width:0px;
        padding:0;
        margin: 0 auto;
    }

    .exec-content-page > .content-section > .fs-grid > .fs-row> .content-area {
        position: relative;
        padding: 0;
        left: 0;
        width: 100%;
    }
    .exec-content-page .link-content > .content {
        padding:0px;
    }
    .exec-content-page .link-content{
        margin:0;
    }

    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-size: 10px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
        font-size: 10px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td p{
        font-size: 10px;
        margin-top:0;
    }
    /*Table styles for the Section component tables End*/

    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
        min-height: 127px;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
        padding-right:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 10px;
        padding-left:0;
        padding-right:0;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area > .two-column-content {
        margin-right: 0;
        margin-left:0;
    }
}

@media print {
    /*Search Styles Start*/
    .exec-content-page #header .menu-item-both-sites-border {
        box-sizing: content-box;
    }
    .exec-content-page #header .menu-item-border-right {
        box-sizing: content-box;
    }
    .exec-content-page #searchField{
        width:480px;
        padding:0px;
    }

    /*Search Styles End*/

    /*Section Styles Start*/

    .exec-content-page > .content-section > .fs-grid {
        max-width: 1300px;
    }
    .exec-content-page > .content-section > .fs-grid > .fs-row > .content-area {
        position: relative;
        left:110px;
        padding:0px;
    }

    /*Table styles for the Section component tables Start*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table  {
        table-layout: auto;
        font-family: "Frutiger 47 Condensed",  sans-serif;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table thead th {
        text-align: center;
        border: 1px solid;
        vertical-align: middle;
        padding: 0px 5px;
        font-weight: 600;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .text table tbody tr td {
        padding: 5px;
        border: 1px solid;
        vertical-align: top;
        word-break: break-word;
        text-align: left;
    }
    /*Table styles for the Section component tables End*/

    /*Section Styles End*/
    .exec-content-page .content-section .fs-grid .fs-row .content-area .news-list .news .news-item {
        margin: 0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .left {
        margin-top: 0;
        padding-left:0;
    }
    .exec-content-page .content-section .fs-grid .fs-row .content-area .image-combination .right {
        margin-top: 10px;
        padding-right:0;
    }
}

/*util class to hide overflow-x on page*/
.exec-content-page {
    overflow-x:hidden;
}
.exec-content-page > .content-section:nth-of-type(2n) {
    background:#f4f5f8;
}
/*reset margins on grid and row els*/
.exec-content-page .fs-grid {
    margin:0px;
    padding:0px;
}
.exec-content-page .fs-row {
    margin:0px;
}
/*util ends*/